import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInfo({
      ...formInfo,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { email, password } = formInfo;
  
    const requestBody = {
      email: email,
      password: password,
    };
  
    try {
      const response = await fetch(
        "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      const responseData = await response.json();
  
      if (response.ok) {
        console.log("hey ami login data", responseData);
        secureLocalStorage.setItem("user-info", responseData);
        secureLocalStorage.setItem("token", responseData.data.token);
  
       
        Swal.fire("Success", responseData.message, "success").then(() => {
          navigate('/quickDashboard');
        });
      } else {
        Swal.fire("Error", responseData.message, "error");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Error", "An error occurred. Please try again later.", "error");
    }
  
    setFormInfo({
      email: "",
      password: "",
    });
  };
  return (
    <Box sx={{ backgroundColor: "var(--primary-color)", height: "100vh" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", 
            alignItems: "center", 
            height: "100vh",
          }}
        >
          <Box
            sx={{
              background: "#ffff",
              width: "450px",
              px: "20px",
              borderRadius: "5px",
              py: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  textAlign: "center",
               
                }}
              >
                Welcome To
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  textAlign: "center",
                  pb: "20px",
                }}
              >
                Quick Tickets Admin Login
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    borderBottom: "1px solid #B6B6CC",
                    display: "flex",
                  }}
                >
                  <Box sx={{ py: "20px", width: "200px" }}>
                    <label
                      style={{
                        color: "var( --profile-boday-color)",
                        fontSize: "18px",
                      }}
                    >
                      Email
                    </label>
                  </Box>

                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={formInfo.email}
                    onChange={handleChange}
                    style={{
                      border: "none",
                      color: "#B6B6CC",
                      outline: "none",
                      background: "none",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #B6B6CC",
                    display: "flex",
                  }}
                >
                  <Box sx={{ py: "20px", width: "200px" }}>
                    <label
                      style={{
                        color: "var( --profile-boday-color)",
                        fontSize: "18px",
                      }}
                    >
                      Password
                    </label>
                  </Box>

                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter Your Password"
                    value={formInfo.password}
                    onChange={handleChange}
                    style={{
                      border: "none",
                      color: "#B6B6CC",
                      outline: "none",
                      background: "none",
                    }}
                  />
                </Box>

                <Box sx={{ py: "10px" }}>
                  <Button
                    type="submit"
                    sx={{
                      borderRadius: "6px",
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      width: "100%",
                      height: "45px",
                      textTransform: "capitalize",
                      fontSize: "15px",
                      mt: "5px",
                      fontStyle: "italic",
                      ":hover": {
                        backgroundColor: "var(--trips-secondary-color)",
                      },
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Container>

    </Box>
  );
};

export default AdminLogin;
