import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Loader from "../Shared/Loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

const styles = {
  root: {
    height: "calc(100vh - 200px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const AllVisa = () => {
  const token = secureLocalStorage.getItem("token");
  const [allVisa, setAllVisa] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/get-visa-info`;

      try {
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setAllVisa(response.data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Loader />
      </Box>
    );
  }

  console.log("all visa", allVisa);

  const handleUpdateVisa = (id) => {
    console.log(id);
    secureLocalStorage.setItem("visaId", id);
    navigate("/quickDashboard/visaManage/countryinfo", {
      state: { id: id },
    });
  };

  const handleAddVisa = () => {
    navigate("/quickDashboard/visaManage/countryinfo", {
      state:{type:"add"}
    });
  };

  //  MUI table column

  const columns = [
    {
      field: "bookingId",
      headerName: "Visa Id",
      width: 200,
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "var(--primary-color)",
              cursor: "pointer",
              border: "none",
              cursor: "pointer",
              padding: "5px 15px",
              width: "80%",
              color: "#00000",
              fontSize: "12px",
            }}
          >
            {params?.row?.visaId ?? "Visa Id"}
          </button>
        );
      },
    },

    {
      field: "countryName",
      headerName: "Country Name",
      suppressSizeToFit: true,
      width: 250,
    },
    {
      field: "countryCapital",
      headerName: "City Name",
      suppressSizeToFit: true,
      width: 250,
    },
    {
      field: "currency",
      headerName: "Currency",
      suppressSizeToFit: true,
      width: 200,
    },

    {
      field: "createdAt",
      headerName: "Created At",
      suppressSizeToFit: true,
      width: 250,
      renderCell: (params) => {
        return <>{moment(params?.createdAt).format("MMM Do YY, h:mm a")}</>;
      },
    },
    {
      field: "createdAtghh",
      headerName: "Actions",
      suppressSizeToFit: true,
      width: 300,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <EditIcon
              onClick={() => handleUpdateVisa(params?.row?.id)}
              sx={{ color: "green", cursor: "pointer" }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <DeleteIcon
              // onClick={() => handleDelete(blog?.id)}
              sx={{ color: "red", cursor: "pointer" }}
            />
          </>
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        m: "0 auto",
        width: {
          sx: "100%",
          md: "95%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: "50px",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "26px",

              color: "var(--trips-secondary-color)",
            }}
          >
            All Visa
          </Typography>
        </Box>

        <Box>
          {/* <Link to="/quickDashboard/visaManage/countryinfo"> */}
          <Button
            onClick={() => {
              secureLocalStorage.removeItem("visaId");
              handleAddVisa();
            }}
            sx={{
              bgcolor: "var(--active-bg-color)",
              color: "white",
              textTransform: "capitalize",
              ":hover": {
                bgcolor: "var(--active-bg-color)",
                color: "white",
              },
            }}
          >
            Add Visa
          </Button>
          {/* </Link> */}
        </Box>
      </Box>

      <Box
        style={styles.root}
        sx={{
          mt: "80px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--input-bgcolor)",
            fontSize: "12px",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderTop: "1px solid #DDDDDD",
            borderBottom: "1px solid #DDDDDD",
            color: "var(--white)",
            fontSize: "12px",
            maxHeight: "20px !important",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "var(--body-color)",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "var(--primary-color)",
            color: "var(--white)",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "var(--dasbord-sidebar--color)",
            background: "var(--primary-bonus-color)",
            fontSize: "10px",
            height: "30px",
            mb: 2,
          },
          ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
            color: "var(--white)",
          },
          ".MuiTablePagination-selectLabel": {
            color: "var(--white)",
          },
          ".MuiSelect-select": {
            color: "var(--white)",
          },
          ".css-levciy-MuiTablePagination-displayedRows": {
            color: "var(--white)",
          },
          ".MuiDataGrid-cellContent": {
            color: "var(--text-color)",
          },
        }}
      >
        <DataGrid
          rows={allVisa}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          scrollbarSize={5}
          style={styles.dataGrid}
        />
      </Box>
    </Box>
  );
};

export default AllVisa;
