import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AirplanIcon } from "../../../image/dashboardIcons/airplane.svg";
import BookingDetails from "../BookingDetails";
import ItineraryPassengerDetails from "../ItineraryPassengerDetails";
import BookingFareDetails from "../BookingFareDetails";
import secureLocalStorage from "react-secure-storage";
import VoidPassengerDetails from "./VoidPassengerDetails";
import VoidFareDetails from "./VoidFareDetails";
import Loader from "../../Shared/Loader/Loader";
import axios from "axios";
import { toastAlert } from "../../Shared/Loader/Common/functions";
import { ToastContainer } from "react-toastify";

const VoidRequest = () => {
  const token = secureLocalStorage.getItem("token");
  const location = useLocation();
  const additionalId = location?.state?.additionalId;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [queueDetails, setQueueDetails] = useState({});
  const [remarks, setRemarks] = useState("");
  const [pnr, setPnr] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/void/${additionalId}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const queData = data?.data;

        queData.bookingInfo.priceBreakDown.forEach((item) => {
          item.newServiceFee = 0;
        });

        // Adding newServiceFee property to each object in the passengers array
        queData.passengers.forEach((passenger) => {
          passenger.newServiceFee = 0; // Example value, replace with your actual value
        });

        setQueueDetails(queData);
        // console.log(queData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleVoid = async () => {
    setIsUpdating(true);
    const body = {
      status: "approved",
      passengers: queueDetails?.passengers?.map((passenger) => ({
        id: passenger.id,
        serviceFee: parseFloat(passenger.newServiceFee),
      })),
      remarks,
      pnr,
    };

    try {
      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/change_agent_void_status/${additionalId}`;

      const response = await axios.put(url, JSON.stringify(body), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = response?.data;
      setIsUpdating(false);
      toastAlert("success", data?.message);
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
      toastAlert("error", error?.message);
    }
  };

  const departureArrivalCityArray = Array.from(
    new Set(
      queueDetails?.bookingInfo?.flightDetailsAndPrices?.cityInfo?.reduce(
        (acc, flight) => {
          acc.push(flight?.departureCity, flight?.arrivalCity);
          return acc;
        },
        []
      )
    )
  );

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "var(--body-bg-color)",
            width: "100%",
            pb: "98px",
          }}
        >
          <Box
            sx={{
              borderRadius: "3px",
              width: "100%",
              p: 5,
              py: 3,
              minHeight: "calc(100vh - 196px)",
            }}
          >
            {/*---------- Page title ----------*/}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* City Title, Booking Id */}

              <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                {departureArrivalCityArray?.map((cityName, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Typography sx={{ fontSize: "35px" }}>
                        {cityName}
                      </Typography>

                      {i < departureArrivalCityArray.length - 1 && (
                        <AirplanIcon
                          style={{
                            height: "37px",
                            width: "37px",
                            fill: "var(--text-medium)",
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </Box>

              <Typography sx={{ fontSize: "28px", color: "var(--text-dark)" }}>
                {queueDetails?.bookingInfo?.bookingId}{" "}
                <span style={{ color: "var(--trips-secondary-color)" }}>
                  {queueDetails?.bookingInfo?.status}
                </span>
              </Typography>
            </Box>

            {/*---------- PDF buttons ----------*/}

            <Box>{/* <PDFbuttons queueDetails={queueDetails} /> */}</Box>

            {/*---------- Page Label ----------*/}

            <Box sx={{ bgcolor: "var(--third-color)", py: "9px", mt: "24px" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "var(--trips-secondary-color)",
                  textAlign: "center",
                }}
              >
                Send Void Request
              </Typography>
            </Box>

            {/*---------- Booking Details  ----------*/}

            <Box sx={{ mt: "24px" }}>
              <BookingDetails
                queueDetails={queueDetails}
                bookingInfo={queueDetails?.bookingInfo}
                setPnr={setPnr}
                pnr={pnr}
              />
            </Box>

            {/*---------- Flight Itinerary and Passenger Details  ----------*/}

            <Box sx={{ mt: "35px" }}>
              <Typography
                sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
              >
                Void Passenger Details
              </Typography>

              <VoidPassengerDetails
                priceBreakdown={queueDetails?.passengers}
                bookingTotalPrices={
                  queueDetails?.bookingInfo?.flightDetailsAndPrices
                    ?.bookingTotalPrices
                }
              />
            </Box>

            {/*---------- Bookign Fare Details  ----------*/}

            <Box sx={{ mt: "35px" }}>
              <Typography
                sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
              >
                Original Fare
              </Typography>

              <VoidFareDetails
                priceBreakdown={queueDetails?.bookingInfo?.priceBreakDown}
                bookingTotalPrices={
                  queueDetails?.bookingInfo?.flightDetailsAndPrices
                    ?.bookingTotalPrices
                }
                setQueueDetails={setQueueDetails}
                queueDetails={queueDetails}
              />
            </Box>

            {/*---------- Remarks area  ----------*/}

            <Box sx={{ mt: "35px" }}>
              <Typography sx={{ fontSize: "18px", color: "var(--text-dark)" }}>
                Remarks
              </Typography>

              <input
                onChange={(e) => setRemarks(e.target.value)}
                type="text"
                className="agent-table-input"
                placeholder="Write your remarks with 150 letters"
                style={{
                  width: "100%",
                  height: "35px",
                  marginTop: "10px",
                  borderBottom: "1px solid var(--input-border)",
                  fontSize: "12px",
                }}
              />
            </Box>

            {/*---------- Send Reissue Request buton  ----------*/}

            <Box sx={{ my: "45px" }}>
              <Button
                disabled={isUpdating}
                onClick={handleVoid}
                sx={{
                  bgcolor: "var(--secondary-color)",
                  ":hover": {
                    bgcolor: "var(--secondary-color)",
                  },
                  color: "white",
                  width: "50%",
                  textTransform: "capitalize",
                }}
              >
                {isUpdating ? "Please wait..." : "Send Voided Request"}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <ToastContainer />
    </>
  );
};

export default VoidRequest;
