import {
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useLocation } from "react-router-dom";
  import { ReactComponent as AirplanIcon } from "../../../image/dashboardIcons/airplane.svg";
  import BookingDetails from "../BookingDetails";
  import ItineraryPassengerDetails from "../ItineraryPassengerDetails";
  import BookingFareDetails from "../BookingFareDetails";

const RefunRequest = () => {
  return (
    <Box
      sx={{
        bgcolor: "var(--body-bg-color)",
        width: "100%",
        pb: "98px",
      }}
    >
   
        <Box
          sx={{
            borderRadius: "3px",
            width: "100%",
            p: 5,
            py: 3,
            minHeight: "calc(100vh - 196px)",
          }}
        >
          {/*---------- Page title ----------*/}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* City Title, Booking Id */}

            <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
              {/* {departureArrivalCityArray?.map((cityName, i) => {
              return (
                <React.Fragment key={i}>
                  <Typography sx={{ fontSize: "35px" }}>
                    {cityName}
                  </Typography>

                  {i < departureArrivalCityArray.length - 1 && (
                    <AirplanIcon
                      style={{
                        height: "37px",
                        width: "37px",
                        fill: "var(--text-medium)",
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })} */}

              <Typography sx={{ fontSize: "35px" }}>Dhaka</Typography>
              <AirplanIcon
                style={{
                  height: "37px",
                  width: "37px",
                  fill: "var(--text-medium)",
                }}
              />
              <Typography sx={{ fontSize: "35px" }}>Cox's Bazar</Typography>
            </Box>

            <Typography sx={{ fontSize: "28px", color: "var(--text-dark)" }}>
              {/* {queueDetails?.bookingInfo?.bookingId}{" "} */} Hold
              <span style={{ color: "var(--trips-secondary-color)" }}>
                {/* {queueDetails?.bookingInfo?.status} */} #QTBB102
              </span>
            </Typography>
          </Box>

          {/*---------- PDF buttons ----------*/}

          <Box>{/* <PDFbuttons queueDetails={queueDetails} /> */}</Box>

          {/*---------- Page Label ----------*/}

          <Box sx={{ bgcolor: "var(--third-color)", py: "9px", mt: "24px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                color: "var(--trips-secondary-color)",
                textAlign: "center",
              }}
            >
          Send Refund Request
            </Typography>
          </Box>

          {/*---------- Booking Details  ----------*/}

          <Box sx={{ mt: "24px" }}>
            <BookingDetails />
          </Box>

          {/*---------- Flight Itinerary and Passenger Details  ----------*/}

          <Box>
            <Box sx={{ mt: "35px" }}>
              <Typography sx={{ fontSize: "18px", color: "var(--text-dark)" }}>
                Passenger Details
              </Typography>

              <ItineraryPassengerDetails />
            </Box>
            {/* {queueDetails?.bookingInfo?.flightDetailsAndPrices?.cityInfo.map(
              (cityInfo, i) => {
                // console.log(flightDetails);

                const label =
                  queueDetails?.bookingInfo?.tripType === "multiCity"
                    ? `${i + 1}`
                    : queueDetails?.bookingInfo?.tripType === "roundWay" &&
                      i === 0
                    ? "Onward"
                    : queueDetails?.bookingInfo?.tripType === "roundWay" &&
                      i === 1
                    ? "Return"
                    : "";

                return (
                  <Box key={i}>
                    <FlightItinerary
                      allFlightDetailsByType={allFlightDetailsByType}
                      setAllFlightDetailsByType={setAllFlightDetailsByType}
                      queueDetails={queueDetails}
                      label={label}
                      cityId={cityInfo.id}
                    />

                    <Box sx={{ mt: "35px" }}>
                      <Typography
                        sx={{ fontSize: "18px", color: "var(--text-dark)" }}
                      >
                        Passenger Details
                      </Typography>

                      <ItineraryPassengerDetails queueDetails={queueDetails} />
                    </Box>
                  </Box>
                );
              }
            )} */}
          </Box>

          {/*---------- Bookign Fare Details  ----------*/}

          <Box sx={{ mt: "35px" }}>
            <Typography
              sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
            >
              Original Fare
            </Typography>
            <BookingFareDetails />
            {/* <BookingFareDetails
              priceBreakdown={queueDetails?.bookingInfo?.priceBreakDown}
              bookingTotalPrices={
                queueDetails?.bookingInfo?.flightDetailsAndPrices
                  ?.bookingTotalPrices
              }
            /> */}
          </Box>

          {/*---------- Choose Reissue Reason  ----------*/}

          <Box sx={{ mt: "35px" }}>
            <Typography
              sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
            >
              Choose Refund Reason
            </Typography>

            <Box sx={{ mt: "10px" }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  // value={selectFlightType}
                  // onChange={(event) => setSelectFlightType(event.target.value)}
                  name="radio-buttons-group"
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    gap: "30px",
                  }}
                >
                  <FormControlLabel
                    value={"refundable"}
                    control={
                      <Radio
                        sx={{
                          color: "var(--trips-secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--trips-secondary-color)",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: "14px", color: "var(--text-dark)" }}
                      >
                        Voluntary Refund
                      </Typography>
                    }
                    onClick={() => {
                      // setSelectFlightType(item.value);
                    }}
                  />

                  <FormControlLabel
                    value={"nonrefundable"}
                    control={
                      <Radio
                        sx={{
                          color: "var(--trips-secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--trips-secondary-color)",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: "14px", color: "var(--text-dark)" }}
                      >
                        Involuntary Refund
                      </Typography>
                    }
                    onClick={() => {
                      // setSelectFlightType(item.value);
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          {/*---------- Remarks area  ----------*/}

          <Box sx={{ mt: "35px" }}>
            <Typography sx={{ fontSize: "18px", color: "var(--text-dark)" }}>
              Remarks
            </Typography>

            <input
              type="text"
              className="agent-table-input"
              placeholder="Write your remarks with 150 letters"
              style={{
                width: "100%",
                height: "35px",
                marginTop: "10px",
                borderBottom: "1px solid var(--input-border)",
                fontSize: "12px",
              }}
            />
          </Box>

          {/*---------- Send Reissue Request buton  ----------*/}

          <Box sx={{ my: "45px" }}>
            <Button
              sx={{
                bgcolor: "var(--secondary-color)",
                ":hover": {
                  bgcolor: "var(--secondary-color)",
                },
                color: "white",
                width: "50%",
                textTransform: "capitalize",
              }}
            >
             Send Refund Request
            </Button>
          </Box>
        </Box>
      
    </Box>
  )
}

export default RefunRequest