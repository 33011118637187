import styled from "@emotion/styled";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PackageImages from "./PackageImages";
import VisitPlaces from "./VisitPlaces";
import TourPlanes from "./TourPlanes";
import InstallmentAndDiscount from "./InstallmentAndDiscount";
import Inclusion from "./Inclusion";
import Exclusion from "./Exclusion";
import BookingPolicy from "./BookingPolicy";
import RefundPolicy from "./RefundPolicy";
import Album from "./Album";
import { addDays, format } from "date-fns";
import { Calendar } from "react-date-range";
import { enUS } from "date-fns/locale";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const TourpackageAdd = () => {
  const adminToken = secureLocalStorage.getItem("Admin-Token");
  const location = useLocation();
  const packageId =
    secureLocalStorage.getItem("packageId") || location.state?.id;
  const Header = styled(Box)({});
  const [addCountry, setaddCountry] = useState([{ description: "" }]);
  const [addCity, setaddCity] = useState([{}]);
  const now = useRef(new Date());

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packageData, setPackageData] = useState([]);

  //  start journey Date
  const [startDate, setStartDate] = useState(addDays(now.current, 0));
  const [startJourneyDateOpen, setStartJourneyDateOpen] = useState(false);
  const [JourneyDate, setJourneyDate] = useState({
    date: addDays(now.current, 0),
  });

  const handleStartDate = (data) => {
    setJourneyDate((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setStartJourneyDateOpen(!startJourneyDateOpen);
  };

  //  end journey Date
  const [endDate, setEndDate] = useState(addDays(now.current, 0));
  const [endJourneyDateOpen, setEndJourneyDateOpen] = useState(false);
  const [endJourneyDate, setendJourneyDate] = useState({
    date: addDays(now.current, 0),
  });

  const handleEndDate = (data) => {
    setendJourneyDate((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setEndJourneyDateOpen(!endJourneyDateOpen);
  };

  //  minimum traveler  Date
  const [minTravelerDate, setMinTravelerDate] = useState(
    addDays(now.current, 0)
  );
  const [minTravelerDateOpen, setMinTravelerDateOpen] = useState(false);
  const [minTravelerDateAdd, setMinTravelerDateAdd] = useState({
    date: addDays(now.current, 0),
  });

  const handleMinTravelerDate = (data) => {
    setMinTravelerDateAdd((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setMinTravelerDateOpen(!minTravelerDateOpen);
  };

  //  maximum traveler  Date
  const [maxTravelerDate, setMaxTravelerDate] = useState(
    addDays(now.current, 0)
  );
  const [maxTravelerDateOpen, setMaxTravelerDateOpen] = useState(false);
  const [maxTravelerDateAdd, setMaxTravelerDateAdd] = useState({
    date: addDays(now.current, 0),
  });
  const handleMaxTravelerDate = (data) => {
    setMaxTravelerDateAdd((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setMaxTravelerDateOpen(!maxTravelerDateOpen);
  };

  //  booking Due Date
  const [bookingPriceDate, setBookingPriceDate] = useState(
    addDays(now.current, 0)
  );
  const [bookingPriceDateOpen, setBookingPriceDateOpen] = useState(false);
  const [bookingPriceDateAdd, setBookingPriceDateAdd] = useState({
    date: addDays(now.current, 0),
  });

  const handleBookingPriceDate = (data) => {
    setBookingPriceDateAdd((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setBookingPriceDateOpen(!bookingPriceDateOpen);
  };

  //  first Instalment date
  const [firstInstalmentDate, setFirstInstalmentDate] = useState(
    addDays(now.current, 0)
  );
  const [firstInstalmentDateOpen, setFirstInstalmentDateOpen] = useState(false);
  const [firstInstalmentDateAdd, setFirstInstalmentDateAdd] = useState({
    date: addDays(now.current, 0),
  });

  const handleFirstInstalmentDate = (data) => {
    setFirstInstalmentDateAdd((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setFirstInstalmentDateOpen(!firstInstalmentDateOpen);
  };

  //  second Instalment date
  const [secondInstalmentDate, setSecondInstalmentDate] = useState(
    addDays(now.current, 0)
  );
  const [secondInstalmentDateOpen, setSecondInstalmentDateOpen] =
    useState(false);
  const [secondInstalmentDateAdd, setSecondInstalmentDateAdd] = useState({
    date: addDays(now.current, 0),
  });

  const handleSecondInstalmentDate = (data) => {
    setSecondInstalmentDateAdd((prev) => ({
      ...prev,
      date: new Date(data).toLocaleDateString("sv"),
    }));
    setSecondInstalmentDateOpen(!secondInstalmentDateOpen);
  };

  //  country and city
  const [visitInput, setVisitInput] = useState([
    {
      country: "",
      city: "",
    },
  ]);

  const handleCountryCity = (e, index) => {
    const { name, value, type, checked } = e.target;
    const list = [...visitInput];
    // Check if it's a checkbox
    if (type === "checkbox") {
      list[index][name] = checked;
    } else {
      list[index][name] = value;
    }
    setVisitInput(list);
    setInputData({ ...inputData, country: visitInput });
  };

  const removeField = (index, dayId) => {
    const list = [...visitInput];
    list.splice(index, 1);
    setVisitInput(list);
    // if (dayId) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Are you sure?",
    //     text: "You Wants to Delete?",
    //     showCancelButton: true,
    //     confirmButtonColor: "var(--secondary-color)",
    //     confirmButtonText: "Delete",
    //     cancelButtonColor: "var(--primary-color)",
    //     cancelButtonText: "Cancel",
    //   }).then((willDelete) => {
    //     if (willDelete.isConfirmed) {
    //       let url = `https://flyfar-ladies-v2.de.r.appspot.com/tourpackage/${packageId}/deletedayplan/${dayId}`;
    //       const body = JSON.stringify({
    //         Id: packageId,
    //         BkId: dayId,
    //       });
    //       fetch(url, {
    //         method: "DELETE",
    //         headers: {
    //           Accept: "application/json",
    //           "Content-type": "application/json",
    //         },
    //         body: body,
    //       })
    //         .then((res) => res.json())
    //         .then((data) => {
    //           if (data?.status === "success") {
    //             Swal.fire({
    //               icon: "success",
    //               title: data?.status,
    //               text: data?.message,
    //               confirmButtonText: "ok",
    //             });
    //           } else {
    //             Swal.fire({
    //               icon: "error",
    //               title: data?.status,
    //               text: data?.message,
    //               confirmButtonText: "ok",
    //             });
    //           }
    //         });
    //     }
    //   });
    // }
  };

  const addField = () => {
    const newIndex = visitInput.length + 1;
    setVisitInput([
      ...visitInput,
      {
        country: "",
        city: "",
      },
    ]);
  };

  const [inputData, setInputData] = useState({
    main_title: "",
    trip_type: "",
    journey_location: "",
    valid_from: format(new Date(JourneyDate?.date), "yyyy-MM-dd"),
    valid_to: format(new Date(endJourneyDate?.date), "yyyy-MM-dd"),
    trip_days: "",
    trip_nights: "",
    total_seat: "",
    minimum_traveler_age: "",
    maximum_traveler_age: "",
    adult_base_price: "",
    adt_tax: "",
    child_base_price: "",
    chd_tax: "",
    infant_base_price: "",
    inf_tax: "",
    package_discount: "",
    booking_money: "",
    booking_money_due_date: format(
      new Date(bookingPriceDateAdd?.date),
      "yyyy-MM-dd"
    ),
    first_installment: "",
    first_installment_due_date: format(
      new Date(firstInstalmentDateAdd?.date),
      "yyyy-MM-dd"
    ),
    second_installment: "",
    second_installment_due_date: format(
      new Date(secondInstalmentDateAdd?.date),
      "yyyy-MM-dd"
    ),
    package_overview: "",
    guide_type: "",
    flight: 0,
    accommodation: 0,
    meal: 0,
    transport: 0,
    guide: 0,
    available: 0,
    show_on_this_on_home_page: 0,
    day_trip: 0,
    night_out_trip: 0,
    fully_guided: 0,
    self: 0,
    country: "",
    family: 0,
    couple: 0,
    friendly: 0,
    child: 0,
  });

  useEffect(() => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package/${packageId}`;
    fetch(url).then((res) =>
      res.json().then((data) => {
        if (data?.data?.length !== 0) {
          setInputData(data?.data);
          setPackageData(data?.data);
          setVisitInput(data?.data?.country);
        }
      })
    );
  }, []);

  const handleOnChange = (e) => {
    if (e.target.type === "checkbox") {
      setInputData({
        ...inputData,
        [e.target.name]: e.target.checked === true ? 1 : 0,
      });
    } else if (e.target.type === "file") {
      setInputData({
        ...inputData,
        [e.target.name]: e.target.files[0],
      });
    } else if (e.target.type === "number") {
      if (e.target.value >= 0) {
        setInputData({
          ...inputData,
          [e.target.name]: Number(e.target.value),
        });
      }
    } else {
      setInputData({
        ...inputData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url;
    if (packageId) {
      url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/update-tour-package/${packageId}`;
    } else {
      url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/create-tour-package`;
    }
    let body = JSON.stringify(inputData);
    await fetch(url, {
      method: packageId ? "PUT" : "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Admin-Token": `${adminToken}`,
      },
      body,
    })
      .then((res) => res.json())
      .then((data) => {
        secureLocalStorage.setItem("packageId", data?.data[0]?.id);
        if (data.success === true) {
          Swal.fire({
            icon: "success",
            // title: data.status,
            text: data.message,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(`/trips/tourpackage/packageimage`);
          });
        } else {
          Swal.fire({
            icon: "error",
            // title: data.status,
            text: data.message,
            confirmButtonText: "OK",
          });
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Box className="tourpackage-body">
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography sx={{ fontSize: "17px" }} my={2}>
              Package Information
            </Typography>
            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Package Name
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <input
                    type="text"
                    placeholder="Enter Package Name"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="main_title"
                    value={inputData?.main_title}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
              }}
              mt={3}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Package Type
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <select
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      color: "#858585",
                    }}
                    name="trip_type"
                    value={inputData?.trip_type}
                    onChange={handleOnChange}
                  >
                    <option>Select Package Type</option>
                    <option>Day Trip</option>
                    <option>Domestic</option>
                    <option>International</option>
                  </select>
                </Grid>
              </Grid>
            </Box>

            <Box>
              {visitInput?.map((item, index) => (
                <>
                  <Box
                    sx={{
                      borderBottom: "1px solid #DADCE0",
                      paddingBottom: "5px",
                      marginTop: "24px",
                    }}
                  >
                    <Grid
                      key={index}
                      container
                      spacing={2}
                      alignItems="end"
                      style={{ background: "" }}
                    >
                      <Grid item xs={12} sm={6} md={7}>
                        <Typography
                          style={{
                            fontSize: "13px",
                            color: "#858585",
                          }}
                        >
                          Country Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <input
                          type="text"
                          style={{
                            outline: "none",
                            border: "none",
                            width: "100%",
                          }}
                          placeholder="Enter Country Name"
                          name="country"
                          id={`country-${index}`}
                          value={item?.country}
                          onChange={(e) => handleCountryCity(e, index)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #DADCE0",
                      paddingBottom: "5px",
                      marginTop: "24px",
                    }}
                  >
                    <Grid
                      key={index}
                      container
                      spacing={2}
                      alignItems="end"
                      style={{ background: "" }}
                    >
                      <Grid item xs={12} sm={6} md={7}>
                        <Typography
                          style={{
                            fontSize: "13px",
                            color: "#858585",
                          }}
                        >
                          City Name
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <input
                          type="text"
                          style={{
                            outline: "none",
                            border: "none",
                            width: "100%",
                          }}
                          placeholder="Enter City Name"
                          name="city"
                          id="city"
                          value={item?.city}
                          onChange={(e) => handleCountryCity(e, index)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                    }}
                    mt={1}
                  >
                    {visitInput.length - 1 === index && (
                      <Button
                        onClick={addField}
                        sx={{
                          marginRight: "10px",
                          width: "130px",
                          height: "35px",
                          background: "var(--trips-primary-color)",
                          color: "#fff",
                          fontSize: { xs: 14, sm: 14, md: 15 },
                          border: "none",
                          borderRadius: "3px",
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "var(--trips-primary-color)",
                            color: "#fff",
                          },
                        }}
                      >
                        Add Another
                      </Button>
                    )}
                    {visitInput.length !== 1 && (
                      <Button
                        onClick={() => removeField(index, item?.dayId)}
                        sx={{
                          width: "130px",
                          marginRight: "5px",
                          height: "35px",
                          background: "var(--trips-secondary-color)",
                          color: "#fff",
                          fontSize: { xs: 14, sm: 14, md: 15 },
                          border: "none",
                          borderRadius: "3px",
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "var(--trips-secondary-color)",
                            color: "#fff",
                          },
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                </>
              ))}
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Journey Locations
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <input
                    type="text"
                    placeholder="Enter Journey Locations Name"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="journey_location"
                    value={inputData?.journey_location}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Journey Start Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                  sx={{
                    position: "relative",
                    height: "50px",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "var(--input-bgcolor)",
                      height: "100%",
                      width: "100%",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setTimeout(
                        () => setStartJourneyDateOpen((prev) => !prev),
                        200
                      );
                    }}
                  >
                    <Stack direction="row" alignItems="center" height="100%">
                      <Box cursor="pointer">
                        <Typography
                          sx={{
                            color: "#808080",
                            fontSize: { xs: "12px", sm: "12px" },
                          }}
                        >
                          Select Journey Start Data{" "}
                          {/* {now?.current?.getTime() ===
                          JourneyDate?.date?.getTime() ? (
                            <>
                              {`${moment(inputData?.valid_from).format(
                                "DD MMM YY"
                              )}`}
                            </>
                          ) : (
                            <>
                              {`${format(
                                new Date(JourneyDate?.date),
                                "dd MMM yy"
                              )}`}
                            </>
                          )} */}
                          {`${format(
                            new Date(JourneyDate?.date),
                            "dd MMM yy"
                          )}`}
                          {/* {`${moment(inputData?.valid_from).format(
                            "DD MMM YY"
                          )}`} */}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  {startJourneyDateOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        zIndex: 10,
                        top: "100%",
                        left: { xs: "auto", md: "auto", lg: "18px" },
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                      }}
                    >
                      <Calendar
                        color="var(--primary-color)"
                        date={new Date(startDate)}
                        onChange={handleStartDate}
                        locale={enUS}
                        months={1}
                        style={{
                          fontSize: "11px",
                          padding: "0",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                        }}
                        minDate={new Date()}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Journey End Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                  sx={{
                    position: "relative",
                    height: "50px",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "var(--input-bgcolor)",
                      height: "100%",
                      width: "100%",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setTimeout(
                        () => setEndJourneyDateOpen((prev) => !prev),
                        200
                      );
                    }}
                  >
                    <Stack direction="row" alignItems="center" height="100%">
                      <Box cursor="pointer">
                        <Typography
                          sx={{
                            color: "#808080",
                            fontSize: { xs: "12px", sm: "12px" },
                          }}
                        >
                          Select Journey End Data{" "}
                          {`${format(
                            new Date(endJourneyDate?.date),
                            "dd MMM yy"
                          )}`}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  {endJourneyDateOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        zIndex: 10,
                        top: "100%",
                        left: { xs: "auto", md: "auto", lg: "18px" },
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                      }}
                    >
                      <Calendar
                        color="var(--primary-color)"
                        date={new Date(endDate)}
                        onChange={handleEndDate}
                        locale={enUS}
                        months={1}
                        style={{
                          fontSize: "11px",
                          padding: "0",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                        }}
                        minDate={new Date()}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Trip Days
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <input
                    type="number"
                    placeholder="Enter Total Trip Days like 5 Days"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="trip_days"
                    value={inputData?.trip_days || ""}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Trip Night
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <input
                    type="number"
                    placeholder="Enter Total Trip Night like 5 Nights"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="trip_nights"
                    value={inputData?.trip_nights || ""}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Total Seat
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <input
                    type="number"
                    placeholder="Enter Total Seat"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="total_seat"
                    value={inputData?.total_seat || ""}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Minimum Traveler Age
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={5}>
                  <input
                    min="12"
                    type="number"
                    placeholder="Minimum Age 12"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="minimum_traveler_age"
                    value={inputData?.minimum_traveler_age || ""}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box> */}

            {/* <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                paddingBottom: "5px",
                marginTop: "24px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#858585",
                    }}
                  >
                    Maximum Traveler Age
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={5}>
                  <input
                    min="12"
                    type="number"
                    placeholder="Maximum Age 12"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                    }}
                    name="maximum_traveler_age"
                    value={inputData?.maximum_traveler_age || ""}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Box> */}

            {/* package Overview */}

            <Box sx={{ marginTop: "90px" }}>
              <Typography sx={{ fontSize: "17px", marginBottom: "22px" }}>
                Package Overview
              </Typography>

              <Box>
                <textarea
                  style={{
                    width: "100%",
                    resize: "none",
                    height: "150px",
                    border: "1px solid #DADCE0",
                    outline: "none",
                    padding: "5px 10px",
                  }}
                  placeholder="Enter Package Overview"
                  name="package_overview"
                  value={inputData?.package_overview}
                  onChange={handleOnChange}
                ></textarea>
              </Box>
            </Box>

            {/* package Pricing Area */}

            <Box sx={{ marginTop: "90px" }}>
              <Typography sx={{ fontSize: "17px", marginBottom: "22px" }}>
                Traveller Base Fare
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Adult Base Fare
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Enter Adult Base fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="adult_base_price"
                      value={inputData?.adult_base_price || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",
                  marginTop: "23px",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Adult Tax
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Adult Tax Fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="adt_tax"
                      value={inputData?.adt_tax || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",
                  marginTop: "23px",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Child Base Fare
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Child Base Fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="child_base_price"
                      value={inputData?.child_base_price || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",
                  marginTop: "23px",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Child Tax
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Child Tax Fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="chd_tax"
                      value={inputData?.chd_tax || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",
                  marginTop: "23px",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Infant Base Fare
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Enter Infant Base Fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="infant_base_price"
                      value={inputData?.infant_base_price || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",
                  marginTop: "23px",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Infant Tax
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Infant Tax Fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="inf_tax"
                      value={inputData?.inf_tax || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Package Overview Area */}
            <Box sx={{ marginTop: "90px" }}>
              <Typography sx={{ fontSize: "17px", marginBottom: "22px" }}>
                Package Pricing
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  display: "flex",
                  justifyContent: "start",
                  gap: "598px",

                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Adult Base Fare
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Adult Base Fare"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="adult_base_price"
                      value={inputData?.adult_base_price || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Instalment and discount */}

            <Box sx={{ marginTop: "90px" }}>
              <Typography sx={{ fontSize: "17px", marginBottom: "22px" }}>
                Instalment & Discount
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  paddingBottom: "8px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Booking Price
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Enter Booking Price"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="booking_money"
                      value={inputData?.booking_money || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  marginTop: "23px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Booking Price Due Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    sx={{
                      position: "relative",
                      height: "50px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "var(--input-bgcolor)",
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTimeout(
                          () => setBookingPriceDateOpen((prev) => !prev),
                          200
                        );
                      }}
                    >
                      <Stack direction="row" alignItems="center" height="100%">
                        <Box cursor="pointer">
                          <Typography
                            sx={{
                              color: "#808080",
                              fontSize: { xs: "12px", sm: "12px" },
                            }}
                          >
                            Select Booking Price Date{" "}
                            {`${format(
                              new Date(bookingPriceDateAdd?.date),
                              "dd MMM yy"
                            )}`}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    {bookingPriceDateOpen && (
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 10,
                          top: "100%",
                          left: { xs: "auto", md: "auto", lg: "18px" },
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                        }}
                      >
                        <Calendar
                          color="var(--primary-color)"
                          date={new Date(bookingPriceDate)}
                          onChange={handleBookingPriceDate}
                          locale={enUS}
                          months={1}
                          style={{
                            fontSize: "11px",
                            padding: "0",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                          }}
                          minDate={new Date()}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  paddingBottom: "8px",
                  marginTop: "23px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      First Instalment Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Enter First Instalment Amount"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="first_installment"
                      value={inputData?.first_installment || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  marginTop: "23px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      First Instalment Due Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    sx={{
                      position: "relative",
                      height: "50px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "var(--input-bgcolor)",
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTimeout(
                          () => setFirstInstalmentDateOpen((prev) => !prev),
                          200
                        );
                      }}
                    >
                      <Stack direction="row" alignItems="center" height="100%">
                        <Box cursor="pointer">
                          <Typography
                            sx={{
                              color: "#808080",
                              fontSize: { xs: "12px", sm: "12px" },
                            }}
                          >
                            Select Booking Price Date{" "}
                            {`${format(
                              new Date(firstInstalmentDateAdd?.date),
                              "dd MMM yy"
                            )}`}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    {firstInstalmentDateOpen && (
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 10,
                          top: "100%",
                          left: { xs: "auto", md: "auto", lg: "18px" },
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                        }}
                      >
                        <Calendar
                          color="var(--primary-color)"
                          date={new Date(firstInstalmentDate)}
                          onChange={handleFirstInstalmentDate}
                          locale={enUS}
                          months={1}
                          style={{
                            fontSize: "11px",
                            padding: "0",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                          }}
                          minDate={new Date()}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  paddingBottom: "8px",
                  marginTop: "23px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Second Instalment Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <input
                      type="number"
                      placeholder="Enter Second Instalment Amount"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                      }}
                      name="second_installment"
                      value={inputData?.second_installment || ""}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  borderBottom: "1px solid #DADCE0",
                  marginTop: "23px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                      }}
                    >
                      Second Instalment Due Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    sx={{
                      position: "relative",
                      height: "50px",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "var(--input-bgcolor)",
                        height: "100%",
                        width: "100%",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTimeout(
                          () => setSecondInstalmentDateOpen((prev) => !prev),
                          200
                        );
                      }}
                    >
                      <Stack direction="row" alignItems="center" height="100%">
                        <Box cursor="pointer">
                          <Typography
                            sx={{
                              color: "#808080",
                              fontSize: { xs: "12px", sm: "12px" },
                            }}
                          >
                            Select Booking Price Date{" "}
                            {`${format(
                              new Date(secondInstalmentDateAdd?.date),
                              "dd MMM yy"
                            )}`}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    {secondInstalmentDateOpen && (
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 10,
                          top: "100%",
                          left: { xs: "auto", md: "auto", lg: "18px" },
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                        }}
                      >
                        <Calendar
                          color="var(--primary-color)"
                          date={new Date(secondInstalmentDate)}
                          onChange={handleSecondInstalmentDate}
                          locale={enUS}
                          months={1}
                          style={{
                            fontSize: "11px",
                            padding: "0",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
                          }}
                          minDate={new Date()}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* check box area  */}

            <Box sx={{ marginTop: "100px" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2.4}>
                    {/* Package Include */}
                    <Typography sx={{ fontSize: "17px", marginBottom: "28px" }}>
                      Package Include
                    </Typography>

                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="flight"
                        checked={inputData?.flight == 1 ? true : false}
                        value={inputData?.flight}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Flights
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={<Checkbox />}
                        name="accommodation"
                        checked={inputData?.accommodation == 1 ? true : false}
                        value={inputData?.accommodation}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Accomodation
                          </Typography>
                        }
                      />

                      {/* <FormControlLabel
                        control={<Checkbox />}
                        name="accomodation"
                        checked={inputData?.accommodation == 1 ? true : false}
                        value={inputData?.accommodation}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Accomodation
                          </Typography>
                        }
                      /> */}

                      <FormControlLabel
                        control={<Checkbox />}
                        name="meal"
                        checked={inputData?.meal == 1 ? true : false}
                        value={inputData?.meal}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Meal
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="transport"
                        checked={inputData?.transport == 1 ? true : false}
                        value={inputData?.transport}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Transport
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        control={<Checkbox />}
                        name="guide"
                        checked={inputData?.guide == 1 ? true : false}
                        value={inputData?.guide}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Guide
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    {/* Show Package */}

                    <Typography sx={{ fontSize: "17px", marginBottom: "28px" }}>
                      Show Package
                    </Typography>

                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="available"
                        checked={inputData?.available == 1 ? true : false}
                        value={inputData?.available}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Available
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="package_show"
                        checked={inputData?.package_show == 1 ? true : false}
                        value={inputData?.package_show}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Show this package on home page
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    {/* Show Package */}
                    <Typography sx={{ fontSize: "17px", marginBottom: "28px" }}>
                      Show Package
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="day_trip"
                        checked={inputData?.day_trip == 1 ? true : false}
                        value={inputData?.day_trip}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Day Trip
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="night_out_trip"
                        checked={inputData?.night_out_trip == 1 ? true : false}
                        value={inputData?.night_out_trip}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Night Out
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    {/* Guide Type  */}
                    <Typography sx={{ fontSize: "17px", marginBottom: "28px" }}>
                      Guide Type
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="fully_guided"
                        checked={inputData?.fully_guided == 1 ? true : false}
                        value={inputData?.fully_guided}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Fully Guided
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="self"
                        checked={inputData?.self == 1 ? true : false}
                        value={inputData?.self}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Self
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={2.4}>
                    {/* Guide Type  */}
                    <Typography sx={{ fontSize: "17px", marginBottom: "28px" }}>
                      Preferable Type
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        name="family"
                        checked={inputData?.family == 1 ? true : false}
                        value={inputData?.family}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Familly
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="couple"
                        checked={inputData?.couple == 1 ? true : false}
                        value={inputData?.couple}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Couple
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="friendly"
                        checked={inputData?.friendly == 1 ? true : false}
                        value={inputData?.friendly}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Adult Friendly
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        name="child"
                        checked={inputData?.child == 1 ? true : false}
                        value={inputData?.child}
                        onChange={handleOnChange}
                        label={
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "var( --profile-boday-color)",
                            }}
                          >
                            Child Friendly
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                <Box sx={{ textAlign: "right" }} my={2}>
                  {loading ? (
                    <Button
                      disabled
                      variant="contained"
                      sx={{
                        bgcolor: "var(--savStaff-color)",
                        color: "var(  --thard-color)",
                        boxShadow: "none",
                        textTransform: "capitalize",
                        width: "30%",
                      }}
                    >
                      Processing....
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        bgcolor: "var(--savStaff-color)",
                        color: "var(  --thard-color)",
                        boxShadow: "none",
                        textTransform: "capitalize",
                        width: "30%",
                      }}
                    >
                      {packageId
                        ? "Update Package Information"
                        : "Submit Package Information"}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default TourpackageAdd;
