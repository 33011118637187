import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          // alignItems: "center",
          paddingTop: "60px",
          justifyContent: "center", // Center vertically
          height: "100vh",
        }}
      >
        <Box>
          <Box>
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "37px",
                textAlign: "center",
              }}
            >
              Our Products
            </Typography>
          </Box>
          <Box sx={{ mt: "80px" }}>
            <Grid container spacing={5}>
              <Grid item md={4} sx={{ width: "15%" }}>
                <Link
                  to="/tech/products/hrmPro"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "15px",
                      border: "1px solid var(--savStaff-color)",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--savStaff-color)",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      HRM Pro
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--savStaff-color)",
                        fontSize: "18px",
                        textDecoration: "none",
                        fontWeight: 500,
                      }}
                    >
                      Count: 0
                    </Typography>
                  </Box>
                </Link>
              </Grid>

              <Grid item md={4} sx={{ width: "15%" }}>
                <Box
                  sx={{
                    background: "#ffff",
                    height: "140px",
                    width: "160px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    px: "10px",
                    borderRadius: "15px",
                    border: "1px solid var(--savStaff-color)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--savStaff-color)",
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                  >
                    White label B2B
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--savStaff-color)",
                      fontSize: "18px",
                      textDecoration: "none",
                      fontWeight: 500,
                    }}
                  >
                    Count: 0
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={4} sx={{ width: "15%" }}>
                <Link to="" style={{ textDecoration: "none" }}>
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "15px",
                      border: "1px solid var(--savStaff-color)",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--savStaff-color)",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      White label B2C
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--savStaff-color)",
                        fontSize: "18px",
                        textDecoration: "none",
                        fontWeight: 500,
                      }}
                    >
                      Count: 0
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Products;
