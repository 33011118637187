import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Swal } from "sweetalert2";

const InventoryInfo = () => {
  const location = useLocation();
  const token = secureLocalStorage.getItem("token");
  const { id, allAdmin } = location.state?.allAdmin;

  const [formData, setFormData] = useState({
    mobile: allAdmin?.Inventory?.mobile || "",
    pc: allAdmin?.Inventory?.computer || "",
    Keyboard: allAdmin?.Inventory?.keyboard || "",
    mouse: allAdmin?.information?.mouse || "",
    headPhone: allAdmin?.information?.headPhone || "",
    extra: allAdmin?.information?.extra || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInventoryUpdate = async (e) => {
    e.preventDefault();

    const requestBody = {
      status: formData.status,
      oEmail: formData.email,
      password: "123456789",
      oPhone: formData.phoneNumber,
    };

    try {
      const response = await fetch(
        `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/update_adminInfos/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      console.log("response", response);
      console.log("requestBody", requestBody);

      if (response.ok) {
        Swal.fire("Success", "Information updated successfully", "success");
      } else {
        const errorData = await response.json();
        Swal.fire(
          "Error",
          errorData.message || "Failed to update information",
          "error"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };

  console.log(allAdmin);
  return (
    <Box sx={{ mx: "20px", minHeight: "700px" }}>
      <Typography sx={{ fontSize: "22px", color: "var(--secondary-color)" }}>
        Inventory Allocate Update
      </Typography>
      <form>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Mobile</label>
          </Box>

          <input
            placeholder="Change Your Mobile"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.mobile}
            onChange={handleInputChange}
            name="mobile"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              PC/Laptop
            </label>
          </Box>

          <input
            type="text"
            placeholder="Change Your Pc/Laptop"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.pc}
            onChange={handleInputChange}
            name="pc"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Keyboard
            </label>
          </Box>

          <input
            type="password"
            placeholder="Change Your Keyboard"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.Keyboard}
            onChange={handleInputChange}
            name="Keyboard"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Mouse</label>
          </Box>

          <input
            placeholder="Change Your Mouse"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.mouse}
            onChange={handleInputChange}
            name="mouse"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Head Phone
            </label>
          </Box>

          <input
            placeholder="Change Your 	Head Phone"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.headPhone}
            onChange={handleInputChange}
            name="headPhone"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Extra</label>
          </Box>

          <input
            placeholder="Change Your Extra"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.extra}
            onChange={handleInputChange}
            name="extra"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            mt: "20px",
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "30%",
              color: "var(--thard-color)",
              textTransform: "capitalize",
              backgroundColor: "var(--savStaff-color)",
              ":hover": {
                backgroundColor: "var(--savStaff-color)",
              },
            }}
          >
            Save Changes Information
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default InventoryInfo;
