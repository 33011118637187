import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const WingsSelected = ({ user }) => {
    const token = secureLocalStorage.getItem("token");
  const defaultWing = "defaultWing"; // Default selected wing
  const [selectedWings, setSelectedWings] = useState([defaultWing]);
  

  const [wing,setWing] = useState([])

  useEffect(() => {
  
    const apiUrl = 'https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/routes';
    
    

  
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setWing(data?.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
}, []);
  console.log("ami wing ato",wing)

  const handleSelectChangeWings = (event, index) => {
    const updatedSelectedWings = [...selectedWings];
    updatedSelectedWings[index] = event.target.value;
    setSelectedWings(updatedSelectedWings);
  };

  const handleAddWings = () => {
    setSelectedWings([...selectedWings, defaultWing]);
  };

  const handleRemoveWings = (index) => {
    if (selectedWings.length > 1) {
      const updatedSelectedWings = [...selectedWings];
      updatedSelectedWings.splice(index, 1);
      setSelectedWings(updatedSelectedWings);
    }
  };


  


  return (
    <>
    </>
  );
};

export default WingsSelected;
