import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { CircleLoader } from "react-spinners";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";

const AddDeals = () => {
  const [isShow, setIsShow] = useState(false);
  const token = secureLocalStorage.getItem("Admin-Token");
  const editor = useRef(null);
  const { id } = useParams();

  console.log(id);
  const [formData, setFormData] = useState({
    selectedCategory: "",
    title: "",
    description: "",
    couponCode: "",
    thumbnailImage: null,
  });
  console.log(formData);

  const handleSelectedCategory = (event) => {
    setFormData({
      ...formData,
      selectedCategory: event.target.value,
    });
  };

  const handleInputChange = (values) => {
    setFormData({
      ...formData,
      ...values,
    });
  };

  const handleThumbnailImageChange = (event) => {
    setFormData({
      ...formData,
      thumbnailImage: event.target.files[0],
    });
  };

  const [subId, setSubId] = useState("");
  console.log(subId);
  const handleExclusiveDeals = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("type", formData.selectedCategory);
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("coupon_code", formData.couponCode);
      formDataToSend.append("image", formData.thumbnailImage);

      const response = await axios.post(
        "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/create-deals",
        formDataToSend,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Admin-Token": `${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });

        setFormData({
          selectedCategory: "",
          title: "",
          description: "",
          couponCode: "",
          thumbnailImage: null,
        });

        console.log(response.data.data.id);
        setSubId(response.data.data.id);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    }
  };

  const [getDealsData, setGetDealsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/get-deals",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setGetDealsData(data?.data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [token]); // Depend

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/get-sub-image",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setData(data?.data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [token]); // Depe

  console.log(subId);

  const handleFirstImageChange = async (value) => {
    // const file = e.target.files[0];

    const formData = new FormData();
    formData.append("image", value);

    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/deals-image/${subId}`,
        {
          method: "POST",
          headers: {
            "Admin-Token": `${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message);
        // Handle success, e.g., update state or show a success message
      } else {
        console.error("First image upload failed:", response.statusText);
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      console.error("Error uploading first image:", error);
      // Handle error, e.g., show an error message
    }
  };

  const handleSecondImageChange = async (value) => {
    const formData = new FormData();
    formData.append("image", value);

    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/deals-image/${subId}}`,
        {
          method: "POST",
          headers: {
            "Admin-Token": `${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message);
        // Handle success, e.g., update state or show a success message
      } else {
        console.error("First image upload failed:", response.statusText);
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      console.error("Error uploading first image:", error);
      // Handle error, e.g., show an error message
    }
  };

  const [firstImage, setFirstImage] = useState(null);

  const [secondImage, setSecondImage] = useState(null);

  const [thirdImage, setThirdImage] = useState(null);

  const handleThirdImageChange = async (value) => {
    const formData = new FormData();
    formData.append("image", value);

    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/deals-image/${subId}`,
        {
          method: "POST",
          headers: {
            "Admin-Token": `${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        toast.success(result.message);
        // Handle success, e.g., update state or show a success message
        setIsShow(false);
      } else {
        console.error("First image upload failed:", response.statusText);
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      console.error("Error uploading first image:", error);
      // Handle error, e.g., show an error message
    }
  };
  return (
    <Box>
      <ToastContainer />
      <Box sx={{ my: "10px", mt: 7 }}>
        <Typography>Exclusive Deals</Typography>
      </Box>
      <form onSubmit={handleExclusiveDeals}>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "300px" }}>
                  <label style={{ fontSize: "12px" }}>Thumbnail Image</label>
                </Box>

                <Box
                  sx={{
                    width: "250px",
                    height: { md: "160px", sm: "210px", xs: "210px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleThumbnailImageChange(e)}
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "none",
                      }}
                    />
                    {isShow ? (
                      <Box
                        sx={{
                          width: "250px",
                          height: { md: "160px", sm: "210px", xs: "210px" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <label
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleThumbnailImageChange(e)}
                            style={{
                              height: "100%",
                              width: "100%",
                              display: "none",
                            }}
                          />
                          <Box
                            sx={{
                              width: "100%",
                              height: { md: "160px", sm: "210px", xs: "210px" },
                            }}
                          >
                            <img
                              src={
                                data.filter(
                                  (x) => x.deal_id === subId.toString()
                                )[0]?.sub_image
                              }
                              alt="Thumbnail"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            />
                          </Box>
                        </label>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: { md: "160px", sm: "210px", xs: "210px" },
                        }}
                      >
                        {formData.thumbnailImage ? (
                          <img
                            src={URL.createObjectURL(formData.thumbnailImage)}
                            alt="Thumbnail"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: { md: "210px", sm: "210px", xs: "210px" },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "30px",
                              textAlign: "center",
                              borderRadius: "10px",
                            }}
                          >
                            Upload Thumbnail Image
                          </Skeleton>
                        )}
                      </Box>
                    )}
                  </label>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
                  mt: -2,
                  width: "100%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                    Selected Categorey
                  </label>
                </Box>
                <select
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    color: "#A3A4A9",
                  }}
                  value={formData.selectedCategory}
                  onChange={handleSelectedCategory}
                >
                  <option value="flight">Flight</option>
                  <option value="hotel">Hotel</option>
                  <option value="tour">Tour</option>
                  <option value="visa">Visa</option>
                </select>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
                  mt: -2,
                  gap: "10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ fontSize: "12px" }}>Title</label>
                </Box>

                <input
                  name=""
                  placeholder="title"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                    width: "100%",
                  }}
                  value={formData.title}
                  onChange={(e) => handleInputChange({ title: e.target.value })}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
                  gap: "10px",
                  mt: -2,
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ fontSize: "12px" }}>Coupon Code</label>
                </Box>

                <input
                  name=""
                  value={formData.couponCode}
                  onChange={(e) =>
                    handleInputChange({ couponCode: e.target.value })
                  }
                  placeholder="Coupon Code"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  mt: -4,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ fontSize: "12px" }}>Short Descriptions</label>
                </Box>
                <JoditEditor
                  ref={editor}
                  value={formData.description}
                  onChange={(content) =>
                    handleInputChange({ description: content })
                  }
                />
              </Box>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "var(--trips-primary-color)",
                  color: "white",
                  ":hover": {
                    background: "var(--trips-primary-color)",
                    color: "white",
                  },
                }}
              >
                Uplode Exclusive Deals
              </Button>
            </Box>
          </Grid>

          {/* pictures */}
        </Grid>
      </form>

      {isShow ? (
        <Box
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {data
            ?.filter((x) => x.deal_id === subId.toString())
            ?.map((x, index) => (
              <Box
                key={index}
                sx={{
                  width: "250px",
                  height: { md: "160px", sm: "210px", xs: "210px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: { md: "160px", sm: "210px", xs: "210px" },
                    }}
                  >
                    <img
                      src={x.sub_image}
                      alt={`Thumbnail ${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </label>
              </Box>
            ))}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              ml: 0.5,
              gap: "12px",
              my: 3,
            }}
          >
            <Box
              sx={{
                mt: -5,
                display: "flex",

                flexDirection: "column",
                gap: "10px",

                color: "#A3A4A9",
              }}
            >
              <Box
                sx={{
                  width: "250px",
                  bgcolor: "var(--primary-color)",
                  height: { md: "160px", sm: "210px", xs: "210px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleFirstImageChange(e.target.files[0]);
                      setFirstImage(e.target.files[0]);
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { md: "160px", sm: "210px", xs: "210px" },
                    }}
                  >
                    {firstImage && firstImage.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(firstImage)}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Typography>Upload Image</Typography>
                    )}
                  </Box>
                </label>
              </Box>
            </Box>

            <Box
              sx={{
                mt: -5,
                display: "flex",

                flexDirection: "column",
                gap: "10px",

                color: "#A3A4A9",
              }}
            >
              <Box
                sx={{
                  width: "250px",
                  bgcolor: "var(--primary-color)",
                  height: { md: "160px", sm: "210px", xs: "210px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleSecondImageChange(e.target.files[0]);
                      setSecondImage(e.target.files[0]);
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { md: "160px", sm: "210px", xs: "210px" },
                    }}
                  >
                    {secondImage && secondImage.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(secondImage)}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Typography>Upload Image</Typography>
                    )}
                  </Box>
                </label>
              </Box>
            </Box>

            <Box
              sx={{
                mt: -5,
                display: "flex",

                flexDirection: "column",
                gap: "10px",

                color: "#A3A4A9",
              }}
            >
              <Box
                sx={{
                  width: "250px",
                  bgcolor: "var(--primary-color)",
                  height: { md: "160px", sm: "210px", xs: "210px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleThirdImageChange(e.target.files[0]);
                      setThirdImage(e.target.files[0]);
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { md: "160px", sm: "210px", xs: "210px" },
                    }}
                  >
                    {thirdImage && thirdImage.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(thirdImage)}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Typography>Upload Image</Typography>
                    )}
                  </Box>
                </label>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddDeals;
