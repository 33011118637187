import {
  Box,
  Button,
  Container,
  Dialog,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StaffAdd = () => {
  const token = secureLocalStorage.getItem("token");
  const user = secureLocalStorage.getItem("user-info");
  console.log("staff add theke user boltecii", user);

  const [wing, setWing] = useState([]);
  // all wing fetch up
  useEffect(() => {
    const apiUrl =
      "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/routes";

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setWing(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log("hiii", wing);

  // const [selectedOptions, setSelectedOptions] = useState([""]); // Initialize with one empty string

  // const handleSelectChange = (event, index) => {
  //   const newValue = event.target.value;
  //   const newOptions = [...selectedOptions];
  //   newOptions[index] = newValue;
  //   setSelectedOptions(newOptions);
  // };

  // const handleAdd = () => {
  //   setSelectedOptions([...selectedOptions, ""]); // Add a new empty string
  // };

  // const handleRemoveSelect = () => {
  //   if (selectedOptions.length > 1) {
  //     const newOptions = [...selectedOptions];
  //     newOptions.pop(); // Remove the last item
  //     setSelectedOptions(newOptions);
  //   }
  // };

  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState([]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleImageClick = (imageURL) => {
    setSelectedImageURL(imageURL);
    setModalOpen(true);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    roles: [],
    designation: "",
    role: "",
    salary: "",
    officeEmail: "",
    personalImage: "",
    nidCopy: "",
    personalEmail: "",
    phoneNumber: "",
    officePhone: "",
    dob: "",
    bloodGroup: "",
    nidNo1: "",
    mobile: "",
    pcOrLaptop: "",
    keyboard: "",
    mouse: "",
    headphone: "",
    extra: "",
  });

  console.log("ami from data theke", formData);
  const handleStaffAdd = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("name", formData.firstName);
    form.append("joinAt", "2023-09-10");
    form.append("joinAt", "2023-09-10");
    // if (formData.wing.length > 0) {
    //   form.append("wing", formData.wing[0]);
    // } else {
    //   form.append("wing", ""); // Or set it to an empty string if there's no wing
    // }
    form.append("roles", formData.roles);
    form.append("designation", formData.designation);
    form.append("roleId", formData.role);
    form.append("salary", formData.salary);
    form.append("oEmail", formData.officeEmail);
    form.append("profilePic", formData?.personalImage);
    form.append("nidCopy", formData?.nidCopy);
    form.append("email", formData.personalEmail);
    form.append("phone", formData.phoneNumber);
    form.append("oPhone", formData.officePhone);
    form.append("address", "123 Main Strseet");
    form.append("dob", formData.dob);
    form.append("bloodGroup", formData.bloodGroup);
    form.append("nidNO", formData.nidNo1);
    form.append("password", "12345678");
    form.append("confirmPassword", "12345678");
    form.append("mobile", formData.mobile);

    // form.append("pcOrLaptop", formData.pcOrLaptop);
    form.append("keyboard", formData.keyboard);
    form.append("mouse", formData.mouse);
    form.append("headphone", formData.headphone);
    form.append("extra", formData.extra);

    const apiUrl =
      "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/create_admin";

    // Make a POST request to the API
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });

      console.log(response);

      if (response.ok) {
        const data = await response.json();
        setFormData({
          firstName: "",
          designation: "",
          role: "",
          salary: "",
          officeEmail: "",
          personalImage: "",
          nidCopy: "",
          personalEmail: "",
          phoneNumber: "",
          officePhone: "",
          dob: "",
          bloodGroup: "",
          nidNo1: "",
          mobile: "",
          pcOrLaptop: "",
          keyboard: "",
          mouse: "",
          headphone: "",
          extra: "",
        });

        // Use sweetalert2 for a success message
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Staff added successfully",
        });
      } else {
        console.error("API request failed.");

        // Use sweetalert2 for an error message
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add staff. Please try again.",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);

      // Use sweetalert2 for an error message
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while adding staff.",
      });
    }
  };
  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedOption(selectedId); // Store selected id in state

    // Find the corresponding win object and get its route
    const selectedWing = wing.find((win) => win.id === selectedId);

    if (selectedWing) {
      // Set the formData.roles to win.route and update the input field
      setFormData({
        ...formData,
        roles: selectedWing.route,
      });
    }
  };

  const handleSave = () => {
    // Update the input value when "Save" is clicked
    setFormData({
      ...formData,
      roles: selectedOption,
    });
  };
  return (
    <Box sx={{ mx: "20px", minHeight: "700px" }}>
      <Typography sx={{ fontSize: "26px", color: "var(--secondary-color)" }}>
        Staff Add
      </Typography>
      <form onSubmit={handleStaffAdd} encType="multipart/form-data">
        <Box sx={{ py: "20px" }}>
          <Typography
            sx={{
              fontSize: "15px",
              color: "var(--secondary-color)",
              fontWeight: "500",
            }}
          >
            Official Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Full name
              </label>
            </Box>

            <input
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="firstName"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "640px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Wing</label>
            </Box>

            <Box>
              <input
                placeholder="Enter Your wing"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="roles"
                value={formData.roles}
                onChange={(e) =>
                  setFormData({ ...formData, roles: e.target.value })
                }
              />
            </Box>
            <Box
              sx={{
                gap: "10px",
                width: "800px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                sx={{
                  ml: "30px",
                  textTransform: "capitalize",
                  color: "#ffff",
                  background: "#A3A4A9",
                  ":hover": {
                    background: "#A3A4A9",
                  },
                }}
                onClick={() => setOpenDialog(true)}
              >
                Wing selected
              </Button>
            </Box>
            <Dialog
              open={openDialog}
              TransitionComponent={Transition}
              onClose={() => setOpenDialog(false)}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              maxWidth="xl"
              sx={{
                height: { xs: "100vh", sm: "100vh", md: "100vh" },
              }}
            >
              <Box>
                <Container>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#ffff",
                        width: "450px",
                        height: "100%",
                        px: "20px",
                        borderRadius: "5px",
                        py: "15px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "22px",
                            fontWeight: "500",
                            color: "var(--profile-boday-color)",
                          }}
                        >
                          Select Your Wings
                        </Typography>
                        <form>
                          <div>
                            <select
                              style={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                paddingBottom: "10px",
                              }}
                              value={selectedOption}
                              onChange={handleSelectChange}
                            >
                              {wing.map((win) => (
                                <option
                                  key={win.id}
                                  value={`${win.id},${win.route}`}
                                >
                                  {win.route}
                                </option>
                              ))}
                            </select>
                          </div>

                          <Button
                            sx={{
                              borderRadius: "6px",
                              backgroundColor: "var(--primary-bonus-color)",
                              color: "var(--thard-color)",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              mt: "10px",
                              mb: "10px",
                              ":hover": {
                                backgroundColor: "var(--primary-bonus-color)",
                              },
                            }}
                          >
                            Add Another Select
                          </Button>
                          <Button
                            sx={{
                              ml: "10px",
                              borderRadius: "6px",
                              backgroundColor: "#A3A4A9",
                              color: "var(--thard-color)",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              mt: "5px",
                              ":hover": {
                                backgroundColor: "#A3A4A9",
                              },
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            onClick={handleSave}
                            sx={{
                              display: "block",
                              borderRadius: "6px",
                              width: "100%",
                              backgroundColor: "#A3A4A9",
                              color: "var(--thard-color)",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              mt: "5px",
                              ":hover": {
                                backgroundColor: "#A3A4A9",
                              },
                            }}
                          >
                            Save
                          </Button>
                        </form>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Dialog>
          </Box>

          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Designations
              </label>
            </Box>

            <input
              placeholder="Enter Your Designations"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name=" designation"
              value={formData.designation}
              onChange={(e) =>
                setFormData({ ...formData, designation: e.target.value })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Role</label>
            </Box>

            <input
              name="role"
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
              placeholder=" Your Full Role"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Salary
              </label>
            </Box>

            <input
              name="salary"
              value={formData.salary}
              onChange={(e) =>
                setFormData({ ...formData, salary: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Office Email
              </label>
            </Box>

            <input
              type="email"
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="officeEmail"
              value={formData.officeEmail}
              onChange={(e) =>
                setFormData({ ...formData, officeEmail: e.target.value })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Office Phone Number
              </label>
            </Box>

            <input
              type="text"
              placeholder="Enter Your Phone"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="officePhone"
              value={formData.officePhone}
              onChange={(e) =>
                setFormData({ ...formData, officePhone: e.target.value })
              }
            />
          </Box>
        </Box>
        <Box sx={{ py: "20px" }}>
          <Typography
            sx={{
              fontSize: "15px",
              color: "var(--secondary-color)",
              fontWeight: "500",
            }}
          >
            Personal Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Personal Image
              </label>
            </Box>

            <Box
              sx={{
                width: "20%",
              }}
            >
              {selectedImage1 ? (
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "var(--secondary-color)",
                    width: "20%",
                  }}
                  onClick={() => {
                    handleImageClick(selectedImage1);
                    setModalOpen(true);
                  }}
                >
                  <img
                    src={selectedImage1}
                    alt="Preview"
                    style={{ width: "70px", height: "65px" }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "var(--secondary-color)",
                    width: "20%",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        personalImage: e.target.files[0],
                      });
                      e.target.files[0] &&
                        setSelectedImage1(
                          URL.createObjectURL(e.target.files[0])
                        );
                    }}
                    name="personalImage"
                    // value={formData.personalImage}
                    style={{
                      backgroundColor: "#D9D9D9",
                      color: "white",
                      width: "100px",
                      padding: "4px",
                      color: "black",
                      height: "65px",
                    }}
                    className="customFileType"
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Personal Email
              </label>
            </Box>

            <input
              name="personalEmail"
              value={formData.personalEmail}
              onChange={(e) =>
                setFormData({ ...formData, personalEmail: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Phone Number
              </label>
            </Box>

            <input
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>DOB</label>
            </Box>

            <input
              name="phoneNumber"
              value={formData.dob}
              onChange={(e) =>
                setFormData({ ...formData, dob: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Blood Group
              </label>
            </Box>

            <input
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={(e) =>
                setFormData({ ...formData, bloodGroup: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                NID No
              </label>
            </Box>

            <input
              name="nidNo1"
              value={formData.nidNo1}
              onChange={(e) =>
                setFormData({ ...formData, nidNo1: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                NID Copy
              </label>
            </Box>
            <Box
              sx={{
                width: "20%",
              }}
            >
              {selectedImage2 ? (
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "var(--secondary-color)",
                    width: "20%",
                  }}
                  onClick={() => handleImageClick(selectedImage2)}
                >
                  <img
                    src={selectedImage2}
                    alt="Preview"
                    style={{ width: "70px", height: "65px" }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "var(--secondary-color)",
                    width: "20%",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setFormData({ ...formData, nidCopy: e.target.files[0] });
                      e.target.files[0] &&
                        setSelectedImage2(
                          URL.createObjectURL(e.target.files[0])
                        );
                    }}
                    style={{
                      backgroundColor: "#D9D9D9",
                      color: "black",
                      width: "100px",
                      height: "65px",
                      padding: "4px",
                    }}
                    className="customFileType"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ py: "20px" }}>
          <Typography
            sx={{
              fontSize: "15px",
              color: "var(--secondary-color)",
              fontWeight: "500",
            }}
          >
            Personal Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Mobile
              </label>
            </Box>

            <input
              name="mobile"
              value={formData.mobile}
              onChange={(e) =>
                setFormData({ ...formData, mobile: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                PC / Laptop
              </label>
            </Box>

            <input
              name="pcOrLaptop"
              value={formData.pcOrLaptop}
              onChange={(e) =>
                setFormData({ ...formData, pcOrLaptop: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Keyboard
              </label>
            </Box>

            <input
              name="keyboard"
              value={formData.keyboard}
              onChange={(e) =>
                setFormData({ ...formData, keyboard: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Mouse
              </label>
            </Box>

            <input
              name="mouse"
              value={formData.mouse}
              onChange={(e) =>
                setFormData({ ...formData, mouse: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Headphone
              </label>
            </Box>

            <input
              name="headphone"
              value={formData.headphone}
              onChange={(e) =>
                setFormData({ ...formData, headphone: e.target.value })
              }
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Extra
              </label>
            </Box>

            <input
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="extra"
              value={formData.extra}
              onChange={(e) =>
                setFormData({ ...formData, extra: e.target.value })
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            mb: "50px",
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "50%",
              color: "var(--thard-color)",
              backgroundColor: "var(--savStaff-color)",
              ":hover": {
                backgroundColor: "var(--savStaff-color)",
              },
            }}
          >
            Save Staff and Information
          </Button>
        </Box>
      </form>
      {modalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleCloseModal}
        >
          <img
            src={selectedImageURL}
            alt="Preview"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default StaffAdd;
