import {
  Box,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { BpCheckedIcon, BpIcon } from "../../../src/FlyFarTrips/common/styles";

const ItineraryPassengerDetails = ({
  reissueData,
  selectedPassengersId,
  setSelectedPassengersId,
}) => {
  const handleSelectOnePassenger = (isChecked, passengerId) => {
    if (isChecked) {
      setSelectedPassengersId([...selectedPassengersId, passengerId]);
    } else {
      setSelectedPassengersId(
        selectedPassengersId.filter((id) => id !== passengerId)
      );
    }
  };

  return (
    <Box
      sx={{
        mt: "24px",
        "& .MuiTableCell-root": {
          fontSize: "12px",
          color: "var(--text-medium)",
        },
        "& .MuiTableCell-head": {
          color: "var(--text-dark)",
          fontFamily: "productSans500 !important",
        },
        "& .MuiTableRow-root": {
          borderTop: "1px solid var(--table-border)",
        },
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ borderTop: "1px solid var(--table-border)" }}>
              {/* <TableCell width={"10%"}>
                <FormControlLabel
                  sx={{ mr: "5px", ml: "-5px" }}
                  value="end"
                  control={
                    <Checkbox
                      checked={
                        selectedPassengersId.length ===
                        reissueData.passengers.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPassengersId(
                            reissueData?.passengers?.map(
                              (passenger) => passenger.id
                            )
                          );
                        } else {
                          setSelectedPassengersId([]);
                        }
                      }}
                      disableRipple
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "var(--text-dark)",
                        pl: "5px",
                        fontFamily: "productSans500 !important",
                      }}
                    >
                      Select
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </TableCell> */}
              <TableCell>Passenger Name</TableCell>
              <TableCell>PAX Type</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Date of Birth</TableCell>
              {/* <TableCell sx={{ width: "11%" }}> Cabin Bag</TableCell>
              <TableCell sx={{ width: "11%" }}> Checked Bag</TableCell> */}
              <TableCell>Ticket No</TableCell>

              {/* <TableCell sx={{ width: "11%" }}>New Ticket No</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {reissueData?.passengers?.map((passenger, i) => (
              <TableRow key={i}>
                {/* <TableCell>
                  <FormControlLabel
                    sx={{ mr: "5px", ml: "-5px" }}
                    checked={selectedPassengersId.includes(passenger.id)}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleSelectOnePassenger(
                            e.target.checked,
                            passenger.id
                          );
                        }}
                        disableRipple
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                      />
                    }
                  />
                </TableCell> */}
                <TableCell>
                  {passenger?.firstName} {passenger?.lastName}
                </TableCell>
                <TableCell>
                  {passenger?.type === "ADT"
                    ? "Adult"
                    : passenger?.type === "CNN"
                    ? "Child"
                    : "Infant"}
                </TableCell>
                <TableCell>{passenger?.gender}</TableCell>
                <TableCell>
                  {moment(passenger?.dob).format("DD MMM YYYY")}
                </TableCell>
                {/* <TableCell>
                  <input
                    value={
                      passenger?.type === "ADT"
                        ? reissueData?.bookingInfo?.adultBag?.toString()
                        : passenger?.type === "CNN"
                        ? reissueData?.bookingInfo?.childBag?.toString()
                        : reissueData?.bookingInfo?.infantBag?.toString()
                    }
                    placeholder="Enter Baggage"
                    style={{
                      width: "100%",
                      outline: "none",
                      padding: "5px",
                      fontSize: "12px",
                      borderRadius: "1px",
                      border: "1px solid var(--table-border)",
                      textAlign: "left",
                    }}
                  />
                </TableCell> */}

                {/* <TableCell>
                  <input
                    value={
                      passenger?.type === "ADT"
                        ? reissueData?.bookingInfo?.adultBag?.toString()
                        : passenger?.type === "CNN"
                        ? reissueData?.bookingInfo?.childBag?.toString()
                        : reissueData?.bookingInfo?.infantBag?.toString()
                    }
                    placeholder="Enter Baggage"
                    style={{
                      width: "100%",
                      outline: "none",
                      padding: "5px",
                      fontSize: "12px",
                      borderRadius: "1px",
                      border: "1px solid var(--table-border)",
                      textAlign: "left",
                    }}
                  />
                </TableCell> */}
                <TableCell>{passenger?.ticketNumber}</TableCell>
                <TableCell>
                  {/* {passenger?.ticketNumber} */}
                  <input
                    // value="coming"
                    placeholder="Enter New Ticket No"
                    style={{
                      width: "100%",
                      outline: "none",
                      fontSize: "12px",
                      padding: "5px",
                      borderRadius: "1px",
                      border: "1px solid var(--table-border)",
                      textAlign: "left",
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ItineraryPassengerDetails;
