import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import Swal from "sweetalert2";

const TourPackage = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "11px",
    color: "#000",
    textAlign: "left",
  };

  const token = secureLocalStorage.getItem("Admin-Token");
  const [allTourPack, setAllTourPack] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package",
          {
            method: "GET",
            headers: {
              "Admin-Token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          setAllTourPack(result.data);
        } else {
          console.error("Request failed with status:", response.status);
          setError("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred");
      }
    };

    fetchData();
  }, [token]);

  // Delete tour package
  const handleDeletedTourPackage = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/delete-tour-package/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          const successData = await response.json();
          const successMessage =
            successData.message || "Tour Package deleted successfully";
          Swal.fire({
            title: "Deleted!",
            text: successMessage,
            icon: "success",
          });
        } else {
          // Handle error cases
          const errorData = await response.json();
          Swal.fire({
            title: "Error!",
            text: errorData.message || "Something went wrong.",
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error during delete operation:", error);
      Swal.fire({
        title: "Error!",
        text:
          error.response && error.response.status === 401
            ? "Admin token is invalid"
            : "An error occurred while deleting the deal.",
        icon: "error",
      });
    }
  };

  //  tour package update function
  const handleTourPackage = (id) => {
    secureLocalStorage.setItem("packageId", id);
    navigate("/trips/tourpackage/packageinformation", {
      state: { id: id },
    });
  };

  return (
    <Box>
      <Box
        my={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: "#202124", fontSize: "20px" }}>
          All Tour Package List
        </Typography>
        <Link to="/trips/tourpackage/packageinformation">
          <Button
            onClick={() => {
              secureLocalStorage.removeItem("packageId");
            }}
            sx={{
              fontSize: "15px",
              textTransform: "capitalize",
              bgcolor: "var(--trips-primary-color)",
              color: "#ffff",
              ":hover": {
                bgcolor: "var(--trips-primary-color)",
                color: "#ffff",
              },
            }}
          >
            Add Tour Package
          </Button>
        </Link>
      </Box>
      <Box>
        <TableContainer sx={{ mt: "30px", width: "100%" }}>
          <Table size="small" aria-label="simple table">
            <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
              <TableRow>
                <TableCell style={tabcellText} component="th" scope="row">
                  TourPackId
                </TableCell>
                <TableCell style={tabcellText}>Main Title</TableCell>
                <TableCell style={tabcellText}>Trip Type</TableCell>
                <TableCell style={tabcellText}>City</TableCell>
                <TableCell style={tabcellText}>country</TableCell>
                <TableCell style={tabcellText}>Journey Location</TableCell>
                <TableCell style={tabcellText}>Journey Start Date</TableCell>
                <TableCell style={tabcellText}>Journey End Date</TableCell>
                <TableCell style={tabcellText}>Trip Days</TableCell>
                <TableCell style={tabcellText}>Trip Nights</TableCell>
                <TableCell style={tabcellText}>Total Seat</TableCell>
                <TableCell style={tabcellText}>Minimum Traveler Age</TableCell>
                <TableCell style={tabcellText}>Maximum Traveler Age</TableCell>
                <TableCell style={tabcellText}>Adult Base Price</TableCell>
                <TableCell style={tabcellText}>Child Base Price</TableCell>
                <TableCell style={tabcellText}>Infant Base Price</TableCell>
                <TableCell style={tabcellText}>Package Discount</TableCell>
                <TableCell style={tabcellText}>Total Price</TableCell>
                <TableCell style={tabcellText}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTourPack?.map((tour, i) => (
                <TableRow
                  key={i}
                  sx={{
                    ":hover": {
                      backgroundColor: "#DDDDDD",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell style={{ cursor: "pointer" }}>
                    <Button
                      onClick={() => handleTourPackage(tour?.id)}
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "var(--primary-text)",
                        fontSize: "11px",
                        textTransform: "capitalize",
                        ":hover": {
                          backgroundColor: "var(--primary-color)",
                        },
                      }}
                    >
                      {tour?.tourpack_id}
                    </Button>
                  </TableCell>
                  <TableCell style={tabcellText}>{tour?.main_title}</TableCell>
                  <TableCell style={tabcellText}>{tour?.trip_type}</TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.country?.map((city) => (
                      <> {city?.city}, </>
                    ))}
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.country?.map((city) => (
                      <> {city?.country}, </>
                    ))}
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.journey_location}
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {moment(tour?.journey_start_date).format("DD MMM YY")}
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {moment(tour?.journey_end_date).format("DD MMM YY")}
                  </TableCell>
                  <TableCell style={tabcellText}>{tour?.trip_days}</TableCell>
                  <TableCell style={tabcellText}>{tour?.trip_nights}</TableCell>
                  <TableCell style={tabcellText}>{tour?.total_seat}</TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.minimum_traveler_age} years
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.maximum_traveler_age} years
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.adult_base_price} BDT
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.child_base_price} BDT
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.infant_base_price} BDT
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.package_discount} %
                  </TableCell>
                  <TableCell style={tabcellText}>
                    {tour?.adult_base_price +
                      tour?.child_base_price +
                      tour?.infant_base_price}{" "}
                    BDT
                  </TableCell>
                  <TableCell style={tabcellText}>
                    <EditIcon
                      // onClick={() => navigateToupdateTourPackage(deals?.id)}
                      style={{
                        fontSize: "20px",
                        color: "green",
                        cursor: "pointer",
                      }}
                    />

                    <DeleteIcon
                      onClick={() => handleDeletedTourPackage(tour?.id)}
                      style={{
                        fontSize: "20px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TourPackage;
