import { Box } from "@mui/material";
import React from "react";

const GroupFareInfo = () => {
  return (
    <Box>
      <Box>
        {/* Adult Base Fare inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Adult Base Fare
            </label>
          </Box>

          <input
            placeholder="Adult Base Fare"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Adult TAX inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Adult TAX
            </label>
          </Box>

          <input
            placeholder="Adult TAX"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Child Base Fare inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Child Base Fare
            </label>
          </Box>

          <input
            placeholder="Child Base Fare"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Child TAXinputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Child TAX
            </label>
          </Box>

          <input
            placeholder="Child TAX"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Infant Base Fare inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Infant Base Fare
            </label>
          </Box>

          <input
            placeholder="Infant Base Fare"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Infant TAX inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Infant TAX
            </label>
          </Box>

          <input
            placeholder="Infant Base Fare"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GroupFareInfo;
