import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";

const VoidPassengerDetails = ({ priceBreakdown }) => {
  // console.log(selectedPassengersId)

  return (
    <Box
      sx={{
        mt: "24px",
        "& .MuiTableCell-root": {
          fontSize: "12px",
          color: "var(--text-medium)",
        },
        "& .MuiTableCell-head": {
          color: "var(--text-dark)",
          fontFamily: "productSans500 !important",
        },
        "& .MuiTableRow-root": {
          borderTop: "1px solid var(--table-border)",
        },
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ borderTop: "1px solid var(--table-border)" }}>
              <TableCell>Passenger Name</TableCell>
              <TableCell>PAX Type</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Passport No</TableCell>
              <TableCell>Passport Expire Date</TableCell>
              <TableCell>Ticket No</TableCell>
              <TableCell>Service Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceBreakdown?.map((passenger, i) => {
            //   console.log(passenger?.newServiceFee);
              return (
                <TableRow key={i}>
                  <TableCell>
                    {passenger?.firstName} {passenger?.lastName}
                  </TableCell>
                  <TableCell>
                    {passenger?.type === "ADT"
                      ? "Adult"
                      : passenger?.type === "CNN"
                      ? "Child"
                      : "Infant"}
                  </TableCell>
                  <TableCell>{passenger?.gender}</TableCell>
                  <TableCell>
                    {moment(passenger?.dob).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell>{passenger?.passNo}</TableCell>
                  <TableCell>
                    {moment(passenger?.passEx).format("DD MMM YYYY")}
                  </TableCell>

                  <TableCell>{passenger?.ticketNumber}</TableCell>
                  <TableCell>{passenger?.newServiceFee}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VoidPassengerDetails;
