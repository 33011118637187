import {
  Box,
  Button,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { CircleLoader } from "react-spinners";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const ExclusiveDeals = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "11px",
    color: "#000",
    textAlign: "left",
  };

  const token = secureLocalStorage.getItem("Admin-Token");
  const navigate = useNavigate();
  const [getDealsData, setGetDealsData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/get-deals",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setGetDealsData(data?.data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [token]); // Depend
  const navigateToupdateTourPackage = (id) => {
    navigate(`${id}`);
  };

  // Delete deals
  const handleDeletedDeals = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/delete-deals/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          // Extract success message from API response
          const successData = await response.json();
          const successMessage =
            successData.message || "ExclusiveDeals deleted successfully";

          // Display dynamic success message
          Swal.fire({
            title: "Deleted!",
            text: successMessage,
            icon: "success",
          });
        } else {
          // Handle error cases
          const errorData = await response.json();
          Swal.fire({
            title: "Error!",
            text: errorData.message || "Something went wrong.",
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error during delete operation:", error);
      Swal.fire({
        title: "Error!",
        text:
          error.response && error.response.status === 401
            ? "Admin token is invalid"
            : "An error occurred while deleting the deal.",
        icon: "error",
      });
    }
  };



  return (
    <Box>
      <Box
        sx={{
          my: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "20px" }}>Exclusive Deals List</Typography>
        <Link to="/trips/cms/exclusivedeals/adddeals">
          <Button
            sx={{
              textTransform: "capitalize",
              color: "white",
              height: "30px",
              fontSize: "13px",
              bgcolor: "var(--trips-primary-color)",
              ":hover": {
                color: "white",
                bgcolor: "var(--trips-primary-color)",
              },
            }}
          >
            Add Deals
          </Button>
        </Link>
      </Box>
      <TableContainer sx={{ mt: "30px", width: "100%" }}>
        <Table size="small" aria-label="simple table">
          <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
            <TableRow>
              <TableCell style={tabcellText} component="th" scope="row">
                Deals ID
              </TableCell>
              <TableCell style={tabcellText}>Type</TableCell>
              <TableCell style={tabcellText}>Title</TableCell>
              <TableCell style={tabcellText}>Coupon Code</TableCell>
              <TableCell style={tabcellText}>Main Image</TableCell>
              <TableCell style={tabcellText}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getDealsData?.map((deals, i) => (
              <TableRow
                key={i}
                sx={{
                  ":hover": {
                    backgroundColor: "#DDDDDD",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell style={{ cursor: "pointer" }}>
                  <Button
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "var(--primary-text)",
                      fontSize: "11px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "var(--primary-color)",
                      },
                    }}
                  >
                    {deals.id.substring(0, 5)}
                  </Button>
                </TableCell>
                <TableCell style={tabcellText}>{deals?.type}</TableCell>
                <TableCell style={tabcellText}>{deals?.title}</TableCell>
                <TableCell style={tabcellText}>{deals?.coupon_code}</TableCell>
                <TableCell style={tabcellText}>
                  <img
                    src={deals.image}
                    style={{ height: "50px", width: "50px" }}
                  />
                </TableCell>
                <TableCell style={tabcellText}>
                  <EditIcon
                    onClick={() => navigateToupdateTourPackage(deals?.id)}
                    style={{
                      fontSize: "20px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  />

                  <DeleteIcon
                    onClick={() => handleDeletedDeals(deals?.id)}
                    style={{
                      fontSize: "20px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExclusiveDeals;
