import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const TourPlanes = () => {
  const [loading, setLoading] = useState(false);
  const packageId = secureLocalStorage.getItem("packageId");
  const adminToken = secureLocalStorage.getItem("Admin-Token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!packageId) {
      Swal.fire({
        icon: "info",
        text: "Please Complete Package Information First ",
        confirmButtonText: "OK",
      }).then(function () {
        navigate("/trips/tourpackage/packageinformation");
      });
    }
  }, []);

  const [tourDays, setTourDays] = useState([
    {
      day_title: "",
      events: [
        {
          plan_id: "",
          day_event_title: "",
          day_event_time: "",
          day_event_location: "",
          day_event_description: "",
        },
      ],
    },
  ]);

  useEffect(() => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package/${packageId}`;
    fetch(url).then((res) =>
      res.json().then((data) => {
        if (data?.data?.tour_plan.length !== 0) {
          setTourDays(data?.data?.tour_plan);
        }
      })
    );
  }, []);

  const handleday_titleChange = (e, dayIndex) => {
    const { value } = e.target;
    const updatedDays = [...tourDays];
    updatedDays[dayIndex].day_title = value;
    setTourDays(updatedDays);
  };

  const handleEventTitleChange = (e, dayIndex, eventIndex) => {
    const { name, value } = e.target;
    const updatedDays = [...tourDays];
    updatedDays[dayIndex].events[eventIndex][name] = value;
    setTourDays(updatedDays);
  };

  const removeEvent = async (dayIndex, eventIndex, event) => {
    const updatedDays = [...tourDays];
    updatedDays[dayIndex].events.splice(eventIndex, 1);
    setTourDays(updatedDays);

    {
      event?.id &&
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/delete-tour-plans-events/${event?.id}`;
            fetch(url, {
              method: "DELETE",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                "Admin-Token": `${adminToken}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data?.success === true) {
                  Swal.fire({
                    title: "Deleted!",
                    text: data?.message,
                    icon: "success",
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: data?.data.message,
                    confirmButtonText: "OK",
                  });
                }
              });
          }
        });
    }
  };

  const addEvent = (dayIndex) => {
    const updatedDays = [...tourDays];
    updatedDays[dayIndex].events.push({
      day_event_title: "",
      day_event_time: "",
      day_event_location: "",
      day_event_description: "",
    });
    setTourDays(updatedDays);
  };

  const addDayPlan = () => {
    setTourDays([
      ...tourDays,
      {
        day_title: "",
        events: [
          {
            day_event_title: "",
            day_event_time: "",
            day_event_location: "",
            day_event_description: "",
          },
        ],
      },
    ]);
  };

  const removeDayPlan = (dayIndex) => {
    const updatedDays = [...tourDays];
    updatedDays.splice(dayIndex, 1);
    setTourDays(updatedDays);
  };

  const filteredEvents = tourDays.map((day) => ({
    ...day,
    events: day.events.filter((event) => !event.id),
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/create-tour-plan/${packageId}`;
    let body = JSON.stringify(tourDays[0]?.day_id ? filteredEvents : tourDays);
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Admin-Token": `${adminToken}`,
      },
      body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(`/trips/tourpackage/inclusion`);
          });
        } else {
          Swal.fire({
            icon: "error",
            text: data?.data.message,
            confirmButtonText: "OK",
          });
        }
        setLoading(false);
      });
  };

  const updateEvent = async () => {
    const updateEventData = tourDays.map((event) => {
      const { day_id, day_title, ...rest } = event;
      return rest;
    });
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/update-tour-plan`;
    const body = JSON.stringify(updateEventData[0]);
    await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Admin-Token": `${adminToken}`,
      },
      body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(`/trips/tourpackage/inclusion`);
          });
        } else {
          Swal.fire({
            icon: "error",
            text: data?.data.message,
            confirmButtonText: "OK",
          });
        }
        setLoading(false);
      });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {tourDays?.map((day, dayIndex) => (
          <div key={dayIndex}>
            {/* Day Title input */}
            <Grid container item>
              <Grid item xs={12} sm={6} md={5}>
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "#858585",
                    borderBottom: "1px solid #DADCE0",
                    paddingBottom: "5px",
                    marginTop: "24px",
                  }}
                >
                  Day 1 Title
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={7}>
                <input
                  type="text"
                  style={{
                    outline: "none",
                    border: "none",
                    width: "100%",
                    borderBottom: "1px solid #DADCE0",
                    paddingBottom: "5px",
                    marginTop: "24px",
                  }}
                  placeholder="Enter Day Title"
                  value={day.day_title}
                  onChange={(e) => handleday_titleChange(e, dayIndex)}
                />
              </Grid>
            </Grid>
            {day.events.map((event, eventIndex) => (
              <Box key={eventIndex}>
                <Grid
                  container
                  alignItems="end"
                  style={{ background: "" }}
                  mb={3}
                >
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                    >
                      Day 1 Even Title
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <input
                      type="text"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                      placeholder=" Enter Day Even Title "
                      name="day_event_title"
                      value={event.day_event_title}
                      onChange={(e) =>
                        handleEventTitleChange(e, dayIndex, eventIndex)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                    >
                      Day 1 Even Time
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <input
                      type="text"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                      placeholder=" Enter Day Even Time "
                      name="day_event_time"
                      value={event.day_event_time}
                      onChange={(e) =>
                        handleEventTitleChange(e, dayIndex, eventIndex)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                    >
                      Day 1 Even Location
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <input
                      type="text"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                      placeholder=" Enter Day Even Location"
                      name="day_event_location"
                      value={event.day_event_location}
                      onChange={(e) =>
                        handleEventTitleChange(e, dayIndex, eventIndex)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#858585",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                    >
                      Day 1 Even Description
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <input
                      type="text"
                      style={{
                        outline: "none",
                        border: "none",
                        width: "100%",
                        borderBottom: "1px solid #DADCE0",
                        paddingBottom: "5px",
                        marginTop: "24px",
                      }}
                      placeholder=" Enter Day Even Description"
                      name="day_event_description"
                      value={event.day_event_description}
                      onChange={(e) =>
                        handleEventTitleChange(e, dayIndex, eventIndex)
                      }
                    />
                  </Grid>
                </Grid>

                <Box mb={2}>
                  <Button
                    onClick={() => addEvent(dayIndex)}
                    sx={{
                      marginRight: "10px",
                      width: "130px",
                      height: "35px",
                      background: "tomato",
                      color: "#fff",
                      fontSize: { xs: 14, sm: 14, md: 15 },
                      border: "none",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "tomato",
                        color: "#fff",
                      },
                    }}
                  >
                    Add Day Event
                  </Button>
                  <Button
                    onClick={() => removeEvent(dayIndex, eventIndex, event)}
                    sx={{
                      width: "150px",
                      marginRight: "5px",
                      height: "35px",
                      background: "#222222",
                      color: "#fff",
                      fontSize: { xs: 14, sm: 14, md: 15 },
                      border: "none",
                      borderRadius: "3px",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "#222222",
                        color: "#fff",
                      },
                    }}
                  >
                    Remove Day Event
                  </Button>

                  {tourDays[0]?.day_id && (
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "var(--savStaff-color)",
                        color: "var(  --thard-color)",
                        boxShadow: "none",
                        textTransform: "capitalize",
                        width: "170px",
                        marginRight: "5px",
                        height: "35px",
                      }}
                      onClick={() => updateEvent()}
                    >
                      Update Tour Plans
                    </Button>
                  )}
                </Box>
              </Box>
            ))}

            <Box>
              <Button
                onClick={addDayPlan}
                sx={{
                  marginRight: "10px",
                  width: "130px",
                  height: "35px",
                  background: "green",
                  color: "#fff",
                  fontSize: { xs: 14, sm: 14, md: 15 },
                  border: "none",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "tomato",
                    color: "#fff",
                  },
                }}
              >
                Add Day Plan
              </Button>
              <Button
                sx={{
                  width: "150px",
                  marginRight: "5px",
                  height: "35px",
                  background: "tomato",
                  color: "#fff",
                  fontSize: { xs: 14, sm: 14, md: 15 },
                  border: "none",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#222222",
                    color: "#fff",
                  },
                }}
                onClick={() => removeDayPlan(dayIndex)}
              >
                Remove Day Plan
              </Button>
            </Box>
          </div>
        ))}

        {/* Add Day Plan button */}

        {/* Submit button */}

        <Box sx={{ textAlign: "right" }} my={2}>
          {loading ? (
            <Button
              disabled
              variant="contained"
              sx={{
                bgcolor: "var(--savStaff-color)",
                color: "var(  --thard-color)",
                boxShadow: "none",
                textTransform: "capitalize",
                width: "30%",
              }}
            >
              Processing....
            </Button>
          ) : (
            <>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "var(--savStaff-color)",
                  color: "var(  --thard-color)",
                  boxShadow: "none",
                  textTransform: "capitalize",
                  width: "30%",
                }}
              >
                Submit Package Information
              </Button>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default TourPlanes;
