import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import VisaRoute from "./VisaRoute";

const AddVisaChecklist = () => {
  const token = secureLocalStorage.getItem("token");
  const visaId = secureLocalStorage.getItem("visaId");
  const ChecklistId = secureLocalStorage.getItem("CheckListId");
  const [data, setData] = useState([
    {
      name: "",
      checkLists: "",
    },
  ]);

  const [checkListType, setCheckListType] = useState("");

  const handleAddAnother = () => {
    setData([...data, { name: "", checkList: "" }]);
  };

  console.log("dfgdsgsadgdf", ChecklistId);
  const handleRemoveChecklist = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleVisa_titleChange = (e, index) => {
    const { name, value } = e.target;
    setData((prevData) => {
      const newData = [...prevData];
      newData[index][name] = value;
      return newData;
    });
  };

  const handleCeckListType = () => {
    const body = {
      checkListType: checkListType,
    };
    fetch(
      `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-visa-check-list-type/${visaId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        secureLocalStorage.setItem("CheckListId", data?.data);
        console.log(data);
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };
  const handleVisaChecklists = () => {
    const body = {
      checkLists: [...data],
    };
    fetch(
      `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-visa-check-list/${ChecklistId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        secureLocalStorage.setItem("CheckListId", data?.data);
        console.log(data);
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };

  return (
    <Box sx={{ px: "30px" }}>
      <VisaRoute />
      <form onSubmit={handleVisaChecklists}>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "95%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Job Holder
                </label>
              </Box>
              <input
                placeholder="Enter Job Holder"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                onChange={(e) => setCheckListType(e.target.value)}
              />
            </Box>

            <Box sx={{ mt: "23px", ml: "15px", display: "flex", gap: "10px" }}>
              <Button
                sx={{
                  fontSize: "11px",
                  textTransform: "capitalize",
                  bgcolor: "#D9D9D9",
                  whiteSpace: "nowrap",
                  color: "var(--meta-black)",
                  ":hover": {
                    bgcolor: "#D9D9D9",
                    color: "var(--meta-black)",
                  },
                }}
                onClick={() => handleCeckListType()}
              >
                submit
              </Button>
            </Box>
          </Box>

          <Box>
            {data?.map((list, index) => (
              <Box sx={{ width: "100%" }} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Check List Name
                    </label>
                  </Box>
                  <input
                    placeholder={`Check List`}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="name"
                    value={list?.name}
                    onChange={(e) => handleVisa_titleChange(e, index)}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: "1px solid #DADCE0",
                      pb: "15px",
                      mt: "20px",
                      width: "95%",
                    }}
                  >
                    <Box sx={{ width: "700px" }}>
                      <label style={{ color: "#5F6368", fontSize: "12px" }}>
                        Check List
                      </label>
                    </Box>
                    <input
                      placeholder={`Check List`}
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                      }}
                      name="checkLists"
                      value={list?.checkLists}
                      onChange={(e) => handleVisa_titleChange(e, index)}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    mt: "23px",
                    
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Button
                    sx={{
                      fontSize: "11px",
                      textTransform: "capitalize",
                      bgcolor: "#D9D9D9",
                      whiteSpace: "nowrap",

                      color: "var(--meta-black)",
                      ":hover": {
                        bgcolor: "#D9D9D9",
                        color: "var(--meta-black)",
                      },
                    }}
                    onClick={handleAddAnother}
                  >
                    Add Check List
                  </Button>
                  {index !== 0 && (
                    <Button
                      sx={{
                        fontSize: "11px",
                        textTransform: "capitalize",
                        bgcolor: "#D9D9D9",
                        whiteSpace: "nowrap",
                        color: "var(--meta-black)",
                        ":hover": {
                          bgcolor: "#D9D9D9",
                          color: "var(--meta-black)",
                        },
                      }}
                      onClick={() => handleRemoveChecklist(index)}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pt: "20px",
          }}
        >
          <Button
            onClick={() => handleVisaChecklists()}
            sx={{
              bgcolor: "var(--trips-secondary-color)",
              color: "white",
              textTransform: "capitalize",
              width: "200px",
              ":hover": {
                bgcolor: "var(--trips-secondary-color)",
                color: "white",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddVisaChecklist;
