import { Grid } from "@material-ui/core";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";

const UpdateTravler = () => {
  const token = secureLocalStorage.getItem("Admin-Token");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const { id } = useParams();
  const [singleDealsData, setSingleDealsData] = useState(null);

  const [formData, setFormData] = useState({
    main_title: "",
    images: "",
    short_description: "",
    is_showIs: "",
    type: "",
  });
  console.log(formData);

  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or event target is undefined");
      return;
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (singleDealsData) {
      setFormData((prevData) => ({
        ...prevData,
        main_title: singleDealsData.main_title || "",
      }));
    }
  }, [singleDealsData]);

  const handleThumbnailImageChange = (event) => {
    setFormData({
      ...formData,
      images: event.target.files[0],
    });
  };

  console.log(formData);

  const handleSelectedCategory = (event) => {
    setFormData({
      ...formData,
      type: event.target.value,
    });
  };

  const handleSelectedIsShow = (event) => {
    setFormData({
      ...formData,
      is_showIs: event.target.value,
    });
  };

  console.log(formData);

  const [storiesId, setStoriesId] = useState("");

  const handlePopularDestination = async (event) => {
    event.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("image", formData.images);
      formDataToSend.append("main_title", formData.main_title);
      formDataToSend.append("short_description", formData.short_description);
      formDataToSend.append("type", formData.type);
      formDataToSend.append("is_show", parseInt(formData.is_showIs));
      const response = await axios.put(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/stories/update-stories/${id}`,
        formDataToSend,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Admin-Token": `${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });

        setFormData({
          images: "",
          main_title: "",
          short_description: "",
          type: "",
          is_showIs: "",
        });

        console.log(response.data);
        setStoriesId(response?.data?.data?.id);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });

        setFormData({
          images: "",
          main_title: "",
          short_description: "",
          type: "",
          is_showIs: "",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });

      setFormData({
        images: "",
        main_title: "",
        short_description: "",
        type: "",
        is_showIs: "",
      });
    }
  };

  // sub image 
  const handleImageChange = async (id, i, e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/stories/update-stories-image/${id}`,
        {
          method: "PUT",
          headers: {
            "Admin-Token": `${token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        const result = await response.json();
        const updatedImages = [...images];
        updatedImages[i] = file;
        setImages(updatedImages);
        Swal.fire({
          icon: "success",
          title: "Image Uploaded",
          text: result?.message || "Your image has been uploaded successfully!",
        }).then(() => {
          setLoading(true);
          window.location.reload();
        });
      } else {
        console.error("Image upload failed:", response.statusText);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error uploading the image. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the image. Please try again.",
      });
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/stories/get-stories/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSingleDealsData(data?.data[0]);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [token]);

  console.log("singleDealsData stries data", singleDealsData);
  return (
    <Box>
      <ToastContainer />

      <Box>
        <Box sx={{ my: "10px", mt: 7 }}>
          <Typography>Update Travel Stories</Typography>
        </Box>

        {/* forms area */}

        <form onSubmit={handlePopularDestination}>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                    color: "#A3A4A9",
                  }}
                >
                  <Box sx={{ width: "300px" }}>
                    <label style={{ fontSize: "12px" }}>Image</label>
                  </Box>

                  <Box
                    sx={{
                      width: "250px",
                      height: { md: "160px", sm: "210px", xs: "210px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <label
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleThumbnailImageChange(e)}
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "none",
                        }}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          height: { md: "160px", sm: "210px", xs: "210px" },
                        }}
                      >
                        {singleDealsData?.images ? (
                          <img
                            src={singleDealsData.images}
                            alt="Thumbnail"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          />
                        ) : (
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: {
                                md: "210px",
                                sm: "210px",
                                xs: "210px",
                              },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "10px",
                              padding: "30px",
                              textAlign: "center",
                              borderRadius: "10px",
                            }}
                          >
                            Upload Thumbnail Image
                          </Skeleton>
                        )}
                      </Box>
                    </label>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #A3A4A9",

                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                      Selected Is show
                    </label>
                  </Box>
                  <select
                    style={{
                      width: "100%",
                      outline: "none",
                      border: "none",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      color: "#A3A4A9",
                    }}
                    onChange={handleSelectedIsShow}
                  >
                    <option value="">Selected Is show</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box sx={{ mt: -4 }}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #A3A4A9",

                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                      Type
                    </label>
                  </Box>
                  <select
                    style={{
                      width: "100%",
                      outline: "none",
                      border: "none",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      color: "#A3A4A9",
                    }}
                    onChange={handleSelectedCategory}
                  >
                    <option value="">Selected Flight</option>
                    <option value="flight">Flight</option>
                    <option value="tour">Tour</option>
                    <option value="hotel">Hotel</option>
                  </select>
                </Box>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ mt: -4 }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: "1px solid #A3A4A9",
                      mt: -4,
                      gap: "10px",
                      width: "100%",
                      color: "#A3A4A9",
                    }}
                  >
                    <Box sx={{ width: "700px" }}>
                      <label style={{ fontSize: "12px" }}>Title</label>
                    </Box>

                    <input
                      placeholder="title"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                        width: "100%",
                      }}
                      value={formData.main_title}
                      onChange={handleInputChange}
                      name="main_title"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box>
                <Box
                  sx={{
                    mt: -4,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                    color: "#A3A4A9",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ fontSize: "12px" }}>
                      Short Descriptions
                    </label>
                  </Box>
                  <JoditEditor
                    ref={editor}
                    value={formData.short_description}
                    onChange={(content) =>
                      handleInputChange({ short_description: content })
                    }
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  sx={{
                    textTransform: "capitalize",
                    bgcolor: "var(--trips-primary-color)",
                    color: "white",
                    ":hover": {
                      background: "var(--trips-primary-color)",
                      color: "white",
                    },
                  }}
                >
                  Update Travel Stories
                </Button>
              </Box>
            </Grid>

            {/* pictures */}
          </Grid>
        </form>

        {/* Box areas  */}

        {/* uploader images area */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            ml: 0.5,
            gap: "12px",
            my: 3,
          }}
        >
          {singleDealsData?.image?.map((sub, i) => (
            <Box
              key={i} // Don't forget to add a unique key when using map
              sx={{
                mt: -5,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                color: "#A3A4A9",
              }}
            >
              <Box
                sx={{
                  width: "250px",
                  bgcolor: "var(--primary-color)",
                  height: { md: "160px", sm: "210px", xs: "210px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(sub?.id, i, e)}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { md: "160px", sm: "210px", xs: "210px" },
                    }}
                  >
                    {sub.image_url? (
                      <img
                        src={sub?.image_url}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Typography>Upload Image</Typography>
                    )}
                  </Box>
                </label>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateTravler;
