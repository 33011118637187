import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const EmployeeInfoChange = () => {
  const { id } = useParams();
  const location = useLocation();
  const allAdmin = location.state?.allAdmin;
  console.log(id);
  const token = secureLocalStorage.getItem("token");
  const [formData, setFormData] = useState({
    status: allAdmin?.information?.Status || "",
    email: allAdmin?.information?.OEmail || "",
    password: "",
    phoneNumber: allAdmin?.information?.OPhone || "",
  });
  console.log("input info data", formData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInfoUpdate = async (e) => {
    e.preventDefault();

    const requestBody = {
      status: formData.status,
      oEmail: formData.email,
      password: "123456789",
      oPhone: formData.phoneNumber,
    };

    try {
      const response = await fetch(
        `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/update_adminInfo/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      console.log("response", response);
      console.log("requestBody", requestBody);

      if (response.ok) {
        Swal.fire("Success", "Information updated successfully", "success");
      } else {
        const errorData = await response.json();
        Swal.fire(
          "Error",
          errorData.message || "Failed to update information",
          "error"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Error", "An error occurred", "error");
    }
  };
  return (
    <Box sx={{ mx: "20px", minHeight: "700px" }}>
      <Typography sx={{ fontSize: "22px", color: "var(--secondary-color)" }}>
        Staff Information Update
      </Typography>
      <form onSubmit={handleInfoUpdate}>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Status</label>
          </Box>

          <input
            placeholder="Change Your Status"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.status}
            onChange={handleInputChange}
            name="status"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              O. Email
            </label>
          </Box>

          <input
            type="email"
            placeholder="Change Your Email"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.email}
            onChange={handleInputChange}
            name="email"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              O. Password
            </label>
          </Box>

          <input
            type="password"
            placeholder="Change Your Password"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.password}
            onChange={handleInputChange}
            name="password"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              O. Phone Number
            </label>
          </Box>

          <input
            placeholder="Change Your Number"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.phoneNumber}
            onChange={handleInputChange}
            name="phoneNumber"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            mt: "20px",
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "30%",
              color: "var(--thard-color)",
              textTransform: "capitalize",
              backgroundColor: "var(--savStaff-color)",
              ":hover": {
                backgroundColor: "var(--savStaff-color)",
              },
            }}
          >
            Save Changes Information
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EmployeeInfoChange;
