import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
//import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { CircleLoader } from "react-spinners";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress'
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
const DeactiveUserList = ({allUsers,loading}) => {
  const maxVisibleCharacters = 4;
  const [showPassword, setShowPassword] = useState(false);
  const token = secureLocalStorage.getItem("Admin-Token");
  const [visiblePasswords, setVisiblePasswords] = useState({});

  // ... other code

  const togglePasswordVisibility = (userId) => {
    setVisiblePasswords((prevVisiblePasswords) => ({
      ...prevVisiblePasswords,
      [userId]: !prevVisiblePasswords[userId],
    }));
  };
  const [values, setValues] = useState([]);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "200px",
        }}
      >

          <CircularProgress />
      </Box>
    );
  }
  console.log(allUsers)





  const handleActivateUser = (id) => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/admin-route/update-user/${id}`;
  
    // Define the request options
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Admin-Token":`${token}`,
      },
      body: JSON.stringify({
        isActive: 1,
      }),
    };
  
    // Make the PUT request
    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        // Check if the deactivation was successful
        if (data.success) {
          // Show SweetAlert success message
          Swal.fire({
            title: 'User Acitvated',
            text: 'The user has been successfully activated.',
            icon: 'success',
          });

          window.location.reload();
        } else {
          // Handle other cases if needed
          console.error('Activation failed:', data.message);
        }
      })
      .catch(error => {
        // Handle errors
        console.error('Error:', error);
      });
  };
  























  return (
    <Box mt={2}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflowX: "auto" }}
          size="small"
          aria-label="simple table"
        >
          <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
            <TableRow>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>
                <Button
                  sx={{
                    bgcolor: "var(--primary-color)",
                    color: "var(--primary-text)",
                    fontSize: "11px",
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "var(--primary-text)",
                    },
                  }}
                >
                  User Id
                </Button>
              </TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Status</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Name</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Username</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Password</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Operation</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Phone Number</TableCell>

              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>DOB</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Address</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Balance</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Passport Number</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Passport Expire Date</TableCell>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>Traveler Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& td, & th": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {allUsers.map((user) => (
              <TableRow
                key={user.userId}
                sx={{
                  width: "100%",
                  ":hover": {
                    backgroundColor: "#DDDDDD",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell component="th" scope="row" className="table-cell">
                  <Button
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "var(--primary-text)",
                      fontSize: "11px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "var(--primary-text)",
                      },
                    }}
                  >
                    {user?.userId}
                  </Button>
                </TableCell>

                <TableCell className="table-cell">
                  {user?.is_active === 0&&"Deactive"}
                </TableCell>
                <TableCell className="table-cell">{user?.user_name}</TableCell>
                <TableCell className="table-cell">{user?.email}</TableCell>
              
                <TableCell className="table-cell">
                {visiblePasswords[user.userId]
                    ? user.password
                    : user?.password.slice(0, 10).replace(/./g, "*")}
                <Button sx={{color:"red"}}  onClick={() => togglePasswordVisibility(user.userId)}>
                {visiblePasswords[user.userId] ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                </Button>
              </TableCell>
              <TableCell className="table-cell">
                <Button disabled={user?.is_active===1?true:false} onClick={()=>handleActivateUser(user?.id)} sx={{fontSize:"11px",boxShadow:"none",textTransform:"capitalize",bgcolor:"green"}} variant="contained" >
                 Active
                </Button>
              </TableCell>
                <TableCell className="table-cell">{user?.phone}</TableCell>
                <TableCell className="table-cell">
                  {user?.dob ? user?.dob.slice(0, 10) : "N/A"}
                </TableCell>
                <TableCell className="table-cell">
                  {user?.address ? user?.address : "N/A"}
                </TableCell>
                <TableCell className="table-cell">{user?.wallet?user?.wallet:0}</TableCell>
                <TableCell className="table-cell">
                  {user?.passport_number?user?.passport_number:"N/A"}
                </TableCell>
                <TableCell className="table-cell">
                  {user?.passport_expiry_date
                    ? user?.passport_expiry_date.slice(0, 10)
                    : "N/A"}
                </TableCell>
                <TableCell className="table-cell">
                  {user?.partner_count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeactiveUserList;
