import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { CircleLoader } from "react-spinners";
import Swal from "sweetalert2";

const MainBannerSlider = () => {
  const token = secureLocalStorage.getItem("Admin-Token");
  const [loading, setLoading] = useState(false);
  const url =
    "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/carousel/get-carousel";
  const [images, setImages] = useState([]);
  const [imageState, setImageState] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setImageState(res?.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [url]);

  const handleImageChange = async (id, index, e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("caption", "Images");
    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/carousel/update-carousel/${id}`,
        {
          method: "PUT",
          headers: {
            "Admin-Token": `${token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        const result = await response.json();
        const updatedImages = [...images];
        updatedImages[index] = file;
        setImages(updatedImages);
        Swal.fire({
          icon: "success",
          title: "Image Uploaded",
          text: result?.message || "Your image has been uploaded successfully!",
        }).then(() => {
          setLoading(true);
          window.location.reload();
        });
      } else {
        console.error("Image upload failed:", response.statusText);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error uploading the image. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the image. Please try again.",
      });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "200px",
        }}
      >
        <CircleLoader size={350} color="red" />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: "20px" }}>
      <Box>
        <Typography>Main Image</Typography>
      </Box>
      <Box sx={{ my: "10px" }}>
        <Grid container spacing={1}>
          {imageState.data &&
            imageState.data.map((data, index) => (
              <Grid item xs={12} sm={6} md={2.4} key={data.id}>
                <Box
                  sx={{
                    width: "100%",
                    height: { md: "210px", sm: "210px", xs: "210px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(data?.id, index, e)}
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "none",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        height: { md: "210px", sm: "210px", xs: "210px" },
                      }}
                    >
                      {data.image ? (
                        <img
                          src={data.image}
                          alt={`Image ${data.id}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: "100%",
                            height: { md: "210px", sm: "210px", xs: "210px" },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "30px",
                            textAlign: "center",
                            borderRadius: "10px",
                          }}
                        >
                          Upload Main Banner Slider Image
                        </Skeleton>
                      )}
                    </Box>
                  </label>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MainBannerSlider;
