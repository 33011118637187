import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const styles = {
  root: {
    height: "calc(100vh - 200px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  background: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "4px",
};

const HRMPro = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `http://192.168.1.103:5000/api/admin/companies`;
      console.log(url);
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log(data?.data);
        setData(data?.data);
      } catch (error) {
        console.error("Error fetching  data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpenModal = (staff) => {
    // setFormInfo(staff);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const columns = [
    { field: "firstname", headerName: "First name", width: 150 },
    {
      field: "lastname",
      headerName: "Last name",
      width: 150,
      suppressSizeToFit: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      suppressSizeToFit: true,
    },
    {
      field: "companyName",
      headerName: "Company Name",

      width: 150,
      suppressSizeToFit: true,
    },
    {
      field: "companyType",
      headerName: "Company Type",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "address",
      headerName: "Address",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "employeeCount",
      headerName: "Employee Count",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "tin",
      headerName: "TIN",
      suppressSizeToFit: true,
      width: 150,

      renderCell: (params) => {
        return (
          <Box>
            <a
              href={params.row?.tin}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              View
            </a>
          </Box>
        );
      },
    },
    // {
    //   field: "employeeCount",
    //   headerName: "Employee Count",
    //   suppressSizeToFit: true,
    //   width: 150,
    // },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex" }}>
            <EditIcon
              style={{
                color: "var(--secondary-color)",
                fontSize: "18px",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOpenModal(params.row);
                // handleSubmitEdit(params.row);
              }}
            />
            <DeleteIcon
              style={{
                color: "red",
                fontSize: "18px",
                marginLeft: "10px",
                cursor: "pointer",
              }}
              // onClick={() => handelDelete(params.row)}
            />
          </Box>
        );
      },
    },
  ];

  console.log(data);
  return (
    <Box>
      <Box
        sx={{
          mt: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            color: "var(--secondary-color)",
          }}
        >
          All Company Details
        </Typography>
        <Button
          sx={{
            color: "var(--thard-color)",
            backgroundColor: "var(--savStaff-color)",
            "&:hover": {
              backgroundColor: "var(--savStaff-color)",
            },
          }}
          onClick={() => navigate("/tech/products/hrmPro/add")}
        >
          Add Company
        </Button>
      </Box>

      <Box sx={{ width: "100%", mt: 5 }}>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          scrollbarSize={5}
          style={styles.dataGrid}
          getRowId={(row) => row.id}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "24px",
                fontWeight: "600",
                mb: "10px",
              }}
            >
              Edit company
            </Typography>
          </Box>

          <Box
            sx={{
              label: {
                color: "var(--secondary-color)",
                fontSize: "16px",
                fontWeight: "500",
              },
              input: {
                width: "100%",
                padding: "5px 0px",
                fontSize: "14px",
                background: "white",
                color: "black",
                border: "none",
                borderBottom: "1px solid var(--savStaff-color)",
                borderRadius: "0px",
                outline: "none",
              },
            }}
          >
            {" "}
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    First Name
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="firstname"
                      autoComplete="off"
                      //   value={inputs.Name}
                      // onChange={handleChange}
                      type="text"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Last Name
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="lastname"
                      autoComplete="off"
                      //   value={inputs.Name}
                      // onChange={handleChange}
                      type="text"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Email
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="email"
                      //   value={inputs.Phone}
                      autoComplete="off"
                      // onChange={handleChange}
                      type="email"
                      placeholder=""
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Password
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="password"
                      //   value={inputs.Phone}
                      autoComplete="off"
                      // onChange={handleChange}
                      type="number"
                      placeholder=""
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Company Name
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="companyName"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="text"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Company Type
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="companyType"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="text"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Company Address
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="address"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="text"
                      placeholder=""
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Phone
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="phone"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="number"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    TIN
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="tin"
                      accept="image/*"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="file"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Employee Count
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="employeeCount"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="number"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Radius
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="Phone"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  //   onChange={handleChange}
                  type="number"
                  placeholder=""
                />
              </Box>
            </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Min Latitude
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="minLatitude"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="number"
                      step="any"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Max Latitude
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="maxLatitude"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="number"
                      step="any"
                      placeholder=""
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Min Longitude
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="minLongitude"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="number"
                      step="any"
                      placeholder=""
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    sx={{
                      color: "var( --savStaff-color)",
                      fontSize: { xs: "12px", sm: "12px", md: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Max Longitude
                  </Typography>
                  <Box style={{ marginTop: "5px" }}>
                    <input
                      required
                      name="maxLongitude"
                      autoComplete="off"
                      //   value={inputs.Designation}
                      // onChange={handleChange}
                      type="number"
                      step="any"
                      placeholder=""
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    sx={{
                      fontWeight: "500",
                      mb: {},
                      fontSize: "14px",
                      textTransform: "capitalize",
                      borderRadius: "2px",
                      background: "#003E6B",
                      color: "#FFFFFF",
                      width: "fit-content",
                      px: 4,
                      mt: "3rem",
                      "&:hover": {
                        backgroundColor: "#2C74B3",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default HRMPro;
