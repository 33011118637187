import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../image/Group.svg";
import logo1 from "../../image/Group(1).svg";
import logo2 from "../../image/Group(2).svg";
import logo3 from "../../image/Group(3).svg";
import logo4 from "../../image/Group(4).svg";
import logo5 from "../../image/Group(5).svg";
import logo6 from "../../image/Group(6).svg";
import logo7 from "../../image/Group(7).svg";
import logo8 from "../../image/Group 5825.svg";
import { Link } from "react-router-dom";

const AdminHome = () => {
  return (
    <Box sx={{ backgroundColor: "var(--active-bg-color)" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            // alignItems: "center",
            paddingTop: "60px",
            justifyContent: "center", // Center vertically
            height: "100vh",
          }}
        >
          <Box>
            <Box sx={{ textAlign: "center" }}>
              <img
                style={{
                  height: "40px",
                  width: "154.454px",
                  marginBottom: "30px",
                }}
                src={logo}
              />
            </Box>
            <Box>
              <Typography
                sx={{ color: "#fff", fontSize: "37px", textAlign: "center" }}
              >
                Welcome Back Syed Afridi
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "18px", textAlign: "center" }}
              >
                Project Manager, Fly Far Tech
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: "80px" }}>
              <Box
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
                gap={"40px"}
              >
                <Box sx={{ width: "15%" }}>
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img className="Company" src={logo1} />
                  </Box>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img className="Company" src={logo2} />
                  </Box>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Link to="/tripsAdminLogin">
                    <Box
                      sx={{
                        background: "#ffff",
                        height: "140px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        px: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <img className="Company" src={logo3} />
                    </Box>
                  </Link>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Link to="/tech">
                    <Box
                      sx={{
                        background: "#ffff",
                        height: "140px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        px: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <img className="Company" src={logo4} />
                    </Box>
                  </Link>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img className="Company" src={logo5} />
                  </Box>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Link to="/quickLogin">
                    <Box
                      sx={{
                        background: "#ffff",
                        height: "140px",
                        width: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        px: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <img className="Company" src={logo8} />
                    </Box>
                  </Link>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img className="Company" src={logo6} />
                  </Box>
                </Box>

                <Box sx={{ width: "15%" }}>
                  <Box
                    sx={{
                      background: "#ffff",
                      height: "140px",
                      width: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <img className="Company" src={logo7} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminHome;
