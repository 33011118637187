import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  CircularProgress,
} from "@mui/material";
//import { format } from "date-fns";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { CircleLoader } from "react-spinners";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const AirTicket = () => {
  const [airTicket, setAirTicket] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("Admin-Token");
  const navigate =  useNavigate();
  // all air ticket
  let url =
    "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/flight/get-flight-booking";
  useEffect(() => {
    setLoading(true);
    if (url) {
      fetch(url, {
        method: "GET",
        headers: {
          "Admin-Token": `${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAirTicket(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  //   loader
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  

  const handleSingleBooking = (booking_id) => {

    navigate(`/trips/queDeatils/${booking_id}`)
  };

  return (
    <>
      <Box mt={4}>
        <Typography
          sx={{ fontSize: "25px", color: "var(--trips-primary-color)" }}
        >
          Air Ticket Booking
        </Typography>
      </Box>

<Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",mt:3}}>
<Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"10px"}}>
<Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Hold</Button>
  <Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Issue On Process</Button>
  <Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Issue Reject</Button>
  <Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Tickited</Button>
  <Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Refund</Button>
  <Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Void</Button>

    <Button variant="contained" sx={{boxShadow:"none", fontSize:"12px",textTransform:"uppercase",bgcolor:"var(--trips-secondary-color)"}}>Reissue</Button>
</Box>
<Box>
  Select
</Box>
</Box>














      <Box mt={2}>
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
              <TableRow>
                <TableCell className="table-cell">
                  <Button
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "var(--primary-text)",
                      fontSize: "11px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "var(--primary-text)",
                      },
                    }}
                  >
                    Booking Id
                  </Button>
                </TableCell>
                <TableCell className="table-cell">Status</TableCell>
                <TableCell className="table-cell">Route</TableCell>
                <TableCell className="table-cell">TripType</TableCell>
                <TableCell className="table-cell">Journey Type</TableCell>
                <TableCell className="table-cell">Pax</TableCell>
                <TableCell className="table-cell">TimeLimit</TableCell>
                <TableCell className="table-cell">AirlinesPnr</TableCell>
                <TableCell className="table-cell">Balance</TableCell>
                <TableCell className="table-cell">AdultBag</TableCell>
                <TableCell className="table-cell">ChildBag</TableCell>
                <TableCell className="table-cell">InfantBag</TableCell>
                <TableCell className="table-cell">Refundable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& td, & th": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            >
              {airTicket?.map((ticket) => (
                <TableRow
                  key={ticket?.id}
                  sx={{
                    width: "100%",
                    ":hover": {
                      backgroundColor: "#DDDDDD",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell component="th" scope="row" className="table-cell">
                    <Button
                    onClick={() => handleSingleBooking(ticket?.booking_id)}
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "var(--primary-text)",
                        fontSize: "11px",
                        textTransform: "capitalize",
                        ":hover": {
                          backgroundColor: "var(--primary-text)",
                        },
                      }}
                    >
                      {ticket?.bookingId}
                    </Button>
                  </TableCell>

                  <TableCell className="table-cell">{ticket?.status}</TableCell>
                  <TableCell className="table-cell">
                    {`${ticket.deptFrom} -${ticket?.arrivalTo}`}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.tripType}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.journeyType}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.adultCount +
                      ticket?.childCount +
                      ticket?.infantCount}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.timeLimit}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.airlinesPnr}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.amount ? ticket?.amount : 0}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.adultBag}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.childBag}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "center" }}
                    className="table-cell"
                  >
                    {ticket?.infantBag}
                  </TableCell>
                  <TableCell className="table-cell">
                    {ticket?.isRefundable === 1
                      ? "Refundable"
                      : "Non Refundable"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AirTicket;
