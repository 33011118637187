import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import VisaRoute from "./VisaRoute";

const UpdateVisaChecklist = () => {
  const token = secureLocalStorage.getItem("token");
  const visaId = secureLocalStorage.getItem("visaId");
  const ChecklistId = secureLocalStorage.getItem("CheckListId");
  const [data, setData] = useState([
    {
      checkListId: "",
      checkListName: "",
      checkListType: "",
    },
  ]);

  const [checkListType, setCheckListType] = useState([{ check_list_type: "" }]);

  const handleAddAnother = () => {
    setData((prevData) => {
      console.log("Previous Data:", prevData);
      const newData = [
        ...prevData,
        {
          checkListId: "",
          checkListName: "",
          checkListType: "",
        },
      ];
      console.log("Updated Data:", newData);
      return newData;
    });
  };

  const handleRemove = (index) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/get-single-visa-info/${visaId}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response", response?.data?.data);

        if (response?.data?.data?.visaChecklist?.length !== 0) {
          setCheckListType(response?.data?.data?.visaChecklist);
        }

        const updatedData = response?.data?.data?.visaChecklist.map((item) => ({
          checkList: item?.checkList?.map((checkListItem) => ({
            checkListId: checkListItem?.checkListId,
            checkListName: checkListItem?.checkListName,
            checkLists: checkListItem?.checkLists,
          })),
        }));
        if (updatedData?.length !== 0) {
          setData(updatedData);
        }

        console.log("updted", updatedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [visaId]);

  const handleRemoveAtIndex = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const handleVisa_titleChange = (e, idx) => {
    const { name, value } = e.target;
    setData((prevData) => {
      const newData = [...prevData];
      newData[idx][name] = value;
      return newData;
    });
  };

  const handleCeckListType = () => {
    const body = {
      checkListType: checkListType,
    };
    fetch(
      `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-visa-check-list-type/${visaId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        secureLocalStorage.setItem("CheckListId", data?.data);
        console.log(data);
        if (data?.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };
  const handleVisaChecklists = () => {
    const body = {
      checkLists: [...data],
    };

    console.log(body);
    fetch(
      `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-visa-check-list/${ChecklistId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        secureLocalStorage.setItem("CheckListId", data?.data);
        console.log(data);
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };

  const updtedCheckListType = async (index, item, id) => {
    try {
      const body = {
        checkListType: item?.check_list_type,
      };

      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-visa-check-list-type/${id}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      Swal.fire({
        icon: responseData.success ? "success" : "error",
        title: responseData.success ? "Success" : "Error",
        text: responseData.message || "Unknown error occurred",
      });
    } catch (error) {
      console.error("There was an error!", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Something went wrong! Please try again later.",
      });
    }
  };

  const updtedCheckList = async (idx, list, listId) => {
    try {
      const body = {
        checkLists: [
          {
            name: list?.checkListName,
            checkList: list?.checkLists,
          },
        ],
      };

      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-visa-check-list/${list?.checkListId}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log(responseData);

      Swal.fire({
        icon: responseData.success ? "success" : "error",
        title: responseData.success ? "Success" : "Error",
        text: responseData.message || "Unknown error occurred",
      });
    } catch (error) {
      console.error("There was an error!", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Something went wrong! Please try again later.",
      });
    }
  };

  return (
    <Box sx={{ px: "30px" }}>
      <VisaRoute />
      <form onSubmit={handleVisaChecklists}>
        {checkListType?.map((item, index) => (
          <Box key={index}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Job Holder
                  </label>
                </Box>
                <input
                  placeholder="Enter Job Holder"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="check_list_type"
                  value={item?.check_list_type}
                  onChange={(e) =>
                    setCheckListType((prevCheckList) => {
                      const updatedChecklist = [...prevCheckList];
                      updatedChecklist[index] = {
                        ...updatedChecklist[index],
                        check_list_type: e.target.value,
                      };
                      return updatedChecklist;
                    })
                  }
                />
              </Box>

              <Box
                sx={{ mt: "23px", ml: "15px", display: "flex", gap: "10px" }}
              >
                <Button
                  sx={{
                    fontSize: "11px",
                    textTransform: "capitalize",
                    bgcolor: "#D9D9D9",
                    whiteSpace: "nowrap",
                    color: "var(--meta-black)",
                    ":hover": {
                      bgcolor: "#D9D9D9",
                      color: "var(--meta-black)",
                    },
                  }}
                  onClick={() => handleCeckListType()}
                >
                  submit
                </Button>
                <Button
                  sx={{
                    fontSize: "11px",
                    textTransform: "capitalize",
                    bgcolor: "#D9D9D9",
                    whiteSpace: "nowrap",
                    color: "var(--meta-black)",
                    ":hover": {
                      bgcolor: "#D9D9D9",
                      color: "var(--meta-black)",
                    },
                  }}
                  onClick={() =>
                    updtedCheckListType(index, item, item?.visa_checklist_id)
                  }
                >
                  update
                </Button>
              </Box>
            </Box>

            <Box>
              {data[index]?.checkList?.map((list, idx) => (
                <Box sx={{ width: "100%" }} key={idx}>
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: "1px solid #DADCE0",
                      pb: "15px",
                      mt: "20px",
                      width: "95%",
                    }}
                  >
                    <Box sx={{ width: "700px" }}>
                      <label style={{ color: "#5F6368", fontSize: "12px" }}>
                        Check List Name
                      </label>
                    </Box>
                    <input
                      placeholder={`Check Lists`}
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                      }}
                      name="checkListName"
                      value={list?.checkListName}
                      onChange={(e) => {
                        setData((prevData) => {
                          const updatedData = [...prevData];
                          updatedData[index].checkList[idx] = {
                            ...updatedData[index].checkList[idx],
                            checkListName: e.target.value,
                          };
                          console.log("Updated Data:", updatedData);
                          return updatedData;
                        });
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #DADCE0",
                        pb: "15px",
                        mt: "20px",
                        width: "95%",
                      }}
                    >
                      <Box sx={{ width: "700px" }}>
                        <label style={{ color: "#5F6368", fontSize: "12px" }}>
                          Check List
                        </label>
                      </Box>
                      <input
                        placeholder={`Check List 3`}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                        name="checkLists"
                        value={list?.checkLists}
                        onChange={(e) => {
                          setData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index].checkList[idx] = {
                              ...updatedData[index].checkList[idx],
                              checkLists: e.target.value,
                            };
                            console.log("Updated Data:", updatedData);
                            return updatedData;
                          });
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: "23px",

                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "11px",
                        textTransform: "capitalize",
                        bgcolor: "#D9D9D9",
                        whiteSpace: "nowrap",
                        color: "var(--meta-black)",
                        ":hover": {
                          bgcolor: "#D9D9D9",
                          color: "var(--meta-black)",
                        },
                      }}
                      onClick={handleAddAnother}
                    >
                      Add Check List
                    </Button>

                    {idx !== 0 && (
                      <Button
                        sx={{
                          fontSize: "11px",
                          textTransform: "capitalize",
                          bgcolor: "#D9D9D9",
                          whiteSpace: "nowrap",
                          color: "var(--meta-black)",
                          ":hover": {
                            bgcolor: "#D9D9D9",
                            color: "var(--meta-black)",
                          },
                        }}
                        onClick={() => handleRemove(idx)}
                      >
                        Remove
                      </Button>
                    )}

                    <Button
                      sx={{
                        fontSize: "11px",
                        textTransform: "capitalize",
                        bgcolor: "#D9D9D9",
                        whiteSpace: "nowrap",
                        color: "var(--meta-black)",
                        ":hover": {
                          bgcolor: "#D9D9D9",
                          color: "var(--meta-black)",
                        },
                      }}
                      onClick={() =>
                        updtedCheckList(
                          index?.idx,
                          list,
                          console.log(list),
                          list?.checkListTypeId
                        )
                      }
                    >
                      update
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ))}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pt: "20px",
          }}
        >
          <Button
            onClick={() => handleVisaChecklists()}
            sx={{
              bgcolor: "var(--trips-secondary-color)",
              color: "white",
              textTransform: "capitalize",
              width: "200px",
              ":hover": {
                bgcolor: "var(--trips-secondary-color)",
                color: "white",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateVisaChecklist;
