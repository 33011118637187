import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Modal,
  Slide,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const PendingPayment = () => {
  const token = secureLocalStorage.getItem("token");
  const [allDeposit, setAllDeposit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [depositId, setDepositId] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  let url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/deposit?status=pending`;
  useEffect(() => {
    setLoading(true);
    if (url) {
      fetch(url, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setAllDeposit(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  const handleApproveClick = (id, status) => {
    setDepositId(id);
    setStatus(status);
    setIsDialogOpen(true);
    // You can perform other actions related to approval here
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleInputChange = (event) => {
    setRemarks(event.target.value);
  };
  const handleDepositReq = async () => {
    setIsDialogOpen(false);
    const depositBody = {
      remarks: remarks,
      status: status,
    };
    try {
      const response = await fetch(
        `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/change_deposit_status/${depositId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(depositBody),
        }
      );
      const data = await response.json();

      if (response.ok) {
        // Show success Swal (SweetAlert) notification
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data?.message,
        });

        // Additional logic you want to perform after a successful API call
      } else {
        // Show error Swal (SweetAlert) notification for API errors
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data?.message || "An error occurred during the API call.",
        });
      }
    } catch (error) {
      console.error("Error:", error);

      // Show error Swal (SweetAlert) notification for network or server errors
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again later.",
      });
    }
    setRemarks(" ");
  };
  return (
    <Box>
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
            <TableRow>
              <TableCell className="table-cell">
                <Button
                  sx={{
                    bgcolor: "var(--primary-color)",
                    color: "var(--primary-text)",
                    fontSize: "11px",
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "var(--primary-text)",
                    },
                  }}
                >
                  Reference
                </Button>
              </TableCell>
              <TableCell className="table-cell">Status</TableCell>
              <TableCell className="table-cell">Type</TableCell>
              <TableCell className="table-cell">Transaction Id</TableCell>

              <TableCell className="table-cell">Requested By</TableCell>
              <TableCell className="table-cell">Balance</TableCell>
              <TableCell className="table-cell">Attachment</TableCell>
              <TableCell className="table-cell">Created At</TableCell>
              <TableCell className="table-cell">Updated At</TableCell>
              <TableCell className="table-cell">Action</TableCell>
              <TableCell className="table-cell">Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& td, & th": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {allDeposit?.map((deposit) => (
              <TableRow
                key={deposit?.uid}
                sx={{
                  width: "100%",
                  ":hover": {
                    backgroundColor: "#DDDDDD",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell component="th" scope="row" className="table-cell">
                  <Button
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "var(--primary-text)",
                      fontSize: "11px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "var(--primary-text)",
                      },
                    }}
                  >
                    {deposit?.depositId}
                  </Button>
                </TableCell>

                <TableCell className="table-cell">{deposit?.status}</TableCell>
                <TableCell className="table-cell">{deposit?.type}</TableCell>
                <TableCell className="table-cell">
                  {deposit?.transactionId ? deposit?.transactionId : "N/A"}
                </TableCell>

                <TableCell className="table-cell">
                  {deposit?.userName != null
                    ? deposit?.userName
                    : deposit?.depositBy}
                </TableCell>
                <TableCell className="table-cell">
                  {deposit?.amount ? deposit?.amount : 0}
                </TableCell>
                <TableCell className="table-cell">
                  <a
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                    href={deposit?.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </TableCell>
                <TableCell className="table-cell">
                  {deposit?.createdAt.slice(0, 19)}
                </TableCell>
                <TableCell className="table-cell">
                  {deposit?.updateAt.slice(0, 19)}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center" }}
                  className="table-cell"
                >
                  <Box sx={{ gap: "5px" }}>
                    <Button
                      sx={{
                        fontSize: "10px",
                        bgcolor: "green",
                        color: "white",
                        textTransform: "capitalize",
                        " :hover": {
                          bgcolor: "green",
                        },
                      }}
                      onClick={() =>
                        handleApproveClick(deposit?.id, "approved")
                      }
                    >
                      Approved
                    </Button>
                    <Button
                      onClick={() =>
                        handleApproveClick(deposit?.id, "rejected")
                      }
                      sx={{
                        fontSize: "10px",
                        bgcolor: "red",
                        color: "white",
                        textTransform: "capitalize",
                        ml: "5px",
                        " :hover": {
                          bgcolor: "red",
                        },
                      }}
                    >
                      Rejected
                    </Button>
                  </Box>
                </TableCell>
                <TableCell className="table-cell">
                  {deposit.rejected_reason}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isDialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
        sx={{
          height: { xs: "100vh", sm: "100vh", md: "100vh" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            background: "#ffff",
            width: "450px",
            px: "20px",
            borderRadius: "5px",
            py: "15px",
          }}
        >
          <label style={{ color: "grey" }}>Remarks</label>
          <Box sx={{ pt: "5px" }}>
            <textarea
              value={remarks}
              onChange={handleInputChange}
              style={{
                height: "110px",
                width: "100%",
                borderRadius: "5px",
                resize: "none",
                outline: "none",
                border: "none",
                border: "1px solid var(--trips-primary-color)",
              }}
            />
          </Box>
          <Box>
            <Button
              onClick={handleDepositReq}
              sx={{
                borderRadius: "6px",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                width: "100%",
                height: "45px",
                textTransform: "capitalize",
                fontSize: "15px",
                border: "1px solid var(--trips-primary-color)",
                mt: "20px",
                ":hover": {
                  backgroundColor: "var(--trips-primary-color)",
                  color: "white",
                },
              }}
            >
              {status}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PendingPayment;
