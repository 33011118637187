import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import VisaRoute from "./VisaRoute";

const AddFaq = () => {
  const [data, setData] = useState([{ question: "", answer: "" }]);

  console.log("datasssssss", data);
  const token = secureLocalStorage.getItem("token");
  const visaId = secureLocalStorage.getItem("visaId");

  const handleAddAnother = () => {
    setData([...data, { question: "", answer: "" }]);
  };

  const handleRemoveAtIndex = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
  };

  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/get-single-visa-info/${visaId}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data?.data?.FAQ?.length !== 0) {
          setData(response?.data?.data.FAQ);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [visaId]);

  console.log("data", data);
  const habdleFaq = (e) => {
    e.preventDefault();
    const body = {
      allFAQ: [...data],
    };

    console.log(body);
    fetch(
      `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-visa-faq/${visaId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
      
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
        } else {
          throw new Error(data.message || "Unknown error occurred");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };

  // handleUpdted

  const handleupdatedFaq = (index, id) => {
    const { question, answer } = data[index];

    const body = {
      allFAQ: [
        {
          question: question,
          answer: answer,
        },
      ],
    };
    console.log("body", body);

    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-visa-faq/${id}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        Swal.fire({
          icon: responseData.success ? "success" : "error",
          title: responseData.success ? "Success" : "Error",
          text: responseData.message || "Unknown error occurred",
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };

  return (
    <Box sx={{px: "30px",}}>
      <VisaRoute/>
      <Box sx={{ pt: "25px" }}>
        <Typography sx={{ color: "#5F6368", fontSize: "13px" }}>
          Add FAQ
        </Typography>
      </Box>
      <form onSubmit={habdleFaq}>
        <Box>
          <Box>
            {data.map((item, index) => (
              <Box key={index}>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: "1px solid #DADCE0",
                      pb: "15px",
                      mt: "20px",
                      width: "95%",
                    }}
                  >
                    <Box sx={{ width: "700px" }}>
                      <label style={{ color: "#5F6368", fontSize: "12px" }}>
                        FAQ {index + 1}
                      </label>
                    </Box>
                    <input
                      placeholder={`Faq ${index + 1}`}
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                      }}
                      name="question"
                      value={item?.question}
                      onChange={(e) =>
                        handleInputChange(index, "question", e.target.value)
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: "23px",
                      ml: "15px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "11px",
                        textTransform: "capitalize",
                        bgcolor: "#D9D9D9",
                        whiteSpace: "nowrap",
                        color: "var(--meta-black)",
                        ":hover": {
                          bgcolor: "#D9D9D9",
                          color: "var(--meta-black)",
                        },
                      }}
                      onClick={handleAddAnother}
                    >
                      Add Another
                    </Button>
                    {index !== 0 && (
                      <Button
                        sx={{
                          fontSize: "11px",
                          textTransform: "capitalize",
                          bgcolor: "#D9D9D9",
                          whiteSpace: "nowrap",
                          color: "var(--meta-black)",
                          ":hover": {
                            bgcolor: "#D9D9D9",
                            color: "var(--meta-black)",
                          },
                        }}
                        onClick={() => handleRemoveAtIndex(index)}
                      >
                        Remove
                      </Button>
                    )}
                    {index === 0 && (
                      <Button
                        sx={{
                          fontSize: "11px",
                          textTransform: "capitalize",
                          bgcolor: "var(--active-bg-color)",
                          whiteSpace: "nowrap",
                          color: "white",
                          ":hover": {
                            bgcolor: "var(--active-bg-color)",
                            color: "white",
                          },
                        }}
                        onClick={() => handleupdatedFaq(index, item?.id)}
                      >
                        update
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "93%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Ans {index + 1}
                    </label>
                  </Box>
                  <input
                    placeholder={`Ans${index + 1}`}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="answer"
                    value={item?.answer}
                    onChange={(e) =>
                      handleInputChange(index, "answer", e.target.value)
                    }
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pt: "20px",
          }}
        >
          <Button
            type="submit"
            sx={{
              bgcolor: "var(--trips-secondary-color)",
              color: "white",
              textTransform: "capitalize",
              width: "200px",
              ":hover": {
                bgcolor: "var(--trips-secondary-color)",
                color: "white",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddFaq;
