import { Box, Button, Typography, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const VisitPlaces = () => {
  const [loading, setLoading] = useState(false);
  const packageId = secureLocalStorage.getItem("packageId");
  const adminToken = secureLocalStorage.getItem("Admin-Token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!packageId) {
      Swal.fire({
        icon: "info",
        text: "Please Complete Package Information First ",
        confirmButtonText: "OK",
      }).then(function () {
        navigate("/trips/tourpackage/packageinformation");
      });
    }
  }, []);

  const [fields, setFields] = useState([
    { id: "", placetovisit_name: "", image: null },
  ]);

  const [updateImage, setUpdateImage] = useState(null);
  const [updatePlaceName, setUpdatePlaceName] = useState("");

  useEffect(() => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package/${packageId}`;
    fetch(url).then((res) =>
      res.json().then((data) => {
        if (data?.data?.place_to_visit.length !== 0) {
          setFields(data?.data?.place_to_visit);
        }
      })
    );
  }, []);

  const handleTextChange = (index, event) => {
    const updatedFields = [...fields];
    updatedFields[index].placetovisit_name = event.target.value;
    setFields(updatedFields);
    setUpdatePlaceName(event.target.value);
  };

  const handleImageChange = (index, event) => {
    const updatedFields = [...fields];
    const file = event.target.files[0];
    setUpdateImage(file);
    // Assuming you have a property like 'previewUrl' in your state
    const previewUrl = URL.createObjectURL(file);
    updatedFields[index] = { ...updatedFields[index], image: file, previewUrl };
    setFields(updatedFields);
  };

  const addField = (index) => {
    const newFields = [...fields, { image: null }];
    setFields(newFields);
  };

  const removeField = (index, data) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);

    {
      data?.id &&
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/delete-place-to-visit-images/${data?.id}`;

            console.log(url, adminToken);

            fetch(url, {
              method: "DELETE",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                "Admin-Token": `${adminToken}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                console.log(data);

                if (data?.success === true) {
                  Swal.fire({
                    title: "Deleted!",
                    text: data?.message,
                    icon: "success",
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: data?.data.message,
                    confirmButtonText: "OK",
                  });
                }
              });
          }
        });
    }
  };

  const handalePackageImage = (e) => {
    setLoading(true);
    e.preventDefault();
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/create-place-to-visit/${packageId}`;
    const formData = new FormData();
    Array.from(fields).forEach((item) => {
      formData.append("id", item.id);
      formData.append("placetovisit_name", item.placetovisit_name);
      formData.append("image", item.image ? item.image : item?.place_image);
    });
    const config = {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
        "Admin-Token": `${adminToken}`,
      },
    };
    try {
      axios.post(url, formData, config).then((res) => {
        console.log(res);
        if (res?.data?.success === true) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            navigate("/trips/tourpackage/tourplan");
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        }
      });
    } catch (err) {
      console.error(err.message);
      Swal.fire({
        icon: "error",
        text: err.message,
        confirmButtonText: "OK",
      }).then(function () {
        caches
          .keys()
          .then((keyList) =>
            Promise.all(keyList.map((key) => caches.delete(key)))
          );
        window.location.reload(true);
      });
    }
  };

  //  update visit place
  const updateVisitPlace = (id) => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/update-place-to-visit/${packageId}`;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("image", updateImage);
    formData.append("placetovisit_name", updatePlaceName);
    const config = {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
        "Admin-Token": `${adminToken}`,
      },
    };
    try {
      axios.put(url, formData, config).then((res) => {
        console.log(res);
        if (res?.data?.success === true) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            window.location.reload(true);
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            window.location.reload(true);
          });
        }
      });
    } catch (err) {
      console.error(err.message);
      Swal.fire({
        icon: "error",
        text: err.message,
        confirmButtonText: "OK",
      }).then(function () {
        window.location.reload(true);
      });
    }
  };

  return (
    <Box>
      <Typography sx={{ fontSize: "17px" }} my={2}>
        Visit Places
      </Typography>
      <form onSubmit={handalePackageImage}>
        <Box>
          <Box>
            {fields?.map((field, index) => (
              <Grid key={index} container spacing={2} mb={1.5}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      width: "100%",
                      height: { md: "210px", sm: "210px", xs: "210px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <label
                      id={`image-${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        id={`image-${index}`}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageChange(index, e)}
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "none",
                        }}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          height: { md: "210px", sm: "210px", xs: "210px" },
                        }}
                      >
                        {field?.place_image ? (
                          <Box
                            sx={{
                              position: "relative",
                              backgroundImage: `url(${
                                field?.previewUrl || field?.place_image
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {" "}
                          </Box>
                        ) : !field?.previewUrl ? (
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            sx={{
                              height: {
                                md: "210px",
                                sm: "210px",
                                xs: "210px",
                              },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "30px",
                              textAlign: "center",
                              borderRadius: "10px",
                            }}
                          >
                            Upload Main Package Image {index + 1}
                          </Skeleton>
                        ) : (
                          <Box
                            sx={{
                              position: "relative",
                              backgroundImage: `url(${field?.previewUrl})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {" "}
                          </Box>
                        )}
                      </Box>
                    </label>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <input
                    type="text"
                    style={{
                      outline: "none",
                      border: "none",
                      width: "100%",
                      borderBottom: "1px solid #DADCE0",
                      paddingBottom: "5px",
                      marginTop: "24px",
                    }}
                    name="placetovisit_name"
                    placeholder="Enter Visit Place Name"
                    value={field.placetovisit_name}
                    onChange={(e) => handleTextChange(index, e)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={4}
                  display={"flex"}
                  alignItems={"end"}
                >
                  {field?.id && (
                    <>
                      {" "}
                      {fields.length !== 1 && (
                        <Button
                          onClick={() => updateVisitPlace(field?.id)}
                          sx={{
                            width: "130px",
                            marginRight: "5px",
                            height: "35px",
                            background: "#2a2e2d",
                            color: "#fff",
                            fontSize: { xs: 14, sm: 14, md: 15 },
                            border: "none",
                            borderRadius: "3px",
                            textTransform: "capitalize",
                            "&:hover": {
                              background: "#2a2e2d",
                              color: "#fff",
                            },
                          }}
                        >
                          Update
                        </Button>
                      )}
                    </>
                  )}

                  {fields.length !== 1 && (
                    <Button
                      sx={{
                        width: "130px",
                        marginRight: "5px",
                        height: "35px",
                        background: "tomato",
                        color: "#fff",
                        fontSize: { xs: 14, sm: 14, md: 15 },
                        border: "none",
                        borderRadius: "3px",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "tomato",
                          color: "#fff",
                        },
                      }}
                      onClick={() => removeField(index, field)}
                    >
                      Remove
                    </Button>
                  )}

                  {fields.length - 1 === index && (
                    <Button
                      sx={{
                        marginRight: "10px",
                        width: "130px",
                        height: "35px",
                        background: "green",
                        color: "#fff",
                        fontSize: { xs: 14, sm: 14, md: 15 },
                        border: "none",
                        borderRadius: "3px",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "green",
                          color: "#fff",
                        },
                      }}
                      onClick={() => addField(index)}
                    >
                      Add Field
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
        <Box sx={{ textAlign: "right" }} my={2}>
          {loading ? (
            <Button
              disabled
              variant="contained"
              sx={{
                bgcolor: "var(--savStaff-color)",
                color: "var(  --thard-color)",
                boxShadow: "none",
                textTransform: "capitalize",
                width: "30%",
              }}
            >
              Processing....
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "var(--savStaff-color)",
                color: "var(  --thard-color)",
                boxShadow: "none",
                textTransform: "capitalize",
                width: "30%",
              }}
            >
              Submit Package Image
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default VisitPlaces;
