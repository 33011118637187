import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'

const CmsRoute = () => {
const navigate=useNavigate()
  const location=useLocation()

  useEffect(() => {
    if (location.pathname === '/trips/cms') {
      navigate('/trips/cms/mainbanner');
    }
  }, [location.pathname, navigate]);
  return (
    <Box>
      <Box my={3}>
        <Typography style={{ color: "#202124", fontSize: "20px" }}>
          Website CMS
        </Typography>
      </Box>
      <Box
        sx={{
          height:"30",
          display: "flex",
        //   justifyContent: "space-between",
          alignItems: "center",

          gap:"10px"
        }}
      >
        <NavLink
           style={{height:"30px",display:"flex",justifyContent:"center",alignItems:'center',padding: "7px 29px",borderRadius:"5px"}}
          to="/trips/cms/mainbanner"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Home
        </NavLink>
        <NavLink
           style={{height:"30px",display:"flex",justifyContent:"center",alignItems:'center',            padding: "7px 29px",borderRadius:"5px"}}
          to="/trips/cms/exclusivedeals"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
         Exclusive Deals
        </NavLink>



        <NavLink
           style={{height:"30px",display:"flex",justifyContent:"center",alignItems:'center', padding: "7px 29px",borderRadius:"5px"}}
          to="/trips/cms/populardestination"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
         Popular Destination
        </NavLink>



        <NavLink
           style={{height:"30px",display:"flex",justifyContent:"center",alignItems:'center', padding: "7px 29px",borderRadius:"5px"}}
          to="/trips/cms/travelstories"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
        Travel Stories
        </NavLink>







        {/* <NavLink
        style={{height:"30px",display:"flex",justifyContent:"center",alignItems:'center',  padding: "7px 29px",borderRadius:"5px"}}
          to="/trips/cms/exclusivedeals"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
        Package
        </NavLink> */}

      </Box>
      <Outlet />
    </Box>
  )
}

export default CmsRoute