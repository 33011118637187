import { Box, Typography } from '@mui/material'
import React from 'react'
import { PacmanLoader } from 'react-spinners';

const Loader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box>
      <PacmanLoader position="center" color="var(--savStaff-color)" />
    </Box>
  </Box>
  
  )
}

export default Loader