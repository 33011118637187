import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import VisaRoute from "./VisaRoute";

const AddVisaDuration = () => {
  const token = secureLocalStorage.getItem("token");
  const visaId = secureLocalStorage.getItem("visaId");
  const [visaExtraInfo, setVisaExtraInfo] = useState({
    single_visa_duration: "",
    single_visa_in_day: "",
    single_visa_processing_time_day: "",
    single_visa_interview_required: "",
    single_visa_embassy_fee: "",
    single_visa_agency_fee: "",
    single_visa_tax: "",
    double_visa_duration: "",
    double_visa_in_day: "",
    double_visa_processing_time_day: "",
    double_visa_interview_required: "",
    double_visa_embassy_fee: "",
    double_visa_agency_fee: "",
    double_visa_tax: "",
    multiple_visa_duration: "",
    multiple_visa_in_day: "",
    multiple_visa_processing_time_day: "",
    multiple_visa_interview_required: "",
    multiple_visa_embassy_fee: "",
    multiple_visa_agency_fee: "",
    multiple_visa_tax: "",
  });

  const handleBasicExtraInfoChange = (e) => {
    const { name, value } = e.target;
    setVisaExtraInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/get-single-visa-info/${visaId}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisaExtraInfo(response?.data?.data?.allVisaInformation[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [visaId]);

  const handleVisaExtraBasicInfoSubmit = (e) => {
    e.preventDefault();

    const body = {
      singleVisaDuration: visaExtraInfo?.single_visa_duration,
      singleVisaIn_day: visaExtraInfo?.single_visa_in_day,
      singleVisaProcessing_time_day:
        visaExtraInfo?.single_visa_processing_time_day,
      singleVisaEmbassyFee: visaExtraInfo?.single_visa_embassy_fee,
      singleVisaAgencyFee: visaExtraInfo?.single_visa_agency_fee,
      singleVisaInterViewRequired:
        visaExtraInfo?.single_visa_interview_required,
      singleVisaTax: visaExtraInfo?.single_visa_tax,
      doubleVisaDuration: visaExtraInfo?.double_visa_duration,
      doubleVisaInDay: visaExtraInfo?.double_visa_in_day,
      doubleVisaProcessingTimeDay:
        visaExtraInfo?.double_visa_processing_time_day,
      doubleVisaEmbassyFee: visaExtraInfo?.double_visa_embassy_fee,
      doubleVisaAgencyFee: visaExtraInfo?.double_visa_agency_fee,
      doubleVisaInterViewRequired:
        visaExtraInfo?.double_visa_interview_required,
      doubleVisaTax: visaExtraInfo?.double_visa_tax,
      multipleVisaDuration: visaExtraInfo?.multiple_visa_duration,
      multipleVisaInDay: visaExtraInfo?.multiple_visa_in_day,
      multipleVisaProcessingTimedDay:
        visaExtraInfo?.multiple_visa_processing_time_day,
      multipleVisaEmbassyFee: visaExtraInfo?.multiple_visa_embassy_fee,
      multipleVisaAgencyFee: visaExtraInfo?.multiple_visa_agency_fee,
      multipleVisaInterViewRequired:
        visaExtraInfo?.multiple_visa_interview_required,
      multipleVisaTax: visaExtraInfo?.multiple_visa_tax,
    };

    const url = visaExtraInfo?.id
      ? `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-all-visa-information/${visaExtraInfo?.id}`
      : `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-all-information/${visaId}`;

    fetch(url, {
      method: visaExtraInfo?.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: data?.message || "Something went wrong!",
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };

  return (
    <Box sx={{ px: "30px" }}>
      <VisaRoute />
      <form onSubmit={handleVisaExtraBasicInfoSubmit}>
        <Box>
          {/* single visa info  */}
          <Box sx={{ mt: "30px" }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Duration in Month
                </label>
              </Box>

              <input
                placeholder="Single Visa Duration in Month"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_duration"
                value={visaExtraInfo?.single_visa_duration}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Maximum Stay in day
                </label>
              </Box>

              <input
                placeholder="Single Visa Maximum Stay in day"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_in_day"
                value={visaExtraInfo?.single_visa_in_day}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Processing Time in Day
                </label>
              </Box>

              <input
                placeholder="Single Visa Processing Time in Day"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_processing_time_day"
                value={visaExtraInfo?.single_visa_processing_time_day}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Interview Required
                </label>
              </Box>

              <input
                placeholder="Single Visa Interview Required"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_interview_required"
                value={visaExtraInfo?.single_visa_interview_required}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Embasy Fee
                </label>
              </Box>

              <input
                placeholder="Single Visa Embasy Fee"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_embassy_fee"
                value={visaExtraInfo?.single_visa_embassy_fee}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Agency Fee
                </label>
              </Box>

              <input
                placeholder="Single Visa Embasy Fee"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_agency_fee"
                value={visaExtraInfo?.single_visa_agency_fee}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Single Visa Tax
                </label>
              </Box>

              <input
                placeholder="Single Visa Tax"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="single_visa_tax"
                value={visaExtraInfo?.single_visa_tax}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
          </Box>
          {/* double visa info  */}
          <Box sx={{ mt: "80px" }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Duration in Month
                </label>
              </Box>

              <input
                placeholder="Double Visa Duration in Month"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_duration"
                value={visaExtraInfo?.double_visa_duration}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Maximum Stay in day
                </label>
              </Box>

              <input
                placeholder="Double Visa Maximum Stay in day"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_in_day"
                value={visaExtraInfo?.double_visa_in_day}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Processing Time in Day
                </label>
              </Box>

              <input
                placeholder="Double Visa Processing Time in Day"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_processing_time_day"
                value={visaExtraInfo?.double_visa_processing_time_day}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Interview Required
                </label>
              </Box>

              <input
                placeholder="Double Visa Interview Required"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_interview_required"
                value={visaExtraInfo?.double_visa_interview_required}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Embasy Fee
                </label>
              </Box>

              <input
                placeholder="Double Visa Embasy Fee"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_embassy_fee"
                value={visaExtraInfo?.double_visa_embassy_fee}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Agency Fee
                </label>
              </Box>

              <input
                placeholder="Double Visa Agency Fee"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_agency_fee"
                value={visaExtraInfo?.double_visa_agency_fee}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Double Visa Tax
                </label>
              </Box>

              <input
                placeholder="  Double Visa Tax"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="double_visa_tax"
                value={visaExtraInfo?.double_visa_tax}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
          </Box>
          {/* multiple visa info  */}
          <Box sx={{ mt: "80px" }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Duration in Month
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Duration in Month"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_duration"
                value={visaExtraInfo?.multiple_visa_duration}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Maximum Stay in day
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Maximum Stay in day"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_in_day"
                value={visaExtraInfo?.multiple_visa_in_day}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Processing Time in Day
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Processing Time in Day"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_processing_time_day"
                value={visaExtraInfo?.multiple_visa_processing_time_day}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Interview Required
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Interview Required"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_interview_required"
                value={visaExtraInfo?.multiple_visa_interview_required}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Embasy Fee
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Embasy Fee"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_embassy_fee"
                value={visaExtraInfo?.multiple_visa_embassy_fee}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Agency Fee
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Agency Fee"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_agency_fee"
                value={visaExtraInfo?.multiple_visa_agency_fee}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Multiple Visa Tax
                </label>
              </Box>

              <input
                placeholder="Multiple Visa Tax"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="multiple_visa_tax"
                value={visaExtraInfo?.multiple_visa_tax}
                onChange={handleBasicExtraInfoChange}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              pt: "20px",
            }}
          >
            <Button
              type="submit"
              sx={{
                bgcolor: "var(--trips-secondary-color)",
                color: "white",
                textTransform: "capitalize",
                width: "200px",
                ":hover": {
                  bgcolor: "var(--trips-secondary-color)",
                  color: "white",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddVisaDuration;
