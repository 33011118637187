import styled from "@emotion/styled";

export const airLineSelectCustomStyle = {
  dropdownIndicator: () => ({
    display: "none",
  }),

  control: (_, state) => {
    return {
      borderColor: state.isFocused ? "" : "none",
      display: "flex",
      cursor: "pointer",
    };
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "var(--third-color)" : "none",
    color: state.isFocused ? "var(--primary-color)" : "var(--text-dark)",
    fontFamily: "productSans500 !important",
    margin: "0px",
    padding: "0px",
  }),
  menu: (provided) => {
    return {
      ...provided,
      overflowY: "hidden",
      borderRadius: 0,
      margin: 0,
      border: "none",
      boxShadow: "none",
    };
  },

  menuList: (provided) => {
    return {
      ...provided,
      maxHeight: "200px",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "var(--third-color)",
      },
      "::-webkit-scrollbar-thumb": {
        background: "var(--third-color)",
      },
    };
  },

  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "var(--third-color)",
    };
  },
  multiValueLabel: (provided) => ({
    ...provided,
    color: "var(--primary-color)",
    fontSize: "13px",
    fontFamily: "productSans500 !important",
  }),

  multiValueRemove: (provided) => {
    return {
      ...provided,
      color: "var(--primary-color)",
      cursor: "pointer",
      padding: 1,
      ":hover": {
        backgroundColor: "",
      },
    };
  },

  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px", // Set the font size of the placeholder
  }),
};

export const BpIcon = styled("span")(() => ({
  width: 13,
  height: 13,
  boxShadow: "0 0 0 1px var(--trips-secondary-color)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const BpCheckedIcon = styled(BpIcon)({
  "&::before": {
    display: "block",
    width: 11,
    height: 11,
    backgroundColor: "var(--trips-secondary-color)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "none",
  },
});
