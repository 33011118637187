import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

const InstallmentAndDiscount = () => {
  const [addInstalment, setAddInstalment] = useState([""]);
  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: "17px" }} my={2}>
          Set Instalment Policy
        </Typography>
        {addInstalment.map((x, index) => (
          <Box>
            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                display: "flex",
                justifyContent: "start",
                gap: "598px",
                paddingBottom: "8px",
              }}
            >
              <Box>
                <input
                  type="text"
                  placeholder={`Instalment ${index + 1} Percentage`}
                  style={{ outline: "none", border: "none" }}
                />
              </Box>
              <Box>
                <input
                  type="text"
                  placeholder="Amount"
                  style={{
                    outline: "none",
                    border: "none",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #DADCE0",
                display: "flex",
                justifyContent: "start",
                gap: "598px",
                paddingBottom: "8px",
                marginTop: "13px",
              }}
            >
              <Box>
                <input
                  type="text"
                  placeholder={`Instalment ${index + 1} Due Date`}
                  style={{ outline: "none", border: "none" }}
                />
              </Box>
              <Box>
                <input
                  type="text"
                  placeholder="Amount"
                  style={{
                    outline: "none",
                    border: "none",
                  }}
                />
              </Box>
            </Box>
          </Box>
        ))}

        <Box
          sx={{
            marginTop: "29px",
            display: "flex",
            justifyContent: "start",
            gap: "11px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (addInstalment.length < 7) {
                setAddInstalment((prev) => [...prev, ""]);
              }
            }}
            sx={{
              bgcolor: "green",
              boxShadow: "none",
              textTransform: "capitalize",
            }}
          >
            Add Event
          </Button>
          <Button
            onClick={() => {
              if (addInstalment.length > 1) {
                setAddInstalment((prev) => prev.slice(0, -1));
              }
            }}
            variant="contained"
            sx={{
              bgcolor: "red",
              boxShadow: "none",
              textTransform: "capitalize",
            }}
          >
            Remove Event
          </Button>
        </Box>
      </Box>

      <Box sx={{ marginTop: "95px" }}>
        <Typography sx={{ fontSize: "17px" }}>Discount</Typography>
        <Box
          sx={{
            borderBottom: "1px solid #DADCE0",
            display: "flex",
            justifyContent: "start",
            gap: "598px",
            paddingBottom: "8px",
            marginTop: "24px",
          }}
        >
          <Box>
            <input
              type="text"
              placeholder="Select Coupon"
              style={{ outline: "none", border: "none" }}
            />
          </Box>
          <Box>
            <input
              type="text"
              placeholder="Amount"
              style={{
                outline: "none",
                border: "none",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "29px",
            display: "flex",
            justifyContent: "start",
            gap: "11px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "green",
              boxShadow: "none",
              textTransform: "capitalize",
            }}
          >
            Add Event
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "red",
              boxShadow: "none",
              textTransform: "capitalize",
            }}
          >
            Remove Event
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default InstallmentAndDiscount;
