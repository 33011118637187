import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import axios from "axios";
import Test from "./Test";
import { useNavigate } from "react-router-dom";

const PackageImages = () => {
  const [addImages, setAddImages] = useState([""]);
  const [loading, setLoading] = useState(false);
  const packageId = secureLocalStorage.getItem("packageId");

  const nevigate = useNavigate();
  useEffect(() => {
    if (!packageId) {
      Swal.fire({
        icon: "info",
        text: "Please Complete Package Information First ",
        confirmButtonText: "OK",
      }).then(function () {
        nevigate("/trips/tourpackage/packageinformation");
      });
    }
  }, []);

  const adminToken = secureLocalStorage.getItem("Admin-Token");
  const [mainImage, setMainImage] = useState("");
  const [fields, setFields] = useState([{ image: null }]);
  const navigate = useNavigate();

  useEffect(() => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package/${packageId}`;
    fetch(url).then((res) =>
      res.json().then((data) => {
        setMainImage(data?.data?.main_image);
        if (data?.data?.cover_image.length !== 0) {
          setFields(data?.data?.cover_image);
        }
      })
    );
  }, [packageId]);

  const handleUpdateImage = (name, value) => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/update-main-image/${packageId}`;
    const formData = new FormData();
    formData.append("image", value);
    const config = {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
        "Admin-Token": `${adminToken}`,
      },
    };
    try {
      axios.put(url, formData, config).then((res) => {
        console.log("res", res);
        if (res?.data?.success === true) {
          Swal.fire({
            icon: "success",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        }
      });
    } catch (err) {
      console.error(err.message);
      Swal.fire({
        icon: "error",
        text: err.message,
        confirmButtonText: "OK",
      }).then(function () {
        caches
          .keys()
          .then((keyList) =>
            Promise.all(keyList.map((key) => caches.delete(key)))
          );
        window.location.reload(true);
      });
    }
  };

  //  package image function
  const handleImageChange = (index, event, id) => {
    const updatedFields = [...fields];
    const file = event.target.files[0];
    // Assuming you have a property like 'previewUrl' in your state
    const previewUrl = URL.createObjectURL(file);
    updatedFields[index] = { ...updatedFields[index], image: file, previewUrl };
    setFields(updatedFields);

    //  package image update

    if (id) {
      const formData = new FormData();
      formData.append("image", file);
      const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/update-cover-images/${id}`;
      const config = {
        headers: {
          Accept: "*/*",
          "content-type": "multipart/form-data",
          "Admin-Token": `${adminToken}`,
        },
      };

      try {
        axios.put(url, formData, config).then((res) => {
          console.log(res);
          if (res?.data?.success === true) {
            Swal.fire({
              icon: "success",
              text: res?.data?.message,
              confirmButtonText: "OK",
            }).then(function () {
              window.location.reload(true);
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res?.data?.message,
              confirmButtonText: "OK",
            }).then(function () {
              window.location.reload(true);
            });
          }
        });
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          text: err.message,
          confirmButtonText: "OK",
        }).then(function () {
          window.location.reload(true);
        });
      }
    }
  };

  const addField = (index) => {
    const newFields = [...fields, { image: null }];
    setFields(newFields);
  };

  const removeField = (index, field) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
    {
      field?.id &&
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const url = `http://192.168.1.106:8000/api/v1/tour-package/delete-cover-image/${field?.id}`;
            fetch(url, {
              method: "DELETE",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                "Admin-Token": `${adminToken}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data?.success === true) {
                  Swal.fire({
                    title: "Deleted!",
                    text: data?.message,
                    icon: "success",
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: data?.data.message,
                    confirmButtonText: "OK",
                  });
                }
              });
          }
        });
    }
  };

  const handalePackageImage = (e) => {
    setLoading(true);
    e.preventDefault();
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/create-cover-images/${packageId}`;
    const formData = new FormData();
    Array.from(fields).forEach((item) => {
      formData.append("image", item.image);
    });
    const config = {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
        "Admin-Token": `${adminToken}`,
      },
    };
    try {
      axios.post(url, formData, config).then((res) => {
        console.log(res);
        if (res?.data?.success === true) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            navigate("/trips/tourpackage/visitplace");
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res?.data?.message,
            confirmButtonText: "OK",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        }
      });
    } catch (err) {
      console.error(err.message);
      Swal.fire({
        icon: "error",
        text: err.message,
        confirmButtonText: "OK",
      }).then(function () {
        caches
          .keys()
          .then((keyList) =>
            Promise.all(keyList.map((key) => caches.delete(key)))
          );
        window.location.reload(true);
      });
    }
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ fontSize: "17px" }} my={2}>
          Main Image
        </Typography>
        <Grid container direction="row" spacing={4}>
          <Grid item md={4}>
            <Box
              sx={{
                width: "100%",
                height: { md: "210px", sm: "210px", xs: "210px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <label
                htmlFor="mainImage"
                style={{
                  width: "100%",
                  height: "100%",
                  // border: mainImage
                  //   ? "2px solid var(--secondary-color)"
                  //   : "none",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <input
                  id="mainImage"
                  type="file"
                  accept=".png,.jpg,.jpeg,.wabp"
                  onChange={(e) =>
                    handleUpdateImage("mainImage", e.target.files[0])
                  }
                  style={{ height: "100%", width: "100%", display: "none" }}
                />
                <Box
                  sx={{
                    width: "100%",
                    height: { md: "210px", sm: "210px", xs: "210px" },
                  }}
                >
                  {!mainImage ? (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      Upload Your Photo
                    </Skeleton>
                  ) : (
                    <Box
                      sx={{
                        position: "relative",
                        backgroundImage: `url(${mainImage})`,
                        backgroundSize: " cover",
                        backgroundPosition: "center",
                        borderRadius: "10px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {" "}
                    </Box>
                  )}
                </Box>
              </label>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <form onSubmit={handalePackageImage}>
        <Box>
          <Typography sx={{ fontSize: "17px" }} my={2}>
            Package Images
          </Typography>
          <Box>
            <Box>
              {fields?.map((field, index) => (
                <Grid key={index} container spacing={2} alignItems="end" mb={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      sx={{
                        width: "100%",
                        height: { md: "210px", sm: "210px", xs: "210px" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <label
                        id={`image-${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          id={`image-${index}`}
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            handleImageChange(index, e, field?.id)
                          }
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "none",
                          }}
                        />
                        <Box
                          sx={{
                            width: "100%",
                            height: { md: "210px", sm: "210px", xs: "210px" },
                          }}
                        >
                          {field?.image_url ? (
                            <Box
                              sx={{
                                position: "relative",
                                backgroundImage: `url(${
                                  field?.previewUrl || field?.image_url
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "10px",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {" "}
                            </Box>
                          ) : !field?.previewUrl ? (
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              sx={{
                                height: {
                                  md: "210px",
                                  sm: "210px",
                                  xs: "210px",
                                },
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "30px",
                                textAlign: "center",
                                borderRadius: "10px",
                              }}
                            >
                              Upload Main Package Image {index + 1}
                            </Skeleton>
                          ) : (
                            <Box
                              sx={{
                                position: "relative",
                                backgroundImage: `url(${field?.previewUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "10px",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {" "}
                            </Box>
                          )}
                        </Box>
                      </label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    {fields.length !== 1 && (
                      <Button
                        sx={{
                          width: "130px",
                          marginRight: "5px",
                          height: "35px",
                          background: "tomato",
                          color: "#fff",
                          fontSize: { xs: 14, sm: 14, md: 15 },
                          border: "none",
                          borderRadius: "3px",
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "tomato",
                            color: "#fff",
                          },
                        }}
                        onClick={() => removeField(index, field)}
                      >
                        Remove
                      </Button>
                    )}

                    {fields.length - 1 === index && (
                      <Button
                        sx={{
                          marginRight: "10px",
                          width: "130px",
                          height: "35px",
                          background: "green",
                          color: "#fff",
                          fontSize: { xs: 14, sm: 14, md: 15 },
                          border: "none",
                          borderRadius: "3px",
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "green",
                            color: "#fff",
                          },
                        }}
                        onClick={() => addField(index)}
                      >
                        Add Field
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
          <Box sx={{ textAlign: "right" }} my={2}>
            {loading ? (
              <Button
                disabled
                variant="contained"
                sx={{
                  bgcolor: "var(--savStaff-color)",
                  color: "var(  --thard-color)",
                  boxShadow: "none",
                  textTransform: "capitalize",
                  width: "30%",
                }}
              >
                Processing....
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "var(--savStaff-color)",
                  color: "var(  --thard-color)",
                  boxShadow: "none",
                  textTransform: "capitalize",
                  width: "30%",
                }}
              >
                Submit Package Image
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default PackageImages;
