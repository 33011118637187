import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const RefundPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [formInfo, setFormInfo] = useState([{ cancellation_policy: "" }]);
  const adminToken = secureLocalStorage.getItem("Admin-Token");
  const packageId = secureLocalStorage.getItem("packageId");
  const navigate = useNavigate();

  useEffect(() => {
    if (!packageId) {
      Swal.fire({
        icon: "info",
        text: "Please Complete Package Information First ",
        confirmButtonText: "OK",
      }).then(function () {
        navigate("/trips/tourpackage/packageinformation");
      });
    }
  }, []);

  useEffect(() => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package/${packageId}`;
    fetch(url).then((res) =>
      res.json().then((data) => {
        if (data?.data?.cancellation_policy.length !== 0) {
          setFormInfo(data?.data?.cancellation_policy);
        }
      })
    );
  }, []);

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const updatedInput = formInfo.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormInfo(updatedInput);
  };
  const removeField = (index, id) => {
    const updatedInput = formInfo.filter((_, i) => i !== index);
    setFormInfo(updatedInput);

    {
      id &&
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/delete-cancellation-policy/${id}`;
            console.log("adminToken", adminToken);
            fetch(url, {
              method: "DELETE",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                "Admin-Token": `${adminToken}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data?.success === true) {
                  Swal.fire({
                    title: "Deleted!",
                    text: data?.message,
                    icon: "success",
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: data?.data.message,
                    confirmButtonText: "OK",
                  });
                }
              });
          }
        });
    }
  };
  const addField = () => {
    setFormInfo([...formInfo, { cancellation_policy: "" }]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/update-cancellation-policy/${packageId}`;
    let body = JSON.stringify(formInfo);
    await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Admin-Token": `${adminToken}`,
      },
      body,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          Swal.fire({
            icon: "success",
            text: data.message,
            confirmButtonText: "OK",
          }).then(() => {
            navigate(`/trips/tourpackage/packageinformation`);
          });
        } else {
          Swal.fire({
            icon: "error",
            text: data.message,
            confirmButtonText: "OK",
          });
        }
        setLoading(false);
      });
  };

  const name = ["Booking Copy", "Client Copy", "Agent Copy"];

  return (
    <Box>
      <Typography sx={{ fontSize: "17px" }} my={2}>
        Package Refund Policy
      </Typography>
      <form onSubmit={handleSubmit}>
        {formInfo?.map((data, index) => (
          <Grid key={index} container alignItems="end" mb={0.5}>
            <Grid item xs={12} sm={6} md={5}>
              <Typography
                style={{
                  fontSize: "13px",
                  color: "#858585",
                  borderBottom: "1px solid #DADCE0",
                  paddingBottom: "5px",
                  marginTop: "24px",
                }}
              >
                Package Refund Policy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <input
                type="text"
                style={{
                  outline: "none",
                  border: "none",
                  width: "100%",
                  borderBottom: "1px solid #DADCE0",
                  paddingBottom: "5px",
                  marginTop: "24px",
                }}
                placeholder="Enter Package Refund Policy "
                name="cancellation_policy"
                value={data?.cancellation_policy}
                onChange={(e) => handleOnChange(e, index)}
              />
            </Grid>

            <Grid item xs={12} sm={2} md={12} mt={2}>
              {index === formInfo.length - 1 && (
                <Button
                  onClick={addField}
                  sx={{
                    marginRight: "10px",
                    width: "130px",
                    height: "35px",
                    background: "tomato",
                    color: "#fff",
                    fontSize: { xs: 14, sm: 14, md: 15 },
                    border: "none",
                    borderRadius: "3px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "tomato",
                      color: "#fff",
                    },
                  }}
                >
                  Add Another
                </Button>
              )}
              {formInfo.length !== 1 && (
                <Button
                  onClick={() => removeField(index, data?.id)}
                  sx={{
                    width: "130px",
                    marginRight: "5px",
                    height: "35px",
                    background: "#222222",
                    color: "#fff",
                    fontSize: { xs: 14, sm: 14, md: 15 },
                    border: "none",
                    borderRadius: "3px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#222222",
                      color: "#fff",
                    },
                  }}
                >
                  Remove
                </Button>
              )}
            </Grid>
          </Grid>
        ))}

        <Box sx={{ textAlign: "right" }} my={2}>
          {loading ? (
            <Button
              disabled
              variant="contained"
              sx={{
                bgcolor: "var(--savStaff-color)",
                color: "var(  --thard-color)",
                boxShadow: "none",
                textTransform: "capitalize",
                width: "30%",
              }}
            >
              Processing....
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "var(--savStaff-color)",
                color: "var(  --thard-color)",
                boxShadow: "none",
                textTransform: "capitalize",
                width: "30%",
              }}
            >
              Submit Package Information
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default RefundPolicy;
