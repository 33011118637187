import { TabContext, TabList } from "@mui/lab";
import { Box, Container, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CommissionCredit = () => {
  const location = useLocation();
  const [value, setValue] = useState("markup");
  const [selectedOption, setSelectedOption] = useState();
  const [selectedMarkupType, setselectedMarkupType] = useState();
  const [additionalMarkup, setAdditionalMarkup] = useState(0);
  const [markupAmount, setMarkupAmount] = useState("");
  const token = secureLocalStorage.getItem("token");
  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handlMarkupTypeChange = (event) => {
    setselectedMarkupType(event.target.value);
  };

  const handleCommission = async (e) => {
    e.preventDefault();

    const apiUrl = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/agent_markups/${id}`;
    console.log("api url daynamic data ", apiUrl);
    const requestData = {
      type: selectedOption,
      amount: markupAmount,
      markupType: selectedMarkupType,
      aMarkup: additionalMarkup,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add the Bearer token here
        },
        body: JSON.stringify(requestData),
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Markup added successfully!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add markup!",
        });
      }
    } catch (error) {
      console.error("API request error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add markup!",
      });
    }
  };

  const handleResetMarkupAgent = async (e) => {
    e.preventDefault();

    const apiUrl = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/agent_reset_markups/${id}`;
    console.log("API URL with dynamic data", apiUrl);

    try {
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Markup reset successfully!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to reset markup!",
        });
      }
    } catch (error) {
      console.error("API request error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to reset markup!",
      });
    }
  };

  return (
    <Container>
      <Box>
        <Box
          sx={{
            width: "450px",
            px: "20px",
            borderRadius: "5px",
            py: "15px",

            width: "1150px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "500",
                color: "var(--secondary-color)",

                pb: "10px",
              }}
            >
              Commission & Credit Set Up
            </Typography>

            <Box
              sx={{
                py: "20px",
              }}
            >
              <Box>
                <TabContext value={value}>
                  <Box sx={{ display: "flex" }}>
                    {/* Tab bar */}
                    <Box
                      style={{
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <TabList
                        onChange={handleChange}
                        indicatorColor="none"
                        textColor="white"
                        aria-label="home-tab"
                        sx={{ py: "3px", px: "3px" }}
                      >
                        <Tab
                          label="Markup"
                          value={"markup"}
                          sx={{
                            color: "var(--active-bg-color)",
                            background: "var(--primary-color)",
                            borderRadius: "10px",
                            opacity: "1",
                            width: "fit-content",
                            minHeight: "20px",
                            margin: {
                              xs: "0px 0px",
                            },
                            "&.MuiTab-root.Mui-selected": {
                              backgroundColor: "var(--active-bg-color)",
                              color: "var(--thard-color)",
                              fontWeight: "bold",
                              borderRadius: "10px",
                              opacity: "1",
                            },
                            fontSize: { xs: "8px", sm: "10px", md: "15px" },
                            textTransform: "Capitalize",
                          }}
                        />
                        <Tab
                          style={{ marginLeft: "3px" }}
                          label="Credit Balance"
                          value={"creditBalance"}
                          sx={{
                            color: "var(--active-bg-color)",
                            background: "var(--primary-color)",
                            borderRadius: "10px",
                            opacity: "1",
                            width: "fit-content",
                            minHeight: "25px",
                            margin: {
                              xs: "0px 0px",
                            },
                            "&.MuiTab-root.Mui-selected": {
                              backgroundColor: "var(--active-bg-color)",
                              color: "var(--thard-color)",
                              borderRadius: "10px",
                              opacity: "1",
                            },
                            fontSize: { xs: "8px", sm: "10px", md: "15px" },
                            textTransform: "Capitalize",
                          }}
                        />
                        <Tab
                          style={{ marginLeft: "3px" }}
                          label="Bonus Amount"
                          value={"bonusAmount"}
                          sx={{
                            color: "var(--active-bg-color)",
                            background: "var(--primary-color)",
                            borderRadius: "10px",
                            opacity: "1",
                            width: "fit-content",
                            minHeight: "25px",
                            margin: {
                              xs: "0px 0px",
                            },
                            "&.MuiTab-root.Mui-selected": {
                              backgroundColor: "var(--active-bg-color)",
                              color: "var(--thard-color)",
                              borderRadius: "10px",
                              opacity: "1",
                            },
                            fontSize: { xs: "8px", sm: "10px", md: "15px" },
                            textTransform: "Capitalize",
                          }}
                        />
                      </TabList>
                    </Box>
                  </Box>
                </TabContext>
              </Box>
              <Box sx={{ my: "10px" }}>
                {value === "markup" ? (
                  <>
                    <form onSubmit={handleCommission}>
                      <Box
                        sx={{
                          display: "flex",
                          borderBottom: "1px solid #DADCE0",
                          pb: "20px",
                          mt: "30px",
                        }}
                      >
                        <Box sx={{ width: "240px" }}>
                          <label style={{ color: "#A3A4A9", fontSize: "15px" }}>
                            Select Markup for
                          </label>
                        </Box>

                        <select
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor: "#ffff",
                            outline: "none",
                            paddingBottom: "10px",
                          }}
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="domestic">Domestic</option>
                          <option value="international">International</option>
                        </select>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          borderBottom: "1px solid #DADCE0",
                          pb: "20px",
                          mt: "20px",
                        }}
                      >
                        <Box sx={{ width: "240px" }}>
                          <label style={{ color: "#A3A4A9", fontSize: "15px" }}>
                            Select Markup Type
                          </label>
                        </Box>

                        <select
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor: "#ffff",
                            outline: "none",
                            paddingBottom: "10px",
                          }}
                          value={selectedMarkupType}
                          onChange={handlMarkupTypeChange}
                        >
                          <option value="amount">Amount</option>
                          <option value="percentage">Percentage</option>
                        </select>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          borderBottom: "1px solid #DADCE0",
                          pb: "20px",
                          mt: "20px",
                        }}
                      >
                        <Box sx={{ width: "200px" }}>
                          <label style={{ color: "#A3A4A9", fontSize: "15px" }}>
                            Markup Amount
                          </label>
                        </Box>

                        <input
                          value={markupAmount}
                          onChange={(e) => setMarkupAmount(e.target.value)}
                          placeholder="Enter the Receiver  Name"
                          style={{
                            border: "none",
                            backgroundColor: "white",
                            outline: "none",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          borderBottom: "1px solid #DADCE0",
                          pb: "20px",
                          mt: "20px",
                        }}
                      >
                        <Box sx={{ width: "200px" }}>
                          <label style={{ color: "#A3A4A9", fontSize: "15px" }}>
                            Additional Markup
                          </label>
                        </Box>

                        <input
                          value={additionalMarkup}
                          onChange={(e) => setAdditionalMarkup(e.target.value)}
                          placeholder="Add Your Additional Markup"
                          style={{
                            border: "none",
                            backgroundColor: "white",
                            outline: "none",
                          }}
                        />
                      </Box>

                      <Box sx={{ py: "30px", display: "flex" }} gap={2}>
                        <Box>
                          <Button
                            type="submit"
                            sx={{
                              color: "#ffff",
                              background: "var(--savStaff-color)",
                              textTransform: "capitalize",
                              height: "40px",
                              width: "305px",
                              ":hover": {
                                backgroundColor: "#2A2E2D",
                              },
                            }}
                          >
                            Add Markup for this Agent
                          </Button>
                        </Box>
                        <Box>
                          {" "}
                          <Button
                            onClick={handleResetMarkupAgent}
                            sx={{
                              height: "40px",
                              width: "305px",
                              color: "#ffff",
                              background: "var(--card-btn-bg-color)",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "#2A2E2D",
                              },
                            }}
                          >
                            Reset Markup for this Agent
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  </>
                ) : value === "creditBalance" ? (
                  <></>
                ) : value === "bonusAmount" ? (
                  <></>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CommissionCredit;
