import React, { useEffect } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import "./AgentList.css";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import secureLocalStorage from "react-secure-storage";
import Loader from "../../Shared/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

const styles = {
  root: {
    height: "calc(100vh - 200px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgentList = () => {
  const token = secureLocalStorage.getItem("token");
  const [value, setValue] = useState("pending");
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [agent, setAgent] = useState("");
  const [agentId, setAgentId] = useState("");
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [subject, setSubject] = useState("");

  useEffect(() => {
    if (agent) {
      setBonusForm({
        ...bonusForm,
        agencyName: agent.company,
        agentName: `${agent.firstName} ${agent.lastName}`,
      });
    }
  }, [agent]);

  // approped deposit
  const handleAgentApproveClick = (id, agent, status) => {
    setAgentId(id);
    setAgent(agent);
    setStatus(status);
    setOpenDialog(true);
  };

  const [bonusForm, setBonusForm] = useState({
    agencyName: agent?.company,
    agentName: `${agent?.firstName} ${agent?.lastName}`,
    bonusAmount: "",
    bonusAvail: "",
  });

  // agnt approved with bonus || withoutbonus and reject
  const handleApproveAgent = (agentId, bonusType, status) => {
    let requestBody;
    if (status === "approved") {
      if (bonusType === "withoutbonus") {
        requestBody = {
          status: status,
          subject: "FFI Account",
          message: "Let's Go",
        };
      } else if (bonusType === "bonus") {
        requestBody = {
          status: status,
          subject: "FFI Account",
          message: "Let's Go",
          bonusAmount: bonusForm.bonusAmount,
          percentage: bonusForm.bonusAvail,
        };
      }
    } else if (status === "rejected") {
      requestBody = {
        status: status,
        subject: subject,
        message: remarks,
      };
    }
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/change_agent_status/${agentId}`;
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("API request failed");
        }
      })
      .then((data) => {
        Swal.fire({
          title: "Are you sure?",
          text: `Your agent ${status}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes agent has been ${status}`,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: `${status}`,
              text: `Your agent has been ${status}`,
              icon: "success",
            });
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Failed to approve agent with ID ${agentId}: ${error.message}`,
        });
      });
    setOpenDialog(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const localTimeZone = "America/New_York";

  function convertUtcToLocal(utcDateString) {
    const utcDate = new Date(utcDateString);
    const options = {
      month: "short",
      day: "2-digit",
      year: "2-digit", // 2-digit year (e.g., "23" for 2023)
      // Short month name (e.g., "Sep")
      // 2-digit day of the month (e.g., "09")
      hour: "2-digit", // 2-digit hour (e.g., "02")
      minute: "2-digit", // 2-digit minute (e.g., "35")
      // 2-digit second (e.g., "00")
      hour12: true, // Use 12-hour time format
    };
    const localDateStr = utcDate.toLocaleString("en-US", options);
    return localDateStr;
  }

  // fetch(url, {
  //   method: "POST",
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },

  const handleActiveDeactive = (id) => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/update_agent_status/${id}`;
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.success === true) {
          Swal.fire({
            text: `${data?.message}`,
            icon: "success",
          }).then(() => {
            navigate(0);
          });
        } else {
          Swal.fire({
            text: `${data?.message}`,
            icon: "error",
          }).then(() => {
            navigate(0);
          });
        }
        console.log(data);
      });
  };

  // all agent get
  useEffect(() => {
    setIsLoading(true);
    let url;
    if (value == "all") {
      url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/agent`;
    } else {
      url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/agent?status=${value}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data?.data?.forEach((agent) => {
          agent.joinAt = convertUtcToLocal(agent.joinAt, localTimeZone);
        });
        setData(data?.data);
        setIsLoading(false);
      });
  }, [value, token]);

  const navigate = useNavigate();
  const navigateToAgentDetails = (id) => {
    navigate(`${id}`);
  };

  //  MUI Table column

  const columns = [
    {
      field: "agentId",
      headerName: "Reference",
      width: 120,
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "var(--primary-color)",
              cursor: "pointer",
              border: "none",
              cursor: "pointer",
              padding: "5px 15px",
              width: "100%",
              color: "#00000",
              fontSize: "12px",
            }}
            onClick={() => navigateToAgentDetails(params?.row?.id)}
          >
            {params?.row?.agentId ?? "Reference No"}
          </button>
        );
      },
    },
    {
      field: "firstName",
      headerName: "Agent Name",
      suppressSizeToFit: true,
      width: 125,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.firstName} {params?.row?.lastName}
          </span>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      suppressSizeToFit: true,
      width: 70,
    },

    {
      field: "email",
      headerName: "Email",
      suppressSizeToFit: true,
      width: 175,
    },
    {
      field: "phone",
      headerName: "Contact Number",
      suppressSizeToFit: true,
      width: 120,
    },

    {
      field: "company",
      headerName: "Company Name",
      suppressSizeToFit: true,
      width: 130,
    },

    {
      field: "joinAt",
      headerName: "Join At",
      suppressSizeToFit: true,
      width: 130,
      renderCell: (params) => {
        return <>{moment(params?.row?.joinAt).format("MMM Do YY, h:mm a")}</>;
      },
    },
    {
      field: "bonus",
      headerName: "Bonus Wallet",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return <span>{params?.row?.bonus} BDT</span>;
      },
    },
    {
      field: "credit",
      headerName: "Credit Wallet",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.credit === null ? 0 : params?.row?.credit} BDT
          </span>
        );
      },
    },
    {
      field: "loanAmount",
      headerName: "Balance Wallet",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return <span>{params?.row?.loanAmount} BDT</span>;
      },
    },
    // {
    //   field: "dhfghdfg",
    //   headerName: "Statistic",
    //   suppressSizeToFit: true,
    //   width: 100,
    // },

    value === "pending"
      ? {
          field: "id",
          headerName: "Operation",
          suppressSizeToFit: true,
          width: 120,
          renderCell: (params) => {
            return (
              <Box>
                {
                  <CheckCircleOutlineIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleAgentApproveClick(
                        params?.row?.id,
                        params?.row,
                        "approved"
                      )
                    }
                  />
                }
                {
                  <ClearIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleAgentApproveClick(
                        params?.row?.id,
                        params?.row?.agent,
                        "rejected"
                      )
                    }
                  />
                }
              </Box>
            );
          },
        }
      : value === "approved"
      ? {
          field: "id",
          headerName: "Action",
          suppressSizeToFit: true,
          width: 120,
          renderCell: (params) => {
            return (
              <Box>
                <button
                  style={{
                    width: "100px",
                    height: "30px",
                    border: "none",
                    background: "var(--primary-bonus-color)",
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "12px",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                  }}
                  onClick={() =>
                    handleActiveDeactive(params?.row?.id, params?.row?.status)
                  }
                >
                  Deactivate
                </button>
              </Box>
            );
          },
        }
      : value === "deactivate" && {
          field: "id",
          headerName: "Action",
          suppressSizeToFit: true,
          width: 120,
          renderCell: (params) => {
            return (
              <Box>
                <button
                  style={{
                    width: "100px",
                    height: "30px",
                    border: "none",
                    background: "var(--button-color)",
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "12px",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                  }}
                  onClick={() =>
                    handleActiveDeactive(params?.row?.id, params?.row?.status)
                  }
                >
                  Approved
                </button>
              </Box>
            );
          },
        },
  ];

  return (
    <Box
      sx={{
        m: "0 auto",
        width: {
          sx: "100%",
          md: "95%",
        },
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "26px", pb: "20px" }}>
          All Agent List
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ display: "flex" }}>
              {/* Tab bar */}
              <Box
                style={{
                  backgroundColor: "var(--thard-color)",
                  borderRadius: "30px",
                  width: "100%",
                }}
              >
                <TabList
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="white"
                  aria-label="home-tab"
                >
                  <Tab
                    label="PENDING"
                    value={"pending"}
                    style={{
                      height: "30px",
                      marginLeft: "3px",
                    }}
                    sx={{
                      opacity: "1",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "5px",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "10px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  <Tab
                    style={{ height: "30px" }}
                    label="ACTIVE"
                    value={"approved"}
                    sx={{
                      color: "var(--primary-text)",
                      opacity: "1",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "5px",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "20px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",

                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />

                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="DEACTIVE"
                    value={"deactivate"}
                    sx={{
                      color: "var(--primary-text)",
                      opacity: "1",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="UNVERIFIED"
                    value={"unverified"}
                    sx={{
                      color: "var(--primary-text)",
                      opacity: "1",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="REJECT"
                    value={"rejected"}
                    sx={{
                      color: "var(--primary-text)",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      opacity: "1",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="All"
                    value={"all"}
                    sx={{
                      color: "var(--primary-text)",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      opacity: "1",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />
                </TabList>
              </Box>
            </Box>
          </TabContext>
        </Box>

        <Box>
          {isLoading ? (
            <Box>
              <Loader />
            </Box>
          ) : (
            <Box
              style={styles.root}
              sx={{
                mt: 2,
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid var(--input-bgcolor)",
                  fontSize: "12px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  borderTop: "1px solid #DDDDDD",
                  borderBottom: "1px solid #DDDDDD",
                  color: "var(--white)",
                  fontSize: "12px",
                  maxHeight: "20px !important",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "var(--body-color)",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white)",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: "var(--dasbord-sidebar--color)",
                  background: "var(--primary-bonus-color)",
                  fontSize: "10px",
                  height: "30px",
                  mb: 2,
                },
                ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root":
                  {
                    color: "var(--white)",
                  },
                ".MuiTablePagination-selectLabel": {
                  color: "var(--white)",
                },
                ".MuiSelect-select": {
                  color: "var(--white)",
                },
                ".css-levciy-MuiTablePagination-displayedRows": {
                  color: "var(--white)",
                },
                ".MuiDataGrid-cellContent": {
                  color: "var(--text-color)",
                },
              }}
            >
              {/* work here-- */}
              {value === "approved" ? (
                <Box>
                  <Box>
                    <DataGrid
                      rows={data}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      scrollbarSize={5}
                      style={styles.dataGrid}
                    />
                  </Box>
                </Box>
              ) : value === "pending" ? (
                <Box>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                </Box>
              ) : value === "deactivate" ? (
                <Box>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                </Box>
              ) : value === "rejected" ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              ) : value === "unverified" ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              ) : value === "credit" ? (
                <Box></Box>
              ) : value === "plusBalance" ? (
                <Box>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                </Box>
              ) : value === "agentFailed" ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              ) : value === "all" ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              ) : (
                <p>selected data no found</p>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        onClose={() => setOpenDialog(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
        sx={{
          height: {
            xs: "100vh",
            sm: "100vh",
            md: "100vh",
          },
        }}
      >
        {status === "approved" ? (
          <Box>
            <Container>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: "20px",
                }}
              >
                <Box
                  sx={{
                    background: "#ffff",
                    width: "450px",
                    px: "20px",
                    borderRadius: "5px",
                    py: "15px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: "500",
                        color: "var(--profile-boday-color)",

                        pb: "10px",
                      }}
                    >
                      Sign Up Bonus
                    </Typography>
                    <form>
                      <Box
                        sx={{
                          borderBottom: "1px solid #B6B6CC",
                        }}
                      >
                        <Box sx={{ py: "20px", width: "200px" }}>
                          <label
                            style={{
                              color: "var( --profile-boday-color)",
                              fontSize: "15px",
                            }}
                          >
                            Agency Name
                          </label>
                        </Box>

                        <input
                          type="text"
                          id="agencyName"
                          name="agencyName"
                          placeholder="Agency Name"
                          style={{
                            border: "none",
                            color: "#B6B6CC",
                            outline: "none",
                            background: "none",
                          }}
                          value={bonusForm.agencyName}
                          onChange={(e) =>
                            setBonusForm({
                              ...bonusForm,
                              agencyName: e.target.value,
                            })
                          }
                        />
                      </Box>

                      <Box
                        sx={{
                          borderBottom: "1px solid #B6B6CC",
                        }}
                      >
                        <Box sx={{ py: "20px", width: "200px" }}>
                          <label
                            style={{
                              color: "var( --profile-boday-color)",
                              fontSize: "15px",
                            }}
                          >
                            Agent Name
                          </label>
                        </Box>

                        <input
                          type="text"
                          id="agentName"
                          name="agentName"
                          placeholder="Agent Name"
                          value={bonusForm.agentName}
                          onChange={(e) =>
                            setBonusForm({
                              ...bonusForm,
                              agentName: e.target.value,
                            })
                          }
                          style={{
                            border: "none",
                            color: "#B6B6CC",
                            outline: "none",
                            background: "none",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #B6B6CC",
                        }}
                      >
                        <Box sx={{ py: "20px", width: "200px" }}>
                          <label
                            style={{
                              color: "var( --profile-boday-color)",
                              fontSize: "15px",
                            }}
                          >
                            Bonus[In Amount]
                          </label>
                        </Box>

                        <input
                          type="text"
                          id="bonusAmount"
                          name="bonusAmount"
                          placeholder="0 BDT"
                          value={bonusForm.bonusAmount}
                          onChange={(e) =>
                            setBonusForm({
                              ...bonusForm,
                              bonusAmount: e.target.value,
                            })
                          }
                          style={{
                            border: "none",
                            color: "#B6B6CC",
                            outline: "none",
                            background: "none",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #B6B6CC",
                        }}
                      >
                        <Box sx={{ py: "20px", width: "200px" }}>
                          <label
                            style={{
                              color: "var( --profile-boday-color)",
                              fontSize: "15px",
                            }}
                          >
                            Bonus Avail[In Percent]
                          </label>
                        </Box>

                        <input
                          type="text"
                          id="bonusAvail"
                          name="bonusAvail"
                          placeholder="0"
                          value={bonusForm.bonusAvail}
                          onChange={(e) =>
                            setBonusForm({
                              ...bonusForm,
                              bonusAvail: e.target.value,
                            })
                          }
                          style={{
                            border: "none",
                            color: "#B6B6CC",
                            outline: "none",
                            background: "none",
                          }}
                        />
                      </Box>

                      <Box sx={{ py: "10px", display: "flex" }} gap={1}>
                        <Button
                          sx={{
                            borderRadius: "6px",
                            backgroundColor: "var( --primary-bonus-color)",
                            color: "var(--thard-color)",
                            width: "50%",
                            height: "45px",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            mt: "5px",

                            ":hover": {
                              backgroundColor: "var( --primary-bonus-color)",
                            },
                          }}
                          onClick={() =>
                            handleApproveAgent(agentId, "withoutbonus", status)
                          }
                        >
                          Approve Without Bonus Agent
                        </Button>
                        <Button
                          onClick={() =>
                            handleApproveAgent(agentId, "bonus", status)
                          }
                          sx={{
                            borderRadius: "6px",
                            backgroundColor: "var( --primary-bonus-color)",
                            color: "var(--thard-color)",
                            width: "50%",
                            height: "45px",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            mt: "5px",

                            ":hover": {
                              backgroundColor: "var( --primary-bonus-color)",
                            },
                          }}
                        >
                          Approve With Bonus Agent
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                background: "#ffff",
                width: "450px",
                px: "20px",
                borderRadius: "5px",
                py: "15px",
              }}
            >
              <Typography sx={{ textAlign: "center", color: "grey" }}>
                Agent Rejected
              </Typography>
              <label style={{ color: "grey" }}>Subject</label>
              <Box sx={{ pt: "5px" }}>
                <input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  style={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "5px",
                    resize: "none",
                    outline: "none",
                    border: "none",
                    border: "1px solid var( --trips-secondary-color)",
                  }}
                />
              </Box>
              <label style={{ color: "grey" }}>Remarks</label>
              <Box sx={{ pt: "5px" }}>
                <textarea
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  style={{
                    height: "110px",
                    width: "100%",
                    borderRadius: "5px",
                    resize: "none",
                    outline: "none",
                    border: "none",
                    border: "1px solid var( --trips-secondary-color)",
                  }}
                />
              </Box>
              <DialogActions>
                <Button
                  onClick={() => handleApproveAgent(agentId, "bonus", status)}
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    width: "100%",
                    height: "45px",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    border: "1px solid var( --trips-secondary-color)",
                    mt: "20px",
                    ":hover": {
                      backgroundColor: "var( --trips-secondary-color)",
                      color: "white",
                    },
                  }}
                >
                  Agent Rejected
                </Button>
              </DialogActions>
            </Box>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default AgentList;
