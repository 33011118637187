import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddHRCompany = () => {
  const [formInfo, setFormInfo] = useState();
  const navigate = useNavigate();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormInfo({ ...formInfo, [name]: value });
  // };

  const handleChange = (e) => {
    if (e.target.name === "tin") {
      setFormInfo((prevFormInfo) => ({
        ...prevFormInfo,
        [e.target.name]: e.target.files[0],
      }));
    } else {
      setFormInfo((prevFormInfo) => ({
        ...prevFormInfo,
        [e.target.name]: e.target.value,
      }));
    }
  };

  console.log(formInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "http://192.168.1.103:5000/api/company/auth/create";

    const formData = new FormData();
    formData.append("firstname", formInfo.firstname);
    formData.append("lastname", formInfo.lastname);
    formData.append("email", formInfo.email);
    formData.append("password", formInfo.password);
    formData.append("companyName", formInfo.companyName);
    formData.append("companyType", formInfo.companyType);
    formData.append("tin", formInfo.tin);
    formData.append("address", formInfo.address);
    formData.append("phone", formInfo.phone);
    formData.append("employeeCount", formInfo.employeeCount);
    formData.append("minLatitude", formInfo.minLatitude);
    formData.append("maxLatitude", formInfo.maxLatitude);
    formData.append("minLongitude", formInfo.minLongitude);
    formData.append("maxLongitude", formInfo.maxLongitude);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Data posted successfully:", data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Add successfully",
        }).then(() => {
          // Navigate back to the previous page
          navigate(-1);
        });
      } else {
        throw new Error("Failed to post data");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to Add",
      }).then(() => {
        navigate(0);
      });
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 12 }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            color: "var(--secondary-color)",
          }}
        >
          Add Company
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 2,
          input: {
            border: "none",
            borderBottom: "1px solid var(--savStaff-color)",
            width: "100%",
            height: "35px",
            borderRadius: "0px",
            color: "var(--savStaff-color)",
            padding: "0px 10px",
            outline: "none",
          },
          //   select: {
          //     border: "none",
          //     paddingRight: "200px",
          //     paddingLeft: "10px",
          //     paddingTop: "8px",
          //     paddingBottom: "8px",
          //     borderRadius: "2px",
          //     color: "var(--input-field)",
          //   },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                First Name
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="firstname"
                  autoComplete="off"
                  //   value={inputs.Name}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Last Name
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="lastname"
                  autoComplete="off"
                  //   value={inputs.Name}
                  onChange={handleChange}  
                  type="text"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Email
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="email"
                  //   value={inputs.Phone}
                  autoComplete="off"
                  onChange={handleChange}
                  type="email"
                  placeholder=""
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Password
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="password"
                  //   value={inputs.Phone}
                  autoComplete="off"
                  onChange={handleChange}
                  type="number"
                  placeholder=""
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Company Name
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="companyName"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Company Type
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="companyType"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Company Address
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="address"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Phone
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="phone"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="number"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                TIN
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="tin"
                  accept="image/*"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="file"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Employee Count
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="employeeCount"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="number"
                  placeholder=""
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Radius
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="Phone"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  //   onChange={handleChange}
                  type="number"
                  placeholder=""
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Min Latitude
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="minLatitude"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="number"
                  step="any"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Max Latitude
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="maxLatitude"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="number"
                  step="any"
                  placeholder=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Min Longitude
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="minLongitude"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="number"
                  step="any"
                  placeholder=""
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  color: "var( --savStaff-color)",
                  fontSize: { xs: "12px", sm: "12px", md: "14px" },
                  fontWeight: 600,
                }}
              >
                Max Longitude
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  name="maxLongitude"
                  autoComplete="off"
                  //   value={inputs.Designation}
                  onChange={handleChange}
                  type="number"
                  step="any"
                  placeholder=""
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                sx={{
                  fontWeight: "500",
                  mb: {},
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "2px",
                  background: "#003E6B",
                  color: "#FFFFFF",
                  width: "fit-content",
                  px: 4,
                  mt: "3rem",
                  "&:hover": {
                    backgroundColor: "#2C74B3",
                  },
                }}
                type="submit"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AddHRCompany;
