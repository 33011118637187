import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
//import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { CircleLoader } from "react-spinners";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress'
const AllUserList = ({loading,allUsers}) => {
  const maxVisibleCharacters = 4;
  const [showPassword, setShowPassword] = useState(false);

  const [visiblePasswords, setVisiblePasswords] = useState({});

  // ... other code

  const togglePasswordVisibility = (userId) => {
    setVisiblePasswords((prevVisiblePasswords) => ({
      ...prevVisiblePasswords,
      [userId]: !prevVisiblePasswords[userId],
    }));
  };
  const [values, setValues] = useState([]);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "200px",
        }}
      >
           <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflowX: "auto" }}
          size="small"
          aria-label="simple table"
        >
          <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
            <TableRow>
              <TableCell className="table-cell"sx={{whiteSpace:"nowrap"}}>
                <Button
                  sx={{
                    bgcolor: "var(--primary-color)",
                    color: "var(--primary-text)",
                    fontSize: "11px",
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "var(--primary-text)",
                    },
                  }}
                >
                  User Id
                </Button>
              </TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Status</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Name</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Username</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Password</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Phone Number</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>DOB</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Address</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Balance</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Passport Number</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Passport Expire Date</TableCell>
              <TableCell className="table-cell" sx={{whiteSpace:"nowrap"}}>Traveler Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& td, & th": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {allUsers.map((user) => (
              <TableRow
                key={user.userId}
                sx={{
                  width: "100%",
                  ":hover": {
                    backgroundColor: "#DDDDDD",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell component="th" scope="row" className="table-cell">
                  <Button
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "var(--primary-text)",
                      fontSize: "11px",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "var(--primary-text)",
                      },
                    }}
                  >
                    {user?.userId}
                  </Button>
                </TableCell>

                <TableCell className="table-cell">
                  {user?.verified_user === 1 ? "Active" : "Deactive"}
                </TableCell>
                <TableCell className="table-cell">{user?.user_name}</TableCell>
                <TableCell className="table-cell">{user?.email}</TableCell>
                <TableCell className="table-cell">
                {visiblePasswords[user.userId]
                    ? user.password
                    : user?.password.slice(0, 10).replace(/./g, "*")}
                <Button sx={{color:"red"}}  onClick={() => togglePasswordVisibility(user.userId)}>
                {visiblePasswords[user.userId] ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}

                </Button>
              </TableCell>
                <TableCell className="table-cell">{user?.phone}</TableCell>
                <TableCell className="table-cell">
                  {user?.dob ? user?.dob.slice(0, 10) : "N/A"}
                </TableCell>
                <TableCell className="table-cell">
                  {user?.address ? user?.address : "N/A"}
                </TableCell>
                <TableCell className="table-cell">{user?.wallet?user?.wallet:0}</TableCell>
                <TableCell className="table-cell">
                  {user?.passport_number?user?.passport_number:"N/A"}
                </TableCell>
                <TableCell className="table-cell">
                  {user?.passport_expiry_date
                    ? user?.passport_expiry_date.slice(0, 10)
                    : "N/A"}
                </TableCell>
                <TableCell className="table-cell">
                  {user?.partner_count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AllUserList;
