import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../image/Group 5825(1).svg";
import { Divider, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, NavLink, Outlet, useMatches } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import SettingsIcon from "@mui/icons-material/Settings";
import { flushSync } from "react-dom";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 200;

function Dashboard(props) {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dashboard, setDashboard] = React.useState(false);
  const [agent, setAgent] = React.useState(false);
  const [manage, setManage] = React.useState(false);
  const [booking, setBooking] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    console.log(index);

    setSelectedIndex(index);
    if (index === 1) {
      setDashboard(!dashboard);
      setAgent(false);
      setManage(false);
      setBooking(false);
    } else if (index === 2) {
      setDashboard(false);
      setAgent(!agent);
      setManage(false);
      setBooking(false);
    } else if (index === 3) {
      setAgent(false);
      setManage(false);
      setBooking(false);
    } else if (index === 4) {
      setDashboard(false);
      setAgent(false);
      setManage(false);
      setBooking(!booking);
    } else if (index === 5) {
      setDashboard(false);
      setAgent(false);
      setManage(!manage);
      setBooking(false);
    } else if (index === 6) {
      setDashboard(false);
      setAgent(false);
      setManage(false);
      setBooking(false);
    }
  };

  const [subSelectedIndex, setSubSelectedIndex] = React.useState();
  const handleSubListItemClick = (event, index) => {
    // setSubSelectedIndex(index);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      sx={{
        backgroundColor: "#EFF2F5",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        py: "20px",
      }}
    >
      <Box>
        <Box>
          <img src={logo} />
        </Box>
        <Box sx={{ mt: "50px" }}>
          <NavLink to="" style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "#222222",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Dashbonard</Typography>
            </Box>
          </NavLink>
          <Link
            onClick={(event) => handleListItemClick(event, 2)}
            to="/quickDashboard/agentList"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <AccountCircleIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Agent</Typography>
            </Box>
          </Link>

          <Link
            to="/quickDashboard/deposit"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Payment</Typography>
            </Box>
          </Link>
          <Link style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              onClick={(event) => handleListItemClick(event, 4)}
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Booking</Typography>
            </Box>
          </Link>
          {selectedIndex === 4 && booking && (
            <Box
              sx={{
                // display: open === false ? "none" : "",
                a: {
                  display: "block",
                  textDecoration: "none",
                  color: "var( --primary-color)",
                  paddingLeft: "30px",
                  marginBottom: 0.2,
                  fontWeight: 600,
                },
              }}
            >
              <Box>
                {/* B2B Agent */}
                <NavLink to="/quickDashboard/airticket">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "#4D4B4B",
                    }}
                  >
                    AirTicket
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          )}
          <Link style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <HomeRepairServiceIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Services</Typography>
            </Box>
          </Link>
          <Link
            onClick={(event) => handleListItemClick(event, 5)}
            to="allEmployee"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <SettingsIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Manage</Typography>
            </Box>
          </Link>
          {selectedIndex === 5 && manage && (
            <Box
              sx={{
                // display: open === false ? "none" : "",
                a: {
                  display: "block",
                  textDecoration: "none",
                  color: "var( --primary-color)",
                  paddingLeft: "30px",
                  marginBottom: 0.2,
                  fontWeight: 600,
                },
              }}
            >
              <Box>
                {/* B2B Agent */}
                <NavLink to="allEmployee">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "#4D4B4B",
                    }}
                  >
                    All Staff
                  </Typography>
                </NavLink>
                <NavLink to="/quickDashboard/allvisa">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "#4D4B4B",
                    }}
                  >
                     All Visa 
                  </Typography>
                </NavLink>
                <NavLink to="/quickDashboard/groupfaremanage/flightinfo">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "#4D4B4B",
                    }}
                  >
                    Add Group Fare
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          )}
          <Link style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Ledger</Typography>
            </Box>
          </Link>
          <Link to="/" style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--colors)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <LogoutIcon
                sx={{ height: "20px", transform: "rotate(180deg)" }}
              />
              <Typography sx={{ fontSize: "15px" }}>Logout</Typography>
            </Box>
          </Link>
        </Box>
        <Box sx={{ display: "flex", mt: "60px" }} gap={1}>
          <Box>
            <svg
              width="32"
              height="31"
              viewBox="0 0 32 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="31" rx="3" fill="#D9D9D9" />
              <path
                d="M16.1599 13.1596C18.4131 13.1596 20.2397 11.333 20.2397 9.07982C20.2397 6.8266 18.4131 5 16.1599 5C13.9067 5 12.0801 6.8266 12.0801 9.07982C12.0801 11.333 13.9067 13.1596 16.1599 13.1596Z"
                stroke="#003566"
                stroke-width="1.6"
              />
              <path
                d="M24.3162 20.9201C24.3193 20.7528 24.3193 20.5825 24.3193 20.4101C24.3193 17.8755 20.6658 15.8203 16.1596 15.8203C11.6535 15.8203 8 17.8755 8 20.4101C8 22.9447 8 24.9999 16.1596 24.9999C18.4352 24.9999 20.0763 24.8398 21.2594 24.5542"
                stroke="#003566"
                stroke-width="1.6"
                stroke-linecap="round"
              />
            </svg>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "18px", color: "#4D4B4B" }}>
              Syed Afridi
            </Typography>
            <Typography sx={{ fontSize: "15px", color: "#4D4B4B" }}>
              Project Manager
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#192030",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 1,
          width: {
            md: `calc(100% - ${drawerWidth}px)`,
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <Outlet></Outlet>
      </Box>
    </Box>
  );
}

Dashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Dashboard;
