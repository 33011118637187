import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const GroupFareRoute = () => {
  return (
    <Box>
      <Box sx={{ px: "30px", py: "30px" }}>
        <Box>
          <Box
            my={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ color: "#202124", fontSize: "20px" }}>
              Group Fare
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#EFF2F5",
              width: "80%",
            }}
          >
            <NavLink
              to="/quickDashboard/groupfaremanage/flightinfo"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Flight Information
            </NavLink>

            <NavLink
              to="/quickDashboard/groupfaremanage/fareinfo"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Fare information
            </NavLink>

            <NavLink
              to="/quickDashboard/groupfaremanage/policy"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Policy
            </NavLink>
            <NavLink
              to="/quickDashboard/groupfaremanage/requriedinfo"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
           Required Information
            </NavLink>
            
          </Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default GroupFareRoute;
