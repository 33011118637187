import { Box, Typography } from '@mui/material'
import React from 'react'

const AddVisa = () => {
  return (
    <Box>
        
    </Box>
  )
}

export default AddVisa