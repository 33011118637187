import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  Tab,
} from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import Loader from "../../Shared/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { TabContext, TabList } from "@mui/lab";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const styles = {
  root: {
    height: "calc(100vh - 200px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const QuickReissue = () => {
  const token = secureLocalStorage.getItem("token");
  const navigate = useNavigate();
  const [allReissueRequest, setAllReissueReuest] = useState([]);
  const [loading, setLoading] = useState(true);

  const [Reissue, setReissue] = useState("pending");
  const handleReissue = (event, newValue) => {
    setReissue(newValue);
  };

  // all reissue data get

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/reissue?status=${Reissue}`;
      console.log("Api", url);
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Data", data);
        setAllReissueReuest(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [Reissue]);

  const handleSingleReissue = (id, additionalId) => {
    console.log(id);
    navigate(`/quickDashboard/quereissue/${additionalId}`);
  };

  //  MUI table column

  const columns = [
    {
      field: "bookingId",
      headerName: "Booking Id",
      width: 120,
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "var(--primary-color)",
              cursor: "pointer",
              border: "none",
              cursor: "pointer",
              padding: "5px 15px",
              width: "100%",
              color: "#00000",
              fontSize: "12px",
            }}
            onClick={() =>
              handleSingleReissue(params?.row?.id, params?.row?.additionalId)
            }
          >
            {params?.row?.bookingId ?? "Booking Id"}
          </button>
        );
      },
    },

    {
      field: "additionalStatus",
      headerName: "Status",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "company",
      headerName: "Company Name",
      suppressSizeToFit: true,
      width: 130,
    },
    {
      field: "deptFrom",
      headerName: "Route",
      suppressSizeToFit: true,
      width: 125,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.deptFrom} - {params?.row?.arriveTo}
          </span>
        );
      },
    },
    {
      field: "tripType",
      headerName: "Trip Type",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "journeyType",
      headerName: "Journey Type",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "pax",
      headerName: "Pax",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "timeLimit",
      headerName: "TimeLimit",
      suppressSizeToFit: true,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.timeLimit === "Immediate Issue" ? (
              <span>Immediate Issue</span>
            ) : params?.row?.timeLimit === null ? (
              ""
            ) : (
              <>
                {moment(
                  params?.row?.timeLimit ? params?.row?.timeLimit : ""
                ).format("MMM Do YY, h:mm a")}
              </>
            )}
          </>
        );
      },
    },

    {
      field: "airlinesPNR",
      headerName: "AirlinesPnr",
      suppressSizeToFit: true,
      width: 100,
    },

    {
      field: "lastAmount    ",
      headerName: "Balance",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "adultBag",
      headerName: "AdultBag",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "childBag",
      headerName: "ChildBag",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "childBag",
      headerName: "ChildBag",
      suppressSizeToFit: true,
      width: 100,
    },

    // value === "pending"
    //   ? {
    //       field: "id",
    //       headerName: "Operation",
    //       suppressSizeToFit: true,
    //       width: 120,
    //       renderCell: (params) => {
    //         return (
    //           <Box>
    //             {
    //               <CheckCircleOutlineIcon
    //                 sx={{
    //                   cursor: "pointer",
    //                 }}
    //                 onClick={() =>
    //                   handleAgentApproveClick(
    //                     params?.row?.id,
    //                     params?.row,
    //                     "approved"
    //                   )
    //                 }
    //               />
    //             }
    //             {
    //               <ClearIcon
    //                 sx={{
    //                   cursor: "pointer",
    //                 }}
    //                 onClick={() =>
    //                   handleAgentApproveClick(
    //                     params?.row?.id,
    //                     params?.row?.agent,
    //                     "rejected"
    //                   )
    //                 }
    //               />
    //             }
    //           </Box>
    //         );
    //       },
    //     }
    //   : value === "approved" && {
    //       field: "id",
    //       headerName: "Action",
    //       suppressSizeToFit: true,
    //       width: 120,
    //       renderCell: (params) => {
    //         return (
    //           <Box>
    //             <button
    //               style={{
    //                 width: "100px",
    //                 height: "30px",
    //                 border: "none",
    //                 background: "green",
    //                 color: "#fff",
    //                 cursor: "pointer",
    //                 fontSize: "12px",
    //                 borderRadius: "5px",
    //               }}
    //               onClick={() =>
    //                 handleActiveDeactive(params?.row?.id, params?.status)
    //               }
    //             >
    //               Active
    //             </button>
    //           </Box>
    //         );
    //       },
    //     },
  ];

  return (
    <Box>
      <Box>
        <TabContext value={Reissue}>
          <Box sx={{ display: "flex" }}>
            <Box
              style={{
                backgroundColor: "var(--thard-color)",
                borderRadius: "30px",
                width: "100%",
              }}
            >
              <TabList
                onChange={handleReissue}
                indicatorColor="none"
                textColor="white"
                aria-label="home-tab"
              >
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissue Request"
                  value={"pending"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissue To Be Confrim"
                  value={"reissue processing"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissue quotation Rejected"
                  value={"rejected"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissue In Process"
                  value={"approved"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissued"
                  value={"reissued"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />

                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissue Rejected"
                  value={"reissue rejected"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--trips-secondary-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
              </TabList>

              <Box
                style={styles.root}
                sx={{
                  mt: 2,
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "1px solid var(--input-bgcolor)",
                    fontSize: "12px",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    borderTop: "1px solid #DDDDDD",
                    borderBottom: "1px solid #DDDDDD",
                    color: "var(--white)",
                    fontSize: "12px",
                    maxHeight: "20px !important",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: "var(--body-color)",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: "var(--dasbord-sidebar--color)",
                    background: "var(--primary-bonus-color)",
                    fontSize: "10px",
                    height: "30px",
                    mb: 2,
                  },
                  ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root":
                    {
                      color: "var(--white)",
                    },
                  ".MuiTablePagination-selectLabel": {
                    color: "var(--white)",
                  },
                  ".MuiSelect-select": {
                    color: "var(--white)",
                  },
                  ".css-levciy-MuiTablePagination-displayedRows": {
                    color: "var(--white)",
                  },
                  ".MuiDataGrid-cellContent": {
                    color: "var(--text-color)",
                  },
                }}
              >
                {Reissue === "pending" ? (
                  <DataGrid
                    rows={allReissueRequest}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                ) : Reissue === "reissue processing" ? (
                  <DataGrid
                    rows={allReissueRequest}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                ) : Reissue === "approved" ? (
                  <DataGrid
                    rows={allReissueRequest}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                ) : Reissue === "reissued" ? (
                  <DataGrid
                    rows={allReissueRequest}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                ) : Reissue === "reissue rejected" ? (
                  <DataGrid
                    rows={allReissueRequest}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                ) : (
                  <DataGrid
                    rows={allReissueRequest}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default QuickReissue;
