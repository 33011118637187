import "./App.css";
import { Box, Typography } from "@mui/material";
import Dashboard from "./QuickTickets/Dashboard/Dashboard";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import AdminLogin from "./QuickTickets/AdminLogin/AdminLogin";
import AdminHome from "./Admin/AdminHome/AdminHome";
import AgentBouns from "./QuickTickets/Agent/AgentBouns";
import AgentList from "./QuickTickets/Agent/AgentList/AgentList";
import AgentDetails from "./QuickTickets/Agent/AgentDetails";
import StaffAdd from "./QuickTickets/AgentManage/StaffAdd/StaffAdd";
import StaffUpdate from "./QuickTickets/AgentManage/StaffUpdate";
import WingsSelected from "./QuickTickets/AgentManage/StaffAdd/WingsSelected";
import { AllEmployee } from "./QuickTickets/AgentManage/AllEmployee";
import EmployeeInfoChange from "./QuickTickets/AgentManage/EmployeeInfoChange";
import PersonalInfoChanges from "./QuickTickets/AgentManage/PersonalInfoChanges";
import InventoryInfo from "./QuickTickets/AgentManage/InventoryInfo";
import TripsSidebar from "./FlyFarTrips/TripsSidebar";
import TripsDashboard from "./FlyFarTrips/Dashboard/TripsDashboard";
import CommissionCredit from "./QuickTickets/Agent/CommissionCredit";
import UserList from "./FlyFarTrips/UserList/UserList";
import AgentUpdate from "./QuickTickets/Agent/AgentUpdate";
import TripsAdminLogIn from "./FlyFarTrips/TripsAdminLogIn/TripsAdminLogIn";
import AllDeposit from "./FlyFarTrips/Payment/AllDeposit/AllDeposit";
import AirTicket from "./FlyFarTrips/Booking/AirTicket/AirTicket";
import QueDetails from "./FlyFarTrips/Booking/AirTicket/QueDetails/QueDetails";
import MakeTicketed from "./FlyFarTrips/Booking/AirTicket/MakeTicketed/MakeTicketed";
import TourpackageAdd from "./FlyFarTrips/TourPackage/TourPackageAdd/TourpackageAdd";
import PackageRoute from "./FlyFarTrips/TourPackage/TourPackageAdd/PackageRoute";
import QuickAirTicket from "./QuickTickets/Booking/QuickAirTicket/QuickAirTicket";
import QuickQueDetails from "./QuickTickets/Booking/QuickAirTicket/QuickQueDetails/QuickQueDetails";
import QuickMakeTicketed from "./QuickTickets/Booking/QuickAirTicket/QuickMakeTicketed/QuickMakeTicketed";
import PackageImages from "./FlyFarTrips/TourPackage/TourPackageAdd/PackageImages";
import VisitPlaces from "./FlyFarTrips/TourPackage/TourPackageAdd/VisitPlaces";
import TourPlanes from "./FlyFarTrips/TourPackage/TourPackageAdd/TourPlanes";
import InstallmentAndDiscount from "./FlyFarTrips/TourPackage/TourPackageAdd/InstallmentAndDiscount";
import Inclusion from "./FlyFarTrips/TourPackage/TourPackageAdd/Inclusion";
import Exclusion from "./FlyFarTrips/TourPackage/TourPackageAdd/Exclusion";
import BookingPolicy from "./FlyFarTrips/TourPackage/TourPackageAdd/BookingPolicy";
import RefundPolicy from "./FlyFarTrips/TourPackage/TourPackageAdd/RefundPolicy";
import Album from "./FlyFarTrips/TourPackage/TourPackageAdd/Album";
import Test from "./FlyFarTrips/TourPackage/TourPackageAdd/Test";
import HomeCms from "./FlyFarTrips/CMS/HomeCms/HomeCms";
import MainBannerSlider from "./FlyFarTrips/CMS/HomeCms/MainBannerSlider";
import CmsRoute from "./FlyFarTrips/CMS/HomeCms/CmsRoute";
import ExclusiveDeals from "./FlyFarTrips/CMS/HomeCms/ExclusiveDeals";
import QuickDeposite from "./QuickTickets/Payment/QuickDeposite";
import { PopularDestination } from "./FlyFarTrips/CMS/HomeCms/PopularDestination";
import { TravelStories } from "./FlyFarTrips/CMS/TravelStories";
import AddDeals from "./FlyFarTrips/CMS/HomeCms/AddDeals";
import UpdatedDeals from "./FlyFarTrips/CMS/HomeCms/UpdatedDeals";
import AddTeavelr from "./FlyFarTrips/CMS/AddTravler";
import AddTravler from "./FlyFarTrips/CMS/AddTravler";
import UpdateTravler from "./FlyFarTrips/CMS/UpdateTravler";
import TourPackage from "./FlyFarTrips/TourPackage/TourPackage";
import ReissueRequest from "./QuickTickets/Booking/ReissueRequest/ReissueRequest";
import VoidRequest from "./QuickTickets/Booking/VoidRequest/VoidRequest";
import RefunRequest from "./QuickTickets/Booking/RefunRequest/RefunRequest";
import TechSideBar from "./FlyFarTech/SideBar/TechSideBar";
import TechDashboard from "./FlyFarTech/Dashboard/TechDashboard";
import Products from "./FlyFarTech/Products/Products";
import HRMPro from "./FlyFarTech/Products/HRMPro/HRMPro";
import AddHRCompany from "./FlyFarTech/Products/HRMPro/AddHRCompany";
import AddVisa from "./QuickTickets/VisaManage/AddVisa";
import VisaRoute from "./QuickTickets/VisaManage/VisaRoute";
import AddVisaChecklist from "./QuickTickets/VisaManage/AddVisaChecklist";
import AddVisaDuration from "./QuickTickets/VisaManage/AddVisaDuration";
import AddBasicInfo from "./QuickTickets/VisaManage/AddBasicInfo";
import AddVisaCountry from "./QuickTickets/VisaManage/AddVisaCountry";
import AddFaq from "./QuickTickets/VisaManage/AddFaq";
import GroupFareRoute from "./QuickTickets/GroupFareManage/GroupFareRoute";
import GroupFareFlightInfo from "./QuickTickets/GroupFareManage/GroupFareFlightInfo";
import GroupFareInfo from "./QuickTickets/GroupFareManage/GroupFareInfo";
import GroupFarePolicy from "./QuickTickets/GroupFareManage/GroupFarePolicy";
import GroupFareReqiredInfo from "./QuickTickets/GroupFareManage/GroupFareReqiredInfo";
import AllVisa from "./QuickTickets/VisaManage/AllVisa";
import UpdateVisaChecklist from "./QuickTickets/VisaManage/UpdateVisaChecklist";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminHome />} />
        <Route path="/adminHome" element={<AdminHome />} />
        <Route path="/quickLogin" element={<AdminLogin />} />

        <Route path="/quickDashboard" element={<Dashboard />}>
          <Route path="/quickDashboard/agentList" element={<AgentList />} />
          <Route
            path="/quickDashboard/agentDetails"
            element={<AgentDetails />}
          />
          <Route
            path="/quickDashboard/agentInfoUpdate/:id"
            element={<AgentUpdate />}
          />
          <Route
            path="/quickDashboard/commission"
            element={<CommissionCredit />}
          />
          <Route path="/quickDashboard/deposit" element={<QuickDeposite />} />
          <Route
            path="/quickDashboard/agentList/:id"
            element={<AgentDetails />}
          />
          <Route path="/quickDashboard/staffAdd" element={<StaffAdd />} />
          <Route
            path="/quickDashboard/commissionCredit/:id"
            element={<CommissionCredit />}
          />
          <Route
            path="/quickDashboard/wingsSelected"
            element={<WingsSelected />}
          />
          <Route path="/quickDashboard/allEmployee" element={<AllEmployee />} />
          <Route
            path="/quickDashboard/allEmployee/:id"
            element={<StaffUpdate />}
          />
          <Route path="/quickDashboard/staffUpdate" element={<StaffUpdate />} />
          <Route
            path="/quickDashboard/changeEmployeInfo"
            element={<EmployeeInfoChange />}
          />
          <Route path="/quickDashboard/addvisa" element={<AddVisa />} />
          <Route
            path="/quickDashboard/changeEmployeInfo/:id"
            element={<EmployeeInfoChange />}
          />
          <Route
            path="/quickDashboard/changePersonalInfo"
            element={<PersonalInfoChanges />}
          />

          <Route
            path="/quickDashboard/changePersonalInfo/:id"
            element={<PersonalInfoChanges />}
          />
          <Route
            path="/quickDashboard/changeInventoryInfo"
            element={<InventoryInfo />}
          />
          <Route path="/quickDashboard/tourpackage" element={<TourPackage />} />
          <Route
            path="/quickDashboard/airticket"
            element={<QuickAirTicket />}
          />
          <Route
            path="/quickDashboard/quedeatils/:id"
            element={<QuickQueDetails />}
          />
          <Route
            path="/quickDashboard/quemakdetails"
            element={<QuickMakeTicketed />}
          />
          <Route
            path="/quickDashboard/quemakdetails"
            element={<QuickMakeTicketed />}
          />
          <Route
            path="/quickDashboard/quereissue"
            element={<ReissueRequest />}
          />
          <Route
            path="/quickDashboard/quereissue/:id"
            element={<ReissueRequest />}
          />
          <Route path="/quickDashboard/quevoided" element={<VoidRequest />} />
          <Route path="/quickDashboard/querefund" element={<RefunRequest />} />

          {/* visa route start  */}
          <Route path="/quickDashboard/allvisa"  element={<AllVisa />}/>
          <Route path="/quickDashboard/visaManage">
            <Route
              path="/quickDashboard/visaManage/countryinfo"
              element={<AddVisaCountry />}
            />
            <Route
              path="/quickDashboard/visaManage/basicinfo"
              element={<AddBasicInfo />}
            />
            <Route
              path="/quickDashboard/visaManage/duration"
              element={<AddVisaDuration />}
            />
            <Route
              path="/quickDashboard/visaManage/Checklist"
              element={<AddVisaChecklist />}
            />
             <Route
              path="/quickDashboard/visaManage/Checklistupdate"
              element={<UpdateVisaChecklist />}
            />
            <Route
              path="/quickDashboard/visaManage/visafaq"
              element={<AddFaq />}
            />
          </Route>
          {/* Group Fatre Route  */}
          <Route
            path="/quickDashboard/groupfaremanage"
            element={<GroupFareRoute />}
          >
            <Route
              path="/quickDashboard/groupfaremanage/flightinfo"
              element={<GroupFareFlightInfo />}
            />
            <Route
              path="/quickDashboard/groupfaremanage/fareinfo"
              element={<GroupFareInfo />}
            />
            <Route
              path="/quickDashboard/groupfaremanage/policy"
              element={<GroupFarePolicy />}
            />
            <Route
              path="/quickDashboard/groupfaremanage/requriedinfo"
              element={<GroupFareReqiredInfo/>}
            />
          </Route>
        </Route>
        {/* trips area nipu vai koreche */}
        <Route path="/trips" element={<TripsSidebar />}>
          <Route path="/trips/dashboard" element={<TripsDashboard />} />
          <Route path="/trips/userList" element={<UserList />} />
          <Route path="/trips/depositReq" element={<AllDeposit />} />
          <Route path="/trips/airTicket" element={<AirTicket />} />
          <Route path="/trips/queDeatils/:id" element={<QueDetails />} />
          <Route path="/trips/makeTicketed" element={<MakeTicketed />} />
          {/* package route start */}

          <Route path="/trips/tourpackageall" element={<TourPackage />} />

          <Route path="/trips/tourpackage" element={<PackageRoute />}>
            <Route
              path="/trips/tourpackage/packageinformation"
              element={<TourpackageAdd />}
            />
            <Route
              path="/trips/tourpackage/packageimage"
              element={<PackageImages />}
            />
            <Route
              path="/trips/tourpackage/visitplace"
              element={<VisitPlaces />}
            />
            <Route
              path="/trips/tourpackage/tourplan"
              element={<TourPlanes />}
            />
            <Route
              path="/trips/tourpackage/instalment"
              element={<InstallmentAndDiscount />}
            />
            <Route
              path="/trips/tourpackage/inclusion"
              element={<Inclusion />}
            />
            <Route
              path="/trips/tourpackage/exclusion"
              element={<Exclusion />}
            />
            <Route
              path="/trips/tourpackage/bookingpolicy"
              element={<BookingPolicy />}
            />
            <Route
              path="/trips/tourpackage/refundpolicy"
              element={<RefundPolicy />}
            />
            <Route path="/trips/tourpackage/album" element={<Album />} />
            <Route path="/trips/tourpackage/test" element={<Test />} />
          </Route>
          {/* package route end */}
          {/*cms route */}
          <Route path="/trips/cms" element={<CmsRoute />}>
            <Route
              path="/trips/cms/mainbanner"
              element={<MainBannerSlider />}
            />
            <Route
              path="/trips/cms/exclusivedeals"
              element={<ExclusiveDeals />}
            />
            <Route
              path="/trips/cms/travelstories"
              element={<TravelStories />}
            />
            <Route
              path="/trips/cms/travelstories/addTravler"
              element={<AddTravler />}
            />
            <Route
              path="/trips/cms/exclusivedeals/:id"
              element={<UpdatedDeals />}
            />
            <Route
              path="/trips/cms/exclusivedeals/adddeals"
              element={<AddDeals />}
            />
            <Route
              path="/trips/cms/travelstories/:id"
              element={<UpdateTravler />}
            />
            <Route path="/trips/cms/adddeals" element={<AddDeals />} />

            <Route
              path="/trips/cms/populardestination"
              element={<PopularDestination />}
            />
          </Route>
          {/* cms route end */}
        </Route>
        <Route path="tripsAdminLogin" element={<TripsAdminLogIn />} />

        <Route path="/tech" element={<TechSideBar />}>
          <Route path="/tech/dashboard" element={<TechDashboard />} />
          <Route path="/tech/products" element={<Products />} />
          <Route path="/tech/products/hrmPro" element={<HRMPro />} />
          <Route path="/tech/products/hrmPro/add" element={<AddHRCompany />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
