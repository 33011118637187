import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import VisaRoute from "./VisaRoute";

const AddBasicInfo = () => {
  const token = secureLocalStorage.getItem("token");
  const visaId = secureLocalStorage.getItem("visaId");

  const [visaBasicInfo, setVisaBasicInfo] = useState({
    visa_type: "",
    visa_style: "",
    embassy_name: "",
    phone_number: "",
    email: "",
    address: "",
    office_house: "",
  });

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;
    setVisaBasicInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/get-single-visa-info/${visaId}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisaBasicInfo(response?.data?.data?.visaBasicInformation[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [visaId]);


  const handleVisaBasicInfoSubmit = (e) => {
    e.preventDefault();
    const body = {
      visaType: visaBasicInfo?.visa_type,
      visaStyle: visaBasicInfo?.visa_style,
      embassyName: visaBasicInfo?.embassy_name,
      phoneNumber: visaBasicInfo?.phone_number,
      email: visaBasicInfo?.email,
      address: visaBasicInfo?.address,
      officeHouse: visaBasicInfo?.office_house,
    };

    const url = visaBasicInfo?.id
      ? `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-visa-basic-information/${visaBasicInfo?.id}`
      : `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-visa-basic-information/${visaId}`;

    fetch(url, {
      method: visaBasicInfo?.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
          setVisaBasicInfo({
            visaType: "",
            visaStyle: "",
            embacyName: "",
            phoneNumber: "",
            email: "",
            address: "",
            officeHours: "",
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error.message || "Something went wrong! Please try again later.",
        });
      });
  };

  console.log("visabasicinfo", visaBasicInfo);
  return (
    <Box sx={{px:"30px"}}>
      <VisaRoute/>
      <form onSubmit={handleVisaBasicInfoSubmit}>
        <Box sx={{ py: "30px" }}>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Visa Type
              </label>
            </Box>

            <input
              placeholder=" Enter  Visa Type"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="visa_type"
              value={visaBasicInfo?.visa_type}
              onChange={handleBasicInfoChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Visa Style
              </label>
            </Box>

            <input
              placeholder=" Enter Visa Style"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="visa_style"
              value={visaBasicInfo?.visa_style}
              onChange={handleBasicInfoChange}
            />
          </Box>
          <Box sx={{ mt: "80px" }}>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Embacy Name
                </label>
              </Box>

              <input
                placeholder=" Enter Embacy Name"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="embassy_name"
                value={visaBasicInfo?.embassy_name}
                onChange={handleBasicInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Phone Number
                </label>
              </Box>

              <input
                placeholder=" Enter Phone Number"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="phone_number"
                value={visaBasicInfo?.phone_number}
                onChange={handleBasicInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Email
                </label>
              </Box>

              <input
                placeholder=" Enter Your Email"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="email"
                value={visaBasicInfo?.email}
                onChange={handleBasicInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Address
                </label>
              </Box>

              <input
                placeholder=" Enter address"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="address"
                value={visaBasicInfo?.address}
                onChange={handleBasicInfoChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #DADCE0",
                pb: "15px",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "700px" }}>
                <label style={{ color: "#5F6368", fontSize: "12px" }}>
                  Office Hours
                </label>
              </Box>

              <input
                placeholder=" Enter Office Hours"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="office_house"
                value={visaBasicInfo?.office_house}
                onChange={handleBasicInfoChange}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              pt: "20px",
            }}
          >
            <Button
              type="submit"
              sx={{
                bgcolor: "var(--trips-secondary-color)",
                color: "white",
                textTransform: "capitalize",
                width: "200px",
                ":hover": {
                  bgcolor: "var(--trips-secondary-color)",
                  color: "white",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddBasicInfo;
