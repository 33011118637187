import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  Tab,
} from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import Loader from "../../Shared/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { TabContext, TabList } from "@mui/lab";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import QuickReissue from "../ReissueRequest/QuickReissue";

const styles = {
  root: {
    height: "calc(100vh - 200px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const QuickAirTicket = () => {
  const token = secureLocalStorage.getItem("token");
  const [allAirTicket, setAllAirTicket] = useState([]);
  const [allReissueRequest, setAllReissueReuest] = useState([]);
  const [allVoidRequest, setAllVoidReuest] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [refundTab, setRefundTab] = useState("reissuerequest");

  const [value, setValue] = useState("Hold");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRefundTab = (event, newValue) => {
    setRefundTab(newValue);
  };

  // all airticket booking
  useEffect(() => {
    const fetchData = async () => {
      let url;
      if (value == "all") {
        url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/booking`;
      } else {
        url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/booking?status=${value}`;
      }
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setAllAirTicket(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [value]);

  // all reissue get
  useEffect(() => {
    const fetchData = async () => {
      const url =
        "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/reissue";
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setAllReissueReuest(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/void?status=${
        refundTab === 0
          ? "pending"
          : refundTab === 1
          ? "approved"
          : refundTab === 2
          ? "rejected"
          : "pending"
      }`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        setAllVoidReuest(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [refundTab]);

  // console.log(refundTab);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Loader />
      </Box>
    );
  }
  const handleSingleBooking = (id, agentId) => {
    const queryParams = new URLSearchParams();
    queryParams.append("agentId", agentId);
    navigate(`/quickDashboard/quedeatils/${id}?${queryParams.toString()}`);
  };

  const handleSingleReissue = (id) => {
    console.log(id);
    navigate(`/quickDashboard/quereissue/${id}`);
  };

  const handleMakeReissue = () => {
    navigate(`/quickDashboard/quereissue`, {
      state: { allAirTicket },
    });
  };

  //  MUI table column

  const columns = [
    {
      field: "bookingId",
      headerName: "Booking Id",
      width: 120,
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "var(--primary-color)",
              cursor: "pointer",
              border: "none",
              cursor: "pointer",
              padding: "5px 15px",
              width: "100%",
              color: "#00000",
              fontSize: "12px",
            }}
            // onClick={() => navigateToAgentDetails(params?.row?.id)}
            onClick={() =>
              handleSingleBooking(params?.row?.id, params?.row?.agentId)
            }
          >
            {params?.row?.bookingId ?? "Booking Id"}
          </button>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      suppressSizeToFit: true,
      width: 70,
    },
    {
      field: "company",
      headerName: "Company Name",
      suppressSizeToFit: true,
      width: 130,
    },
    {
      field: "deptFrom",
      headerName: "Route",
      suppressSizeToFit: true,
      width: 125,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.deptFrom} - {params?.row?.arriveTo}
          </span>
        );
      },
    },
    {
      field: "tripType",
      headerName: "Trip Type",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "journeyType",
      headerName: "Journey Type",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "pax",
      headerName: "Pax",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "timeLimit",
      headerName: "TimeLimit",
      suppressSizeToFit: true,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.timeLimit === "Immediate Issue" ? (
              <span>Immediate Issue</span>
            ) : params?.row?.timeLimit === null ? (
              ""
            ) : (
              <>
                {moment(
                  params?.row?.timeLimit ? params?.row?.timeLimit : ""
                ).format("MMM Do YY, h:mm a")}
              </>
            )}
          </>
        );
      },
    },

    {
      field: "airlinesPNR",
      headerName: "AirlinesPnr",
      suppressSizeToFit: true,
      width: 100,
    },

    {
      field: "lastAmount    ",
      headerName: "Balance",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "adultBag",
      headerName: "AdultBag",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "childBag",
      headerName: "ChildBag",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "childBag",
      headerName: "ChildBag",
      suppressSizeToFit: true,
      width: 100,
    },

    // value === "pending"
    //   ? {
    //       field: "id",
    //       headerName: "Operation",
    //       suppressSizeToFit: true,
    //       width: 120,
    //       renderCell: (params) => {
    //         return (
    //           <Box>
    //             {
    //               <CheckCircleOutlineIcon
    //                 sx={{
    //                   cursor: "pointer",
    //                 }}
    //                 onClick={() =>
    //                   handleAgentApproveClick(
    //                     params?.row?.id,
    //                     params?.row,
    //                     "approved"
    //                   )
    //                 }
    //               />
    //             }
    //             {
    //               <ClearIcon
    //                 sx={{
    //                   cursor: "pointer",
    //                 }}
    //                 onClick={() =>
    //                   handleAgentApproveClick(
    //                     params?.row?.id,
    //                     params?.row?.agent,
    //                     "rejected"
    //                   )
    //                 }
    //               />
    //             }
    //           </Box>
    //         );
    //       },
    //     }
    //   : value === "approved" && {
    //       field: "id",
    //       headerName: "Action",
    //       suppressSizeToFit: true,
    //       width: 120,
    //       renderCell: (params) => {
    //         return (
    //           <Box>
    //             <button
    //               style={{
    //                 width: "100px",
    //                 height: "30px",
    //                 border: "none",
    //                 background: "green",
    //                 color: "#fff",
    //                 cursor: "pointer",
    //                 fontSize: "12px",
    //                 borderRadius: "5px",
    //               }}
    //               onClick={() =>
    //                 handleActiveDeactive(params?.row?.id, params?.status)
    //               }
    //             >
    //               Active
    //             </button>
    //           </Box>
    //         );
    //       },
    //     },
  ];

  return (
    <Box
      sx={{
        m: "0 auto",
        width: {
          sx: "100%",
          md: "95%",
        },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "26px",
            pb: "20px",
            color: "var(--trips-secondary-color)",
          }}
        >
          Air Ticket Booking
        </Typography>
      </Box>
      <Box sx={{ my: "10px" }}>
        <TabContext value={value}>
          <Box sx={{ display: "flex" }}>
            {/* Tab bar */}
            <Box
              style={{
                backgroundColor: "var(--thard-color)",
                borderRadius: "30px",
                width: "100%",
              }}
            >
              <TabList
                onChange={handleChange}
                indicatorColor="none"
                textColor="white"
                aria-label="home-tab"
              >
                <Tab
                  style={{ height: "30px" }}
                  label="Hold"
                  value={"Hold"}
                  sx={{
                    color: "var(--primary-text)",
                    opacity: "1",
                    backgroundColor: "var(--primary-color)",
                    borderRadius: "5px",
                    width: {
                      lg: "60px",
                      xs: "fit-content",
                    },
                    minHeight: "20px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",

                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  label="Issue on Process"
                  value={"Issue Request"}
                  style={{
                    height: "30px",
                    marginLeft: "3px",
                  }}
                  sx={{
                    opacity: "1",
                    backgroundColor: "var(--primary-color)",
                    borderRadius: "5px",
                    width: {
                      lg: "100px",
                      xs: "fit-content",
                    },
                    whiteSpace: "nowrap",
                    minHeight: "10px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  label="Issue Rejected"
                  value={"Issue Rejected"}
                  style={{
                    height: "30px",
                    marginLeft: "3px",
                  }}
                  sx={{
                    opacity: "1",
                    backgroundColor: "var(--primary-color)",
                    borderRadius: "5px",
                    width: {
                      lg: "100px",
                      xs: "fit-content",
                    },
                    whiteSpace: "nowrap",
                    minHeight: "10px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />

                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Ticketed"
                  value={"Ticketed"}
                  sx={{
                    color: "var(--primary-text)",
                    opacity: "1",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    width: {
                      lg: "60px",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "12px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Refunded"
                  value={"Refunded"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "60px",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Reissue"
                  value={"Reissue Request"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Voided"
                  value={"Voided"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="Cancelled"
                  value={"Cancelled"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "100",
                      xs: "fit-content",
                    },
                    minHeight: "10px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
                <Tab
                  style={{ marginLeft: "5px", height: "30px" }}
                  label="All"
                  value={"all"}
                  sx={{
                    color: "var(--primary-text)",
                    borderRadius: "5px",
                    backgroundColor: "var(--primary-color)",
                    opacity: "1",
                    width: {
                      lg: "60px",
                      xs: "fit-content",
                    },
                    minHeight: "25px",
                    margin: {
                      xs: "0px 0px",
                    },
                    "&.MuiTab-root.Mui-selected": {
                      backgroundColor: "var(--active-bg-color)",
                      color: "var(--thard-color)",
                      borderRadius: "5px",
                      opacity: "1",
                    },
                    fontSize: { xs: "8px", sm: "10px", md: "11px" },
                    textTransform: "Capitalize",
                  }}
                />
              </TabList>
            </Box>
          </Box>
        </TabContext>
      </Box>

      <Box
        style={styles.root}
        sx={{
          mt: 2,
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--input-bgcolor)",
            fontSize: "12px",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderTop: "1px solid #DDDDDD",
            borderBottom: "1px solid #DDDDDD",
            color: "var(--white)",
            fontSize: "12px",
            maxHeight: "20px !important",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "var(--body-color)",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "var(--primary-color)",
            color: "var(--white)",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "var(--dasbord-sidebar--color)",
            background: "var(--primary-bonus-color)",
            fontSize: "10px",
            height: "30px",
            mb: 2,
          },
          ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
            color: "var(--white)",
          },
          ".MuiTablePagination-selectLabel": {
            color: "var(--white)",
          },
          ".MuiSelect-select": {
            color: "var(--white)",
          },
          ".css-levciy-MuiTablePagination-displayedRows": {
            color: "var(--white)",
          },
          ".MuiDataGrid-cellContent": {
            color: "var(--text-color)",
          },
        }}
      >
        {value === "Hold" ? (
          <DataGrid
            rows={allAirTicket}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            scrollbarSize={5}
            style={styles.dataGrid}
          />
        ) : value === "Issue Request" ? (
          <DataGrid
            rows={allAirTicket}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            scrollbarSize={5}
            style={styles.dataGrid}
          />
        ) : value === "Ticketed" ? (
          <DataGrid
            rows={allAirTicket}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            scrollbarSize={5}
            style={styles.dataGrid}
          />
        ) : value === "Refunded" ? (
          <Box>
            <Box>
              <TabContext value={refundTab}>
                <Box sx={{ display: "flex" }}>
                  {/* Tab bar */}
                  <Box
                    style={{
                      backgroundColor: "var(--thard-color)",
                      borderRadius: "30px",
                      width: "100%",
                    }}
                  >
                    <TabList
                      onChange={handleRefundTab}
                      indicatorColor="none"
                      textColor="white"
                      aria-label="home-tab"
                    >
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Refund Request"
                        refundTab={"refundRequest"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Refund To Be Confrim"
                        refundTab={"reissueConfrim"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Refund In Process"
                        refundTab={"reissueProcess"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Refunding"
                        refundTab={"reissued"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Refunded"
                        refundTab={"reissueRejected"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Force Refunded"
                        refundTab={"reissueRejected"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Refund Rejected"
                        refundTab={"reissueRejected"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                    </TabList>
                  </Box>
                </Box>
              </TabContext>
            </Box>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                  <TableRow>
                    <TableCell className="table-cell">
                      <Button
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "var(--primary-text)",
                          fontSize: "11px",
                          textTransform: "capitalize",
                          ":hover": {
                            backgroundColor: "var(--primary-text)",
                          },
                        }}
                      >
                        Booking Id
                      </Button>
                    </TableCell>
                    <TableCell className="table-cell">Status</TableCell>
                    <TableCell className="table-cell">Company Name</TableCell>
                    <TableCell className="table-cell">Route</TableCell>
                    <TableCell className="table-cell">TripType</TableCell>
                    <TableCell className="table-cell">Journey Type</TableCell>
                    <TableCell className="table-cell">Pax</TableCell>
                    <TableCell className="table-cell">TimeLimit</TableCell>
                    <TableCell className="table-cell">AirlinesPnr</TableCell>
                    <TableCell className="table-cell">Balance</TableCell>
                    <TableCell className="table-cell">AdultBag</TableCell>
                    <TableCell className="table-cell">ChildBag</TableCell>
                    <TableCell className="table-cell">InfantBag</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td, & th": {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                >
                  {allAirTicket?.map((ticket) => (
                    <TableRow
                      key={ticket?.id}
                      sx={{
                        width: "100%",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell"
                      >
                        <Button
                          onClick={() =>
                            handleSingleBooking(ticket?.id, ticket?.agentId)
                          }
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "var(--primary-text)",
                            fontSize: "11px",
                            textTransform: "capitalize",
                            ":hover": {
                              backgroundColor: "var(--primary-text)",
                            },
                          }}
                        >
                          {ticket?.bookingId}
                        </Button>
                      </TableCell>

                      <TableCell className="table-cell">
                        {ticket?.status}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.company}
                      </TableCell>
                      <TableCell className="table-cell">
                        {`${ticket?.deptFrom} -${ticket?.arriveTo}`}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.tripType}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.journeyType}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.adultCount +
                          ticket?.childCount +
                          ticket?.infantCount}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.timeLimit ? ticket?.timeLimit.toString() : ""}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.airlinesPnr}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.amount ? ticket?.amount : 0}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.adultBag}
                      </TableCell>
                      <TableCell className="table-cell">
                        {ticket?.childBag}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center" }}
                        className="table-cell"
                      >
                        {ticket?.infantBag}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : value === "Reissue Request" ? (
          <>
            <QuickReissue />
          </>
        ) : value === "Voided" ? (
          <Box>
            <Box>
              <TabContext value={refundTab}>
                <Box sx={{ display: "flex" }}>
                  {/* Tab bar */}
                  <Box
                    style={{
                      backgroundColor: "var(--thard-color)",
                      borderRadius: "30px",
                      width: "100%",
                    }}
                  >
                    <TabList
                      onChange={handleRefundTab}
                      indicatorColor="none"
                      textColor="white"
                      aria-label="home-tab"
                    >
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Void Request"
                        refundTab={"pending"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      {/* <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Void To Be Confrim"
                        refundTab={"voidConfrim"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Void In Process"
                        refundTab={"voidProcess"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      /> */}
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Voided"
                        refundTab={"Voided"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                      <Tab
                        style={{ marginLeft: "5px", height: "30px" }}
                        label="Void Rejected"
                        refundTab={"voidRejected"}
                        sx={{
                          color: "var(--primary-text)",
                          borderRadius: "5px",
                          backgroundColor: "var(--primary-color)",
                          opacity: "1",
                          width: {
                            lg: "100",
                            xs: "fit-content",
                          },
                          minHeight: "25px",
                          margin: {
                            xs: "0px 0px",
                          },
                          "&.MuiTab-root.Mui-selected": {
                            backgroundColor: "var(--trips-secondary-color)",
                            color: "var(--thard-color)",
                            borderRadius: "5px",
                            opacity: "1",
                          },
                          fontSize: { xs: "8px", sm: "10px", md: "11px" },
                          textTransform: "Capitalize",
                        }}
                      />
                    </TabList>
                  </Box>
                </Box>
              </TabContext>
            </Box>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                  <TableRow>
                    <TableCell className="table-cell">
                      <Button
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "var(--primary-text)",
                          fontSize: "11px",
                          textTransform: "capitalize",
                          ":hover": {
                            backgroundColor: "var(--primary-text)",
                          },
                        }}
                      >
                        Booking Id
                      </Button>
                    </TableCell>
                    <TableCell className="table-cell">Status</TableCell>
                    <TableCell className="table-cell">Company Name</TableCell>
                    <TableCell className="table-cell">Route</TableCell>
                    <TableCell className="table-cell">TripType</TableCell>
                    <TableCell className="table-cell">Journey Type</TableCell>
                    <TableCell className="table-cell">Pax</TableCell>
                    <TableCell className="table-cell">TimeLimit</TableCell>
                    <TableCell className="table-cell">AirlinesPnr</TableCell>
                    <TableCell className="table-cell">Balance</TableCell>
                    <TableCell className="table-cell">AdultBag</TableCell>
                    <TableCell className="table-cell">ChildBag</TableCell>
                    <TableCell className="table-cell">InfantBag</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td, & th": {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                >
                  {allVoidRequest?.map((ticket) => {
                    // console.log(ticket);
                    return (
                      <TableRow
                        key={ticket?.id}
                        sx={{
                          width: "100%",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell"
                        >
                          <Button
                            onClick={() =>
                              navigate(
                                `/quickDashboard/quedeatils/${ticket?.id}?${ticket?.agentId}`,
                                {
                                  state: {
                                    additionalId: ticket?.additionalId,
                                  },
                                }
                              )
                            }
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            {ticket?.bookingId}
                          </Button>
                        </TableCell>

                        <TableCell className="table-cell">
                          {ticket?.additionalStatus}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.company}
                        </TableCell>
                        <TableCell className="table-cell">
                          {`${ticket?.deptFrom} -${ticket?.arriveTo}`}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.tripType}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.journeyType}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.adultCount +
                            ticket?.childCount +
                            ticket?.infantCount}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.timeLimit
                            ? ticket?.timeLimit.toString()
                            : ""}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.airlinesPNR}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.amount ? ticket?.amount : 0}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.adultBag}
                        </TableCell>
                        <TableCell className="table-cell">
                          {ticket?.childBag}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center" }}
                          className="table-cell"
                        >
                          {ticket?.infantBag}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : value === "Cancelled" ? (
          <DataGrid
            rows={allAirTicket}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            scrollbarSize={5}
            style={styles.dataGrid}
          />
        ) : value === "all" ? (
          <DataGrid
            rows={allAirTicket}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            scrollbarSize={5}
            style={styles.dataGrid}
          />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default QuickAirTicket;
