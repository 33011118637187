import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ActiveUserList from "./ActiveUserList";
import DeactiveUserList from "./DeactiveUserList";
import AllUserList from "./AllUserList";
import { CircleLoader } from "react-spinners";
import secureLocalStorage from "react-secure-storage";
import  Unverified  from "./Unverified";

const UserList = () => {
  const [value, setValue] = useState("1");
  const token = secureLocalStorage.getItem("Admin-Token");

  console.log(token)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // all users active and deactive all state
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  let url;
  if (value === "3") {
    url ="https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/admin-route/get-all-users";
  } else if (value === "1") {
    url ="https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/admin-route/get-all-users?activeUser=true";
  } else if (value === "2") {
    url ="https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/admin-route/get-all-users?activeUser=false";
  }
  else if (value === "4") {
    url ="https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/admin-route/get-all-users?verifiedUser=false";
  }

  console.log(value)

  useEffect(() => {
    setLoading(true);
    if (url) {
      fetch(url, {
        method: "GET",
        // Add headers if needed
        headers: {
          "Admin-Token":`${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAllUsers(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  return (
    <Box>
    
      <Box mt={4}>
        <Typography
          sx={{ fontSize: "25px", color: "var(--trips-primary-color)" }}
        >
          All User List
        </Typography>
      </Box>

      <Box sx={{ width: "100%", typography: "body1", mt: 4, padding: 0 }}>
        <TabContext value={value}>
          <Box sx={{ display: "flex", borderColor: "divider" }}>
            <TabList
              indicatorColor="none"
              textColor="white"
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ padding: 0 }}
            >
              <Tab
               style={{ height: "30px" }}
                sx={{
                  color: "var(--primary-text)",
                  opacity: "1",
  
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "5px",
                  width: {
                    lg: "60px",
                    xs: "fit-content",
                  },
                  minHeight: "20px",
                  margin: {
                    xs: "0px 0px",
                  },
                  "&.MuiTab-root.Mui-selected": {
                    backgroundColor: "var(--trips-secondary-color)",
                    color: "var(--thard-color)",
                    borderRadius: "5px",
                    textTransform:"uppercase",
                    opacity: "1",
                  },

                  fontSize: { xs: "8px", sm: "10px", md: "11px" },
                  textTransform:"uppercase",
                }}
                label="Active"
                value="1"
              />
              <Tab
               style={{ height: "30px" }}
                sx={{
                  color: "var(--primary-text)",
                  opacity: "1",
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "5px",
                  width: {
                    lg: "60px",
                    xs: "fit-content",
                  },
                  minHeight: "20px",
                  margin: {
                    xs: "0px 10px",
                  },
                  "&.MuiTab-root.Mui-selected": {
                    backgroundColor: "var(--trips-secondary-color)",
                    color: "var(--thard-color)",
                    borderRadius: "5px",
                    textTransform:"uppercase",
                    opacity: "1",
                  },
                  fontSize: { xs: "8px", sm: "10px", md: "11px" },
                  textTransform:"uppercase",
                }}
                label="Deactive"
                value="2"
              />

<Tab
 style={{ height: "30px" }}
                sx={{
                  color: "var(--primary-text)",
                  opacity: "1",
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "5px",
                  width: {
                    lg: "60px",
                    xs: "fit-content",
                  },
                  minHeight: "20px",
                  margin: {
                    xs: "0px 10px",
                  },
                  "&.MuiTab-root.Mui-selected": {
                    backgroundColor: "var(--trips-secondary-color)",
                    color: "var(--thard-color)",
                    borderRadius: "5px",
                    textTransform:"uppercase",
                    opacity: "1",
                  },
                  fontSize: { xs: "8px", sm: "10px", md: "11px" },
                  textTransform:"uppercase",
                }}
                label="Unverified"
                value="4"
              />
              <Tab
               style={{ height: "30px" }}
                sx={{
                  color: "var(--primary-text)",
                  opacity: "1",
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "5px",
                  width: {
                    lg: "60px",
                    xs: "fit-content",
                  },
                  minHeight: "20px",
                  margin: {
                    xs: "0px 5px",
                  },
                  "&.MuiTab-root.Mui-selected": {
                    backgroundColor: "var(--trips-secondary-color)",
                    color: "var(--thard-color)",
                    borderRadius: "5px",
                    textTransform:"uppercase",
                    opacity: "1",
                  },
                  fontSize: { xs: "8px", sm: "10px", md: "11px" },
                  textTransform:"uppercase",
                }}
                label="All"
                value="3"
              />
            </TabList>
          </Box>
          <Box>
            <TabPanel value="1">
              <ActiveUserList loading={loading} allUsers={allUsers} />
            </TabPanel>
            <TabPanel value="2">
              <DeactiveUserList loading={loading} allUsers={allUsers} />
            </TabPanel>
            <TabPanel value="3">
              <AllUserList loading={loading} allUsers={allUsers} />
            </TabPanel>
            <TabPanel value="4">
            <Unverified loading={loading} allUsers={allUsers} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default UserList;
