import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import Loader from "../Shared/Loader/Loader";



const StaffUpdate = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "12px",
    color: "#000",
  };
  const tabcellTexts = {
    whiteSpace: "nowrap",
    fontSize: "8px",
    color: "#000",
  };
  const navigate = useNavigate();

  const [allAdmin, setAllAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const { id } = useParams();

  useEffect(() => {
    const apiUrl = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/getAllOfAdmins/${id}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAllAdmin(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, [token]);

  console.log("info", allAdmin);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Grid container>
            <Grid
              item
              container
              md={9.5}
              sx={{
                borderRight: "10px solid #EFF2F5",
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                width: "100%",
                pr: "20px",
              }}
            >
              <Grid
                item
                md={12}
                sx={{
                  bgcolor: "#FFFFFF",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
                >
                  Zinga Lala Travel
                </Typography>
                <TableContainer sx={{ mt: "10px" }}>
                  <Table
                    size="small"
                    aria-label="simple table"
                    style={{ width: "100%" }}
                  >
                    <TableHead
                      sx={{ borderTop: "1px solid #DDDDDD" }}
                      style={{ width: "100%" }}
                    >
                      <TableRow>
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          Employee ID
                        </TableCell>
                        <TableCell style={tabcellText}>Status</TableCell>
                        <TableCell style={tabcellText}>Name</TableCell>
                        <TableCell style={tabcellText}>O. Email</TableCell>
                        <TableCell style={tabcellText}>O. Password</TableCell>
                        <TableCell style={tabcellText}>
                          O. Phone Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ "& td, & th": {} }}>
                      <TableRow
                        sx={{
                          height: "20px",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          {allAdmin?.information?.Employee_ID}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.information?.Status}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.information?.name}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.information?.OEmail}
                        </TableCell>
                        <TableCell style={tabcellText}>
                         {allAdmin?.information?.password}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.information?.OPhone}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  bgcolor: "#FFFFFF",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
                >
                  Personal Information
                </Typography>
                <TableContainer sx={{ mt: "10px" }}>
                  <Table
                    size="small"
                    aria-label="simple table"
                    style={{ width: "100%" }}
                  >
                    <TableHead
                      sx={{ borderTop: "1px solid #DDDDDD" }}
                      style={{ width: "100%" }}
                    >
                      <TableRow>
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          Full Name
                        </TableCell>
                        <TableCell style={tabcellText}>DOB</TableCell>
                        <TableCell style={tabcellText}>Blood Group</TableCell>
                        <TableCell style={tabcellText}>
                          Personal Email
                        </TableCell>
                        <TableCell style={tabcellText}>
                          Personal Phone
                        </TableCell>
                        <TableCell style={tabcellText}>Address</TableCell>
                        <TableCell style={tabcellText}>NID No</TableCell>
                        <TableCell style={tabcellText}>NID Copy</TableCell>
                        <TableCell style={tabcellText}>
                          Passport Size Image
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ "& td, & th": {} }}>
                      <TableRow
                        sx={{
                          height: "20px",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          {allAdmin?.personalInfo?.name}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.personalInfo?.DOB
                            ? moment(allAdmin?.personalInfo?.DOB).format(
                                "YYYY-MM-DD"
                              )
                            : "Invalid Date"}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.personalInfo?.Blood_Group}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.personalInfo?.Personal_Email}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.personalInfo?.Personal_Phone}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.personalInfo?.Address}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.personalInfo?.NID_NO}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          <img
                            style={{ height: "50px", width: "50px" }}
                            src={allAdmin?.personalInfo?.NID_COPY}
                          />
                        </TableCell>
                        <TableCell style={tabcellText}>
                          <img
                            style={{ height: "50px", width: "50px" }}
                            src={allAdmin?.personalInfo?.Image}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  bgcolor: "#FFFFFF",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
                >
                  Official Information
                </Typography>
                <TableContainer sx={{ mt: "10px" }}>
                  <Table
                    size="small"
                    aria-label="simple table"
                    style={{ width: "100%" }}
                  >
                    <TableHead
                      sx={{ borderTop: "1px solid #DDDDDD" }}
                      style={{ width: "100%" }}
                    >
                      <TableRow>
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          Wing
                        </TableCell>
                        <TableCell style={tabcellText}>Designation</TableCell>
                        <TableCell style={tabcellText}>Role</TableCell>
                        <TableCell style={tabcellText}>Salary</TableCell>
                        <TableCell style={tabcellText}>Join At</TableCell>
                        <TableCell style={tabcellText}>Created By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ "& td, & th": {} }}>
                      <TableRow
                        sx={{
                          height: "20px",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {allAdmin?.officialInfo?.wings?.length ? (
                          allAdmin.officialInfo.wings.map((wing, index) => (
                            <TableCell
                              key={index}
                              style={tabcellText}
                              component="th"
                              scope="row"
                            >
                              {wing.route}
                            </TableCell>
                          ))
                        ) : (
                          <TableCell
                            style={tabcellText}
                            component="th"
                            scope="row"
                          >
                            No data available
                          </TableCell>
                        )}

                        <TableCell style={tabcellText}>
                          {allAdmin?.officialInfo?.Designation}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.officialInfo?.Role}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.officialInfo?.Salary}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.personalInfo?.DOB
                            ? moment(allAdmin?.officialInfo?.joinAt).format(
                                "YYYY-MM-DD"
                              )
                            : "Invalid Date"}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.officialInfo?.createByName}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  bgcolor: "#FFFFFF",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
                >
                  Inventory Allocate
                </Typography>
                <TableContainer sx={{ mt: "10px" }}>
                  <Table
                    size="small"
                    aria-label="simple table"
                    style={{ width: "100%" }}
                  >
                    <TableHead
                      sx={{ borderTop: "1px solid #DDDDDD" }}
                      style={{ width: "100%" }}
                    >
                      <TableRow>
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          Mobile
                        </TableCell>
                        <TableCell style={tabcellText}>PC/ Laptop</TableCell>
                        <TableCell style={tabcellText}>Keyboard</TableCell>
                        <TableCell style={tabcellText}>Mouse</TableCell>
                        <TableCell style={tabcellText}>Head Phone</TableCell>
                        <TableCell style={tabcellText}>Extra</TableCell>
                        <TableCell style={tabcellText}>Authorized By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ "& td, & th": {} }}>
                      <TableRow
                        sx={{
                          height: "20px",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          style={tabcellText}
                          component="th"
                          scope="row"
                        >
                          {allAdmin?.Inventory?.mobile}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.Inventory?.computer}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.Inventory?.keyboard}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {allAdmin?.Inventory?.mouse}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.Inventory?.headPhone}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.Inventory?.extra}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {allAdmin?.Inventory?.authorizedByName}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={2.5}
              sx={{ bgcolor: "#FFFFFF", borderRadius: "5px" }}
            >
              <Box sx={{ mt: "10px", px: "30px" }}>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "5px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Change Information
                  </Typography>

                  <Button
                    onClick={() =>
                      navigate(`/quickDashboard/changeEmployeInfo/${id}`, {
                        state: { id, allAdmin },
                      })
                    }
                    sx={{
                      width: "250px",
                      background: "var(--savStaff-color)",
                      ":hover": {
                        background: "var(--savStaff-color)",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Change Information
                  </Button>
                </Box>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "5px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Personal Information
                  </Typography>

                  <Button
                    onClick={() =>
                      navigate(`/quickDashboard/changePersonalInfo/${id}`, {
                        state: { id, allAdmin },
                      })
                    }
                    sx={{
                      width: "250px",
                      background: "var(--savStaff-color)",
                      ":hover": {
                        background: "var(--savStaff-color)",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Change Information
                  </Button>
                </Box>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "10px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Official Ionfirmation
                  </Typography>
                  <Button
                    sx={{
                      width: "250px",
                      background: "var(--savStaff-color)",
                      ":hover": {
                        background: "var(--savStaff-color)",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Change Information
                  </Button>
                  <Button
                    sx={{
                      my: "20px",
                      width: "250px",
                      background: "#DC143C",
                      ":hover": {
                        background: "#DC143C",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Add Information
                  </Button>
                </Box>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "10px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Inventory Information
                  </Typography>
                  <Button
                    onClick={() =>
                      navigate(`/quickDashboard/changeInventoryInfo`, {
                        state: { id, allAdmin },
                      })
                    }
                    sx={{
                      width: "250px",
                      background: "var(--savStaff-color)",
                      ":hover": {
                        background: "var(--savStaff-color)",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Change Information
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default StaffUpdate;
