import { Box } from "@mui/material";
import React from "react";

const GroupFarePolicy = () => {
  return (
    <Box>
      <Box>
        {/* Refund Policy inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Refund Policy
            </label>
          </Box>

          <input
            placeholder="Refund Policy"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Reissue Policy inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Reissue Policy
            </label>
          </Box>

          <input
            placeholder="Reissue Policy"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Void Policy inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Void Policy
            </label>
          </Box>

          <input
            placeholder="Void Policy"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* Cancellation Policy inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Cancellation Policy
            </label>
          </Box>

          <input
            placeholder="Cancellation Policy"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
      </Box>
      <Box sx={{ mt: "50px" }}>
        {/* Before Departure Reissue Fee inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Before Departure Reissue Fee
            </label>
          </Box>

          <input
            placeholder="Befre Reissue Fee"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* After Departure Reissue Fee inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              After Departure Reissue Fee
            </label>
          </Box>

          <input
            placeholder="After Reissue Fee"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
      </Box>
      <Box sx={{ mt: "50px" }}>
        {/* Before Departure Reissue Fee inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Before Departure Refund Fee
            </label>
          </Box>

          <input
            placeholder="Before  Refund Fee"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
        {/* After Departure Refund Fee inputs */}
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #DADCE0",
            pb: "15px",
            mt: "30px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "700px" }}>
            <label style={{ color: "#5F6368", fontSize: "12px" }}>
              After Departure Refund Fee
            </label>
          </Box>

          <input
            placeholder="After Refund Fee"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            name="visaType"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GroupFarePolicy;
