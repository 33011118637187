import { Grid } from '@material-ui/core'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import axios from 'axios';
import React, { useRef, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import JoditEditor from "jodit-react";

export const PopularDestination = () => {
    const token = secureLocalStorage.getItem("Admin-Token");
    const editor = useRef(null);
    const [formData, setFormData] = useState({
      type: "",
      images: "",
      short_description: "",
    
    });
    console.log(formData);
  
    const handleSelectedCategory = (event) => {
      setFormData({
        ...formData,
        type: event.target.value,
      });
    };
  
    const handleInputChange = (values) => {
      setFormData({
        ...formData,
        ...values,
      });
    };
  
    const handleThumbnailImageChange = (event) => {
      setFormData({
        ...formData,
        images: event.target.files[0],
      });
    };
  
    console.log(formData)



    const handlePopularDestination = async (event) => {
        event.preventDefault();
    
        try {
          const formDataToSend = new FormData();
          formDataToSend.append("type", formData.type);
          formDataToSend.append("image", formData.images);
          formDataToSend.append("short_description", formData.short_description);
          const response = await axios.post(
            "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/destination/create-destination",
            formDataToSend,
    
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Admin-Token": `${token}`,
              },
            }
          );
    
          if (response.data.success) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
            });
    
            setFormData({
              type: "",
             images:'',
            short_description:""
            });
    
      

          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message,
            });

            setFormData({
                type: "",
               images:'',
              short_description:""
              });
      
          }
        } catch (error) {
          console.error("Error submitting form:", error);
    
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while submitting the form.",
          });

          setFormData({
            type: "",
           images:'',
          short_description:""
          });
  
        }
      };


  return (
    <Box>
          <Box sx={{ my: "10px",mt:7 }} >
        <Typography>PopularDestination</Typography>
      </Box>


{/* forms area */}


<form onSubmit={handlePopularDestination} >
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
         

                  width: "100%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                    Selected Destination Type
                  </label>
                </Box>
                <select
                  onChange={handleSelectedCategory}
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    color: "#A3A4A9",
                  }}
                 
                >
            <option value="">Select Destination</option>
                  <option value="domestic">Domestic</option>
                  <option value="international">International</option>

                </select>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
              <Box>
                <Box
                  sx={{
                    mt: -4,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                    color: "#A3A4A9",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ fontSize: "12px" }}>
                      Short Descriptions
                    </label>
                  </Box>

                  {/* <textarea
                  value={formData.short_description}
                  onChange={(e) => handleInputChange(e, "short_description")}
                  placeholder="Shorts Descriptions"
                  style={{
                    height: "20px",
                    border: "none",
                    backgroundColor: "white",
                    resize: "none",
                    outline: "none",
                    width: "100%",
                  }}
                /> */}
                  <JoditEditor
                    ref={editor}
                    value={formData.short_description}
                    onChange={(content) =>
                      handleInputChange({ short_description: content })
                    }
                  />
                </Box>
              </Box>
            </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  mt:-2,
                  display: "flex",
                  flexDirection: "column",
                  gap:"10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "300px" }}>
                  <label style={{ fontSize: "12px" }}>Image</label>
                </Box>

                <Box
                  sx={{
                    width: "250px",
                    height: { md: "160px", sm: "210px", xs: "210px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                
                  }}
                >
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleThumbnailImageChange(e)}
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "none",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        height: { md: "160px", sm: "210px", xs: "210px" },
                      }}
                    >
                      {formData.images ? (
                        <img
                          src={URL.createObjectURL(formData.images)}
                          alt="Thumbnail"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: "100%",
                            height: { md: "210px", sm: "210px", xs: "210px" },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "30px",
                            textAlign: "center",
                            borderRadius: "10px",
                          }}
                        >
                          Upload Thumbnail Image
                        </Skeleton>
                      )}
                    </Box>
                  </label>
                </Box>
              </Box>
            </Box>
          </Grid>






          <Grid item md={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "var(--trips-primary-color)",
                  color: "white",
                  ":hover": {
                    background: "var(--trips-primary-color)",
                    color: "white",
                  },
                }}
              >
                Upload Popular Destination
              </Button>
            </Box>
          </Grid>




{/* pictures */}
















        </Grid>
      </form>



















    </Box>
  )
}
