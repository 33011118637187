import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import VisaRoute from "./VisaRoute";

const AddVisaCountry = () => {
  const token = secureLocalStorage.getItem("token");
  const visaId = secureLocalStorage.getItem("visaId");
  const [daynamicCountry, setDaynamicCountry] = useState([]);
  const [isRefetch, setIsRefetch] = useState(false);
  const location = useLocation();

  const [visaCountryInfo, setVisaCountryInfo] = useState({
    countryName: "",
    countryCapital: "",
    currency: "",
    countryFlag: "",
    countryDescription: "",
  });

  const type = location?.state?.type || "update";

  console.log(location?.state);

  let countryName = visaCountryInfo?.countryName.split(" ")[0];
  console.log(countryName);

  let filteredData =
    daynamicCountry.find((data, i) => data?.name === countryName) || {};
  console.log(filteredData);
  let filteredDataKeys;

  console.log("f data", filteredData);
  let currencyName;
  if (filteredData && filteredData.currency) {
    const filteredDataKeys = Object.keys(filteredData.currency);
    filteredDataKeys.forEach(function (key) {
      currencyName = key;
    });
  }

  

  const [data, setData] = useState([]);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      setVisaCountryInfo({
        ...visaCountryInfo,
        [name]: files[0],
      });
    } else {
      setVisaCountryInfo({
        ...visaCountryInfo,
        [name]: value,
      });
    }
  };

  // single visa info

  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/get-single-visa-info/${visaId}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response?.data?.data);
        setVisaCountryInfo(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [visaId, isRefetch]);

  // countries api

  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/get-rest-countries`;
    axios
      .get(url)
      .then((response) => {
        setDaynamicCountry(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleVisaInfo = async (e) => {
    e.preventDefault();

    const body = {
      countryName: visaCountryInfo?.countryName,
      countryCapital: filteredData?.capital,
      currency: currencyName,
      flagImage: filteredData?.countryCode,
      countryDescription: visaCountryInfo?.countryDescription,
    };

    try {
      const response = await fetch(
        visaId
          ? `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-country-information/${visaId}`
          : "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/create-country-information",
        {
          method: visaId ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const responseData = await response.json();

      
      if (responseData?.success === true) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: responseData?.message,
        });
        secureLocalStorage.setItem("visaId", responseData?.data);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: responseData?.message,
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred. Please try again later.",
      });
    }
  };

  const handleVisaImageChange = async (imgFile, id) => {
    console.log("id", id);

    const formData = new FormData();
    formData.append("image", imgFile);
    try {
      let response;
      if (id) {
        response = await axios.put(
          `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/update-country-image/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await axios.post(
          `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/admin/visa-module/upload-country-images/${visaId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (response && response.data) {
        const { success, message, data: imageUrl } = response.data;

        if (success) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: message,
          }).then(() => setIsRefetch((prev) => !prev));
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: message,
          });
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response
          ? error.response.data.message
          : "An error occurred while uploading the image.",
      });
    }
  };

  return (
    <Box sx={{ px: "30px" }}>
      <VisaRoute type={type} />
      <Box sx={{ py: "30px" }}>
        <form onSubmit={handleVisaInfo}>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Country Name
              </label>
            </Box>

            <select
              placeholder="Enter Country Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="countryName"
              value={visaCountryInfo?.countryName}
              onChange={handleInputChange}
            >
              <option value="">Select a country</option>
              {daynamicCountry?.map((country) => (
                <option key={country.code} value={country?.name}>
                  {country?.name} ({country?.capital})
                  <img src={country?.name} />
                </option>
              ))}
            </select>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                County Capital
              </label>
            </Box>

            <input
              placeholder=" Enter County Capital"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="countryCapital"
              Value={filteredData?.capital}
              onChange={handleInputChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                County Currency
              </label>
            </Box>

            <input
              placeholder=" Enter County Currency"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="currency"
              value={currencyName}
              onChange={handleInputChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                County Code
              </label>
            </Box>

            <Box
              sx={{
                // height: { md: "158px", sm: "150px", xs: "150px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                placeholder=" Enter County Code"
                style={{
                  border: "none",
                  backgroundColor: "white",
                  outline: "none",
                }}
                name="countryCode"
                value={filteredData?.countryCode}
                onChange={handleInputChange}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                County Description
              </label>
            </Box>

            <input
              placeholder=" Enter County Description"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="countryDescription"
              value={visaCountryInfo?.countryDescription}
              onChange={handleInputChange}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              pt: "20px",
            }}
          >
            <Button
              type="submit"
              sx={{
                bgcolor: "var(--trips-secondary-color)",
                color: "white",
                textTransform: "capitalize",
                width: "200px",
                ":hover": {
                  bgcolor: "var(--trips-secondary-color)",
                  color: "white",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
        <Box>
          <Grid container sx={{ py: "50px" }} spacing={2}>
            {/* country image  */}
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image1"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image1"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage1"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[0]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[0]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image2"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image2"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage2"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[1]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[1]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image3"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image3"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage3"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[2]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[2]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image4"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image4"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage4"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[3]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[3]?.country_image}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image5"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image5"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage5"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[4]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[4]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image6"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image6"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage6"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[5]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[5]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image7"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image7"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage7"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[0]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[6]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  height: { md: "158px", sm: "150px", xs: "150px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="image8"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <input
                    id="image8"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="visaImage8"
                    onChange={(e) =>
                      handleVisaImageChange(
                        e.target.files[0],
                        data?.countryImage[7]?.id
                      )
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "none",
                    }}
                  />

                  {data?.countryImage ? (
                    <img
                      src={data?.countryImage[7]?.country_image}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: {
                          md: "400px",
                          sm: "200px",
                          xs: "200px",
                        },
                        height: {
                          md: "250px",
                          sm: "150px",
                          xs: "150px",
                        },
                      }}
                    />
                  )}
                </label>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AddVisaCountry;
