import React from "react";
import Select from "react-select";
import { Box } from "@mui/material";

const SearchableDropDown = ({ handler, options, index, placeholder }) => {
  return (
    <Box
      sx={{
        ".css-1xc3v61-indicatorContainer": {
          color: "var(--primary-color) !important",
        },
        ".css-1jqq78o-placeholder": {
          color: "var(--primary-color) !important",
        },
        ".css-13cymwt-control": {
          borderColor: "var(--primary-color) !important",
          color: "var(--primary-color) !important",
        },
        ".css-1dimb5e-singleValue": {
          color: "var(--primary-color) !important",
          fontWeight: "500",
        },
      }}>
      <Select
        onChange={(item) => {
          handler(item.value, index);
        }}
        options={options}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default SearchableDropDown;