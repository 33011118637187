import React, { useState } from "react";
import {
  Box,
  Button,
  ListItemText,
  SwipeableDrawer,
  Typography,
  styled,
} from "@mui/material";

import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorageIcon from "@mui/icons-material/Storage";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ListItemButton from "@mui/material/ListItemButton";
import MuiDrawer from "@mui/material/Drawer";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { withStyles } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";
import { NavLink, Outlet } from "react-router-dom";
import logo from "../../image/companylogo 1.png";

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#222222",
      color: "white",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#222222",
      color: "white",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#696969",
      color: "white",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MuiListItem);
const SubListItem = withStyles({
  subselected: {},
})(MuiListItem);

const drawerWidth = 210;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto",
  height: "100%",
  "&::-webkit-scrollbar-thumb": {
    display: "none !important",
    width: "0px important",
  },
  "&::-webkit-scrollbar-track": {
    display: "none !important",
  },
  "&::-webkit-scrollbar": {
    display: "none !important",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...openedMixin(theme),
  "& .MuiDrawer-paper": openedMixin(theme),
}));

const TechSideBar = () => {
  const [open, setOpen] = useState(false);
  const [queues, setQueues] = useState(false);
  const [payment, setPayment] = useState(false);
  const [settings, setSettings] = useState(false);
  const [account, setAccount] = useState(false);
  const [user, setUser] = useState(false);
  const [booking, setBooking] = useState(false);
  const [report, setReport] = useState(false);
  const [manage, setManage] = useState(false);
  const [cms, setCms] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index == 1) {
      setOpen(true);
      setUser(!user);
      setManage(false);
      setPayment(false);
      setAccount(false);
      setReport(false);
      setSettings(false);
      setBooking(false);
      setCms(false);
    } else if (index === 2) {
      setOpen(true);
      setUser(false);
      setManage(!manage);
      setPayment(false);
      setAccount(false);
      setReport(false);
      setSettings(false);
      setBooking(false);
      setCms(false);
    } else if (index === 3) {
      setOpen(true);
      setUser(false);
      setManage(false);
      setPayment(false);
      setAccount(!account);
      setReport(false);
      setSettings(false);
      setBooking(false);
      setCms(false);
    } else if (index === 4) {
      setOpen(true);
      setUser(false);
      setManage(false);
      setPayment(false);
      setAccount(false);
      setReport(!report);
      setSettings(false);
      setBooking(false);
      setCms(false);
    } else if (index === 5) {
      setOpen(true);
      setUser(false);
      setManage(false);
      setPayment(false);
      setAccount(false);
      setReport(false);

      setSettings(!settings);
      setBooking(false);
      setCms(false);
    } else if (index === 6) {
      setOpen(true);
      setUser(false);
      setManage(false);
      setPayment(false);
      setAccount(false);
      setReport(false);
      setSettings(false);
      setBooking(!booking);
      setCms(false);
    } else if (index === 7) {
      setOpen(true);
      setUser(false);
      setManage(false);
      setPayment(!payment);
      setAccount(false);
      setReport(false);
      setSettings(false);
      setBooking(false);
      setCms(false);
    } else if (index === 8) {
      setOpen(true);
      setUser(false);
      setManage(false);
      setPayment(!payment);
      setAccount(false);
      setReport(false);
      setSettings(false);
      setBooking(false);
      setCms(!false);
    }
  };

  return (
    <Box sx={{ display: "flex" }} className="sideBar-user">
      <CssBaseline />

      {/* For Moblie */}

      <Box
        sx={{
          display: { xs: "flex", sm: "none", md: "none" },
          // background:'red',
          position: "absolute",
          right: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
      >
        {["left"].map((anchor) => (
          <Box key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuIcon
                style={{
                  color: "var(--trips-secondary-color)",
                  fontSize: "35px",
                  marginTop: "20px",
                }}
              />
            </Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              <Box backgroundColor="#042568" height="100vh">
                <List sx={{ mt: 4 }}>
                  <NavLink
                    to="/tech"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 0}
                      onClick={(event) => handleListItemClick(event, 0)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                        }}
                      >
                        <DashboardIcon
                          sx={{
                            fontSize: "16px",
                            color: "#FFFF",
                          }}
                        />
                        <ListItemText sx={{ ml: 1 }}>
                          <Typography sx={{ color: "#ffff" }}>
                            {" "}
                            Dashboard
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>

                  <NavLink
                    to=""
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 2}
                      onClick={(event) => handleListItemClick(event, 2)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                        }}
                      >
                        <StorageIcon
                          sx={{
                            fontSize: "16px",
                            color: "#FFFF",
                          }}
                        />
                        <ListItemText sx={{ ml: 1 }}>
                          <Typography
                            sx={{ textDecoration: "none", color: "#ffff" }}
                          >
                            Manage
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  {/* Sub Menu Queues Section start  */}
                  {selectedIndex === 2 && manage && (
                    <Box
                      className="dash-sub-menu"
                      style={{ display: open === false ? "none" : "" }}
                    >
                      <Box sx={{ opacity: open ? 1 : 0 }}>
                        <NavLink
                          to="trips/tourpackage"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          <SubListItem style={{ padding: "0px" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  ml: 1,
                                }}
                              >
                                Add Tour Package
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>

                        <NavLink
                          to=""
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          <SubListItem style={{ padding: "0px" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  opacity: open ? 1 : 0,
                                  ml: 1,
                                }}
                              >
                                Add Group Fare
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>
                      </Box>
                    </Box>
                  )}
                  {/* Sub Menu Queues Section end  */}




  

           

                  <NavLink
                    to="/"
                    style={{
                      display: "block",
                      marginTop: "10%",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      // selected={selectedIndex === 7}
                      //   onClick={adminLogout}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                        }}
                      >
                        <ExitToAppIcon
                          sx={{
                            fontSize: "16px",
                            color: "#FFFF",
                          }}
                        />
                        <ListItemText sx={{ ml: 1 }}>
                          <Typography
                            sx={{ textDecoration: "none", color: "#ffff" }}
                          >
                            Log Out
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                </List>
              </Box>
            </SwipeableDrawer>
          </Box>
        ))}
      </Box>

      {/* -----------------For Screen------------------------ */}

      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
      >
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{
              display: "block",
              backgroundColor: "#042568",
              textAlign: "center",
            }}
            // onClick={() => setOpen(!open)}
          >
            <Box sx={{ py: 3 }}>
              <img src={logo} width="100px" />
            </Box>
          </DrawerHeader>

          <Box backgroundColor="#042568" height="100vh">
            <List>
              <NavLink
                // to=" "
                style={{
                  display: "block",
                  marginBottom: "10px",
                  textDecoration: "none",
                }}
              >
                <ListItem
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                    }}
                  >
                    <DashboardIcon
                      sx={{
                        fontSize: "16px",
                        color: "#FFFF",
                      }}
                    />
                    <ListItemText sx={{ ml: 1 }}>
                      <Typography sx={{ color: "#ffff" }}>
                        {" "}
                        Dashboard
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              <NavLink
                to="/tech/products"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  textDecoration: "none",
                }}
              >
                <ListItem
                //   selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                    }}
                  >
                    <StorageIcon
                      sx={{
                        fontSize: "16px",
                        color: "#FFFF",
                      }}
                    />
                    <ListItemText sx={{ ml: 1 }}>
                      <Typography
                        sx={{ textDecoration: "none", color: "#ffff" }}
                      >
                        Products
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Sub Menu Queues Section start  */}
              {/* {selectedIndex === 2 && manage && (
                <Box
                  className="dash-sub-menu"
                  style={{ display: open === false ? "none" : "" }}
                >
                  <Box sx={{ opacity: open ? 1 : 0 }}>
                    <NavLink
                      to="/"
                      style={{
                        display: "block",
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      <SubListItem style={{ padding: "0px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              ml: 1,
                            }}
                          >
                            HRM Pro
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>

                    <NavLink
                      to=""
                      style={{
                        display: "block",
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      <SubListItem style={{ padding: "0px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Others
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>
                  </Box>
                </Box>
              )} */}
              {/* Sub Menu Queues Section end  */}

         
          

            

          

       

    


              <NavLink
                to="/"
                style={{
                  display: "block",
                  marginTop: "10%",
                  textDecoration: "none",
                }}
              >
                <ListItem
                  // selected={selectedIndex === 7}
                  //   onClick={adminLogout}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                    }}
                  >
                    <ExitToAppIcon
                      sx={{
                        fontSize: "16px",
                        color: "#FFFF",
                      }}
                    />
                    <ListItemText sx={{ ml: 1 }}>
                      <Typography
                        sx={{ textDecoration: "none", color: "#ffff" }}
                      >
                        Log Out
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
          </Box>
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          width: {
            md: `calc(100% - ${drawerWidth}px)`,
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};

export default TechSideBar;
