import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const AgentUpdate = () => {
  const location = useLocation();
  const allAgent = location?.state?.allAgent;
  const token = secureLocalStorage.getItem("token");

  const [formData, setFormData] = useState({
    status: allAgent?.agentData?.status || "",
    agentName:
      allAgent?.agentData?.firstName + " " + allAgent?.agentData?.lastName ||
      "",
    email: allAgent?.agentData?.email || "",
    nidPassportNo: allAgent?.agentData?.nid_passport || "",
    country: allAgent?.agentData?.country || "",
    city: allAgent?.agentData?.city || "",
    postalCode: allAgent?.agentData?.postalCode || "",
    address: allAgent?.agentData?.address || "",
    phoneNumber: allAgent?.agentData?.phone || "",
    whatsAppNumber: allAgent?.agentData?.whatsAppNumber || "",
  });

  console.log(formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAgentUpdate = async (e) => {
    e.preventDefault();
    try {
      const data = {
        firstName: formData.agentName.split(" ")[0] || "",
        lastName: formData.agentName.split(" ")[1] || "",
        company: "ABC Company",
        phone: formData.phoneNumber || "",
        companyAdd: formData.address || "",
        whatsAppNumber: formData.whatsAppNumber || "",
        country: formData.country || "",
        city: formData.city || "",
        postalCode: formData.postalCode || 0,
      };

      const apiUrl =
        "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/agent/update_agent";

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();

        Swal.fire({
          title: "Success!",
          text: responseData.message,
          icon: "success",
        });
      } else {
        const errorData = await response.json();

        Swal.fire({
          title: "Error!",
          text: `Error: ${errorData.message}`,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);

      Swal.fire({
        title: "Error!",
        text: "An error occurred. Please try again later.",
        icon: "error",
      });
    }
  };

  return (
    <Box sx={{ mx: "20px", minHeight: "700px" }}>
      <Typography sx={{ fontSize: "22px", color: "var(--secondary-color)" }}>
        Agent Information Update
      </Typography>
      <form onSubmit={handleAgentUpdate}>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Status</label>
          </Box>

          <input
            placeholder="Change Your Status"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.status}
            onChange={handleInputChange}
            name="status"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Agent Name
            </label>
          </Box>

          <input
            type="text"
            placeholder="Change Your agentName"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.agentName}
            onChange={handleInputChange}
            name="agentName"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>Email</label>
          </Box>

          <input
            type="email"
            placeholder="Change Your Email"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.email}
            onChange={handleInputChange}
            name="email"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              NID / Passport No
            </label>
          </Box>

          <input
            placeholder="Change Your NID / Passport No"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.nidPassportNo}
            onChange={handleInputChange}
            name="nidPassportNo"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Country
            </label>
          </Box>

          <input
            placeholder="Change Your Country"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.country}
            onChange={handleInputChange}
            name="country"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>City</label>
          </Box>

          <input
            placeholder="Change Your City"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.city}
            onChange={handleInputChange}
            name="city"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Postal Code
            </label>
          </Box>

          <input
            placeholder="Change Your Postal Code"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.postalCode}
            onChange={handleInputChange}
            name="postalCode"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Address
            </label>
          </Box>

          <input
            placeholder="Change Your Address"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.address}
            onChange={handleInputChange}
            name="address"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Phone Number
            </label>
          </Box>

          <input
            placeholder="Change Your Phone Number"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.phoneNumber}
            onChange={handleInputChange}
            name="phoneNumber"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              WhatsApp Number
            </label>
          </Box>

          <input
            placeholder="Change Your WhatsApp Number"
            style={{
              border: "none",
              backgroundColor: "white",
              outline: "none",
            }}
            value={formData.whatsAppNumber}
            onChange={handleInputChange}
            name="whatsAppNumber"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            mt: "20px",
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "30%",
              color: "var(--thard-color)",
              textTransform: "capitalize",
              backgroundColor: "var(--savStaff-color)",
              ":hover": {
                backgroundColor: "var(--savStaff-color)",
              },
            }}
          >
            Save Changes Information
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AgentUpdate;
