import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableBody,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const MakeTicketed = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "11px",
    color: "#000",
  };
  const tabcellTexts = {
    whiteSpace: "nowrap",
    fontSize: "8px",
    color: "#000",
  };

  const location = useLocation();
  const { singleQuedetails } = location?.state;
  const [formData, setFormData] = useState({
    airlinesPNR: "",
    systemPNR: "",
    selectedSystem: "System Sabre",
    gdsSegment: "",
    ticketingClass: "",
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData)
  };

  

  const handleMakeTickted = (e) => {
    e.preventDefault();
  };

  return (
    <Box sx={{ width: "100%", my: "20px" }}>
      <form onSubmit={handleMakeTickted}>
        <Grid container>
          <Grid
            item
            container
            md={10}
            sx={{
              borderRight: "10px solid #EFF2F5",
              bgcolor: "#FFFFFF",
              borderRadius: "5px",
              width: "100%",
              height: "100%",
              pr: "20px",
            }}
          >
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "20px", color: "var(--secondary-color)" }}
                >
                  {singleQuedetails?.bookingInfo?.bookingId} |{" "}
                  {singleQuedetails?.bookingInfo?.tripType} |{" "}
                  {`${singleQuedetails?.bookingInfo?.deptFrom}-${singleQuedetails?.bookingInfo?.arriveTo}`}{" "}
                  | Biman Bangladesh | 01 jan 2024:01:AM
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Booking Details
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Booking Id
                      </TableCell>
                      <TableCell style={tabcellText}>Status</TableCell>
                      <TableCell style={tabcellText}>Route</TableCell>
                      <TableCell style={tabcellText}>System</TableCell>
                      <TableCell style={tabcellText}>Segment</TableCell>
                      <TableCell style={tabcellText}>Airlines PNR</TableCell>
                      <TableCell style={tabcellText}>GDS PNR</TableCell>
                      <TableCell style={tabcellText}>
                        Expire Timleimit
                      </TableCell>
                      <TableCell style={tabcellText}>Ticket Type</TableCell>
                      <TableCell style={tabcellText}>Booking Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText} component="th" scope="row">
                        <Button
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "var(--primary-text)",
                            fontSize: "11px",
                            textTransform: "capitalize",
                            ":hover": {
                              backgroundColor: "var(--primary-text)",
                            },
                          }}
                        >
                          {singleQuedetails?.bookingInfo?.bookingId}
                        </Button>
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.bookingInfo?.status}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                      >{`${singleQuedetails?.bookingInfo?.deptFrom}-${singleQuedetails?.bookingInfo?.arriveTo}`}</TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.bookingInfo?.system
                          ? singleQuedetails?.bookingInfo?.system
                          : "System001"}
                      </TableCell>
                      <TableCell style={tabcellText}>Comming</TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.bookingInfo?.airlinesPNR}
                      </TableCell>
                      <TableCell style={tabcellText}>Comming!!!!!!!!</TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {singleQuedetails?.bookingInfo?.timeLimit}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.bookingInfo?.journeyType}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {new Date(
                          singleQuedetails?.bookingInfo?.assignTime
                        ).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Ticket Details
              </Typography>

              <Grid container spacing={5}>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",
                        mt: "20px",
                        width: "100%",
                        color: "#A3A4A9",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ fontSize: "12px" }}>Airlines PNR</label>
                      </Box>

                      <input
                        name="airlinesPNR"
                        value={formData.airlinesPNR}
                        onChange={handleInputChange}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",
                        mt: "20px",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          System pnr
                        </label>
                      </Box>

                      <input
                        name="systemPNR"
                        placeholder="Enter The System Pnr"
                        value={formData.systemPNR}
                        onChange={handleInputChange}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Selected system
                        </label>
                      </Box>

                      <select
                       value={formData.selectedSystem}
                       onChange={handleInputChange}
                       name="selectedSystem"
                        style={{
                          width: "45%",
                          outline: "none",
                          border: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          color: "#A3A4A9",
                        }}
                      >
                        {/* Add your options here */}
                        <option value="sabre">System Sabre</option>
                        <option value="gallleo">System Gallleo</option>

                        {/* Add more options as needed */}
                      </select>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          GDS Segment
                        </label>
                      </Box>

                      <input
                       value={formData.gdsSegment}
                       onChange={handleInputChange}
                        name="gdsSegment"
                        placeholder="Enter The Gds Segment"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Ticketing Class
                        </label>
                      </Box>

                      <input
                        name="ticketingClass"
                        value={formData.ticketingClass}
                        onChange={handleInputChange}
                        placeholder=" Enter The Ticketing Class"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Vendor Details
              </Typography>

              <Grid container spacing={5}>
                <Grid item md={12}>
                  <Box sx={{ my: "20px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "500px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Selected Vendor
                        </label>
                      </Box>

                      <select
                        style={{
                          width: "100%",
                          outline: "none",
                          border: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          color: "#A3A4A9",
                        }}
                      >
                        {/* Add your options here */}
                        <option value="option1">System Sabre</option>
                        <option value="option2">System Gallleo</option>

                        {/* Add more options as needed */}
                      </select>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                        color: "#A3A4A9",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ fontSize: "12px" }}>Base Fare</label>
                      </Box>

                      <input
                        name="Base Fare"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Enter the Base Fare"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Tax
                        </label>
                      </Box>

                      <input
                        name="23.36"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Enter The Tax"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          AIT
                        </label>
                      </Box>

                      <input
                        name="gfhg0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Enter The AIT"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Vendor Name
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder=" Enter The Vendor Name"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          IATA Number
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Enter The IATA Number"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          PCC Number
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Enter The PCC Number"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Currency
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="BDT"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Invoice Details
              </Typography>

              <Grid container spacing={5} sx={{ mt: "5px" }}>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                        color: "#A3A4A9",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ fontSize: "12px" }}>
                          Client Invoice Amount
                        </label>
                      </Box>

                      <input
                        name="9660"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Client Invoice Amount"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Client Invioce Commission
                        </label>
                      </Box>

                      <input
                        name="23.36"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder=" Client Invioce Commission"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Vendor Invioce Amount
                        </label>
                      </Box>

                      <input
                        name="gfhg0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Vendor Invioce Amount"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Vendor Invioce Commission
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Vendor Invioce Commission"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Loss/Profit Amount
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Loss/Profit Amount"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Loss / Profit Commission
                        </label>
                      </Box>

                      <input
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Loss / Profit Commission"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Passenger Details
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText}>Title</TableCell>
                      <TableCell style={tabcellText}>First Name</TableCell>
                      <TableCell style={tabcellText}>Last Name</TableCell>
                      <TableCell style={tabcellText}>Nationality</TableCell>
                      <TableCell style={tabcellText}>DOB</TableCell>
                      <TableCell style={tabcellText}>Gender</TableCell>
                      <TableCell style={tabcellText}>Pax</TableCell>
                      <TableCell style={tabcellText}>Passport Number</TableCell>
                      <TableCell style={tabcellText}>Expiry Date</TableCell>

                      <TableCell style={tabcellText}>Ticket Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    {/* Passenger information */}
                    {singleQuedetails?.passengers?.map((data) => (
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.prefix}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.firstName}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.lastName}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passNation}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.dob}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.gender}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.type}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passNo}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passEx}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passEx}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Operation Details Written By SayedAfridi
              </Typography>
              <Grid container sx={{ my: "20px" }} spacing={5}>
                <Grid item md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      borderBottom: "1px solid #A3A4A9",
                      pb: "20px",

                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "500px" }}>
                      <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                        Loss Profit Explanation
                      </label>
                    </Box>

                    <textarea
                      name="0"
                      // value={formData.mouse}
                      // onChange={(e) =>
                      //   setFormData({ ...formData, mouse: e.target.value })
                      // }
                      placeholder="Paste your ticket Coupon..."
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                        width: "100%",
                        resize: "none",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Remarks...
                        </label>
                      </Box>

                      <textarea
                        name="0"
                        // value={formData.mouse}
                        // onChange={(e) =>
                        //   setFormData({ ...formData, mouse: e.target.value })
                        // }
                        placeholder="Remarks..."
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                          resize: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "#A3A4A9",
                      }}
                      type="checkbox"
                    />{" "}
                    <Typography
                      sx={{ color: "green", fontWeight: "500", pl: "5px" }}
                    >
                      {" "}
                      I, SayedAfridi have accepted the profit amount of BDT 0
                      and I am going to deliver the ticket.
                    </Typography>
                  </Box>
                  <Box sx={{ py: "10px" }}>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        bgcolor: "green",
                        color: "var(--thard-color)",
                        width: "100px",
                        ":hover": {
                          bgcolor: "green",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={2}
            sx={{ bgcolor: "#FFFFFF", borderRadius: "5px", px: "20px" }}
          >
            <Box sx={{ height: "96.5%" }}>
              <label> Ticket Coupon</label>
              <textarea
                placeholder="Paste Your ticket Coupon..."
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  height: "500px",
                  resize: "none",
                  outline: "none",
                  marginTop: "3px",
                  height: "100%",
                  borderRadius: "5px",
                  border: "none",
                  border: "1px solid  #A3A4A9",
                }}
              ></textarea>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default MakeTicketed;
