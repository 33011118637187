import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

const Album = () => {
  const [addPhoto1, setaddPhoto1] = useState([""]);
  return (
    <Box>
      <Typography sx={{ fontSize: "17px" }} my={2}>
        Package Album
      </Typography>
      <Box
        sx={{
          borderBottom: "1px solid #DADCE0",
          display: "flex",
          justifyContent: "start",
          gap: "598px",
          paddingBottom: "8px",
        }}
      >
        <Box>
          <input
            type="text"
            placeholder="Album Cover Photo"
            style={{ outline: "none", border: "none" }}
          />
        </Box>
        <Box>
          <input
            type="text"
            placeholder="Amount"
            style={{
              outline: "none",
              border: "none",
            }}
          />
        </Box>
      </Box>

      {addPhoto1.map((x, index) => (
        <Box
          sx={{
            borderBottom: "1px solid #DADCE0",
            display: "flex",
            justifyContent: "start",
            gap: "598px",
            paddingBottom: "8px",
            marginTop: "24px",
          }}
        >
          <Box>
            <input
              type="text"
              placeholder={`Photo ${index + 1}`}
              style={{ outline: "none", border: "none" }}
            />
          </Box>
          <Box>
            <input
              type="text"
              placeholder="Amount"
              style={{
                outline: "none",
                border: "none",
              }}
            />
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          marginTop: "29px",
          display: "flex",
          justifyContent: "start",
          gap: "11px",
        }}
      >
        <Button
          onClick={(prev) => {
            if (addPhoto1.length < 7) {
              setaddPhoto1((prev) => [...prev, ""]);
            }
          }}
          variant="contained"
          sx={{
            bgcolor: "green",
            boxShadow: "none",
            textTransform: "capitalize",
          }}
        >
          Add Event
        </Button>
        <Button
          onClick={(prev) => {
            if (addPhoto1.length > 1) {
              setaddPhoto1((prev) => prev.slice(0, -1));
            }
          }}
          variant="contained"
          sx={{
            bgcolor: "red",
            boxShadow: "none",
            textTransform: "capitalize",
          }}
        >
          Remove Event
        </Button>
      </Box>
    </Box>
  );
};

export default Album;
