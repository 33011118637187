import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const TripsAdminLogIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { email, password } = formData;
  
    const requestBody = {
      email: email,
      password: password,
    };
  
    try {
      const response = await fetch(
        "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      const data = await response.json();
  
      if (response.ok) {
        console.log("Hey ami login data", data);
  
        secureLocalStorage.setItem("user-info", JSON.stringify(data));
        secureLocalStorage.setItem("Admin-Token", data.token);
  
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data?.message,
        }).then(() => {
          window.location.href = "/trips";
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            data?.message || "Login failed. Please check your credentials.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again later.",
      });
    }
  
    setFormData({
      email: "",
      password: "",
    });
  };
  
  return (
    <Box sx={{ backgroundColor: "var(--primary-color)", height: "100vh" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            height: "100vh", // Make the container take up the full viewport height
          }}
        >
          <Box
            sx={{
              background: "#ffff",
              width: "450px",
              px: "20px",
              borderRadius: "5px",
              py: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  textAlign: "center",
              
                }}
              >
                Welcome To The  Fly Far Trips
              </Typography>
              <Typography
                sx={{
                  fontSize: "27px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  textAlign: "center",
                  pb: "20px",
                  pt:"5px"
                }}
              >
                Admin Login
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    borderBottom: "1px solid #B6B6CC",
                    display: "flex",
                  }}
                >
                  <Box sx={{ py: "20px", width: "200px" }}>
                    <label
                      style={{
                        color: "var( --profile-boday-color)",
                        fontSize: "18px",
                      }}
                    >
                      Email
                    </label>
                  </Box>

                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{
                      border: "none",
                      color: "#B6B6CC",
                      outline: "none",
                      background: "none",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #B6B6CC",
                    display: "flex",
                  }}
                >
                  <Box sx={{ py: "20px", width: "200px" }}>
                    <label
                      style={{
                        color: "var( --profile-boday-color)",
                        fontSize: "18px",
                      }}
                    >
                      Password
                    </label>
                  </Box>

                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter Your Password"
                    value={formData.password}
                    onChange={handleChange}
                    style={{
                      border: "none",
                      color: "#B6B6CC",
                      outline: "none",
                      background: "none",
                    }}
                  />
                </Box>

                <Box sx={{ py: "10px" }}>
                  <Button
                    type="submit"
                    sx={{
                      borderRadius: "6px",
                      backgroundColor: "var(--trips-primary-color)",
                      color: "var(--thard-color)",
                      width: "100%",
                      height: "45px",
                      textTransform: "capitalize",
                      fontSize: "15px",
                      mt: "5px",
                      fontStyle: "italic",
                      ":hover": {
                        backgroundColor: "var(--trips-primary-color)",
                      },
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TripsAdminLogIn;
