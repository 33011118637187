import { CallMerge } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import moment from "moment";
const PersonalInfoChanges = () => {
  const location = useLocation();
  const token = secureLocalStorage.getItem("token");
  const allAdmin = location.state?.allAdmin;
  const { id } = useParams();
  console.log("ami personalPage theke", allAdmin);
  const [name, setName] = useState(allAdmin?.personalInfo?.name || "");
  const [DOB, setDOB] = useState(
    moment(new Date(allAdmin?.personalInfo?.DOB).toISOString()).format(
      "YYYY-MM-DD"
    ) || ""
  );
  const [PersonalEmail, setPersonalEmail] = useState(
    allAdmin?.personalInfo?.Personal_Email || ""
  );
  const [PersonalPhone, setPersonalPhone] = useState(
    allAdmin?.personalInfo?.Personal_Phone || ""
  );
  const [Address, setAddress] = useState(allAdmin?.personalInfo?.Address || "");
  const [bloodGroup, setBloodGroup] = useState(
    allAdmin?.personalInfo?.Blood_Gropup || ""
  );
  const [NIDNO, setNIDNO] = useState(allAdmin?.personalInfo?.NID_NO || "");

  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  // const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState([]);

  const handleImageClick = (imageURL) => {
    setSelectedImageURL(imageURL);
  };

  const [formData, setFormData] = useState({
    personalImage: allAdmin?.personalInfo?.NID_COPY || null,
    nidCopy: allAdmin?.personalInfo?.Image || null,
  });
  const fileInputRef = useRef(null);
  // handleallInfo
  const handlePersonalInfo = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: PersonalEmail,
      address: Address,
      dob: DOB,
      bloodGroup: bloodGroup,
      phone: PersonalPhone,
    };

    try {
      const response = await fetch(
        `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/update_personalInfo/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Personal information updated successfully!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update personal information.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating personal information.",
      });
    }
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to send the files
    const data = new FormData();
    data.append("image", formData.personalImage);
    data.append("fieldName", formData.nidCopy);

    try {
      const token = secureLocalStorage.getItem("token"); // Retrieve the authentication token

      // Create a fetch request and add the token to the header
      const response = await fetch(
        `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/update_images/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Images uploaded successfully!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to upload images.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while uploading images.",
      });
    }
  };

  return (
    <Box sx={{ mx: "20px", minHeight: "700px" }}>
      <Typography sx={{ fontSize: "22px", color: "var(--secondary-color)" }}>
        Personal Information Update
      </Typography>
      <form onSubmit={handlePersonalInfo}>
        <Box sx={{ py: "20px" }}>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Full Name
              </label>
            </Box>

            <input
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>DOB</label>
            </Box>

            <input
              value={DOB}
              onChange={(e) => setDOB(e.target.value)}
              placeholder="Enter Your DOB"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Personal Email
              </label>
            </Box>

            <input
              value={PersonalEmail}
              onChange={(e) => setPersonalEmail(e.target.value)}
              placeholder="Enter Your Personal Email"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Personal Phone
              </label>
            </Box>

            <input
              value={PersonalPhone}
              onChange={(e) => setPersonalPhone(e.target.value)}
              placeholder="Enter Your Personal Phone"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Address
              </label>
            </Box>

            <input
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter Your Address"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                Blood Group
              </label>
            </Box>

            <input
              value={bloodGroup}
              onChange={(e) => setBloodGroup(e.target.value)}
              placeholder="Enter Your Blood Group"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #A3A4A9",
              pb: "20px",
              mt: "20px",
              width: "1300px",
            }}
          >
            <Box sx={{ width: "500px" }}>
              <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                NID No
              </label>
            </Box>

            <input
              value={NIDNO}
              onChange={(e) => setNIDNO(e.target.value)}
              placeholder="Enter Your Full Name"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            mt: "20px",
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "30%",
              color: "var(--thard-color)",
              textTransform: "capitalize",
              backgroundColor: "var(--savStaff-color)",
              ":hover": {
                backgroundColor: "var(--savStaff-color)",
              },
            }}
          >
            Save Changes Information
          </Button>
        </Box>
      </form>
      <form onSubmit={handleImageSubmit}>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              Personal Image
            </label>
          </Box>

          <Box sx={{ width: "20%" }}>
            {allAdmin?.personalInfo?.Image ? (
              <Box
                sx={{
                  fontSize: "12px",
                  color: "var(--secondary-color)",
                  width: "20%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // When the existing image is clicked, trigger the file input
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                <img
                  src={allAdmin?.personalInfo?.Image}
                  alt="Personal Image"
                  style={{ width: "70px", height: "65px" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  fontSize: "12px",
                  color: "var(--secondary-color)",
                  width: "20%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                Select Image
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #A3A4A9",
            pb: "20px",
            mt: "20px",
            width: "1300px",
          }}
        >
          <Box sx={{ width: "500px" }}>
            <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
              NID Copy
            </label>
          </Box>
          <Box sx={{ width: "20%" }}>
            {allAdmin?.personalInfo?.NID_COPY ? (
              <Box
                sx={{
                  fontSize: "12px",
                  color: "var(--secondary-color)",
                  width: "20%",
                }}
                onClick={() => {
                  handleImageClick(allAdmin?.personalInfo?.NID_COPY);
                }}
              >
                <img
                  src={allAdmin?.personalInfo?.NID_COPY}
                  alt="NID Copy"
                  style={{ width: "70px", height: "65px" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  fontSize: "12px",
                  color: "var(--secondary-color)",
                  width: "20%",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      NID_COPY: e.target.files[0],
                    });
                    e.target.files[0] &&
                      setSelectedImage1(URL.createObjectURL(e.target.files[0]));
                  }}
                  name="NID_COPY"
                  style={{
                    backgroundColor: "#D9D9D9",
                    color: "white",
                    width: "100px",
                    padding: "4px",
                    color: "black",
                    height: "65px",
                  }}
                  className="customFileType"
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            mt: "20px",
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "30%",
              color: "var(--thard-color)",
              textTransform: "capitalize",
              backgroundColor: "var(--savStaff-color)",
              ":hover": {
                backgroundColor: "var(--savStaff-color)",
              },
            }}
          >
            Save Changes images
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default PersonalInfoChanges;
