import {
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const VoidFareDetails = ({
  bookingTotalPrices,
  priceBreakdown,
  setQueueDetails,
  queueDetails,
}) => {
  const handleAddServiceFee = (e, paxType) => {
    const queData = queueDetails;

    queData.bookingInfo.priceBreakDown.forEach((item) => {
      if (item.paxType === paxType) {
        item.newServiceFee = e.target.value;
      }
    });

    queData.passengers.forEach((passenger) => {
      if (passenger.type === paxType) {
        passenger.newServiceFee = e.target.value;
      } // Example value, replace with your actual value
    });

    setQueueDetails({ ...queData });
  };

  //   console.log({ ...queData });
  //   console.log(queueDetails);
  return (
    <Box
      mt={3}
      sx={{
        "& .MuiTableCell-root": {
          fontSize: "12px",
          color: "var(--text-medium)",
        },
        "& .MuiTableCell-head": {
          color: "var(--text-dark)",
          fontFamily: "productSans500 !important",
        },
        "& .MuiTableRow-root": {
          borderTop: "1px solid var(--table-border)",
        },
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            {/* table title row  */}
            <TableRow>
              <TableCell>Pax Type</TableCell>
              <TableCell>Pax Count</TableCell>
              <TableCell>Base Fare</TableCell>
              <TableCell>Tax</TableCell>
              <TableCell>Total Base Fare</TableCell>
              <TableCell>Total Tax</TableCell>
              <TableCell>Service Fee</TableCell>
              <TableCell>Markup</TableCell>
              <TableCell>Sub Total</TableCell>
              <TableCell>Void Service Fee (-)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceBreakdown?.map((price, i) => (
              <TableRow key={i}>
                <TableCell>
                  {price?.paxType === "ADT"
                    ? "Adult"
                    : price?.paxType === "CNN"
                    ? "Child"
                    : "Infant"}
                </TableCell>
                <TableCell>0{price?.passengerCount}</TableCell>
                <TableCell>
                  {price?.currency} {price?.baseFare?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {price?.currency} {price?.tax?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {price?.currency}{" "}
                  {price?.totalBaseFareAmount?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {price?.currency} {price?.totalTax?.toLocaleString()}
                </TableCell>

                <TableCell>
                  {price?.currency} {price?.serviceFee?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {price?.currency} {price?.markup || 0?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {price?.currency} {price?.totalClientPrice?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {price?.currency}
                  <input
                    type="number"
                    value={price?.newServiceFee}
                    onChange={(e) => handleAddServiceFee(e, price?.paxType)}
                    style={{ marginLeft: "5px", width: "55px" }}
                  />
                  
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={7} sx={{ color: "var(--text-medium)" }}>
                <Typography sx={{ fontSize: "14px" }}>Fare Summery</Typography>
              </TableCell>

              <TableCell colSpan={2}>
                <Typography
                  sx={{ color: "var(--text-dark)", fontSize: "12px" }}
                >
                  Grand Total or Customer Total
                </Typography>

                <Typography
                  sx={{ color: "var(--text-dark)", fontSize: "12px", py: 0.5 }}
                >
                  Discount
                </Typography>
                <Typography
                  sx={{ color: "var(--text-dark)", fontSize: "12px", py: 0.5 }}
                >
                  AIT
                </Typography>

                <Typography
                  sx={{ color: "var(--text-dark)", fontSize: "12px", py: 0.5 }}
                >
                  Agent Payable Amount
                </Typography>

                <Typography
                  sx={{
                    color: "var(--text-dark)",
                    fontSize: "12px",
                    color: "var(--trips-secondary-color)",
                  }}
                >
                  Total Void Service Charge
                </Typography>
                <Typography
                  sx={{ color: "var(--text-dark)", fontSize: "12px", py: 0.5 }}
                >
                  Agent Refundable Amount
                </Typography>
              </TableCell>

              <TableCell colSpan={1}>
                <Typography
                  sx={{ fontSize: "12px", color: "var(--text-dark)" }}
                >
                  BDT{" "}
                  {queueDetails?.bookingInfo?.priceBreakDown
                    ?.reduce(
                      (total, passenger) =>
                        total +
                        passenger.passengerCount *
                          (passenger?.totalClientPrice -
                            passenger?.newServiceFee),
                      0
                    )
                    .toLocaleString("en-In")}
                </Typography>

                <Typography
                  sx={{ fontSize: "12px", color: "var(--text-dark)", py: 0.4 }}
                >
                  BDT{" "}
                  {parseInt(
                    bookingTotalPrices?.clientPrice -
                      bookingTotalPrices?.agentPrice
                  ).toLocaleString("en-In")}
                </Typography>

                <Typography
                  sx={{ fontSize: "12px", color: "var(--text-dark)", py: 0.4 }}
                >
                  BDT{" "}
                  {Math.ceil(bookingTotalPrices?.aitPrice).toLocaleString(
                    "en-In"
                  )}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", color: "var(--text-dark)" }}
                >
                  BDT{" "}
                  {parseInt(bookingTotalPrices?.agentPrice).toLocaleString(
                    "en-In"
                  )}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--trips-secondary-color)",
                    py: 0.4,
                  }}
                >
                  BDT{" "}
                  {queueDetails?.bookingInfo?.priceBreakDown
                    ?.reduce(
                      (total, passenger) =>
                        total + parseFloat(passenger?.newServiceFee || 0),
                      0
                    )
                    .toLocaleString("en-In")}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", color: "var(--text-dark)", py: 0.4 }}
                >
                  BDT{" "}
                  {(
                    parseInt(bookingTotalPrices?.agentPrice) -
                    parseInt(
                      queueDetails?.bookingInfo?.priceBreakDown?.reduce(
                        (total, passenger) =>
                          total + parseFloat(passenger?.newServiceFee || 0),
                        0
                      )
                    )
                  ).toLocaleString("en-In")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VoidFareDetails;
