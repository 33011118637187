import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

const VisaRoute = ({ type }) => {
  console.log(type);

  const location = useLocation();
  return (
    <Box>
      <Box sx={{ py: "30px" }}>
        <Box>
          <Box
            my={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ color: "#202124", fontSize: "20px" }}>
              {location.pathname === "/quickDashboard/visaManage/countryinfo"
                ? "Add Visa, Country Information"
                : location.pathname === "/quickDashboard/visaManage/basicinfo"
                ? "Add Visa, Basic Information"
                : "Add Visa, Country Information"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#EFF2F5",
              width: "80%",
            }}
          >
            <NavLink
              to="/quickDashboard/visaManage/countryinfo"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Country Information
            </NavLink>

            <NavLink
              to="/quickDashboard/visaManage/basicinfo"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Basic Information
            </NavLink>

            <NavLink
              to="/quickDashboard/visaManage/duration"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Visa Information, Entry, Duration and Fee
            </NavLink>
            {type === "update" ? (
              <NavLink
                to="/quickDashboard/visaManage/Checklistupdate"
                className={({ isActive }) =>
                  isActive ? "active-link" : "link"
                }
              >
                Visa Checklist Test
              </NavLink>
            ) : (
              <NavLink
                to="/quickDashboard/visaManage/Checklist"
                className={({ isActive }) =>
                  isActive ? "active-link" : "link"
                }
              >
                Visa Checklist
              </NavLink>
            )}

            <NavLink
              to="/quickDashboard/visaManage/visafaq"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              FAQ
            </NavLink>
          </Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default VisaRoute;
