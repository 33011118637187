import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { CircleLoader } from "react-spinners";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";

const UpdatedDeals = () => {
  const [singleDealsData, setSingleDealsData] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const token = secureLocalStorage.getItem("Admin-Token");
  const editor = useRef(null);
  const { id } = useParams();

  console.log(id);
  const [formData, setFormData] = useState({
    selectedCategory: "",
    title: "",
    description: "",
    couponCode: "",
    thumbnailImage: null,
  });
  console.log(formData);

  const handleSelectedCategory = (event) => {
    setFormData({
      ...formData,
      selectedCategory: event.target.value,
    });
  };

  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or event target is undefined");
      return;
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (singleDealsData) {
      setFormData((prevData) => ({
        ...prevData,
        title: singleDealsData.title || "",
        couponCode: singleDealsData.coupon_code || "",
      }));
    }
  }, [singleDealsData]);

  const handleThumbnailImageChange = (event) => {
    setFormData({
      ...formData,
      thumbnailImage: event.target.files[0],
    });
  };

  const [subId, setSubId] = useState("");
  console.log(subId);
  const handleExclusiveDeals = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("type", formData.selectedCategory);
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("coupon_code", formData.couponCode);
      formDataToSend.append("image", formData.thumbnailImage);

      const response = await axios.put(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/update-deals/${id}`,
        formDataToSend,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Admin-Token": `${token}`,
          },
        }
      );
      console.log("boady",formDataToSend)
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });

        setFormData({
          selectedCategory: "",
          title: "",
          description: "",
          couponCode: "",
          thumbnailImage: null,
        });

        console.log(response.data.data.id);
        setSubId(response.data.data.id);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    }
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/get-deals/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Admin-Token": `${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSingleDealsData(data?.data[0]);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [token]); // Depend

 
// sub image 
  const handleImageChange = async (id, i, e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/deals/update-deals-images/${id}`,
        {
          method: "PUT",
          headers: {
            "Admin-Token": `${token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        const result = await response.json();
        const updatedImages = [...images];
        updatedImages[i] = file;
        setImages(updatedImages);
        Swal.fire({
          icon: "success",
          title: "Image Uploaded",
          text: result?.message || "Your image has been uploaded successfully!",
        }).then(() => {
          setLoading(true);
          window.location.reload();
        });
      } else {
        console.error("Image upload failed:", response.statusText);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error uploading the image. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the image. Please try again.",
      });
    }
  };

  return (
    <Box>
      <ToastContainer />
      <Box sx={{ my: "10px", mt: 7 }}>
        <Typography> Updated Exclusive Deals</Typography>
      </Box>
      <form onSubmit={handleExclusiveDeals}>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "300px" }}>
                  <label style={{ fontSize: "12px" }}>Thumbnail Image</label>
                </Box>

                <Box
                  sx={{
                    width: "250px",
                    height: { md: "160px", sm: "210px", xs: "210px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <label
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleThumbnailImageChange(e)}
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "none",
                      }}
                    />

                    <Box
                      sx={{
                        width: "100%",
                        height: { md: "160px", sm: "210px", xs: "210px" },
                      }}
                    >
                      {singleDealsData?.image ? (
                        <img
                          src={singleDealsData?.image}
                          alt="Thumbnail"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: "100%",
                            height: { md: "210px", sm: "210px", xs: "210px" },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "30px",
                            textAlign: "center",
                            borderRadius: "10px",
                          }}
                        >
                          Upload Thumbnail Image
                        </Skeleton>
                      )}
                    </Box>
                  </label>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
                  mt: -2,
                  width: "100%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                    Selected Categorey
                  </label>
                </Box>
                <select
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    color: "#A3A4A9",
                  }}
                  value={formData.selectedCategory}
                  onChange={handleSelectedCategory}
                >
                  <option value="flight">Flight</option>
                  <option value="hotel">Hotel</option>
                  <option value="tour">Tour</option>
                  <option value="visa">Visa</option>
                </select>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
                  mt: -2,
                  gap: "10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ fontSize: "12px" }}>Title</label>
                </Box>

                <input
                  placeholder="title"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                    width: "100%",
                  }}
                  value={formData.title}
                  onChange={handleInputChange}
                  name="title"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #A3A4A9",
                  gap: "10px",
                  mt: -2,
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ fontSize: "12px" }}>Coupon Code</label>
                </Box>

                <input
                  value={formData.couponCode}
                  onChange={handleInputChange}
                  name="couponCode"
                  placeholder="Coupon Code"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box>
              <Box
                sx={{
                  mt: -4,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  color: "#A3A4A9",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ fontSize: "12px" }}>Short Descriptions</label>
                </Box>
                <JoditEditor
                  ref={editor}
                  value={formData.description}
                  onChange={(content) =>
                    handleInputChange({ description: content })
                  }
                />
              </Box>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "var(--trips-primary-color)",
                  color: "white",
                  ":hover": {
                    background: "var(--trips-primary-color)",
                    color: "white",
                  },
                }}
              >
                Update Exclusive Deals
              </Button>
            </Box>
          </Grid>

          {/* pictures */}
        </Grid>
      </form>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          ml: 0.5,
          gap: "12px",
          my: 3,
        }}
      >
        {singleDealsData?.sub_images?.map((sub, i) => (
          <Box
            key={i} // Don't forget to add a unique key when using map
            sx={{
              mt: -5,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              color: "#A3A4A9",
            }}
          >
            <Box
              sx={{
                width: "250px",
                bgcolor: "var(--primary-color)",
                height: { md: "160px", sm: "210px", xs: "210px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <label
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(sub?.id, i, e)}
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "none",
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: { md: "160px", sm: "210px", xs: "210px" },
                  }}
                >
                  {sub.sub_image ? (
                    <img
                      src={sub?.sub_image}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Typography>Upload Image</Typography>
                  )}
                </Box>
              </label>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UpdatedDeals;
