import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as AirplanIcon } from "../../../image/dashboardIcons/airplane.svg";
import BookingDetails from "../BookingDetails";
import FlightItinerary from "../FlightItinerary";
import ItineraryPassengerDetails from "../ItineraryPassengerDetails";
import BookingFareDetails from "../BookingFareDetails";
import { TabContext, TabList } from "@mui/lab";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import Swal from "sweetalert2";
//   import BookingDetails from "../components/BookingDetails";
//   import BookingFareDetails from "../components/BookingFareDetails";
//   import FlightItinerary from "../components/FlightItinerary";
//   import ItineraryPassengerDetails from "../components/ItineraryPassengerDetails";

const ReissueRequest = () => {
  const token = secureLocalStorage.getItem("token");
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedPassengersId, setSelectedPassengersId] = useState([]);

  const [reissueFormInfo, setReissueFormInfo] = useState({
    adtBag: "",
    chdBag: "",
    infBag: "",
    flightNo: "",
    bookingClass: "",
    cabinClass: "",
    travelDate: "",
  });
  const [priceBreakDowns, setPriceBreakdowns] = useState({
    baseFare: "",
    tax: "",
    totalBaseFare: "",
    totalTax: "",
    serviceFee: "",
    markup: "",
    subTotal: "",
  });

  const [reissueData, setReissueData] = useState([]);
  const [reissueInfosData, setReissueInfosData] = useState({});
  const { id } = useParams();
  // reissue quedetails
  const [flightSegments, setFlightSegments] = useState([]);

  //  state for data post
  const [pnr, setPnr] = useState("");

  const [bagInfo, setBagInfo] = useState([]);

  console.log("bagInfo", bagInfo);

  const [priceBreakDownValue, setPriceBreakDownValue] = useState([]);

  for (let i = 0; i < priceBreakDownValue.length; i++) {
    delete priceBreakDownValue[i].adultClientCost;
  }
  // console.log("priceBreakDownValue", priceBreakDownValue);

  useEffect(() => {
    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/reissue/${id}`;
    setLoading(true);
    if (url) {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const baggageData =
            data?.data?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType.map(
              (data) => data?.bagInfo
            );
          const mergedBaggageArray = baggageData?.map((data) =>
            data?.cabin?.concat(data?.check)
          );

          // const modifiedFlightDetails =
          //   data?.data?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.map(
          //     (flight) => {
          //       const { bagInfo, segment, ...rest } = flight;
          //       return rest;
          //     }
          //   );

          // setFlightSegments(modifiedFlightDetails);

          setBagInfo(mergedBaggageArray[0]);
          setReissueData(data?.data);
          setReissueInfosData(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, token]);

  const departureArrivalCityArray = Array.from(
    new Set(
      reissueData?.bookingInfo?.flightDetailsAndPrices?.cityInfo?.reduce(
        (acc, flight) => {
          acc.push(flight?.departureCity, flight?.arrivalCity);
          return acc;
        },
        []
      )
    )
  );

  const [allFlightDetailsByType, setAllFlightDetailsByType] = useState([]);
  // const priceBreakDownDetails = reissueInfosData?.bookingInfo?.priceBreakDown.map((price)=>{
  //   return{
  //     ...price,
  //   }
  // })
  // console.log(priceBreakDownDetails)
  // const priceBreakDown =
  //   reissueData?.bookingInfo?.priceBreakDown?.map((price, i) => ({
  //     ...price,
  //   })) || [];

  // const [priceBreakdowns, setPriceBreakdowns] = useState(...priceBreakDownDetails);

  const [bookingTotal, setBookingTotal] = useState({
    id: reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
      ?.id,
    currency:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.currency,
    totalTax:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.totalTax,
    basePrice:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.basePrice,
    agentPrice:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.agentPrice,
    clientPrice:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.clientPrice,
    isRefundable:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.isRefundable,
  });

  let totalserviceFee = priceBreakDownValue.reduce(
    (sum, item) => sum + item.serviceFee,
    0
  );

  let totalDiscountFee = priceBreakDownValue.reduce(
    (sum, item) => sum + item.discount,
    0
  );

  let totalServiceFee = totalserviceFee - totalDiscountFee;

  const [timeExpire, setTimeExpire] = useState(0);

  // const [agentPrice,setAgentPrice]=useState(0)

  const [airlineCharge, setAirlineCharge] = useState(0);

  let [bookingTotalPricesValue, setSetBookingTotalPricesValue] = useState({
    id: reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices.id,
    currency:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        .currency,
    totalTax: "",
    basePrice: "",
    agentPrice: "",
    clientPrice: "",
    isRefundable:
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.isRefundable,
  });

  const [clientPrice, setClientPrice] = useState(0);

  const body = {
    bookingClass: reissueData?.bookingInfo?.bookingClass,
    pnr: reissueData?.ticketedData?.gdsPnr,
    gds: reissueData?.ticketedData?.gds,
    airlinesPNR: reissueData?.ticketedData?.airlinesPnr,
    timeExpire: parseInt(timeExpire),
    airlineCharge: parseInt(airlineCharge),
    serviceFee: parseInt(totalServiceFee),
    baggageInfo: bagInfo,
    bookingTotalPrices: {
      id: reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.id,
      currency:
        reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
          ?.currency,
      totalTax: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.totalTax,
        0
      ),
      basePrice: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.baseFare,
        0
      ),
      agentPrice: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.agentPrice,
        0
      ),
      clientPrice: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.totalClientPrice,
        0
      ),
      isRefundable:
        reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
          ?.isRefundable,
    },
    priceBreakDown: priceBreakDownValue,
    flightSegments: flightSegments,
  };

  let agentPrice;

  const body2 = {
    bookingClass: reissueData?.bookingInfo?.bookingClass,
    pnr: reissueData?.ticketedData?.gdsPnr,
    gds: reissueData?.ticketedData?.gds,
    airlinesPNR: reissueData?.ticketedData?.airlinesPnr,
    timeExpire: parseInt(timeExpire),
    airlineCharge: parseInt(airlineCharge),
    serviceFee: parseInt(totalServiceFee),
    baggageInfo: bagInfo,
    bookingTotalPrices: {
      id: reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.id,
      currency:
        reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
          ?.currency,
      totalTax: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.totalTax,
        0
      ),
      basePrice: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.baseFare,
        0
      ),
      agentPrice:
        parseInt(
          priceBreakDownValue?.reduce(
            (sum, item) => sum + item.totalClientPrice,
            0
          )
        ) +
        parseInt(airlineCharge) -
        parseInt(
          reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
            ?.clientPrice
        ),
      clientPrice: priceBreakDownValue?.reduce(
        (sum, item) => sum + item.totalClientPrice,
        0
      ),
      isRefundable:
        reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
          ?.isRefundable,
    },
    priceBreakDown: priceBreakDownValue,
    flightSegments: flightSegments,
  };

  const handleReissueApporoved = async () => {
    const body = {
      bookingClass: reissueData?.bookingInfo?.bookingClass,
      pnr: reissueData?.ticketedData?.gdsPnr,
      gds: reissueData?.ticketedData?.gds,
      airlinesPNR: reissueData?.ticketedData?.airlinesPnr,
      timeExpire: parseInt(timeExpire),
      baggageInfo: bagInfo,

      bookingTotalPrices: {
        id: reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
          ?.id,
        currency:
          reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
            ?.currency,
        totalTax: priceBreakDownValue?.reduce(
          (sum, item) => sum + item.totalTax,
          0
        ),

        basePrice: priceBreakDownValue?.reduce(
          (sum, item) => sum + item.baseFare,
          0
        ),

        agentPrice: priceBreakDownValue?.reduce(
          (sum, item) => sum + item.agentPrice,
          0
        ),

        clientPrice: priceBreakDownValue?.reduce(
          (sum, item) => sum + item.totalClientPrice,
          0
        ),
        isRefundable:
          reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
            ?.isRefundable,
      },
      priceBreakDown: priceBreakDownValue,
      flightSegments: flightSegments,
    };

    const url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/approve_reissue/${id}`;
    console.log(url, body);

    // try {
    //   const response = await fetch(url, {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //     body: JSON.stringify(body),
    //   });

    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }

    //   const responseData = await response.json();
    //   console.log("responseData", responseData);
    //   if (responseData?.success === true) {
    //     Swal.fire({
    //       title: "Success!",
    //       text: `${responseData?.message}`,
    //       icon: "success",
    //       confirmButtonText: "OK",
    //     });
    //   } else {
    //     Swal.fire({
    //       title: "Error!",
    //       text: `${responseData?.message}`,
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //   }
    // } catch (error) {
    //   console.error("Error during PUT request:", error);
    // }
  };

  return (
    <Box
      sx={{
        bgcolor: "var(--body-bg-color)",
        width: "100%",
        pb: "98px",
      }}
    >
      <Box
        sx={{
          borderRadius: "3px",
          width: "100%",
          p: 5,
          py: 3,
          minHeight: "calc(100vh - 196px)",
        }}
      >
        {/*---------- Page title ----------*/}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* City Title, Booking Id */}

          <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {departureArrivalCityArray?.map((cityName, i) => {
              return (
                <React.Fragment key={i}>
                  <Typography sx={{ fontSize: "35px" }}>{cityName}</Typography>

                  {i < departureArrivalCityArray.length - 1 && (
                    <AirplanIcon
                      style={{
                        height: "37px",
                        width: "37px",
                        fill: "var(--text-medium)",
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Box>

          <Typography sx={{ fontSize: "28px", color: "var(--text-dark)" }}>
            {reissueData?.bookingInfo?.bookingId}{" "}
            <span style={{ color: "var(--trips-secondary-color)" }}>
              {reissueData?.bookingInfo?.status}
            </span>
          </Typography>
        </Box>

        {/*---------- PDF buttons ----------*/}

        {/*---------- Page Label ----------*/}

        <Box sx={{ bgcolor: "var(--third-color)", py: "9px", mt: "24px" }}>
          <Typography
            sx={{
              fontSize: "20px",
              color: "var(--trips-secondary-color)",
              textAlign: "center",
            }}
          >
            Reissue Request
          </Typography>
        </Box>

        {/*---------- Booking Details  ----------*/}

        <Box sx={{ mt: "24px" }}>
          <BookingDetails
            bookingInfo={reissueData?.bookingInfo}
            setPnr={setPnr}
          />
        </Box>

        {/*---------- Flight Itinerary  ----------*/}
        <Box>
          {reissueData?.bookingInfo?.flightDetailsAndPrices?.cityInfo.map(
            (cityInfo, i) => {
              const label =
                reissueData?.bookingInfo?.tripType === "multiCity"
                  ? `${i + 1}`
                  : reissueData?.bookingInfo?.tripType === "roundWay" && i === 0
                  ? "Onward"
                  : reissueData?.bookingInfo?.tripType === "roundWay" && i === 1
                  ? "Return"
                  : "";

              return (
                <Box key={i}>
                  <FlightItinerary
                    flightSegments={flightSegments}
                    setFlightSegments={setFlightSegments}
                    bagInfo={bagInfo}
                    setBagInfo={setBagInfo}
                    reissueFormInfo={reissueFormInfo}
                    setReissueFormInfo={setReissueFormInfo}
                    reissueData={reissueData}
                    label={label}
                    cityId={cityInfo.id}
                  />
                </Box>
              );
            }
          )}
        </Box>
        {/*---------- Passenger Details  ----------*/}

        <Box sx={{ mt: "35px" }}>
          <Typography sx={{ fontSize: "18px", color: "var(--text-dark)" }}>
            Passenger Details
          </Typography>

          <ItineraryPassengerDetails
            reissueData={reissueData}
            selectedPassengersId={selectedPassengersId}
            setSelectedPassengersId={setSelectedPassengersId}
          />
        </Box>

        {/*---------- Bookign Fare Details  ----------*/}

        <Box sx={{ mt: "35px" }}>
          <Typography
            sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
          >
            Original Fare
          </Typography>
          {/* <BookingFareDetails /> */}
          <BookingFareDetails
            agentPrice={agentPrice}
            clientPrice={clientPrice}
            setClientPrice={setClientPrice}
            airlineCharge={airlineCharge}
            setAirlineCharge={setAirlineCharge}
            timeExpire={timeExpire}
            setTimeExpire={setTimeExpire}
            bookingTotalPricesValue={bookingTotalPricesValue}
            setSetBookingTotalPricesValue={setSetBookingTotalPricesValue}
            priceBreakDownValue={priceBreakDownValue}
            setPriceBreakDownValue={setPriceBreakDownValue}
            reissueData={reissueData}
            bookingTotal={bookingTotal}
            setBookingTotal={setBookingTotal}
            priceBreakdown={reissueData?.bookingInfo?.priceBreakDown}
            setPriceBreakdowns={setPriceBreakdowns}
            priceBreakDowns={priceBreakDowns}
            bookingTotalPrices={
              reissueData?.bookingInfo?.flightDetailsAndPrices
                ?.bookingTotalPrices
            }
          />
        </Box>

        {/* ------- */}

        {/*---------- Choose Reissue Reason  ----------*/}

        {/* <Box sx={{ mt: "35px" }}>
          <Typography
            sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
          ></Typography>

          <Box sx={{ mt: "10px" }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectFlightType}
                onChange={(event) => setSelectFlightType(event.target.value)}
                name="radio-buttons-group"
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  gap: "30px",
                }}
              >
                <FormControlLabel
                  value={"refundable"}
                  control={
                    <Radio
                      sx={{
                        color: "var(--trips-secondary-color)",
                        "&.Mui-checked": {
                          color: "var(--trips-secondary-color)",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{ fontSize: "14px", color: "var(--text-dark)" }}
                    >
                      Voluntary Reissue
                    </Typography>
                  }
                  onClick={() => {
                    setSelectFlightType(item.value);
                  }}
                />

                <FormControlLabel
                  value={"nonrefundable"}
                  control={
                    <Radio
                      sx={{
                        color: "var(--trips-secondary-color)",
                        "&.Mui-checked": {
                          color: "var(--trips-secondary-color)",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{ fontSize: "14px", color: "var(--text-dark)" }}
                    >
                      Involuntary Reissue
                    </Typography>
                  }
                  onClick={() => {
                    setSelectFlightType(item.value);
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box> */}

        {/*---------- Remarks area  ----------*/}

        {/* <Box sx={{ mt: "35px" }}>
          <Typography
            sx={{ fontSize: "18px", color: "var(--text-dark)" }}
          ></Typography>

          <input
            type="text"
            className="agent-table-input"
            placeholder="Write your remarks with 150 letters"
            style={{
              width: "100%",
              height: "35px",
              marginTop: "10px",
              borderBottom: "1px solid var(--input-border)",
              fontSize: "12px",
            }}
          />
        </Box> */}

        {/*---------- Send Reissue Request buton  ----------*/}

        <Box sx={{ my: "45px" }}>
          <Button
            onClick={handleReissueApporoved}
            sx={{
              bgcolor: "var(--secondary-color)",
              ":hover": {
                bgcolor: "var(--secondary-color)",
              },
              color: "white",
              width: "50%",
              textTransform: "capitalize",
            }}
          >
            Send Reissue Request
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReissueRequest;
