import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import companyLogo from "../../image/companylogo 1.png";
import secureLocalStorage from "react-secure-storage";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../Shared/Loader/Loader";
import "./AgentDetails.css";

const AgentDetails = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "10.5px",
    color: "#000",
    textAlign: "left",
  };

  // agent single info

  const [allAgent, setAllAgent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // brerr token
  const token = secureLocalStorage.getItem("token");
  const { id } = useParams();

  useEffect(() => {
    const apiUrl = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/agent_data/${id}`;
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAllAgent(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, [token]);

  const navigate = useNavigate();

  console.log("ami all agent", allAgent);
  return (
    <Box sx={{ width: "100%" }}>
      {isLoading ? (
        <Box>
          <Loader />
        </Box>
      ) : (
        <Grid container>
          <Grid
            item
            container
            md={10.2}
            sx={{
              borderRight: "10px solid #EFF2F5",
              bgcolor: "#FFFFFF",
              borderRadius: "5px",
              width: "100%",
              pr: "20px",
            }}
          >
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Typography className="ami" sx={{ fontSize: "22px" }}>
                {allAgent?.agentData?.firstName} {allAgent?.agentData?.lastName}
              </Typography>
              <TableContainer style={{ mt: "20px", overflowX: "none" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%", overflowX: "none" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Status
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Agent Name
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Email
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Join At
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        NID / Passport No
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Country
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        City
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Postal Code
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Address
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        Phone Number
                      </TableCell>
                      <TableCell style={tabcellText} align="right">
                        WhatsApp Number
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText} component="th" scope="row">
                        {allAgent?.agentData?.status}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.firstName}{" "}
                        {allAgent?.agentData?.lastName}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.email}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {moment(
                          allAgent?.agentData?.joinAt,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("MMM DD, YY, hh:mm A")}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.nid}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.country}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.city}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.postalCode}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.address}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.phone}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {allAgent?.agentData?.whatsAppNumber}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                my: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Company Information
              </Typography>
              <TableContainer sx={{ mt: "20px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Company name
                      </TableCell>
                      <TableCell style={tabcellText}>TIN Certificate</TableCell>
                      <TableCell style={tabcellText}>Primary Color</TableCell>
                      <TableCell style={tabcellText}>
                        Secoundary Color
                      </TableCell>
                      <TableCell style={tabcellText}>Third Color</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.company}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        <img
                          style={{ height: "50px", width: "50px" }}
                          src={allAgent?.agentData?.cmsData?.cacCertificate}
                        />
                      </TableCell>

                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.cmsData?.primaryColor}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.cmsData?.secondaryColor}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.cmsData?.thirdColor}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Commission Set Up
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Domestic Flight Markup
                      </TableCell>
                      <TableCell style={tabcellText}>
                        International Flight Markup
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Additional Markup
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Agent Bonus Wallet
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Credit Balance Wallet
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.Markup?.dMarkup}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.Markup?.iMarkup}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.Markup?.aMarkup}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.bonusAmount}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.loanAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                my: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Search Statistics
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Number Of Search
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Today Total Search
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Total Oneway Search
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Total Roundway Search
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Total Multicity Search
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.search?.NumberOfSearch}   
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.search?.totalTodaySearch}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.search?.totalOneWaySearch}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.search?.totalRoundWaySearch}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.search?.totalMultiCitySearch}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Deposit Statistics
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Number Of Deposit
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Total Deposit Amount
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Last Deposit Date
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Last Deposit Amount
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Highest Deposit Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.depositData?.totalDeposits}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.depositData?.totalDeposits}
                      </TableCell>

                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.depositData?.lastDepositDate
                          ? moment(
                              allAgent?.agentData?.depositData?.lastDepositDate
                            ).format("YYYY-MM-DD")
                          : "Invalid Date"}
                      </TableCell>

                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.depositData?.totalDepositAmount}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {allAgent?.agentData?.depositData?.highestDepositAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                my: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Booking Statistics
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Number Of Booking
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Booking Ticketed
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Last Deposit Date
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Booking Cancelled
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Booking Refunded
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Booking Reissued
                      </TableCell>
                      <TableCell style={tabcellText}>
                        Booking Reissued
                      </TableCell>
                      <TableCell style={tabcellText}>Booking Voided</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.total}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.ticketed}
                      </TableCell>

                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.depositData?.lastDepositDate
                          ? moment(
                              allAgent?.agentData?.depositData?.lastDepositDate
                            ).format("YYYY-MM-DD")
                          : "Invalid Date"}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.cancelled}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.refunded}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.reissued}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.reissued}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {allAgent?.agentData?.bookingStats?.voided}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Staff Information
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Staff Name
                      </TableCell>
                      <TableCell style={tabcellText}>Designations</TableCell>
                      <TableCell style={tabcellText}>Role</TableCell>
                      <TableCell style={tabcellText}>Phone Number</TableCell>
                      <TableCell style={tabcellText}>
                        NID / Passport Number
                      </TableCell>
                      <TableCell style={tabcellText}>
                        NID / Passport Copy
                      </TableCell>
                      <TableCell style={tabcellText}>Create At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    {allAgent?.agentData?.staff?.map((staffMember, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          height: "20px",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell style={tabcellText}>
                          {staffMember?.name}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {staffMember?.designation}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {staffMember?.role}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {staffMember?.phone}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {staffMember?.phone}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          <img
                            style={{ height: "50px", width: "50px" }}
                            src={staffMember?.nidCopy}
                          />
                        </TableCell>

                        <TableCell style={tabcellText}>
                          {moment(
                            staffMember?.createdAt,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("MMM DD, YY, hh:mm A")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={1.8}
            sx={{ bgcolor: "#FFFFFF", borderRadius: "5px" }}
          >
            <Box sx={{ mt: "50px", px: "10px" }}>
              <Typography
                sx={{
                  py: "10px",
                  fontSize: "20px",
                  color: "var(--secondary-color)",
                }}
              >
                {" "}
                Company Logo
              </Typography>
              <Box
                sx={{
                  background: "#EFF2F5",
                  px: "20px",
                  py: "20px",
                  borderRadius: "5px",
                }}
              >
                <img
                  style={{ width: "127px", height: "48px" }}
                  src={companyLogo}
                />
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography
                  sx={{
                    py: "5px",
                    fontSize: "15px",
                    color: "var(--secondary-color)",
                  }}
                >
                  {" "}
                  Change Information
                </Typography>
                <Button
                  onClick={() =>
                    navigate(`/quickDashboard/agentInfoUpdate/${id}`, {
                      state: { id, allAgent },
                    })
                  }
                  sx={{
                    width: "200px",
                    background: "var(--savStaff-color)",
                    ":hover": {
                      background: "var(--savStaff-color)",
                    },
                    borderRadius: "5px",
                    color: "var(--thard-color)",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  Modify Information
                </Button>
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography
                  sx={{
                    py: "10px",
                    fontSize: "15px",
                    color: "var(--secondary-color)",
                  }}
                >
                  {" "}
                  Commission & Credit Set Up
                </Typography>
                <Button
                  onClick={() =>
                    navigate(`/quickDashboard/commissionCredit/${id}`, {
                      state: { id, allAgent },
                    })
                  }
                  sx={{
                    width: "200px",
                    background: "var(--savStaff-color)",
                    ":hover": {
                      background: "var(--savStaff-color)",
                    },
                    borderRadius: "5px",
                    color: "var(--thard-color)",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  Add Commission & Credit
                </Button>
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography
                  sx={{
                    py: "10px",
                    fontSize: "15px",
                    color: "var(--secondary-color)",
                  }}
                >
                  {" "}
                  Ledger Report
                </Typography>
                <Button
                  sx={{
                    width: "200px",
                    background: "var(--savStaff-color)",
                    ":hover": {
                      background: "var(--savStaff-color)",
                    },
                    borderRadius: "5px",
                    color: "var(--thard-color)",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  View Ledger Report
                </Button>
              </Box>
              <Box sx={{ my: "20px" }}>
                <Typography
                  sx={{
                    py: "10px",
                    fontSize: "15px",
                    color: "var(--secondary-color)",
                  }}
                >
                  {" "}
                  Add Payment Gate way
                </Typography>
                <Button
                  sx={{
                    width: "200px",
                    background: "var(--savStaff-color)",
                    ":hover": {
                      background: "var(--savStaff-color)",
                    },
                    borderRadius: "5px",
                    color: "var(--thard-color)",
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  Add Payment Gate way
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AgentDetails;
