import {
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const BookingFareDetails = ({
  agentPrice,
  setAgentPrice,
  timeExpire,
  setTimeExpire,
  bookingTotalPricesValue,
  setSetBookingTotalPricesValue,
  priceBreakDownValue,
  setPriceBreakDownValue,
  bookingTotalPrices,
  priceBreakdown,
  priceBreakdowns,
  priceBreakDowns,
  setPriceBreakdowns,
  reissueData,
  bookingTotal,
  setBookingTotal,
  airlineCharge,
  setAirlineCharge,
  clientPrice,
  setClientPrice,
}) => {
  // const handleInputBookingFareDetails = (e, i, field) => {
  //   const updatedPriceBreakdown = [...priceBreakdown];
  //   updatedPriceBreakdown[i] = {
  //     ...updatedPriceBreakdown[i],
  //     [field]: e.target.value,
  //   };
  //   setPriceBreakdowns(updatedPriceBreakdown);
  // };

  let adultCount = reissueData?.passengers?.filter(
    (x) => x.type === "ADT"
  ).length;
  let childCount = reissueData?.passengers?.filter(
    (x) => x.type === "CNN"
  ).length;
  let infantCount = reissueData?.passengers?.filter(
    (x) => x.type === "INF"
  ).length;

  let newPriceBreakdown = reissueData?.bookingInfo?.priceBreakDown?.map(
    (x, i) => {
      return {
        id: x?.id,
        bag: x.bag,
        paxType: x.paxType,
        currency: x.currency,
        discount: x.discount,
        agentPrice: x.agentPrice,
        clientPrice: x.clientPrice,
        isRefundable: x.isRefundable,
        passengerCount: x.passengerCount,
        serviceFee: x.serviceFee,
        reissueCharge: x.reissueCharge,
        // totalBaseFareAmountWithTax: x.totalBaseFareAmountWithTax,
      };
    }
  );

  const handleInputChange = (e, index, propertyName) => {
    const { value } = e.target;
    const existingProperties = newPriceBreakdown[index];

    const updatedSegment = {
      ...existingProperties,
      ...priceBreakDownValue[index],
      [propertyName]: parseInt(value),
    };

    if (propertyName === "baseFare") {
      if (existingProperties.paxType === "ADT") {
        let adultCount = reissueData?.passengers?.filter(
          (x) => x.type === "ADT"
        ).length;
        updatedSegment.totalBaseFareAmount = parseInt(value) * adultCount;
      } else if (existingProperties.paxType === "CNN") {
        // Multiply with childCount for CNN
        updatedSegment.totalBaseFareAmount =
          parseInt(value) * parseInt(childCount);
      } else if (existingProperties.paxType === "INF") {
        // Multiply with infantCount for INF
        updatedSegment.totalBaseFareAmount =
          parseInt(value) * parseInt(infantCount);
      }
    }

    if (propertyName === "tax") {
      if (existingProperties.paxType === "ADT") {
        let adultCount = reissueData?.passengers?.filter(
          (x) => x.type === "ADT"
        ).length;
        updatedSegment.totalTax = parseInt(value) * adultCount;

        updatedSegment.adultClientCost =
          (updatedSegment.baseFare || 0) + parseInt(value);
      } else if (existingProperties.paxType === "CNN") {
        // Multiply with childCount for CNN
        updatedSegment.totalTax = parseInt(value) * parseInt(childCount);

        // updatedSegment.childClientCost =
        //   updatedSegment.baseFare + parseInt(value);

        // updatedSegment.childClientCost =
        //   (updatedSegment.baseFare || 0) + parseInt(value);
      } else if (existingProperties.paxType === "INF") {
        // Multiply with infantCount for INF
        updatedSegment.totalTax = parseInt(value) * parseInt(infantCount);

        // updatedSegment.infantClientCost =
        //   updatedSegment.baseFare + parseInt(value);

        // updatedSegment.infantClientCost =
        //   (updatedSegment.baseFare || 0) + parseInt(value);
      }
    }

    // updatedSegment.totalClientPrice =
    //   updatedSegment.clientPrice +
    //   (updatedSegment.totalTax || 0) +
    //   parseInt(updatedSegment.serviceFee);

    // updatedSegment.totalClientPrice =
    //   updatedSegment.totalBaseFareAmount || 0 + (updatedSegment.totalTax || 0);

    if (propertyName === "commissionValue") {
      updatedSegment.agentPrice = Math.round(
        Math.round(updatedSegment.totalBaseFareAmount) *
          (1 - Number(e.target.value) / 100) +
          updatedSegment.totalTax +
          updatedSegment.totalBaseFareAmount * reissueData?.ticketedData?.ait
      );
    }

    console.log("updatedSegment", updatedSegment);
    updatedSegment.clientPrice =
      updatedSegment.baseFare + updatedSegment.totalTax;

    if (propertyName === "serviceFee") {
      updatedSegment.serviceFee = updatedSegment.serviceFee;
    }

    if (propertyName === "reissueCharge") {
      updatedSegment.reissueCharge = updatedSegment.reissueCharge;
    }

    updatedSegment.agentPrice =
      (updatedSegment.baseFare +
        updatedSegment.tax +
        updatedSegment.serviceFee +
        updatedSegment.reissueCharge) *
      updatedSegment?.passengerCount;

    updatedSegment.clientPrice =
      updatedSegment.baseFare +
      updatedSegment.tax +
      updatedSegment.serviceFee +
      updatedSegment.reissueCharge;

    updatedSegment.totalClientPrice =
      (updatedSegment.baseFare +
        updatedSegment.tax +
        updatedSegment.serviceFee +
        updatedSegment.reissueCharge) *
      updatedSegment?.passengerCount;

    updatedSegment.totalBaseFareAmountWithTax =
      (updatedSegment.baseFare +
        updatedSegment.tax +
        updatedSegment.serviceFee +
        updatedSegment.reissueCharge) *
      updatedSegment?.passengerCount;

    setPriceBreakDownValue((prevSegments) => {
      const updatedSegments = [...prevSegments];
      updatedSegments[index] = updatedSegment;
      return updatedSegments;
    });
  };

  // const handleBookingTotalInputChange = (e, propertyName) => {
  //   const { value } = e.target;

  //   setSetBookingTotalPricesValue((prevBookingTotal) => ({
  //     ...prevBookingTotal,
  //     [propertyName]: value,
  //   }));
  // };

  agentPrice =
    parseInt(
      priceBreakDownValue?.reduce((sum, item) => sum + item.totalClientPrice, 0)
    ) +
    parseInt(airlineCharge) -
    parseInt(
      reissueData?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.clientPrice
    );
  const uniqueTypesSet = new Set();

  // Filter out passengers with duplicate types

  const uniquePassengers = reissueData?.passengers?.filter((passenger) => {
    if (!uniqueTypesSet.has(passenger.type)) {
      uniqueTypesSet.add(passenger.type);
      return true;
    }
    return false;
  });

  const customerTotal = priceBreakDownValue.reduce(
    (acc, flight) => acc + flight.totalClientPrice,
    0
  );

  const AgentTotal = priceBreakDownValue.reduce(
    (acc, flight) => acc + flight.agentPrice,
    0
  );

  const agentTest = reissueData?.bookingInfo?.priceBreakDown.reduce(
    (acc, flight) => acc + flight.agentPrice,
    0
  );

  const airlinesCharege = agentTest - AgentTotal;
  const total = customerTotal + AgentTotal;

  return (
    <Box
      mt={3}
      sx={{
        "& .MuiTableCell-root": {
          fontSize: "12px",
          color: "var(--text-medium)",
        },
        "& .MuiTableCell-head": {
          color: "var(--text-dark)",
          fontFamily: "productSans500 !important",
        },
        "& .MuiTableRow-root": {
          borderTop: "1px solid var(--table-border)",
        },

        ".fareSummary-content": {
          color: "var(--text-dark)",
          fontSize: "12px",
          height: "25px",
        },
        ".fareSummary-value-container": {
          display: "flex",
          alignItems: "center",
          gap: "10px",
          height: "25px",
        },
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            {/* table title row  */}
            <TableRow>
              <TableCell sx={{ width: "2%", textAlign: "left" }}>Pax</TableCell>
              <TableCell sx={{ width: "2%", textAlign: "left" }}>
                Count
              </TableCell>
              <TableCell>Per Base Fare</TableCell>
              <TableCell>Per Tax</TableCell>
              <TableCell> Reissue Fee</TableCell>
              <TableCell>Service Fee</TableCell>
              <TableCell> Per Client Cost</TableCell>
              <TableCell>T. Base Fare</TableCell>
              <TableCell>T. Tax</TableCell>
              <TableCell>T.R.F</TableCell>
              <TableCell>T.S.F</TableCell>
              <TableCell>T. Client Cost</TableCell>

              {/* <TableCell>CM</TableCell>
              <TableCell>Agent Cost</TableCell> */}
              {/* <TableCell >Service Fee</TableCell> */}
              {/* <TableCell >T. Client Cost</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {uniquePassengers?.map((price, i) => (
              <TableRow key={i}>
                <TableCell>{price?.type}</TableCell>
                <TableCell>
                  {(price?.type === "ADT" && adultCount) ||
                    (price?.type === "CNN" && childCount) ||
                    (price?.type === "INF" && infantCount)}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {
                        reissueData?.bookingInfo?.priceBreakDown.find(
                          (x) => x.paxType === price?.type
                        )?.baseFare
                      }
                    </span>

                    <input
                      type="number"
                      name="baseFare"
                      onChange={(e) => handleInputChange(e, i, "baseFare")}
                      placeholder="Enter BaseFare"
                      style={{
                        width: "100%",
                        outline: "none",
                        fontSize: "12px",
                        marginLeft: "5px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {
                        reissueData?.bookingInfo?.priceBreakDown.find(
                          (x) => x.paxType === price?.type
                        )?.tax
                      }
                    </span>
                    <input
                      type="number"
                      name="tax"
                      onChange={(e) => handleInputChange(e, i, "tax")}
                      placeholder="Enter Tax"
                      style={{
                        width: "100%",
                        marginLeft: "5px",
                        outline: "none",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>
                  {/* {price?.currency} {price?.tax?.toLocaleString()} */}
                </TableCell>
                <TableCell>
                  <input
                    type="number"
                    name="reissueCharge"
                    onChange={(e) => handleInputChange(e, i, "reissueCharge")}
                    placeholder="Enter Reissue Fee"
                    style={{
                      width: "100%",
                      outline: "none",
                      fontSize: "12px",
                      marginLeft: "5px",
                      padding: "5px",
                      borderRadius: "1px",
                      border: "1px solid var(--table-border)",
                      textAlign: "left",
                    }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <input
                    type="number"
                    name="serviceFee"
                    onChange={(e) => handleInputChange(e, i, "serviceFee")}
                    placeholder="Enter Service Fee"
                    style={{
                      width: "100%",
                      outline: "none",
                      fontSize: "12px",
                      marginLeft: "5px",
                      padding: "5px",
                      borderRadius: "1px",
                      border: "1px solid var(--table-border)",
                      textAlign: "left",
                    }}
                  />
                </TableCell>

                {/* Per Client Cost */}

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {(price?.type === "ADT" &&
                        priceBreakDownValue[0]?.clientPrice) ||
                        (price?.type === "CNN" &&
                          priceBreakDownValue[1]?.clientPrice) ||
                        (price?.type === "INF" &&
                          priceBreakDownValue[2]?.clientPrice)}
                    </span>

                    <input
                      disabled={true}
                      value={
                        (price?.type === "ADT" &&
                          priceBreakDownValue[0]?.baseFare +
                            priceBreakDownValue[0]?.tax +
                            priceBreakDownValue[0]?.serviceFee +
                            priceBreakDownValue[0]?.reissueCharge) ||
                        (price?.type === "CNN" &&
                          priceBreakDownValue[1]?.baseFare +
                            priceBreakDownValue[1]?.tax +
                            priceBreakDownValue[1]?.serviceFee +
                            priceBreakDownValue[1]?.reissueCharge) ||
                        (price?.type === "INF" &&
                          priceBreakDownValue[2]?.baseFare +
                            priceBreakDownValue[2]?.tax +
                            priceBreakDownValue[2]?.serviceFee +
                            priceBreakDownValue[2]?.reissueCharge)
                      }
                      name="serviceFee"
                      type="number"
                      style={{
                        width: "100%",
                        fontSize: "12px",
                        marginLeft: "5px",
                        outline: "none",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>

                  {/* {price?.currency} {price?.serviceFee?.toLocaleString()} */}
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {" "}
                      <span>
                        {(price?.type === "ADT" &&
                          priceBreakDownValue[0]?.totalBaseFareAmount) ||
                          (price?.type === "CNN" &&
                            priceBreakDownValue[1]?.totalBaseFareAmount) ||
                          (price?.type === "INF" &&
                            priceBreakDownValue[2]?.totalBaseFareAmount)}
                      </span>
                    </span>
                    <input
                      disabled={true}
                      defaultValue={priceBreakDownValue[i]?.totalBaseFareAmount}
                      type="number"
                      name="totalBaseFareAmount"
                      style={{
                        width: "100%",
                        marginLeft: "5px",
                        outline: "none",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>

                  {/* {price?.currency}{" "}
                  {price?.totalBaseFareAmount?.toLocaleString()} */}
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {(price?.type === "ADT" &&
                        priceBreakDownValue[0]?.totalTax) ||
                        (price?.type === "CNN" &&
                          priceBreakDownValue[1]?.totalTax) ||
                        (price?.type === "INF" &&
                          priceBreakDownValue[2]?.totalTax)}
                    </span>
                    <input
                      disabled={true}
                      defaultValue={priceBreakDownValue[i]?.totalTax}
                      name="totalTax"
                      onChange={(e) => handleInputChange(e, i, "totalTax")}
                      type="number"
                      style={{
                        width: "100%",
                        outline: "none",
                        marginLeft: "5px",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>

                  {/* {price?.currency} {price?.totalTax?.toLocaleString()} */}
                </TableCell>

                {/* total reissue fee */}
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      disabled={true}
                      value={
                        priceBreakDownValue[i]?.reissueCharge *
                        priceBreakDownValue[i]?.passengerCount
                      }
                      type="number"
                      style={{
                        width: "100%",
                        outline: "none",
                        marginLeft: "5px",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      disabled={true}
                      value={
                        priceBreakDownValue[i]?.serviceFee *
                        priceBreakDownValue[i]?.passengerCount
                      }
                      type="number"
                      style={{
                        width: "100%",
                        outline: "none",
                        marginLeft: "5px",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                    />
                  </Box>
                </TableCell>

                {/* T. Client Cost */}
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {
                        reissueData?.bookingInfo?.priceBreakDown.find(
                          (x) => x.paxType === price?.type
                        )?.totalClientPrice
                      }
                    </span>

                    <input
                      disabled={true}
                      defaultValue={priceBreakDownValue[i]?.totalClientPrice}
                      name="totalClientPrice"
                      type="number"
                      // placeholder="Enter Agent Price"
                      style={{
                        width: "100%",
                        outline: "none",
                        marginLeft: "5px",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                      // onChange={(e) => handleInputChange(e,i,"totalClientPrice")}
                    />
                  </Box>
                </TableCell>

                {/* 
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>{reissueData?.commissionValue}</span>
                    <input
                      name="commissionValue"
                      min={0}
                      type="number"
                      placeholder="Enter CM"
                      style={{
                        width: "100%",
                        outline: "none",
                        marginLeft: "5px",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                      onChange={(e) =>
                        handleInputChange(e, i, "commissionValue")
                      }
                    />
                  </Box>
                </TableCell> */}
                {/* <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>
                      {
                        reissueData?.bookingInfo?.priceBreakDown.find(
                          (x) => x.paxType === price?.type
                        )?.agentPrice
                      }
                    </span>

                    <input
                      name="agentPrice"
                      type="number"
                      // placeholder="Enter Agent Price"
                      style={{
                        width: "100%",
                        outline: "none",
                        marginLeft: "5px",
                        fontSize: "12px",
                        padding: "5px",
                        borderRadius: "1px",
                        border: "1px solid var(--table-border)",
                        textAlign: "left",
                      }}
                      disabled={true}
                      defaultValue={priceBreakDownValue[i]?.agentPrice}
                      // onChange={(e) => handleInputChange(e, i, "agentPrice")}
                    />
                  </Box>
                </TableCell> */}
              </TableRow>
            ))}

            {/* fare summary */}

            <TableRow>
              <TableCell colSpan={7}>
                <Typography sx={{ fontSize: "14px" }}>Fare Summery</Typography>
              </TableCell>
              <TableCell colSpan={2}>
                <Typography className="fareSummary-content">
                  Grand Total or Customer Total
                </Typography>
                <Typography className="fareSummary-content">
                  Agent Total
                </Typography>
                <Typography className="fareSummary-content">
                  Time Limit (In between 5 minute to 120 minutes )
                </Typography>
                {/* <Typography className="fareSummary-content">
                  Airline Charge
                </Typography>
                <Typography className="fareSummary-content">
                  Service Fee
                </Typography>
                <Typography className="fareSummary-content">Total</Typography> */}

                {/* <Typography className="fareSummary-content">
                  Agent Payable
                </Typography>
                <Typography className="fareSummary-content">
                  Fare Difference/ Agent Actual Payble
                </Typography> */}
              </TableCell>

              <TableCell colSpan={3}>
                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  {/* <span>{customerTotal}</span> */}
                  <input style={{ width: "50%" }} value={customerTotal} />
                </Box>

                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  {/* <span>{agentTest}</span> */}
                  <input style={{ width: "50%" }} value={AgentTotal} />
                </Box>
                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  {/* <span>{agentTest}</span> */}
                  <input
                    style={{ width: "50%" }}
                    onChange={(e) => setTimeExpire(e.target.value)}
                  />
                </Box>

                {/*
                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <input style={{ width: "50%" }} value={airlinesCharege} />
                </Box>

                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--text-dark)",
                    }}
                  >
                    {airlineCharge}
                  </Typography>
                  <input
                    value={airlineCharge}
                    onChange={(e) => setAirlineCharge(e.target.value)}
                    placeholder="Airline Charge"
                    style={{ width: "50%" }}
                  />
                </Box>

                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--text-dark)",
                    }}
                  >
                    {parseInt(
                      priceBreakDownValue?.reduce(
                        (sum, item) => sum + item.totalClientPrice,
                        0
                      )
                    ) + parseInt(airlineCharge)}
                  </Typography>
                  <input
                    value={total}
                    placeholder="Total"
                    style={{ width: "50%" }}
                  />
                </Box> */}

                {/* <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--text-dark)",
                    }}
                  ></Typography>
                  <input
                    value={`${
                      parseInt(
                        priceBreakDownValue?.reduce(
                          (sum, item) => sum + item.totalClientPrice,
                          0
                        )
                      ) +
                      parseInt(airlineCharge) -
                      parseInt(
                        reissueData?.bookingInfo?.flightDetailsAndPrices
                          ?.bookingTotalPrices?.clientPrice
                      )
                    }`}
                    style={{ width: "50%" }}
                  />
                </Box> */}
                {/* 
                <Box
                  className="fareSummary-value-container"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "var(--text-dark)",
                    }}
                  ></Typography>
                  <input
                    onChange={(e) => {
                      const inputValue = e.target.value.slice(0, 3);
                      setTimeExpire(inputValue);
                    }}
                    type="number"
                    value={timeExpire}
                    placeholder="Time Expire"
                    style={{
                      width: "50%",
                      marginLeft: "30px",
                    }}
                  />
                </Box> */}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BookingFareDetails;
