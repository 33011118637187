import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const PackageRoute = () => {
  const packageId = secureLocalStorage.getItem("packageId");

  return (
    <Box>
      <Box
        my={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: "#202124", fontSize: "20px" }}>
          Tour Package Add
        </Typography>
        {/* <Button
          sx={{
            fontSize: "15px",
            textTransform: "capitalize",
            bgcolor: "var(--trips-primary-color)",
            color: "#ffff",
            ":hover": {
              bgcolor: "var(--trips-primary-color)",
              color: "#ffff",
            },
          }}
        >
          Add Tour Package 
        </Button> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EFF2F5",
        }}
      >
        <NavLink
          to="/trips/tourpackage/packageinformation"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Package Information
        </NavLink>

        <NavLink
          to="/trips/tourpackage/packageimage"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Package Images
        </NavLink>

        <NavLink
          to="/trips/tourpackage/visitplace"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Visit Place
        </NavLink>
        <NavLink
          to="/trips/tourpackage/tourplan"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Tour Planes
        </NavLink>
        {/* <NavLink
          to="/trips/tourpackage/instalment"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Instalment & Discount
        </NavLink> */}
        <NavLink
          to="/trips/tourpackage/inclusion"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Inclusion
        </NavLink>
        <NavLink
          to="/trips/tourpackage/exclusion"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Exclusion
        </NavLink>
        <NavLink
          to="/trips/tourpackage/bookingpolicy"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Booking Policy
        </NavLink>
        <NavLink
          to="/trips/tourpackage/refundpolicy"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Refund Policy
        </NavLink>
        {/* <NavLink
          to="/trips/tourpackage/album"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Album
        </NavLink> */}
        {/* <NavLink
          to="/trips/tourpackage/test"
          className={({ isActive }) => (isActive ? "active-link" : "link")}
        >
          Upload
        </NavLink> */}
      </Box>
      <Outlet />
    </Box>
  );
};

export default PackageRoute;
