import React, { useState } from "react";
import {
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import { BpCheckedIcon, BpIcon } from "../../../src/FlyFarTrips/common/styles";

const BookingDetails = ({ bookingInfo, queueDetails, setPnr, pnr }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onewayTravelTime = moment(
    bookingInfo?.flightDetailsAndPrices?.flightDetailsByType[0]?.departureDateTime?.slice(
      0,
      19
    )
  ).format("Do MMM YYYY");

  const goRoundwayTravelTime = moment(
    bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.find(
      (flight) => flight?.type === "roundWayGo"
    )?.departureDateTime
  ).format("Do MMM YYYY");

  const multicityTravelTime =
    bookingInfo?.tripType === "multiCity"
      ? moment(
          bookingInfo?.flightDetailsAndPrices?.cityInfo[0]?.departureDate
        ).format("Do MMM YYYY")
      : "";

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
        >
          Booking Details
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "24px",
          "& .MuiTableCell-root": {
            fontSize: "12px",
            color: "var(--text-medium)",
          },
          "& .MuiTableCell-head": {
            color: "var(--text-dark)",
            fontFamily: "productSans500 !important",
          },
          "& .MuiTableRow-root": {
            borderTop: "1px solid var(--table-border)",
          },
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              {/* table title row  */}
              <TableRow>
                <TableCell>Booking ID</TableCell>
                <TableCell>Trip Type</TableCell>
                <TableCell>PAX</TableCell>
                <TableCell colSpan={2}>Travel Date</TableCell>
                <TableCell>Airlines PNR</TableCell>
                {/* <TableCell>Issuing Time Limit</TableCell> */}
                <TableCell>Partial Booking</TableCell>
                {/* <TableCell>PNR</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{bookingInfo?.bookingId}</TableCell>
                <TableCell>
                  {bookingInfo?.tripType === "oneWay"
                    ? "Oneway"
                    : bookingInfo?.tripType === "roundWay"
                    ? "Roundway"
                    : bookingInfo?.tripType === "multicity"
                    ? "Multicity"
                    : ""}
                </TableCell>
                <TableCell>
                  {bookingInfo?.pax}{" "}
                  {bookingInfo?.pax > 1 ? "Traveles" : "Traveler"}
                </TableCell>
                <TableCell colSpan={2}>
                  {bookingInfo?.tripType === "oneWay"
                    ? onewayTravelTime
                    : bookingInfo?.tripType === "roundWay"
                    ? goRoundwayTravelTime
                    : multicityTravelTime}
                </TableCell>
                <TableCell>{bookingInfo?.airlinesPNR}</TableCell>
                {/* <TableCell>
                  {console.log(
                    "bookingInfo?.timeLimit",
                    bookingInfo?.timeLimit
                  )}

                  {bookingInfo?.timeLimit === "Immediate Issue" ? (
                    <span>{bookingInfo?.timeLimit}</span>
                  ) : (
                    <span>
                      {" "}
                      {moment(
                        bookingInfo?.timeLimit,
                        "YYYY-MM-DDTHH:mm"
                      ).format("hh:mm A Do MMMM YYYY")}
                    </span>
                  )}
            
                </TableCell> */}
                <TableCell>
                  {!!bookingInfo?.partialBooking ? "true" : "false"}
                </TableCell>
                {/* <TableCell>
                  <input
                    type="text"
                    placeholder="Enter pnr"
                    style={{
                      width: "50%",
                      outline: "none",
                      height: "25px",
                      padding: "0px 5px",
                      boxSizing: "border-box",
                    }}
                    value={pnr}
                    onChange={(e) => setPnr(e.target.value)}
                  />
                </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default BookingDetails;
