import {
  Box,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { ReactComponent as CalendarIcon } from "../../image/dashboardIcons/calendar2.svg";
import { Calendar } from "react-date-range";
import { BpCheckedIcon, BpIcon } from "../../../src/FlyFarTrips/common/styles";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const centerItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const FlightItinerary = ({
  bagInfo,
  setBagInfo,
  flightSegments,
  setFlightSegments,

  reissueData,
  label,
  cityId,
  allFlightDetailsByType,
  setAllFlightDetailsByType,
  setReissueFormInfo,
  reissueFormInfo,
}) => {
  // const handleOpenReissueCalendar = (id) => {
  //   console.log("Clicked on item with id:", id);
  //   const updatedCalendarData = allFlightDetailsByType.map((item) => {
  //     if (item.id === id) {
  //       return {
  //         ...item,
  //         openDepartureDate: !item.openDepartureDate,
  //       };
  //     } else {
  //       return { ...item, openDepartureDate: false };
  //     }
  //   });
  //   console.log("Updated Calendar Data:", updatedCalendarData);
  //   setAllFlightDetailsByType(updatedCalendarData);
  // };

  // const handleSelectReissueSegments = (id, isChecked) => {
  //   const updatedCalendarData = allFlightDetailsByType.map((item) => {
  //     if (isChecked === false) {
  //       return {
  //         ...item,
  //         openDepartureDate: false,
  //         isSelected: false,
  //         newDepartureDate: item.departureDateTime,
  //         newFlightNumber: item?.itemNumber,
  //         newBookingClass: item?.bookingClass,
  //         newCabinTypeCode: item?.cabinTypeCode,
  //       };
  //     }

  //     if (item.id === id) {
  //       return { ...item, isSelected: isChecked };
  //     }

  //     return item;
  //   });
  //   setAllFlightDetailsByType(updatedCalendarData);
  // };

  // const getDepartureDateFromCalendar = (date, index) => {
  //   const copySegmentData = [...allFlightDetailsByType];

  //   copySegmentData[index] = {
  //     ...copySegmentData[index],
  //     newDepartureDate: date,
  //     openDepartureDate: false,
  //   };

  //   for (let j = index + 1; j < copySegmentData.length; j++) {
  //     if (date > new Date(copySegmentData[j].newDepartureDate)) {
  //       copySegmentData[j] = {
  //         ...copySegmentData[j],
  //         newDepartureDate: date,
  //         isSelected: true,
  //       };
  //     } else {
  //       copySegmentData[j] = {
  //         ...copySegmentData[j],
  //         newDepartureDate: copySegmentData[j].departureDateTime,
  //         isSelected: false,
  //       };
  //     }
  //   }

  //   setAllFlightDetailsByType([...copySegmentData]);
  // };

  // const handleGetInputValue = (e, index) => {
  //   const { name, value } = e.target;
  //   const copySegmentData = [...allFlightDetailsByType];

  //   copySegmentData[index] = {
  //     ...copySegmentData[index],
  //     [name]: value,
  //   };

  //   setAllFlightDetailsByType([...copySegmentData]);

  // };

  let newFlightmap =
    reissueData?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.map(
      (x) => {
        return {
          id: x?.id,
          type: x?.type,
          itemId: x?.itemId,
          mealCode: x?.mealCode,
          bookingId: x?.bookingId,
          airlineCode: x?.airlineCode,
          airlineName: x?.airlineName,
          arrivalCity: x?.arrivalCity,
          arrivalGate: x?.arrivalGate,
          arrivalTime: x?.arrivalTime,
          distanceInKM: x?.distanceInKM,
          cabinTypeName: x?.cabinTypeName,
          departureCity: x?.departureCity,
          departureGate: x?.departureGate,
          departureTime: x?.departureTime,
          numberOfSeats: x?.numberOfSeats,
          arrivalAirport: x?.arrivalAirport,
          arrivalCountry: x?.arrivalCountry,
          arrivalDateTime: x?.arrivalDateTime,
          durationInHours: x?.durationInHours,
          mealDescription: x?.mealDescription,
          aircraftTypeCode: x?.aircraftTypeCode,
          aircraftTypeName: x?.aircraftTypeName,
          departureAirport: x?.departureAirport,
          departureCountry: x?.departureCountry,
          flightStatusCode: x?.flightStatusCode,
          flightStatusName: x?.flightStatusName,
          departureDateTime: x?.departureDateTime,
          arrivalAirportCode: x?.arrivalAirportCode,
          arrivalTerminalName: x?.arrivalTerminalName,
          departureAirportCode: x?.departureAirportCode,
          marketingAirlineCode: x?.marketingAirlineCode,
          marketingAirlineName: x?.marketingAirlineName,
          operatingAirlineCode: x?.operatingAirlineCode,
          operatingAirlineName: x?.operatingAirlineName,
          departureTerminalName: x?.departureTerminalName,
          marketingFlightNumber: x?.marketingFlightNumber,
          operatingFlightNumber: x?.operatingFlightNumber,
          cityId: x?.cityId,
        };
      }
    );

  // const [flightSegments, setFlightSegments] = useState([

  // ]);

  // const handleInputChange = (e, index, propertyName) => {
  //   const { value } = e.target;

  //   setFlightSegments((prevSegments) => {
  //     const updatedSegments = prevSegments.map((segment, i) => {
  //       if (i === index) {
  //         // Extract existing properties from the corresponding object in the backend data
  //         const existingProperties = reissueData?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType[i];

  //         // Create a new copy of the segment object and merge existing properties with the updated property
  //         const updatedSegment = {
  //           ...segment,
  //           ...existingProperties,
  //           [propertyName]:propertyName === "departureDateTime"?  moment(value).format('YYYY-MM-DD HH:mm:ss.SSSSSS'):value,
  //         };

  //         return updatedSegment;
  //       }
  //       // Keep other segments unchanged
  //       return segment;
  //     });

  //     return updatedSegments;
  //   });
  // };

  const handleInputChange = (e, index, propertyName) => {
    console.log(propertyName);
    const { value } = e.target;
    const existingProperties = newFlightmap[index];

    // Create a new copy of the segment object and merge existing properties with the updated property
    const updatedSegment = {
      ...existingProperties,
    };
    setFlightSegments((prevSegments) => {
      const updatedSegments = [...prevSegments];
      updatedSegments[index] = {
        ...updatedSegments[index],
        ...existingProperties,
        [propertyName]:
          propertyName === "departureDateTime"
            ? moment(value).format("YYYY-MM-DD HH:mm:ss.SSSSSS")
            : propertyName === "flightNumber"
            ? parseInt(value)
            : value,
      };
      return updatedSegments;
    });
  };

  console.log(flightSegments);
  const [startDate, setStartDate] = useState(new Date());

  const [baggageInfo, setBaggeInfo] = useState([
    {
      bagInfo: {
        cabin: [
          {
            bookingId: "",
            bagInfoId: "",
            maximumPieces: "",
            totalWeightInKilograms: "",
            numberOfPieces: "",
            maximumSizeInInches: "",
            maximumSizeInCentimeters: "",
            maximumWeightInKilograms: "",
            maximumWeightInPounds: "",
            forAdult: "",
            forChild: "",
            forInfant: "",
            item1: "",
            item2: "",
            item3: "",
            item4: "",
            item5: "",
            item6: "",
            type: "",
          },
        ],
      },
    },
  ]);

  // const[bagInfo,setBagInfo]=useState([])

  const handleBaggeInputChange = (e, index, propertyName) => {
    console.log(propertyName);
    const { value } = e.target;
    const existingCabin =
      reissueData?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType[
        index
      ]?.bagInfo?.cabin;
    const existingCheck =
      reissueData?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType[
        index
      ]?.bagInfo?.check;

    let updatedSegment = {};

    if (existingCabin) {
      // Iterate through existingCabin to find the correct item
      for (const cabinItem of existingCabin) {
        if (cabinItem.type === "cabin") {
          // Convert item properties to strings and set to empty string if null
          const updatedItem = {
            ...cabinItem,
            item1: cabinItem.item1 || "",
            item2: cabinItem.item2 || "",
            item3: cabinItem.item3 || "",
            item4: cabinItem.item4 || "",
            item5: cabinItem.item5 || "",
            item6: cabinItem.item6 || "",
            [propertyName]: parseInt(value),
          };

          updatedSegment = updatedItem;
          break; // Stop the loop once you find the correct item
        }
      }
    } else if (existingCheck) {
      // Iterate through existingCheck to find the correct item
      for (const checkItem of existingCheck) {
        if (checkItem.type === "check") {
          // Convert item properties to strings and set to empty string if null
          const updatedItem = {
            ...checkItem,
            item1: checkItem.item1 || "",
            item2: checkItem.item2 || "",
            item3: checkItem.item3 || "",
            item4: checkItem.item4 || "",
            item5: checkItem.item5 || "",
            item6: checkItem.item6 || "",
            [propertyName]: parseInt(value),
          };

          updatedSegment = updatedItem;
          break; // Stop the loop once you find the correct item
        }
      }
    }

    setBagInfo((prevSegments) => {
      const updatedSegments = [...prevSegments];
      updatedSegments[index] = {
        ...updatedSegments[index],
        ...updatedSegment,
      };
      return updatedSegments;
    });
  };

  return (
    <Box
      sx={{
        mt: "35px",
        "& .MuiTableCell-root": {
          fontSize: "12px",
          color: "var(--text-medium)",
        },
        "& .MuiTableCell-head": {
          color: "var(--text-dark)",
          fontFamily: "productSans500 !important",
        },
        "& .MuiTableRow-root": {
          borderTop: "1px solid var(--table-border)",
        },
      }}
    >
      {/* Setion Title */}

      <Typography
        sx={{ fontSize: "20px", color: "var(--trips-secondary-color)" }}
      >
        {reissueData?.bookingInfo?.tripType === "multiCity" ? (
          <>Flight {label} Itinerary </>
        ) : reissueData?.bookingInfo?.tripType === "roundWay" ? (
          <>{label} Flight Itinerary </>
        ) : (
          "Flight Itinerary"
        )}
      </Typography>

      {/*---------- Flight Itinerary Table ----------*/}

      <Box sx={{ mt: "24px", position: "relative" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>Select</TableCell> */}
                <TableCell>ItemId</TableCell>
                <TableCell>Airlines</TableCell>
                <TableCell>Destination</TableCell>
                {reissueData?.bookingInfo?.priceBreakDown?.map((pax, index) => {
                  return (
                    <TableCell key={index} style={{ whiteSpace: "nowrap" }}>
                      {pax?.paxType === "ADT" && <>Adt Bag</>}
                    </TableCell>
                  );
                })}

                {reissueData?.bookingInfo?.priceBreakDown?.map((pax, index) => {
                  return (
                    <TableCell key={index} style={{ whiteSpace: "nowrap" }}>
                      {pax?.paxType === "CNN" && <>Chd Bag</>}
                    </TableCell>
                  );
                })}
                {reissueData?.bookingInfo?.priceBreakDown?.map((pax, index) => {
                  return (
                    <TableCell key={index} style={{ whiteSpace: "nowrap" }}>
                      {pax?.paxType === "INF" && (
                        <Typography>Int Bag</Typography>
                      )}
                    </TableCell>
                  );
                })}
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Flight No
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Booking Class
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Cabin Class
                </TableCell>
                <TableCell style={{ width: "10%", whiteSpace: "nowrap" }}>
                  Travel Date
                </TableCell>
                {/* <TableCell>Select Reissue Date</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {reissueData?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.map(
                (segments, i, arr) => {
                  // console.log(segment);
                  return (
                    <>
                      <TableRow
                        sx={{
                          display: segments?.cityId === cityId ? "" : "none",
                        }}
                        key={i}
                      >
                        {/* <TableCell>
                          <FormControlLabel
                            sx={{ mr: "5px", ml: "-5px" }}
                            value="end"
                            control={
                              <Checkbox
                                checked={segments?.isSelected}
                           
                                disableRipple
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                              />
                            }
                          />
                        </TableCell> */}
                        <TableCell>{segments?.itemId}</TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {segments?.airlineName}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {segments?.departureCity} - {segments?.arrivalCity}
                        </TableCell>

                        {reissueData?.bookingInfo?.priceBreakDown?.map(
                          (pax, index) => {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  whiteSpace: "nowrape",
                                }}
                              >
                                {pax?.paxType === "ADT" && (
                                  <>
                                    {segments?.bagInfo?.check?.map(
                                      (cab, cabIndex) => (
                                        <span
                                          key={cabIndex}
                                          sx={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {cab.forAdult === 1 ? (
                                            <>
                                              {cab.totalWeightInKilograms ||
                                                cab.maximumWeightInKilograms}
                                              kg
                                            </>
                                          ) : null}
                                        </span>
                                      )
                                    )}
                                  </>
                                )}
                              </TableCell>
                            );
                          }
                        )}
                        {reissueData?.bookingInfo?.priceBreakDown?.map(
                          (pax, index) => {
                            return (
                              <TableCell
                                style={{
                                  whiteSpace: "nowrape",
                                }}
                                key={index}
                              >
                                {pax?.paxType === "CNN" && (
                                  <>
                                    {segments?.bagInfo?.check?.map(
                                      (cab, cabIndex) => (
                                        <span
                                          key={cabIndex}
                                          sx={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {cab.forChild === 1 ? (
                                            <>
                                              {cab.totalWeightInKilograms ||
                                                cab.maximumWeightInKilograms}
                                              kg
                                            </>
                                          ) : null}
                                        </span>
                                      )
                                    )}
                                  </>
                                )}
                              </TableCell>
                            );
                          }
                        )}
                        {reissueData?.bookingInfo?.priceBreakDown?.map(
                          (pax, index) => {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  whiteSpace: "nowrape",
                                }}
                              >
                                {pax?.paxType === "INF" && (
                                  <>
                                    {segments?.bagInfo?.check?.map(
                                      (cab, cabIndex) => (
                                        <span
                                          key={cabIndex}
                                          sx={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {cab.forInfant === 1 ? (
                                            <>
                                              {cab.totalWeightInKilograms ||
                                                cab.maximumWeightInKilograms}
                                              kg
                                            </>
                                          ) : null}
                                        </span>
                                      )
                                    )}
                                  </>
                                )}
                              </TableCell>
                            );
                          }
                        )}

                        <TableCell style={{ whiteSpace: "nowrape" }}>
                          {segments?.flightNumber}
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "nowrape",
                          }}
                        >
                          {segments?.bookingClass}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrape" }}>
                          {" "}
                          {segments?.cabinTypeCode}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {moment(segments?.departureDateTime).format(
                            "hh:mm A DD MMM YYYY"
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          display: segments?.cityId === cityId ? "" : "none",
                        }}
                        key={i}
                      >
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <Typography
                              sx={{
                                color: "var(--trips-secondary-color)",
                                fontSize: "13px",
                              }}
                            >
                              {segments?.segment?.flightNumber}
                            </Typography> */}
                            <input
                              onChange={(e) =>
                                handleInputChange(e, i, "itemId")
                              }
                              min={0}
                              name="itemId"
                              // value={reissueFormInfo?.flightNo}
                              // placeholder="Enter Flight Number"
                              type="number"
                              style={{
                                width: "40px",
                                outline: "none",
                                marginLeft: "5px",
                                fontSize: "13px",
                                padding: "5px",
                                borderRadius: "1px",
                                border: "1px solid var(--table-border)",
                                textAlign: "left",
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          style={{
                            color: "var(--trips-secondary-color)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Request Change
                        </TableCell>
                        <TableCell></TableCell>

                        {reissueData?.bookingInfo?.priceBreakDown?.map(
                          (pax, index) => {
                            return (
                              <TableCell key={index}>
                                {pax?.paxType === "ADT" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "var(--trips-secondary-color)",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {segments?.segment?.bookingClass}
                                    </Typography>
                                    <input
                                      type="number"
                                      name="adtBag"
                                      onChange={(e) =>
                                        handleBaggeInputChange(
                                          e,
                                          i,
                                          "totalWeightInKilograms"
                                        )
                                      }
                                      // placeholder="Enter Booking Class"
                                      style={{
                                        width: "40px",
                                        outline: "none",
                                        marginLeft: "5px",
                                        fontSize: "13px",
                                        padding: "5px",
                                        borderRadius: "1px",
                                        border: "1px solid var(--table-border)",
                                        textAlign: "left",
                                      }}
                                    />
                                  </Box>
                                )}
                              </TableCell>
                            );
                          }
                        )}

                        {reissueData?.bookingInfo?.priceBreakDown?.map(
                          (pax, index) => {
                            return (
                              <TableCell key={index}>
                                {pax?.paxType === "CNN" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "var(--trips-secondary-color)",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {segments?.segment?.bookingClass}
                                    </Typography>
                                    <input
                                      onChange={(e) =>
                                        handleBaggeInputChange(
                                          e,
                                          i,
                                          "totalWeightInKilograms"
                                        )
                                      }
                                      name="chdBag"
                                      type="number"
                                      // placeholder="Enter Booking Class"
                                      style={{
                                        width: "40px",
                                        outline: "none",
                                        marginLeft: "5px",
                                        fontSize: "13px",
                                        padding: "5px",
                                        borderRadius: "1px",
                                        border: "1px solid var(--table-border)",
                                        textAlign: "left",
                                      }}
                                    />
                                  </Box>
                                )}
                              </TableCell>
                            );
                          }
                        )}

                        {reissueData?.bookingInfo?.priceBreakDown?.map(
                          (pax, index) => {
                            return (
                              <TableCell key={index}>
                                {pax?.paxType === "INF" && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "var(--trips-secondary-color)",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {segments?.segment?.bookingClass}
                                    </Typography>
                                    <input
                                      onChange={(e) =>
                                        handleBaggeInputChange(
                                          e,
                                          i,
                                          "totalWeightInKilograms"
                                        )
                                      }
                                      name="infBag"
                                      type="number"
                                      // placeholder="Enter Booking Class"
                                      style={{
                                        width: "40px",
                                        outline: "none",
                                        marginLeft: "5px",
                                        fontSize: "13px",
                                        padding: "5px",
                                        borderRadius: "1px",
                                        border: "1px solid var(--table-border)",
                                        textAlign: "left",
                                      }}
                                    />
                                  </Box>
                                )}
                              </TableCell>
                            );
                          }
                        )}

                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                color: "var(--trips-secondary-color)",
                                fontSize: "13px",
                              }}
                            >
                              {segments?.segment?.flightNumber}
                            </Typography>
                            <input
                              onChange={(e) =>
                                handleInputChange(e, i, "flightNumber")
                              }
                              name="flightNo"
                              // value={reissueFormInfo?.flightNo}
                              // placeholder="Enter Flight Number"
                              type="number"
                              style={{
                                width: "40px",
                                outline: "none",
                                marginLeft: "5px",
                                fontSize: "13px",
                                padding: "5px",
                                borderRadius: "1px",
                                border: "1px solid var(--table-border)",
                                textAlign: "left",
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                color: "var(--trips-secondary-color)",
                                fontSize: "13px",
                              }}
                            >
                              {segments?.segment?.bookingClass}
                            </Typography>
                            <input
                              name="bookingClass"
                              // placeholder="Enter Booking Class"

                              onChange={(e) =>
                                handleInputChange(e, i, "bookingClass")
                              }
                              style={{
                                width: "25px",
                                outline: "none",
                                marginLeft: "5px",
                                fontSize: "13px",
                                padding: "5px",
                                borderRadius: "1px",
                                border: "1px solid var(--table-border)",
                                textAlign: "left",
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "var(--trips-secondary-color)",
                                fontSize: "13px",
                              }}
                            >
                              {segments?.segment?.cabinTypeCode}
                            </Typography>
                            <input
                              // value={segment?.cabinTypeCode || ""}
                              // placeholder="Enter Cabin Class"
                              onChange={(e) =>
                                handleInputChange(e, i, "cabinTypeCode")
                              }
                              name="cabinClass"
                              style={{
                                width: "25px",
                                outline: "none",
                                padding: "5px",
                                marginLeft: "5px",
                                fontSize: "12px",
                                borderRadius: "1px",
                                border: "1px solid var(--table-border)",
                                textAlign: "left",
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              color: "var(--trips-secondary-color)",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "10px", marginRight: "5px" }}
                            >
                              {moment(
                                segments?.segment?.departureDateTime
                              ).format("DD MMM YYYY")}
                            </Typography>

                            <Box>
                              <Box
                                // onClick={() =>
                                //   handleOpenReissueCalendar(
                                //     segments?.segment?.id
                                //   )
                                // }
                                sx={{
                                  display: "flex",
                                  cursor: "pointer",
                                  pointerEvents: segments?.segment?.isSelected
                                    ? "auto"
                                    : "none",
                                  border: "1px solid",
                                  borderColor: segments?.segment?.isSelected
                                    ? "var(--trips-secondary-color)"
                                    : "var(--table-border)",
                                  width: "max-content",
                                }}
                              >
                                {/* <Box
                                  sx={{
                                    width: "80px",

                                    ...centerItem,
                                  }}
                                >
                                  {moment(
                                    segments?.segment?.newDepartureDate
                                  ).format("DD MMM YYYY")}
                                </Box> */}
                              </Box>

                              <input
                                type="date"
                                style={{
                                  color: "var(--trips-secondary-color)",
                                  // Adjust padding as needed
                                  fontSize: "10px",
                                  border: "1px solid #ccc", // Adjust border styles as needed
                                  borderRadius: "4px", // Adjust border radius as needed
                                  // Add more styles as needed
                                }}
                                onChange={(e) =>
                                  handleInputChange(e, i, "departureDateTime")
                                }
                              />

                              {/* {segments?.segment?.openDepartureDate && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: "0",
                                    zIndex: "100",
                                    // bottom: "-100%",
                                    pt: "22px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      border: "1px solid var(--table-border)",
                                      display: "flex",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <Calendar
                                      className={"dashboard-calendar"}
                                      color="var(--trips-secondary-color)"
                                      direction="vertical"
                                      months={1}
                                      date={new Date(segments.newDepartureDate)}
                                      minDate={
                                        i === 0
                                          ? new Date()
                                          : new Date(
                                              arr[i - 1].newDepartureDate
                                            )
                                      }
                                      // onChange={(date) => {
                                      //   const formatedDate =
                                      //     moment(date).format("YYYY-MM-DD");
                                      //   getDateOfBirthAndPassporExpire(
                                      //     passengerNumber,
                                      //     "dob",
                                      //     formatedDate,
                                      //     "openDob"
                                      //   );
                                      // }}

                                      onChange={(date) => {
                                        getDepartureDateFromCalendar(date, i);
                                      }}
                                      style={{
                                        fontWeight: "600",
                                        padding: "0 10px 10px",
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 100,
                                      }}
                                    />
                                  </Box>
                                </Box>
                              )} */}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/*---------- Passenger Details Table  ----------*/}

      {/* <Box sx={{ mt: "35px" }}>
        <Typography sx={{ fontSize: "18px", color: "var(--text-dark)" }}>
          Passenger Details
        </Typography>

        <TableContainer sx={{ mt: "24px" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderTop: "1px solid var(--table-border)" }}>
                <TableCell width={"10%"}>
                  <FormControlLabel
                    sx={{ mr: "5px", ml: "-5px" }}
                    value="end"
                    control={
                      <Checkbox
                        onChange={(e) => {
                          // if (e.target.checked) {
                          //   setSegmentCount(1);
                          // } else {
                          //   setSegmentCount(0);
                          // }
                        }}
                        disableRipple
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "var(--text-dark)",
                          pl: "5px",
                          fontFamily: "productSans500 !important",
                        }}
                      >
                        Select
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </TableCell>
                <TableCell>Passenger Name</TableCell>
                <TableCell>PAX Type</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Date of Birth</TableCell>
                <TableCell>Passport No</TableCell>
                <TableCell>Passport Expire Date</TableCell>
                <TableCell>Ticket No</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queueDetails?.passengers?.map((passenger, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <FormControlLabel
                      sx={{ mr: "5px", ml: "-5px" }}
                      value="end"
                      control={
                        <Checkbox
                          onChange={(e) => {
                            // if (e.target.checked) {
                            //   setSegmentCount(1);
                            // } else {
                            //   setSegmentCount(0);
                            // }
                          }}
                          disableRipple
                          checkedIcon={<BpCheckedIcon />}
                          icon={<BpIcon />}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {passenger?.firstName} {passenger?.lastName}
                  </TableCell>
                  <TableCell>
                    {passenger?.type === "ADT"
                      ? "Adult"
                      : passenger?.type === "CNN"
                      ? "Child"
                      : "Infant"}
                  </TableCell>
                  <TableCell>{passenger?.gender}</TableCell>
                  <TableCell>
                    {moment(passenger?.dob).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell>{passenger?.passNo}</TableCell>
                  <TableCell>
                    {moment(passenger?.passEx).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell>{passenger?.ticketNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </Box>
  );
};

export default FlightItinerary;
