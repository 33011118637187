import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Loader from "../Shared/Loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const AllEmployee = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "11px",
    color: "#000",
    textAlign: "left",
  };
  // brerr token
  const token = secureLocalStorage.getItem("token");
  // all admin data get
  const [allAdmin, setAllAdimn] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl =
      "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/getAllAdmins";
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data?.data);
        setAllAdimn(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, [token]);

  console.log(allAdmin);

  const navigateToStaffDetails = (id) => {
    navigate(`${id}`);
  };
  return (
    <Box sx={{ width: "1350px" }}>
      {" "}
      <Grid container>
        <Grid
          item
          md={12}

          // sx={{
          //   bgcolor: "#FFFFFF",
          //   borderRadius: "5px",
          // }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              sx={{ fontSize: "26px", color: "var(--secondary-color)" }}
            >
              Employee
            </Typography>
            <Link to="/quickDashboard/staffAdd">
              <Button
                sx={{
                  height: "25px",
                  width: "106px",
                  color: "var(--thard-color)",
                  backgroundColor: "var(--savStaff-color)",
                  textTransform: "capitalize",
                  ":hover": {
                    color: "var(--thard-color)",
                    backgroundColor: "var(--savStaff-color)",
                  },
                }}
              >
                Add Staff
              </Button>
            </Link>
          </Box>
          {isLoading ? (
            <Loader></Loader>
          ) : (
            <TableContainer sx={{ mt: "10px" }}>
              <Table size="small" aria-label="simple table">
                <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                  <TableRow>
                    <TableCell style={tabcellText} component="th" scope="row">
                      Employee ID
                    </TableCell>
                    <TableCell style={tabcellText}>Status</TableCell>
                    <TableCell style={tabcellText}>Name</TableCell>
                    <TableCell style={tabcellText}>O. Email</TableCell>
                    <TableCell style={tabcellText}>O. Phone Number</TableCell>
                    <TableCell style={tabcellText}>Password</TableCell>
                    <TableCell style={tabcellText}>Designation</TableCell>
                    <TableCell style={tabcellText}>Role</TableCell>
                    <TableCell style={tabcellText}>Join At</TableCell>
                    <TableCell style={tabcellText}>Created By</TableCell>
                    <TableCell style={tabcellText}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ "& td, & th": {} }}>
                  {allAdmin?.map((admin) => {
                    return (
                      <TableRow key={admin?.id}>
                        <TableCell style={{ cursor: "pointer" }}>
                          <Button
                            onClick={() => navigateToStaffDetails(admin?.id)}
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-color)",
                              },
                            }}
                          >
                            {admin?.Employee_ID}
                          </Button>
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {admin?.Status}
                        </TableCell>
                        <TableCell style={tabcellText}>{admin.name}</TableCell>
                        <TableCell style={tabcellText}>
                          {admin.OEmail}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {admin.OPhone}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {admin.password}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {admin.Designation}
                        </TableCell>
                        <TableCell style={tabcellText}>{admin.Role}</TableCell>
                        <TableCell style={tabcellText}>
                          {" "}
                          {new Date(admin.joinAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          {admin.createBy}
                        </TableCell>
                        <TableCell style={tabcellText}>
                          <NavLink>
                            <EditIcon
                              style={{
                                fontSize: "20px",
                                color: "green",
                                cursor: "pointer",
                              }}
                            />
                          </NavLink>{" "}
                          <DeleteIcon
                            style={{
                              fontSize: "20px",
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
