import { Box, Button, Grid, SwipeableDrawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Test = () => {
  const [open, setOpen] = useState(false);
  const drawerWidth = 300;
  const toggleDrawer = (openStatus) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(openStatus);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/tour-package/get-tour-package/d5129505f80b420994cee5cef57b6215`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data?.place_to_visit);
      });
  });

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box mt={4}>
      <Typography sx={{ fontSize: "17px" }} my={2} onClick={toggleDrawer(true)}>
        Tour Planes
      </Typography>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          style: {
            width: drawerWidth,
          },
        }}
      >
        <div>
          <Button onClick={toggleDrawer(false)}>Close Drawer</Button>
        </div>
      </SwipeableDrawer>

      {/* <Grid container spacing={2}>
        {data?.map((tourData) => (
          <Grid item xs={12} sm={6} md={3}>
            <Box
              style={{
                backgroundColor: "#ccc",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Slider {...settings}>
                {[...new Array(5)].map((_, index) => (
                  <div key={index}>
                    <Box>
                      <img
                        style={{ width: "100%", height: "200px" }}
                        src={tourData?.place_image}
                        alt={`Image ${index}`}
                      />
                    </Box>
                  </div>
                ))}
              </Slider>

              <Typography>{tourData?.placetovisit_name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid> */}
    </Box>
  );
};

export default Test;
