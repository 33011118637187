import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
//import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { CircleLoader } from "react-spinners";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllDeposit = ({ allUsers }) => {
  const token = secureLocalStorage.getItem("Admin-Token");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allDeposit, setAllDeposit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");
  const [depositId, setDepositId] = useState("");
  const [changeTabs, setChangeTabs] = useState("pending");
  let url =
    "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/all-deposit";
  useEffect(() => {
    setLoading(true);
    if (url) {
      fetch(url, {
        method: "GET",

        headers: {
          "Admin-Token": `${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAllDeposit(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // approped deposit

  const handleApproveClick = (id, status) => {
    setDepositId(id);
    setStatus(status);
    setIsDialogOpen(true);
    // You can perform other actions related to approval here
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleApproveConfirm = () => {
    // Handle the approval confirmation
    setIsDialogOpen(false);
  };

  // approped deposit

  const handleInputChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleDepositReq = async () => {
    setIsDialogOpen(false);
    const depositBody = {
      reason: remarks,
      status: status,
    };
    try {
      const response = await fetch(
        `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/update-deposit/${depositId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Admin-Token": `${token}`,
          },
          body: JSON.stringify(depositBody),
        }
      );
      const data = await response.json();

      if (response.ok) {
        // Show success Swal (SweetAlert) notification
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data?.message,
        });
        window.location.reload();
        // Additional logic you want to perform after a successful API call
      } else {
        // Show error Swal (SweetAlert) notification for API errors
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data?.message || "An error occurred during the API call.",
        });
      }
    } catch (error) {
      console.error("Error:", error);

      // Show error Swal (SweetAlert) notification for network or server errors
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again later.",
      });
    }
    setRemarks(" ");
  };

  return (
    <>
      <Box mt={4}>
        <Typography
          sx={{ fontSize: "25px", color: "var(--trips-primary-color)" }}
        >
          All Deposit List
        </Typography>
      </Box>

      <Box
        mt={4}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setChangeTabs("pending")}
          sx={{
            color: changeTabs === "pending" ? "white" : "black",
            bgcolor:
              changeTabs === "pending"
                ? "var(--trips-secondary-color)"
                : "var(--primary-color)",
            boxShadow: "none",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            "&:hover": {
              textTransform: "uppercase",
              backgroundColor: "var(--trips-secondary-color)", // Set your hover background color
              color: "#ffffff", // Set your hover text color
            },
          }}
        >
          Pending
        </Button>
        <Button
          variant="contained"
          onClick={() => setChangeTabs("approved")}
          sx={{
            "&:hover": {
              // Hover styles
              backgroundColor: "var(--trips-secondary-color)", // Set your hover background color
              color: "#ffffff", // Set your hover text color
            },
            color: changeTabs === "approved" ? "white" : "black",
            textTransform: "uppercase",
            bgcolor:
              changeTabs === "approved"
                ? "var(--trips-secondary-color)"
                : "var(--primary-color)",
            boxShadow: "none",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
          }}
        >
          Approved
        </Button>
        <Button
          variant="contained"
          onClick={() => setChangeTabs("rejected")}
          sx={{
            "&:hover": {
              // Hover styles
              backgroundColor: "var(--trips-secondary-color)", // Set your hover background color
              color: "#ffffff", // Set your hover text color
            },
            color: changeTabs === "rejected" ? "white" : "black",
            textTransform: "uppercase",
            bgcolor:
              changeTabs === "rejected"
                ? "var(--trips-secondary-color)"
                : "var(--primary-color)",
            boxShadow: "none",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
          }}
        >
          Rejected
        </Button>
      </Box>

      {changeTabs === "pending" && (
        <Box mt={2}>
          <TableContainer>
            <Table size="small" aria-label="simple table" sx>
              <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                <TableRow>
                  <TableCell className="table-cell">
                    <Button
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "var(--primary-text)",
                        fontSize: "11px",
                        textTransform: "capitalize",
                        ":hover": {
                          backgroundColor: "var(--primary-text)",
                        },
                      }}
                    >
                      Reference
                    </Button>
                  </TableCell>
                  <TableCell className="table-cell">Status</TableCell>
                  <TableCell className="table-cell">Type</TableCell>
                  <TableCell className="table-cell">Transaction Id</TableCell>
                  <TableCell
                    className="table-cell"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Transaction Date
                  </TableCell>
                  <TableCell className="table-cell">Requested By</TableCell>
                  <TableCell className="table-cell">Balance</TableCell>
                  <TableCell className="table-cell">Attachment</TableCell>
                  <TableCell className="table-cell">Created At</TableCell>
                  <TableCell className="table-cell">Updated At</TableCell>
                  <TableCell className="table-cell">Action</TableCell>
                  <TableCell className="table-cell">Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                {allDeposit?.map((deposit) => (
                  <TableRow
                    key={deposit?.id}
                    sx={{
                      width: "100%",
                      ":hover": {
                        backgroundColor: "#DDDDDD",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell"
                    >
                      <Button
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "var(--primary-text)",
                          fontSize: "11px",
                          textTransform: "capitalize",
                          ":hover": {
                            backgroundColor: "var(--primary-text)",
                          },
                        }}
                      >
                        {deposit?.reference_no}
                      </Button>
                    </TableCell>

                    <TableCell className="table-cell">
                      {deposit?.status}
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit?.type}
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit?.transaction_id
                        ? deposit?.transaction_id
                        : "N/A"}
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit?.transaction_date}
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit?.requested_by_user_name != null
                        ? deposit?.requested_by_user_name
                        : deposit?.requested_by_email}
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit?.amount ? deposit?.amount : 0}
                    </TableCell>
                    <TableCell className="table-cell">
                      <a
                        style={{
                          background: "var(--trips-primary-color)",
                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                          textDecoration: "none",
                        }}
                        href={deposit?.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit?.created_at}
                    </TableCell>
                    <TableCell className="table-cell">
                      
                     { moment(deposit?.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className="table-cell"
                    >
                      <Box sx={{ gap: "5px" }}>
                        <Button
                          sx={{
                            fontSize: "10px",
                            bgcolor: "green",
                            color: "white",
                            textTransform: "capitalize",
                            " :hover": {
                              bgcolor: "green",
                            },
                          }}
                          onClick={() =>
                            handleApproveClick(deposit?.id, "approved")
                          }
                        >
                          Approved
                        </Button>
                        <Button
                          onClick={() =>
                            handleApproveClick(deposit?.id, "rejected")
                          }
                          sx={{
                            fontSize: "10px",
                            bgcolor: "red",
                            color: "white",
                            textTransform: "capitalize",
                            ml: "5px",
                            " :hover": {
                              bgcolor: "red",
                            },
                          }}
                        >
                          Rejected
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell className="table-cell">
                      {deposit.rejected_reason}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xl"
            sx={{
              height: { xs: "100vh", sm: "100vh", md: "100vh" },
            }}
          >
            <Box
              sx={{
                background: "#ffff",
                width: "450px",
                px: "20px",
                borderRadius: "5px",
                py: "15px",
              }}
            >
              <label style={{ color: "grey" }}>Remarks</label>
              <Box sx={{ pt: "5px" }}>
                <textarea
                  value={remarks}
                  onChange={handleInputChange}
                  style={{
                    height: "110px",
                    width: "100%",
                    borderRadius: "5px",
                    resize: "none",
                    outline: "none",
                    border: "none",
                    border: "1px solid var(--trips-primary-color)",
                  }}
                />
              </Box>
              <DialogActions>
                <Button
                  onClick={handleDepositReq}
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    width: "100%",
                    height: "45px",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    border: "1px solid var(--trips-primary-color)",
                    mt: "20px",
                    ":hover": {
                      backgroundColor: "var(--trips-primary-color)",
                      color: "white",
                    },
                  }}
                >
                  {status}
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Box>
      )}

      {/*approved area */}

      {changeTabs === "approved" && (
        <Box mt={2}>
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                <TableRow>
                  <TableCell className="table-cell">
                    <Button
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "var(--primary-text)",
                        fontSize: "11px",
                        textTransform: "capitalize",
                        ":hover": {
                          backgroundColor: "var(--primary-text)",
                        },
                      }}
                    >
                      Reference
                    </Button>
                  </TableCell>
                  <TableCell className="table-cell">Status</TableCell>
                  <TableCell className="table-cell">Type</TableCell>
                  <TableCell className="table-cell">Transaction Id</TableCell>
                  <TableCell
                    className="table-cell"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Transaction Date
                  </TableCell>
                  <TableCell className="table-cell">Requested By</TableCell>
                  <TableCell className="table-cell">Balance</TableCell>
                  <TableCell className="table-cell">Attachment</TableCell>
                  <TableCell className="table-cell">Created At</TableCell>
                  <TableCell className="table-cell">Updated At</TableCell>
                  <TableCell className="table-cell">Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                {allDeposit
                  ?.filter((x) => x.status === "approved")
                  .map((deposit) => (
                    <TableRow
                      key={deposit?.id}
                      sx={{
                        width: "100%",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell"
                      >
                        <Button
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "var(--primary-text)",
                            fontSize: "11px",
                            textTransform: "capitalize",
                            ":hover": {
                              backgroundColor: "var(--primary-text)",
                            },
                          }}
                        >
                          {deposit?.reference_no}
                        </Button>
                      </TableCell>

                      <TableCell className="table-cell">
                        {deposit?.status}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.type}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.transaction_id
                          ? deposit?.transaction_id
                          : "N/A"}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.transaction_date.slice(0, 10)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.requested_by_user_name != null
                          ? deposit?.requested_by_user_name
                          : deposit?.requested_by_email}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.amount ? deposit?.amount : 0}
                      </TableCell>
                      <TableCell className="table-cell">
                        <a
                          style={{
                            background: "var(--trips-primary-color)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            textDecoration: "none",
                          }}
                          href={deposit?.attachment}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.created_at}
                      </TableCell>
                      <TableCell className="table-cell">
                        
                        {
                          moment(deposit?.updated_at).format("YYYY-MM-DD HH:mm:ss")
                        }
                      </TableCell>

                      <TableCell className="table-cell">
                        {deposit.rejected_reason}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xl"
            sx={{
              height: { xs: "100vh", sm: "100vh", md: "100vh" },
            }}
          >
            <Box
              sx={{
                background: "#ffff",
                width: "450px",
                px: "20px",
                borderRadius: "5px",
                py: "15px",
              }}
            >
              <label style={{ color: "grey" }}>Remarks</label>
              <Box sx={{ pt: "5px" }}>
                <textarea
                  value={remarks}
                  onChange={handleInputChange}
                  style={{
                    height: "110px",
                    width: "100%",
                    borderRadius: "5px",
                    resize: "none",
                    outline: "none",
                    border: "none",
                    border: "1px solid var(--trips-primary-color)",
                  }}
                />
              </Box>
              <DialogActions>
                <Button
                  onClick={handleDepositReq}
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    width: "100%",
                    height: "45px",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    border: "1px solid var(--trips-primary-color)",
                    mt: "20px",
                    ":hover": {
                      backgroundColor: "var(--trips-primary-color)",
                      color: "white",
                    },
                  }}
                >
                  {status}
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Box>
      )}

      {/* rejected area */}

      {changeTabs === "rejected" && (
        <Box mt={2}>
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                <TableRow>
                  <TableCell className="table-cell">
                    <Button
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "var(--primary-text)",
                        fontSize: "11px",
                        textTransform: "capitalize",
                        ":hover": {
                          backgroundColor: "var(--primary-text)",
                        },
                      }}
                    >
                      Reference
                    </Button>
                  </TableCell>
                  <TableCell className="table-cell">Status</TableCell>
                  <TableCell className="table-cell">Type</TableCell>
                  <TableCell className="table-cell">Transaction Id</TableCell>
                  <TableCell
                    className="table-cell"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Transaction Date
                  </TableCell>
                  <TableCell className="table-cell">Requested By</TableCell>
                  <TableCell className="table-cell">Balance</TableCell>
                  <TableCell className="table-cell">Attachment</TableCell>
                  <TableCell className="table-cell">Created At</TableCell>
                  <TableCell className="table-cell">Updated At</TableCell>
                  <TableCell className="table-cell">Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                {allDeposit
                  ?.filter((x) => x.status === "rejected")
                  .map((deposit) => (
                    <TableRow
                      key={deposit?.id}
                      sx={{
                        width: "100%",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="table-cell"
                      >
                        <Button
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "var(--primary-text)",
                            fontSize: "11px",
                            textTransform: "capitalize",
                            ":hover": {
                              backgroundColor: "var(--primary-text)",
                            },
                          }}
                        >
                          {deposit?.reference_no}
                        </Button>
                      </TableCell>

                      <TableCell className="table-cell">
                        {deposit?.status}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.type}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.transaction_id
                          ? deposit?.transaction_id
                          : "N/A"}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.transaction_date.slice(0, 10)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.requested_by_user_name != null
                          ? deposit?.requested_by_user_name
                          : deposit?.requested_by_email}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.amount ? deposit?.amount : 0}
                      </TableCell>
                      <TableCell className="table-cell">
                        <a
                          style={{
                            background: "var(--trips-primary-color)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            textDecoration: "none",
                          }}
                          href={deposit?.attachment}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.created_at.slice(0, 19)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {deposit?.updated_at.slice(0, 19)}
                      </TableCell>

                      <TableCell className="table-cell">
                        {deposit.rejected_reason}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xl"
            sx={{
              height: { xs: "100vh", sm: "100vh", md: "100vh" },
            }}
          >
            <Box
              sx={{
                background: "#ffff",
                width: "450px",
                px: "20px",
                borderRadius: "5px",
                py: "15px",
              }}
            >
              <label style={{ color: "grey" }}>Remarks</label>
              <Box sx={{ pt: "5px" }}>
                <textarea
                  value={remarks}
                  onChange={handleInputChange}
                  style={{
                    height: "110px",
                    width: "100%",
                    borderRadius: "5px",
                    resize: "none",
                    outline: "none",
                    border: "none",
                    border: "1px solid var(--trips-primary-color)",
                  }}
                />
              </Box>
              <DialogActions>
                <Button
                  onClick={handleDepositReq}
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    width: "100%",
                    height: "45px",
                    textTransform: "capitalize",
                    fontSize: "15px",
                    border: "1px solid var(--trips-primary-color)",
                    mt: "20px",
                    ":hover": {
                      backgroundColor: "var(--trips-primary-color)",
                      color: "white",
                    },
                  }}
                >
                  {status}
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default AllDeposit;
