import { Box } from "@mui/material";
import React, { useState } from "react";

const GroupFareFlightInfo = () => {
  const [selectedStops, setSelectedStops] = useState("Ostops");

  const handleSelectChange = (event) => {
    setSelectedStops(event.target.value);
  };
  return (
    <Box>
      <Box>
        {/* grorp fare type  */}
        <Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "30px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Group Fare Type
              </label>
            </Box>

            <input
              placeholder="Enter Job Holder"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name="visaType"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "30px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Select Stop
              </label>
            </Box>

            <select
              value={selectedStops}
              onChange={handleSelectChange}
              style={{
                border: "none",
                backgroundColor: "white",
                color: "#5F6368",
                outline: "none",
              }}
              name="visaType"
            >
              <option value="">Select an option</option>
              <option value="Ostops">O Stops</option>
              <option value="onestops">One Stops</option>
            </select>
          </Box>
        </Box>
        {selectedStops === "Ostops" && (
          <Box sx={{ mt: "80px" }}>
            {/* grorp flight info fare type  */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Departure
                  </label>
                </Box>

                <input
                  placeholder="Departure"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Arrival
                  </label>
                </Box>

                <input
                  placeholder="Arrival"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Departure Date & Time
                  </label>
                </Box>

                <input
                  placeholder="Departure Date & Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>

              {/* Arrival Date & Time inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Arrival Date & Time
                  </label>
                </Box>

                <input
                  placeholder="Arrival Date & Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/* Travel Time inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Travel Time
                  </label>
                </Box>

                <input
                  placeholder="Travel Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/*Career Code inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Career Code
                  </label>
                </Box>

                <input
                  placeholder="Career Code"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/*Flight Number inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Flight Number
                  </label>
                </Box>

                <input
                  placeholder="Flight Number"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/*Class inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Class
                  </label>
                </Box>

                <input
                  placeholder="Class"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
            </Box>
          </Box>
        )}

        {selectedStops === "onestops" && (
          <Box sx={{ mt: "80px" }}>
            <Box sx={{ mt: "80px" }}>
              {/* grorp flight info fare type  */}
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Departure
                    </label>
                  </Box>

                  <input
                    placeholder="Departure"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Arrival
                    </label>
                  </Box>

                  <input
                    placeholder="Arrival"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Departure Date & Time
                    </label>
                  </Box>

                  <input
                    placeholder="Departure Date & Time"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>

                {/* Arrival Date & Time inputs*/}
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Arrival Date & Time
                    </label>
                  </Box>

                  <input
                    placeholder="Arrival Date & Time"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>
                {/* Travel Time inputs*/}
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Travel Time
                    </label>
                  </Box>

                  <input
                    placeholder="Travel Time"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>
                {/*Career Code inputs*/}
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Career Code
                    </label>
                  </Box>

                  <input
                    placeholder="Career Code"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>
                {/*Flight Number inputs*/}
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Flight Number
                    </label>
                  </Box>

                  <input
                    placeholder="Flight Number"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>
                {/*Class inputs*/}
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid #DADCE0",
                    pb: "15px",
                    mt: "20px",
                    width: "95%",
                  }}
                >
                  <Box sx={{ width: "700px" }}>
                    <label style={{ color: "#5F6368", fontSize: "12px" }}>
                      Class
                    </label>
                  </Box>

                  <input
                    placeholder="Class"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      outline: "none",
                    }}
                    name="visaType"
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: "80px" }}>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Transit Time
                  </label>
                </Box>

                <input
                  placeholder="Transit Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
            </Box>
            <Box sx={{ mt: "80px" }}>
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Departure
                  </label>
                </Box>

                <input
                  placeholder="Departure"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Arrival
                  </label>
                </Box>

                <input
                  placeholder="Arrival"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Departure Date & Time
                  </label>
                </Box>

                <input
                  placeholder="Departure Date & Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>

              {/* Arrival Date & Time inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Arrival Date & Time
                  </label>
                </Box>

                <input
                  placeholder="Arrival Date & Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/* Travel Time inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Travel Time
                  </label>
                </Box>

                <input
                  placeholder="Travel Time"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/*Career Code inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Career Code
                  </label>
                </Box>

                <input
                  placeholder="Career Code"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/*Flight Number inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Flight Number
                  </label>
                </Box>

                <input
                  placeholder="Flight Number"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
              {/*Class inputs*/}
              <Box
                sx={{
                  display: "flex",
                  borderBottom: "1px solid #DADCE0",
                  pb: "15px",
                  mt: "20px",
                  width: "95%",
                }}
              >
                <Box sx={{ width: "700px" }}>
                  <label style={{ color: "#5F6368", fontSize: "12px" }}>
                    Class
                  </label>
                </Box>

                <input
                  placeholder="Class"
                  style={{
                    border: "none",
                    backgroundColor: "white",
                    outline: "none",
                  }}
                  name="visaType"
                />
              </Box>
            </Box>
          </Box>
        )}

        {/* baggage info  */}
        <Box  sx={{py:"50px"}}>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Fare Type
              </label>
            </Box>
            <input
              placeholder="Fare Type"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "20px",
              width: "100%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
              Baggege
              </label>
            </Box>
            <input
              placeholder="Baggege"
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupFareFlightInfo;
