import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { CircleLoader } from "react-spinners";
import PersonIcon from "@mui/icons-material/Person";
import RefreshIcon from "@mui/icons-material/Refresh";
import commaNumber from "comma-number";
import moment from "moment";
import Dialog from "@mui/material/Dialog";

const QueDetails = () => {
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "10.5px",
    color: "#000",
    textAlign: "left",
  };

  // baggge modal
  const [isModalbaggeOpen, setModalbaggeOpen] = useState(false);
  const [flightInfo, setFlightInfo] = useState("");

  const handleButtonClick = (data) => {
    setModalbaggeOpen(true);
    setFlightInfo(data);
  };

  const handleCloseModal = () => {
    setModalbaggeOpen(false);
  };

  const token = secureLocalStorage.getItem("Admin-Token");
  const [loading, setLoading] = useState(true);
  const [singleQuedetails, setSingleQuedetails] = useState([]);
  const [ledger, setLedger] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  let url = `https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/flight/get-single-flight-booking/${id}`;
  // Remove the extra forward slash here ^

  useEffect(() => {
    setLoading(true);
    if (url) {
      fetch(url, {
        method: "GET",
        headers: {
          "Admin-Token": `${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setSingleQuedetails(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);
  // leager api fetch
  useEffect(() => {
    setLoading(true);

    const url =
      "https://flyfar-quicktickets-394105.an.r.appspot.com/api/v1/get-ledger";

    fetch(url, {
      method: "GET",
      headers: {
        "Admin-Token": `${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setLedger(data?.data); // Fix the typo here
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors based on your application's requirements
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  console.log("ledger data", ledger);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // text capitalize
  const capitalize = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s|-)\S/g, (match) => match.toUpperCase());
  };

  console.log("single qudetails", singleQuedetails);

  console.log(singleQuedetails?.bookingInfo?.status);
  return (
    <Box sx={{ mt: "20px" }}>
      <Grid container>
        <Grid
          item
          md={10}
          sx={{
            borderRight: "12px solid #EFF2F5",
            bgcolor: "#FFFFFF",
            borderRadius: "5px",
            width: "100%",
            pr: "15px",
          }}
        >
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Booking Details {singleQuedetails?.bookingInfo?.bookingId}
              </Typography>
              <Box mt={2}>
                <TableContainer>
                  <Table size="small" aria-label="simple table">
                    <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                      <TableRow>
                        <TableCell className="table-cell">
                          <Button
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            Booking Id
                          </Button>
                        </TableCell>
                        <TableCell className="table-cell">Status</TableCell>
                        <TableCell className="table-cell">Route</TableCell>
                        <TableCell className="table-cell">System</TableCell>
                        <TableCell className="table-cell">Segment</TableCell>
                        <TableCell className="table-cell">
                          Airlines PNR
                        </TableCell>
                        <TableCell className="table-cell">GDS PNR</TableCell>
                        <TableCell className="table-cell">
                          Expire Timleimit
                        </TableCell>
                        <TableCell className="table-cell">
                          Ticket Type
                        </TableCell>
                        <TableCell className="table-cell">
                          Booking Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& td, & th": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell"
                        >
                          <Button
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            {singleQuedetails?.bookingInfo?.bookingId}
                          </Button>
                        </TableCell>

                        <TableCell className="table-cell">
                          {singleQuedetails?.bookingInfo?.status}
                        </TableCell>
                        <TableCell className="table-cell">{`${singleQuedetails?.bookingInfo?.deptFrom}-${singleQuedetails?.bookingInfo?.arriveTo}`}</TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.bookingInfo?.system
                            ? singleQuedetails?.bookingInfo?.system
                            : "System001"}
                        </TableCell>
                        <TableCell className="table-cell">Comming</TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.bookingInfo?.airlinesPNR}
                        </TableCell>
                        <TableCell className="table-cell">Comming</TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.bookingInfo?.timeLimit}
                        </TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.bookingInfo?.journeyType}
                        </TableCell>
                        <TableCell className="table-cell">
                          {new Date(
                            singleQuedetails?.bookingInfo?.assignTime
                          ).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                User Details
              </Typography>
              <Box mt={2}>
                <TableContainer>
                  <Table size="small" aria-label="simple table">
                    <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                      <TableRow>
                        <TableCell className="table-cell">
                          <Button
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            User ID
                          </Button>
                        </TableCell>
                        <TableCell className="table-cell">User Name</TableCell>
                        <TableCell className="table-cell">Wallet</TableCell>
                        <TableCell className="table-cell">Purchase</TableCell>
                        <TableCell className="table-cell">Dom Markup</TableCell>
                        <TableCell className="table-cell">
                          Intl Markup
                        </TableCell>
                        <TableCell className="table-cell">
                          Additional Markup
                        </TableCell>
                        <TableCell className="table-cell">Ledger</TableCell>
                        <TableCell className="table-cell">Contact</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& td, & th": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      {ledger?.map((ledgerItem) => (
                        <TableRow
                          key={ledgerItem?.id}
                          sx={{
                            width: "100%",
                            ":hover": {
                              backgroundColor: "#DDDDDD",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="table-cell"
                          >
                            <Button
                              sx={{
                                bgcolor: "var(--primary-color)",
                                color: "var(--primary-text)",
                                fontSize: "11px",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "var(--primary-text)",
                                },
                              }}
                            >
                              {ledgerItem?.userId}
                            </Button>
                          </TableCell>
                          <TableCell className="table-cell">Comming</TableCell>
                          <TableCell className="table-cell">
                            {" "}
                            {ledgerItem?.wallet}
                          </TableCell>
                          <TableCell className="table-cell">
                            {" "}
                            {ledgerItem?.purchase}
                          </TableCell>
                          <TableCell className="table-cell">
                            {" "}
                            {ledgerItem?.IntMarkup
                              ? ledgerItem?.domMarkup
                              : "0"}
                          </TableCell>
                          <TableCell className="table-cell">
                            {ledgerItem?.IntMarkup
                              ? ledgerItem?.IntMarkup
                              : "0"}
                          </TableCell>
                          <TableCell className="table-cell">
                            {ledgerItem?.additionalMarkup
                              ? ledgerItem?.additionalMarkup
                              : "0"}
                          </TableCell>
                          <TableCell className="table-cell">Comming</TableCell>
                          <TableCell className="table-cell">Comming</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box
                sx={{
                  backgroundColor: "var(--third-color)",
                  borderRadius: "10px",
                }}
              >
                {/*  Flight details */}
                <Box
                  sx={{
                    py: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "22px" }}>
                    {" "}
                    Flight Itenary Details
                  </Typography>
                </Box>

                {/*  Flight details */}
                <Box sx={{ pt: "10px" }}>
                  {singleQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.map(
                    (data, i, arr) => (
                      <Grid container>
                        <Grid item md={9}>
                          {/* departure airport  */}
                          <Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                height={"35px"}
                                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.airlineCode}.png`}
                                alt=""
                              />
                              <Typography
                                sx={{
                                  pl: "10px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  textTransfrom: "capitalize",
                                }}
                              >
                                {capitalize(data?.airlineName)} {bull}{" "}
                                {data?.airlineCode}{" "}
                                {data?.marketingFlightNumber} {bull}{" "}
                                {capitalize(data?.aircraftTypeName)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyItems: "center",
                                pt: "20px",
                              }}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.5"
                                  stroke="#DADCE0"
                                />
                              </svg>
                              <Typography
                                sx={{
                                  pl: "10px",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  fontWeight: "400",
                                }}
                              >
                                {moment(data?.departureDateTime).format("h:mm")}
                                {bull}
                                {data?.departureAirport}, {data?.departureCity},{" "}
                                {capitalize(data?.departureCountry)} , (
                                <span> {data?.departureAirportCode}) </span>
                              </Typography>
                            </Box>
                          </Box>
                          {/*  transit time */}
                          <Box
                            sx={{
                              pl: "3px",
                              display: "flex",
                              alignItems: "center",
                              my: "3px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  pl: "10px",
                                  color: "#5F6368",
                                  fontSize: "13px",
                                }}
                              >
                                Travel time: {data?.durationInHours}{" "}
                              </Typography>
                            </Box>
                          </Box>
                          {/* aiirval airport  */}
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyItems: "center",
                              }}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.5"
                                  stroke="#DADCE0"
                                />
                              </svg>
                              <Typography
                                sx={{
                                  pl: "10px",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {moment(data?.arrivalDateTime).format(" h:mm")}
                                {bull}
                                {data?.arrivalAirport}, {data?.arrivalCity},{" "}
                                {capitalize(data?.arrivalCountry)},
                                <span>({data?.arrivalAirportCode})</span>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {/* refandable box  */}
                        <Grid item md={3}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0ZM9.35 12.75H7.65V11.05H9.35V12.75ZM9.35 9.35H7.65L7.225 4.25H9.775L9.35 9.35Z"
                                fill="#5F6368"
                              />
                            </svg>{" "}
                            <Typography sx={{ pl: "10px", fontSize: "13px" }}>
                              {singleQuedetails?.fare?.isRefundable === true
                                ? "Refandable"
                                : "Non-Refandable"}
                            </Typography>
                          </Box>
                          {/* economy box */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pt: "10px",
                            }}
                          >
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.7 13.3333V16H4.25V13.3333H12.75V16H15.3V10.6667H1.7V13.3333ZM14.45 6.22222H17V8.88889H14.45V6.22222ZM0 6.22222H2.55V8.88889H0V6.22222ZM12.75 8.88889H4.25V1.77778C4.25 1.30628 4.42911 0.854097 4.74792 0.520699C5.06673 0.187301 5.49913 0 5.95 0H11.05C11.5009 0 11.9333 0.187301 12.2521 0.520699C12.5709 0.854097 12.75 1.30628 12.75 1.77778V8.88889Z"
                                fill="#5F6368"
                              />
                            </svg>
                            <Typography sx={{ pl: "10px", fontSize: "13px" }}>
                              {capitalize(
                                singleQuedetails?.bookingInfo?.cabinTypeName
                              )}{" "}
                              | W
                            </Typography>
                          </Box>
                          {/* bags box */}
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              mt: 1,
                            }}
                          >
                            <svg
                              width="14"
                              height="20"
                              viewBox="0 0 14 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.5 16V7H3V16M7.75 16V7H6.25V16M11 16V7H9.5V16M12.03 4C13.11 4 14 4.88 14 6V17C14 18.13 13.11 19 12.03 19C12.03 19.58 11.56 20 11 20C10.5 20 10 19.58 10 19H4C4 19.58 3.5 20 3 20C2.44 20 1.97 19.58 1.97 19C0.89 19 0 18.13 0 17V6C0 4.88 0.89 4 1.97 4H4V1C4 0.42 4.46 0 5 0H9C9.54 0 10 0.42 10 1V4M5.5 1.5V4H8.5V1.5M12.03 17V6H1.97V17"
                                fill="#5F6368"
                              />
                            </svg>
                            <Typography
                              sx={{
                                display: "flex",
                                gap: "2px",
                                fontSize: "13px",
                                pl: "10px",
                              }}
                            >
                              Baggage{" "}
                            </Typography>
                            {singleQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType.map(
                              (item, index) =>
                                index === 0 && (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                      pl: "2px",
                                    }}
                                  >
                                    {" "}
                                    {/* Add a key prop for each mapped element */}
                                    {singleQuedetails?.bookingInfo?.priceBreakDown.map(
                                      (pax, index) => (
                                        <Box
                                          sx={{ display: "flex", gap: "5px" }}
                                          key={index}
                                        >
                                          {pax.paxType === "ADT" && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                Adult
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item?.bagInfo?.cabin?.map(
                                                  (cab, cabIndex) => (
                                                    <span
                                                      key={cabIndex}
                                                      sx={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {cab.forAdult === 1 ? (
                                                        <>
                                                          {cab.totalWeightInKilograms ||
                                                            cab.maximumWeightInKilograms}
                                                          kg
                                                        </>
                                                      ) : null}
                                                    </span>
                                                  )
                                                )}
                                              </Typography>
                                            </Box>
                                          )}

                                          {pax.paxType === "CNN" && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                Child
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item?.bagInfo?.cabin.map(
                                                  (cab, cabIndex) => (
                                                    <span
                                                      key={cabIndex}
                                                      sx={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {cab.forChild === 1 ? (
                                                        <>
                                                          {cab.totalWeightInKilograms ||
                                                            cab.maximumWeightInKilograms}
                                                          kg
                                                        </>
                                                      ) : null}
                                                    </span>
                                                  )
                                                )}
                                              </Typography>
                                            </Box>
                                          )}

                                          {pax.paxType === "INF" && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                Infant
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item.bagInfo?.cabin?.map(
                                                  (cab, cabIndex) => (
                                                    <span
                                                      key={cabIndex}
                                                      sx={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {cab.forInfant === 1 ? (
                                                        <>
                                                          {cab.totalWeightInKilograms ||
                                                            cab.maximumWeightInKilograms}
                                                          kg
                                                        </>
                                                      ) : null}
                                                    </span>
                                                  )
                                                )}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                )
                            )}
                            {/* bagge modal*/}
                          </Box>
                          {/* cabin box */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pt: "10px",
                            }}
                          >
                            <svg
                              width="17"
                              height="14"
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.9915 12.7484L15.9094 4.3484C15.8743 4.07471 15.7311 3.82257 15.5071 3.64021C15.2831 3.45786 14.9941 3.35809 14.6954 3.36H12.1435C12.1435 2.46887 11.7598 1.61424 11.0768 0.984121C10.3937 0.353999 9.46731 0 8.50134 0C7.53537 0 6.60896 0.353999 5.92591 0.984121C5.24287 1.61424 4.85914 2.46887 4.85914 3.36H2.30429C2.00555 3.35809 1.71654 3.45786 1.49255 3.64021C1.26856 3.82257 1.12531 4.07471 1.09022 4.3484L0.00818072 12.7484C-0.0116483 12.9052 0.00466807 13.064 0.0560598 13.2146C0.107452 13.3652 0.192758 13.504 0.306386 13.622C0.420835 13.7405 0.561082 13.8354 0.717977 13.9006C0.874871 13.9657 1.04489 13.9996 1.21694 14H15.7797C15.9527 14.0004 16.1239 13.9669 16.2819 13.9017C16.4399 13.8365 16.5812 13.7412 16.6963 13.622C16.8094 13.5038 16.8941 13.3648 16.945 13.2143C16.9959 13.0637 17.0117 12.905 16.9915 12.7484ZM8.50134 1.12C9.14532 1.12 9.76293 1.356 10.2183 1.77608C10.6737 2.19616 10.9295 2.76591 10.9295 3.36H6.0732C6.0732 2.76591 6.32903 2.19616 6.78439 1.77608C7.23975 1.356 7.85736 1.12 8.50134 1.12Z"
                                fill="#5F6368"
                              />
                            </svg>
                            <Typography sx={{ pl: "10px", fontSize: "13px" }}>
                              Cabin{" "}
                              {singleQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType.map(
                                (item) => {
                                  return item?.bagInfo?.cabin?.map(
                                    (cab, cabIndex) => (
                                      <span
                                        key={cabIndex}
                                        sx={{
                                          color: "#5F6368",
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {cab?.forAdult &&
                                        cab?.forChild &&
                                        cab?.forInfant ? (
                                          <>
                                            {cab.totalWeightInKilograms +
                                              cab.maximumWeightInKilograms}{" "}
                                            kg
                                          </>
                                        ) : null}
                                      </span>
                                    )
                                  );
                                }
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Box
                          sx={{
                            pt: "30px",
                            pb: "30px",
                            width: "100%",
                            display: `${
                              i - (arr?.length - 1) ? "block" : "none"
                            }`,
                          }}
                        >
                          {i < arr?.length - 1 && (
                            <Box
                              mt={3}
                              sx={{
                                borderTop: "1px solid #DADCE0",
                                borderBottom: "1px solid #DADCE0",
                                py: 2,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "var(--text-medium)",
                                }}
                              >
                                Change Plane {arr[i + 1]?.departureCity},{" "}
                                {arr[i + 1]?.departureCountry} (
                                {arr[i]?.arrivalAirportCode}) Connecting time{" "}
                                {data?.transit}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        {/* change plane 1 time repet  */}
                      </Grid>
                    )
                  )}
                </Box>
                {/* Fare  details  */}
                <Box sx={{ pt: "30px" }}>
                  <Box
                    sx={{
                      px: "10px",
                      py: "5px",
                    }}
                  >
                    <Typography sx={{ fontSize: "22px" }}>
                      {" "}
                      Fare Details
                    </Typography>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        {/* table title row  */}
                        <TableRow>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Pax Type
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Pax Count
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Base Fare
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Tax
                          </TableCell>

                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Total Base Fare
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Total Tax
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Service Fee
                          </TableCell>
                          {/* <TableCell sx={{ fontWeight: "bold", fontSize: "13px" }}>
              Markup
            </TableCell> */}
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Sub Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* adult table row calcultaion  */}
                        {singleQuedetails?.bookingInfo?.priceBreakDown?.map(
                          (data) => (
                            <TableRow>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                {data?.paxType}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                {data?.passengerCount}
                              </TableCell>

                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT {commaNumber(parseInt(data?.baseFare))}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT {commaNumber(parseInt(data?.tax))}
                              </TableCell>

                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT{" "}
                                {commaNumber(
                                  parseInt(data?.totalBaseFareAmount)
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT {commaNumber(parseInt(data?.totalTax))}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT{" "}
                                {commaNumber(
                                  parseInt(data?.serviceFee).toFixed(2)
                                )}
                              </TableCell>
                              {/* <TableCell sx={{ color: "#5F6368", fontSize: "12px" }}>
                BDT{" "}
                {commaNumber(parseInt(data?.OtherCharges).toFixed(2))}
              </TableCell> */}
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT{" "}
                                {commaNumber(
                                  (parseInt(data?.baseFare) +
                                    parseInt(data?.tax) +
                                    parseInt(data?.serviceFee)) *
                                    parseInt(data?.passengerCount)
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                        <TableRow>
                          {/* total summary calculation  */}
                          <TableCell colSpan={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ color: "#5F6368", fontSize: "15px" }}
                              >
                                Fare Summary
                              </Typography>
                              <Box sx={{ width: "50%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Grand Total or Customer Total
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    BDT{" "}
                                    {
                                      singleQuedetails?.bookingInfo
                                        ?.flightDetailsAndPrices
                                        ?.bookingTotalPrices?.clientPrice
                                    }
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "12px",
                                    pt: "7px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Discount{" "}
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    {" "}
                                    BDT{" "}
                                    {commaNumber(
                                      singleQuedetails?.bookingInfo
                                        ?.flightDetailsAndPrices
                                        ?.bookingTotalPrices?.clientPrice -
                                        singleQuedetails?.bookingInfo
                                          ?.flightDetailsAndPrices
                                          ?.bookingTotalPrices?.agentPrice
                                    )}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pt: "7px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    User Payable
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    BDT{" "}
                                    {
                                      singleQuedetails?.bookingInfo
                                        ?.flightDetailsAndPrices
                                        ?.bookingTotalPrices?.agentPrice
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* passanger Details  */}
                <Box sx={{ py: "10px" }}>
                  <Box
                    sx={{
                      px: "10px",
                      py: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "500", fontSize: "22px" }}>
                      {" "}
                      Passenger Details
                    </Typography>
                  </Box>

                  <Box>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          {/* table title row  */}
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Passenger ID
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Title
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              First Name
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Last Name
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Nationality
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              DOB
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Gender
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              PAX
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Passport Number
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Expire Date
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Passport
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Visa
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Ticked Number
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Passenger information */}
                          {singleQuedetails?.passengers?.map((data) => (
                            <TableRow>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.paxId}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#5F6368",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.prefix}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#5F6368",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.firstName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#5F6368",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.lastName}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.passNation}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.dob}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.gender}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.type}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.passNo}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.passEx}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                Comming
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                Comming
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                Comming
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sx={{ bgcolor: "#FFFFFF", borderRadius: "5px" }}>
          <Grid container>
            <Grid item md={12}>
              <Box sx={{ mt: "50px", width: "100%" }}>
                <Typography
                  sx={{
                    py: "10px",
                    fontSize: "20px",
                    color: "var(--secondary-color)",
                    px: "10px",
                  }}
                >
                  {" "}
                  PNR History
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      borderLeft: "5px solid #D9D9D9",

                      position: "relative",
                      height: "350px",
                      mx: "25px",
                      mt: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        bgcolor: "#DC143C",
                        position: "absolute",
                        left: "-15px",
                        right: "30px",
                        top: "-10px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        bgcolor: "#DC143C",
                        position: "absolute",
                        left: "-15px",
                        top: "120px",
                        right: "30px",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        bgcolor: "#DC143C",
                        position: "absolute",
                        left: "-15px",
                        bottom: "80px",
                        right: "30px",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "55px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: "16px", color: "#003566" }}>
                          Ticketed
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "8px",
                            color: "var(--primary-button-color)",
                            borderBottom:
                              "1px solid var(--primary-button-color)",
                          }}
                        >
                          View Ticketed Details
                        </Typography>
                      </Box>

                      <Box sx={{ pt: "10px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#DC143C",
                          }}
                        >
                          Fly Far International
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "400",
                            color: "#767676",
                          }}
                        >
                          22 Dec 2023
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "16px", color: "#003566" }}>
                        Issue Requested
                      </Typography>
                      <Box sx={{ pt: "10px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#DC143C",
                          }}
                        >
                          Syed Afridi, Zinga Lala Travel
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "400",
                            color: "#767676",
                          }}
                        >
                          22 Dec 2023
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "16px", color: "#003566" }}>
                        Hold
                      </Typography>
                      <Box sx={{ mt: "10px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#DC143C",
                          }}
                        >
                          Fly Far International
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "400",
                            color: "#767676",
                          }}
                        >
                          22 Dec 2023
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box sx={{ mt: "50px", px: "10px" }}>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "5px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Change Information
                  </Typography>
                  <Button
                    sx={{
                      width: "100%",
                      background: "var(--savStaff-color)",
                      ":hover": {
                        background: "var(--savStaff-color)",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Modify Information
                  </Button>
                </Box>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "10px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Add Extra
                  </Typography>
                  <Button
                    sx={{
                      width: "100%",
                      background: "var(--savStaff-color)",
                      ":hover": {
                        background: "var(--savStaff-color)",
                      },
                      borderRadius: "5px",
                      color: "var(--thard-color)",
                      fontSize: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    Add Extra Service
                  </Button>
                </Box>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "10px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Ticket Confirmation
                  </Typography>

                  {singleQuedetails?.bookingInfo?.status ===
                    "Issue Request" && (
                    <>
                      <Button
                        onClick={() =>
                          navigate(`/trips/makeTicketed`, {
                            state: { singleQuedetails },
                          })
                        }
                        sx={{
                          width: "100%",
                          background: "var(--savStaff-color)",
                          ":hover": {
                            background: "var(--savStaff-color)",
                          },
                          borderRadius: "5px",
                          color: "var(--thard-color)",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        Make Issue Ticketed
                      </Button>

                      <Button
                        sx={{
                          width: "100%",
                          mt: 2,
                          background: "red",
                          ":hover": {
                            background: "var(--savStaff-color)",
                          },
                          borderRadius: "5px",
                          color: "var(--thard-color)",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        Cancel Issue Ticketed
                      </Button>
                    </>
                  )}

                  {singleQuedetails?.bookingInfo?.status === "Hold" && (
                    <Button
                      sx={{
                        mt: "10px",
                        width: "100%",
                        background: "var(--primary-button-color)",
                        ":hover": {
                          background: "var(--primary-button-color)",
                        },
                        borderRadius: "5px",
                        color: "var(--thard-color)",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                    >
                      Cancel Issue Reject
                    </Button>
                  )}

                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueDetails;
