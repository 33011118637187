import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import secureLocalStorage from "react-secure-storage";
import PendingPayment from "../../FlyFarTrips/Payment/AllPayment.js/PendingPayment";
import ApprovePayment from "../../FlyFarTrips/Payment/AllPayment.js/ApprovePayment";
import RejectPayment from "../../FlyFarTrips/Payment/AllPayment.js/RejectPayment";
import AllPayment from "../../FlyFarTrips/Payment/AllPayment.js/AllPayment";

const QuickDeposite = () => {
  const token = secureLocalStorage.getItem("token");
  const [value, setValue] = useState("pending");
  const [allDeposit, setAllDeposit] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setIsLoading] = useState(true);

  let url =
    "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/deposit";
  useEffect(() => {
    setIsLoading(true);
    if (url) {
      fetch(url, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAllDeposit(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [url]);

  console.log(allDeposit);
  return (
    <Box>
      <Box sx={{ padding: "7px" }}>
        <Typography sx={{ fontSize: "26px", pb: "20px" }}>
          All Deposit List
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ display: "flex" }}>
              {/* Tab bar */}
              <Box
                style={{
                  backgroundColor: "var(--thard-color)",
                  borderRadius: "30px",
                  width: "100%",
                }}
              >
                <TabList
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="white"
                  aria-label="home-tab"
                >
                  <Tab
                    style={{ height: "30px" }}
                    label="PENDING"
                    value={"pending"}
                    sx={{
                      color: "var(--primary-text)",
                      opacity: "1",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "5px",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "20px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",

                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  <Tab
                    label="APPROVED"
                    value={"approved"}
                    style={{
                      height: "30px",
                      marginLeft: "3px",
                    }}
                    sx={{
                      opacity: "1",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "5px",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "10px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />

                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="REJECTED"
                    value={"rejected"}
                    sx={{
                      color: "var(--primary-text)",
                      opacity: "1",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "12px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  {/* <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="BANK DEPOSIT"
                    value={"bank"}
                    sx={{
                      color: "var(--primary-text)",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      opacity: "1",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />
                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="CARD DEPOSIT"
                    value={"card"}
                    sx={{
                      color: "var(--primary-text)",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      opacity: "1",
                      width: {
                        lg: "100",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  /> */}

                  <Tab
                    style={{ marginLeft: "5px", height: "30px" }}
                    label="All"
                    value={"all"}
                    sx={{
                      color: "var(--primary-text)",
                      borderRadius: "5px",
                      backgroundColor: "var(--primary-color)",
                      opacity: "1",
                      width: {
                        lg: "60px",
                        xs: "fit-content",
                      },
                      minHeight: "25px",
                      margin: {
                        xs: "0px 0px",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        backgroundColor: "var(--active-bg-color)",
                        color: "var(--thard-color)",
                        borderRadius: "5px",
                        opacity: "1",
                      },
                      fontSize: { xs: "8px", sm: "10px", md: "11px" },
                      textTransform: "Capitalize",
                    }}
                  />
                </TabList>
              </Box>
            </Box>
          </TabContext>
        </Box>
        {isLoading ? (
          <Box>{/* <Loader /> */} Loading.............</Box>
        ) : (
          <Box>
            <div>
              {value === "pending" ? (
                <Box>
                  <PendingPayment />
                </Box>
              ) : value === "approved" ? (
                <Box>
                  <ApprovePayment />
                </Box>
              ) : value === "rejected" ? (
                <Box>
                  <RejectPayment />
                </Box>
              ) : value === "bank" ? (
                <Box>
                  <TableContainer sx={{ mt: "40px" }}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                        <TableRow>
                          <TableCell className="table-cell">
                            <Button
                              sx={{
                                bgcolor: "var(--primary-color)",
                                color: "var(--primary-text)",
                                fontSize: "11px",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "var(--primary-text)",
                                },
                              }}
                            >
                              Reffrence
                            </Button>
                          </TableCell>
                          <TableCell className="table-cell">
                            Agent Name
                          </TableCell>
                          <TableCell className="table-cell">Status</TableCell>
                          <TableCell className="table-cell">Email</TableCell>
                          <TableCell className="table-cell">
                            Phone Number
                          </TableCell>
                          <TableCell className="table-cell">
                            Company Name
                          </TableCell>
                          <TableCell className="table-cell">Join At</TableCell>
                          <TableCell className="table-cell">
                            Bonus Wallet
                          </TableCell>
                          <TableCell className="table-cell">
                            Credit Wallet
                          </TableCell>
                          <TableCell className="table-cell">
                            Balance Wallet
                          </TableCell>
                          <TableCell className="table-cell">
                            Statistics
                          </TableCell>
                          <TableCell className="table-cell">
                            Operation
                          </TableCell>
                          <TableCell className="table-cell">Contact</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ "& td, & th": {} }}>
                        {/* {data.map((data) => (
                          <TableRow
                            key={data.id}
                            sx={{
                              width: "100%",
                              ":hover": {
                                backgroundColor: "#DDDDDD",
                                cursor: "pointer",
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className="table-cell"
                            >
                              <Button
                                sx={{
                                  bgcolor: "var(--primary-color)",
                                  color: "var(--primary-text)",
                                  fontSize: "11px",
                                  textTransform: "capitalize",
                                  ":hover": {
                                    backgroundColor: "var(--primary-text)",
                                  },
                                }}
                              >
                                {data.agentId}
                              </Button>
                            </TableCell>

                            <TableCell className="table-cell">
                              {data.firstName} {data.lastName}
                            </TableCell>
                            <TableCell className="table-cell">
                              {data.status}
                            </TableCell>
                            <TableCell className="table-cell">
                              {data.email}
                            </TableCell>
                            <TableCell className="table-cell">
                              {data.phone}
                            </TableCell>
                            <TableCell className="table-cell">
                              {data.company}
                            </TableCell>
                            <TableCell className="table-cell">
                              {data.joinAt}
                            </TableCell>
                            <TableCell className="table-cell">0</TableCell>
                            <TableCell className="table-cell">0</TableCell>
                            <TableCell className="table-cell">0</TableCell>
                            <TableCell className="table-cell">0</TableCell>
                            <TableCell className="table-cell">
                              {<CheckCircleOutlineIcon />}
                              {
                                <ClearIcon
                                  onClick={() => handleRemoveAgent(data.id)}
                                />
                              }
                            </TableCell>
                            <TableCell className="table-cell">0</TableCell>
                          </TableRow>
                        ))} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : value === "card" ? (
                <Box>
                  <TableContainer sx={{ mt: "40px" }}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                        <TableRow>
                          <TableCell className="table-cell">
                            <Button
                              sx={{
                                bgcolor: "var(--primary-color)",
                                color: "var(--primary-text)",
                                fontSize: "11px",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "var(--primary-text)",
                                },
                              }}
                            >
                              Reffrence
                            </Button>
                          </TableCell>
                          <TableCell className="table-cell">
                            Agent Name
                          </TableCell>
                          <TableCell className="table-cell">Status</TableCell>
                          <TableCell className="table-cell">Email</TableCell>
                          <TableCell className="table-cell">
                            Phone Number
                          </TableCell>
                          <TableCell className="table-cell">
                            Company Name
                          </TableCell>
                          <TableCell className="table-cell">Join At</TableCell>
                          <TableCell className="table-cell">
                            Bonus Wallet
                          </TableCell>
                          <TableCell className="table-cell">
                            Credit Wallet
                          </TableCell>
                          <TableCell className="table-cell">
                            Balance Wallet
                          </TableCell>
                          <TableCell className="table-cell">
                            Statistics
                          </TableCell>
                          <TableCell className="table-cell">
                            Operation
                          </TableCell>
                          <TableCell className="table-cell">Contact</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ "& td, & th": {} }}>
                        <TableRow
                          sx={{
                            height: "20px",
                            ":hover": {
                              backgroundColor: "#DDDDDD",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Button
                              sx={{
                                bgcolor: "var(--primary-color)",
                                color: "var(--primary-text)",
                                fontSize: "11px",
                                textTransform: "capitalize",
                                ":hover": {
                                  backgroundColor: "var(--primary-text)",
                                },
                              }}
                            >
                              FFTRB14525
                            </Button>
                          </TableCell>

                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                          <TableCell>0</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : value === "all" ? (
                <Box>
                  <AllPayment />
                </Box>
              ) : (
                <p>selected data no found</p>
              )}
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuickDeposite;
