import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../image/dashboardIcons/logo.svg";
import { Divider, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, NavLink, Outlet, useMatches } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 200;

const TripsDashboard = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dashboard, setDashboard] = React.useState(false);
  const [agent, setAgent] = React.useState(false);
  const [manage, setManage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box
      sx={{
        backgroundColor: "#457FA2",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        py: "20px",
      }}
    >
      <Box>
        <Box>
          <img src={logo} width="120px"  />
        </Box>

        <Box sx={{ mt: "50px" }}>
          <NavLink to="/tripsDashboard" style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px", color: "#F1592A" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Dashboard</Typography>
            </Box>
          </NavLink>
          <Link
            onClick={(event) => handleListItemClick(event, 2)}
            // to="/dashboard/agentList"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <AccountCircleIcon
                sx={{ height: "20px", color: "#F1592A" }}
              />{" "}
              <Typography sx={{ fontSize: "15px" }}>Agent</Typography>
            </Box>
          </Link>
          {/* {selectedIndex === 2 && agent && (
            <Box
              sx={{
                display: open === false ? "none" : "",
                a: {
                  display: "block",
                  textDecoration: "none",
                  color: "var( --primary-color)",
                  paddingLeft: "30px",
                  marginBottom: 0.2,
                  fontWeight: 600,
                },
              }}
            >
              <Box sx={{ opacity: open ? 1 : 0 }}>
          
                <NavLink to=" ">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "var(--white)",
                    }}
                  >
                    Group Fare
                  </Typography>
                </NavLink>

          
                <NavLink to="/admintour">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "var(--white)",
                    }}
                  >
                    Tour Package
                  </Typography>
                </NavLink>

              
                <NavLink to="/adminservice">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "var(--white)",
                    }}
                  >
                    Other Service
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          )} */}
          <Link
            // to="/dashboard/agentDetails"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px", color: "#F1592A" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Payment</Typography>
            </Box>
          </Link>
          <Link style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px", color: "#F1592A" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Booking</Typography>
            </Box>
          </Link>
          <Link style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <HomeRepairServiceIcon
                sx={{ height: "20px", color: "#F1592A" }}
              />{" "}
              <Typography sx={{ fontSize: "15px" }}>Services</Typography>
            </Box>
          </Link>
          <Link
            onClick={(event) => handleListItemClick(event, 5)}
            // to="allEmployee"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <SettingsIcon sx={{ height: "20px", color: "#F1592A" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Manage</Typography>
            </Box>
          </Link>
          {/* {selectedIndex === 5 && manage && (
            <Box
              sx={{
                display: open === false ? "none" : "",
                a: {
                  display: "block",
                  textDecoration: "none",
                  color: "var( --primary-color)",
                  paddingLeft: "30px",
                  marginBottom: 0.2,
                  fontWeight: 600,
                },
              }}
            >
              <Box sx={{ opacity: open ? 1 : 0 }}>
            
                <NavLink to="staffAdd">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "var(--white)",
                    }}
                  >
                    Add Staff Update
                  </Typography>
                </NavLink>

              
                <NavLink to="staffupdated">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "var(--white)",
                    }}
                  >
                    Staff Updated
                  </Typography>
                </NavLink>

              
                <NavLink to="/adminservice">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: 12,
                      py: 1,
                      px: 1,
                      fontWeight: 500,
                      color: "var(--white)",
                    }}
                  >
                    Other Service
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          )} */}
          <Link style={{ textDecoration: "none", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                color: "var(--dasbord-sidebar--color)",
                alignItems: "center",
              }}
              gap={1}
            >
              {" "}
              <DashboardIcon sx={{ height: "20px", color: "#F1592A" }} />{" "}
              <Typography sx={{ fontSize: "15px" }}>Ledger</Typography>
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );

  const handleListItemClick = (event, index) => {
    console.log(index);

    setSelectedIndex(index);
    if (index === 1) {
      setDashboard(!dashboard);
      setAgent(false);
      setManage(false);
    } else if (index === 2) {
      setDashboard(false);
      setAgent(!agent);
      setManage(false);
    } else if (index === 3) {
      setOpen(true);
      setAgent(false);
      setManage(false);
    } else if (index === 4) {
      setDashboard(false);
      setAgent(false);
      setManage(false);
    } else if (index === 5) {
      setDashboard(false);
      setAgent(false);
      setManage(!manage);
    } else if (index === 6) {
      setDashboard(false);
      setAgent(false);
      setManage(false);
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#192030",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          p: 3,
        }}
      >
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};

export default TripsDashboard;
