import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import ReissueQueDetails from "./ReissueQueDetails";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const QuickQueDetails = () => {
  const location = useLocation();
  const additionalId = location?.state?.additionalId;
  const agentId = new URLSearchParams(location.search).get("agentId");
  const [isCancelReuestOpen, setIsCancelReuestOpen] = useState(false);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  const { id } = useParams();
  const token = secureLocalStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [singleQuedetails, setSingleQuedetails] = useState([]);
  const [bookingQuedetails, setBookingQuedetails] = useState([]);
  const [loginDilog, setLoginDilog] = useState(false);
  const [issueRemarks, setIssueRemarks] = useState("");
  const handleLoginClick = () => {
    setLoginDilog(true);
  };

  const handleCloseDialog = () => {
    setLoginDilog(false);
  };

  // cancel issue reject dailog and function
  const handleButtonClickIssueReject = () => {
    // Open the dialog
    setIsCancelReuestOpen(true);
  };
  const handleClickIssueRejectClose = () => {
    // Open the dialog
    setIsCancelReuestOpen(false);
  };

  // cancele issue rejected handler
  const handleIsuueRejected = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/approve_booking/${bookingQuedetails?.bookingInfo?.id}`;
      console.log(apiUrl);
      setIsCancelReuestOpen(false);
      const bodyData = {
        status: "Issue Rejected",
        remarks: issueRemarks,
      };
      console.log("bookingbody", bodyData);

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("response data", responseData);
      Swal.fire({
        icon: "success",
        text: responseData.message || "Request successful!",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error:", error.message);

      // Display error alert with dynamic message
      Swal.fire({
        icon: "error",
        text:
          error.message || "Failed to submit the request. Please try again.",
        confirmButtonText: "OK",
      });
    }
  };
  //   booking info
  let url = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/booking_log/${id}`;
  useEffect(() => {
    setLoading(true);
    if (url) {
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setSingleQuedetails(data.data[0]);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);
  //   booking agencydetails

  // let apiurl = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/agent?id=${agentId}`;

  // useEffect(() => {
  //   setLoading(true);
  //   if (apiurl) {
  //     fetch(apiurl, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! Status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         setAgentQuedetails(data?.data[0]);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [id]);

  //   booking QueDetails

  let apiurls = `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/agent_booking_history/${id}?id=${agentId}`;

  useEffect(() => {
    setLoading(true);
    if (apiurls) {
      fetch(apiurls, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setBookingQuedetails(data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const capitalize = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s|-)\S/g, (match) => match.toUpperCase());
  };

  console.log("singleQuedetails", singleQuedetails);
  console.log("bookingQuedetails", bookingQuedetails);

  return (
    <Box sx={{ mt: "20px" }}>
      <Grid container>
        <Grid
          item
          md={10}
          sx={{
            borderRight: "12px solid #EFF2F5",
            bgcolor: "#FFFFFF",
            borderRadius: "5px",
            width: "100%",
            pr: "15px",
          }}
        >
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Booking Details {singleQuedetails?.bookingId}
              </Typography>
              <Box mt={2}>
                <TableContainer>
                  <Table size="small" aria-label="simple table">
                    <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                      <TableRow>
                        <TableCell className="table-cell">
                          <Button
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            Booking Id
                          </Button>
                        </TableCell>
                        <TableCell className="table-cell">Status</TableCell>
                        <TableCell className="table-cell">Route</TableCell>
                        <TableCell className="table-cell">System</TableCell>
                        <TableCell className="table-cell">Segment</TableCell>
                        <TableCell className="table-cell">
                          Airlines PNR
                        </TableCell>
                        <TableCell className="table-cell">GDS PNR</TableCell>
                        <TableCell className="table-cell">
                          Expire Timleimit
                        </TableCell>
                        <TableCell className="table-cell">
                          Ticket Type
                        </TableCell>
                        <TableCell className="table-cell">
                          Booking Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& td, & th": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell"
                        >
                          <Button
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            {singleQuedetails?.bookingId}
                          </Button>
                        </TableCell>

                        <TableCell className="table-cell">
                          {singleQuedetails?.status}
                        </TableCell>
                        <TableCell className="table-cell">{`${singleQuedetails?.deptFrom}-${singleQuedetails?.arriveTo}`}</TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.bookingInfo?.system
                            ? singleQuedetails?.bookingInfo?.system
                            : "System001"}
                        </TableCell>
                        <TableCell className="table-cell">Comming</TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.airlinesPNR}
                        </TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.pnr}
                        </TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.timeLimit}
                        </TableCell>
                        <TableCell className="table-cell">
                          {singleQuedetails?.journeyType}
                        </TableCell>
                        <TableCell className="table-cell">
                          {moment(singleQuedetails?.assignTime).format(
                            "DD MMM YYYY hh:mm A"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: "22px", color: "var(--secondary-color)" }}
              >
                Agency Details
              </Typography>
              <Box mt={2}>
                <TableContainer>
                  <Table size="small" aria-label="simple table">
                    <TableHead sx={{ borderTop: "1px solid #DDDDDD" }}>
                      <TableRow>
                        <TableCell className="table-cell">
                          <Button
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            Agent ID
                          </Button>
                        </TableCell>
                        <TableCell className="table-cell">
                          Agency Name
                        </TableCell>
                        <TableCell className="table-cell">
                          Last Balance
                        </TableCell>
                        <TableCell className="table-cell">
                          Credit Balance
                        </TableCell>
                        <TableCell className="table-cell">Dom Markup</TableCell>
                        <TableCell className="table-cell">
                          Intl Markup
                        </TableCell>
                        <TableCell className="table-cell">
                          Additional Markup
                        </TableCell>
                        <TableCell className="table-cell">Ledger</TableCell>
                        <TableCell className="table-cell">Action</TableCell>
                        <TableCell className="table-cell">Contact</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& td, & th": {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          ":hover": {
                            backgroundColor: "#DDDDDD",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="table-cell"
                        >
                          <Button
                            sx={{
                              // bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              ":hover": {
                                backgroundColor: "var(--primary-text)",
                              },
                            }}
                          >
                            {singleQuedetails?.agentID}
                          </Button>
                        </TableCell>
                        <TableCell className="table-cell">
                          {" "}
                          {singleQuedetails?.companyName}
                        </TableCell>
                        <TableCell className="table-cell">
                          BDT {singleQuedetails?.lastLedgerAmount}
                        </TableCell>
                        <TableCell className="table-cell">
                          BDT {singleQuedetails?.loanAmount}
                        </TableCell>
                        <TableCell className="table-cell">
                          BDT {singleQuedetails?.dMarkup}
                        </TableCell>
                        <TableCell className="table-cell">
                          BDT {singleQuedetails?.iMarkup}
                        </TableCell>
                        <TableCell className="table-cell">
                          BDT {singleQuedetails?.aMarkup}
                        </TableCell>
                        <TableCell className="table-cell">View</TableCell>
                        <TableCell className="table-cell">
                          {" "}
                          <Button
                            onClick={handleLoginClick}
                            sx={{
                              bgcolor: "var(--primary-color)",
                              color: "var(--primary-text)",
                              fontSize: "11px",
                              textTransform: "capitalize",
                              fontWeight: "600",
                              ":hover": {
                                backgroundColor: "var(--primary-colo)",
                              },
                            }}
                          >
                            LogIn
                          </Button>
                        </TableCell>
                        <TableCell className="table-cell">Details</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Dialog open={loginDilog} onClose={handleCloseDialog}>
                <DialogTitle sx={{ textAlign: "center", fontSize: "17px" }}>
                  Login Info
                </DialogTitle>
                <DialogContent>
                  <input type="email" style={{ border: "1px " }} />
                </DialogContent>
              </Dialog>
            </Grid>

            <Grid item md={12}>
              <Box
                sx={{
                  // backgroundColor: "var(--third-color)",
                  borderRadius: "10px",
                }}
              >
                {/*  Flight details */}
                <Box
                  sx={{
                    py: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "22px" }}>
                    {" "}
                    Flight Itenary Details
                  </Typography>
                </Box>

                {/*  Flight details */}
                <Box sx={{ pt: "10px" }}>
                  {bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.map(
                    (data, i, arr) => (
                      <Grid container>
                        <Grid item md={9}>
                          {/* departure airport  */}
                          <Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                height={"35px"}
                                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.airlineCode}.png`}
                                alt=""
                              />
                              <Typography
                                sx={{
                                  pl: "10px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  textTransfrom: "capitalize",
                                }}
                              >
                                {capitalize(data?.airlineName)} {bull}{" "}
                                {data?.airlineCode}{" "}
                                {data?.marketingFlightNumber} {bull}{" "}
                                {capitalize(data?.aircraftTypeName)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyItems: "center",
                                pt: "20px",
                              }}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.5"
                                  stroke="#DADCE0"
                                />
                              </svg>
                              <Typography
                                sx={{
                                  pl: "10px",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  fontWeight: "400",
                                }}
                              >
                                {moment(data?.departureDateTime).format("h:mm")}
                                {bull}
                                {data?.departureAirport},{" "}
                                {data?.departureTerminalName}, Gate:
                                {data?.departureGate}, {data?.departureCity},{" "}
                                {capitalize(data?.departureCountry)}, (
                                <span> {data?.departureAirportCode}) </span>
                              </Typography>
                            </Box>
                          </Box>
                          {/*  transit time */}
                          <Box
                            sx={{
                              pl: "3px",
                              display: "flex",
                              alignItems: "center",
                              my: "3px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50px",
                                  backgroundColor: "#DADCE0",
                                }}
                              ></div>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  pl: "10px",
                                  color: "#5F6368",
                                  fontSize: "13px",
                                }}
                              >
                                Travel time: {data?.durationInHours}{" "}
                              </Typography>
                            </Box>
                          </Box>
                          {/* aiirval airport  */}
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyItems: "center",
                              }}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.5"
                                  stroke="#DADCE0"
                                />
                              </svg>
                              <Typography
                                sx={{
                                  pl: "10px",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {moment(data?.arrivalDateTime).format(" h:mm")}
                                {bull}
                                {data?.arrivalAirport},
                                {data?.arrivalTerminalName}, Gate:
                                {data?.arrivalGate},{data?.arrivalCity},{" "}
                                {capitalize(data?.arrivalCountry)},
                                <span>({data?.arrivalAirportCode})</span>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {/* refandable box  */}
                        <Grid item md={3}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0ZM9.35 12.75H7.65V11.05H9.35V12.75ZM9.35 9.35H7.65L7.225 4.25H9.775L9.35 9.35Z"
                                fill="#5F6368"
                              />
                            </svg>{" "}
                            <Typography sx={{ pl: "10px", fontSize: "13px" }}>
                              {bookingQuedetails?.fare?.isRefundable === true
                                ? "Refandable"
                                : "Non-Refandable"}
                            </Typography>
                          </Box>
                          {/* economy box */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pt: "10px",
                            }}
                          >
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.7 13.3333V16H4.25V13.3333H12.75V16H15.3V10.6667H1.7V13.3333ZM14.45 6.22222H17V8.88889H14.45V6.22222ZM0 6.22222H2.55V8.88889H0V6.22222ZM12.75 8.88889H4.25V1.77778C4.25 1.30628 4.42911 0.854097 4.74792 0.520699C5.06673 0.187301 5.49913 0 5.95 0H11.05C11.5009 0 11.9333 0.187301 12.2521 0.520699C12.5709 0.854097 12.75 1.30628 12.75 1.77778V8.88889Z"
                                fill="#5F6368"
                              />
                            </svg>
                            <Typography sx={{ pl: "10px", fontSize: "13px" }}>
                              {capitalize(
                                bookingQuedetails?.bookingInfo?.cabinTypeName
                              )}{" "}
                              | W
                            </Typography>
                          </Box>
                          {/* bags box */}
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              pt: "10px",
                            }}
                          >
                            <svg
                              width="14"
                              height="20"
                              viewBox="0 0 14 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.5 16V7H3V16M7.75 16V7H6.25V16M11 16V7H9.5V16M12.03 4C13.11 4 14 4.88 14 6V17C14 18.13 13.11 19 12.03 19C12.03 19.58 11.56 20 11 20C10.5 20 10 19.58 10 19H4C4 19.58 3.5 20 3 20C2.44 20 1.97 19.58 1.97 19C0.89 19 0 18.13 0 17V6C0 4.88 0.89 4 1.97 4H4V1C4 0.42 4.46 0 5 0H9C9.54 0 10 0.42 10 1V4M5.5 1.5V4H8.5V1.5M12.03 17V6H1.97V17"
                                fill="#5F6368"
                              />
                            </svg>
                            <Typography
                              sx={{
                                display: "flex",
                                gap: "2px",
                                fontSize: "13px",
                                pl: "10px",
                              }}
                            >
                              Baggage{" "}
                            </Typography>
                            {bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType.map(
                              (item, index) =>
                                index === 0 && (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                      pl: "2px",
                                    }}
                                  >
                                    {" "}
                                    {/* Add a key prop for each mapped element */}
                                    {bookingQuedetails?.bookingInfo?.priceBreakDown.map(
                                      (pax, index) => (
                                        <Box
                                          sx={{ display: "flex", gap: "5px" }}
                                          key={index}
                                        >
                                          {pax.paxType === "ADT" && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                Adult
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item?.bagInfo?.cabin?.map(
                                                  (cab, cabIndex) => (
                                                    <span
                                                      key={cabIndex}
                                                      sx={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {cab.forAdult === 1 ? (
                                                        <>
                                                          {cab.totalWeightInKilograms ||
                                                            cab.maximumWeightInKilograms}
                                                          kg
                                                        </>
                                                      ) : null}
                                                    </span>
                                                  )
                                                )}
                                              </Typography>
                                            </Box>
                                          )}

                                          {pax.paxType === "CNN" && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                Child
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item?.bagInfo?.cabin.map(
                                                  (cab, cabIndex) => (
                                                    <span
                                                      key={cabIndex}
                                                      sx={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {cab.forChild === 1 ? (
                                                        <>
                                                          {cab.totalWeightInKilograms ||
                                                            cab.maximumWeightInKilograms}
                                                          kg
                                                        </>
                                                      ) : null}
                                                    </span>
                                                  )
                                                )}
                                              </Typography>
                                            </Box>
                                          )}

                                          {pax.paxType === "INF" && (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                Infant
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item.bagInfo?.cabin?.map(
                                                  (cab, cabIndex) => (
                                                    <span
                                                      key={cabIndex}
                                                      sx={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {cab.forInfant === 1 ? (
                                                        <>
                                                          {cab.totalWeightInKilograms ||
                                                            cab.maximumWeightInKilograms}
                                                          kg
                                                        </>
                                                      ) : null}
                                                    </span>
                                                  )
                                                )}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                )
                            )}
                          </Box>
                          {/* cabin box */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pt: "10px",
                            }}
                          >
                            <svg
                              width="17"
                              height="14"
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.9915 12.7484L15.9094 4.3484C15.8743 4.07471 15.7311 3.82257 15.5071 3.64021C15.2831 3.45786 14.9941 3.35809 14.6954 3.36H12.1435C12.1435 2.46887 11.7598 1.61424 11.0768 0.984121C10.3937 0.353999 9.46731 0 8.50134 0C7.53537 0 6.60896 0.353999 5.92591 0.984121C5.24287 1.61424 4.85914 2.46887 4.85914 3.36H2.30429C2.00555 3.35809 1.71654 3.45786 1.49255 3.64021C1.26856 3.82257 1.12531 4.07471 1.09022 4.3484L0.00818072 12.7484C-0.0116483 12.9052 0.00466807 13.064 0.0560598 13.2146C0.107452 13.3652 0.192758 13.504 0.306386 13.622C0.420835 13.7405 0.561082 13.8354 0.717977 13.9006C0.874871 13.9657 1.04489 13.9996 1.21694 14H15.7797C15.9527 14.0004 16.1239 13.9669 16.2819 13.9017C16.4399 13.8365 16.5812 13.7412 16.6963 13.622C16.8094 13.5038 16.8941 13.3648 16.945 13.2143C16.9959 13.0637 17.0117 12.905 16.9915 12.7484ZM8.50134 1.12C9.14532 1.12 9.76293 1.356 10.2183 1.77608C10.6737 2.19616 10.9295 2.76591 10.9295 3.36H6.0732C6.0732 2.76591 6.32903 2.19616 6.78439 1.77608C7.23975 1.356 7.85736 1.12 8.50134 1.12Z"
                                fill="#5F6368"
                              />
                            </svg>
                            <Typography sx={{ pl: "10px", fontSize: "13px" }}>
                              Cabin{" "}
                              {bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType.map(
                                (item) => {
                                  return item?.bagInfo?.cabin?.map(
                                    (cab, cabIndex) => (
                                      <span
                                        key={cabIndex}
                                        sx={{
                                          color: "#5F6368",
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {cab?.forAdult &&
                                        cab?.forChild &&
                                        cab?.forInfant ? (
                                          <>
                                            {cab.totalWeightInKilograms +
                                              cab.maximumWeightInKilograms}{" "}
                                            kg
                                          </>
                                        ) : null}
                                      </span>
                                    )
                                  );
                                }
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Box
                          sx={{
                            pt: "30px",
                            pb: "30px",
                            width: "100%",
                            display: `${
                              i - (arr?.length - 1) ? "block" : "none"
                            }`,
                          }}
                        >
                          {i < arr?.length - 1 && (
                            <Box
                              mt={3}
                              sx={{
                                borderTop: "1px solid #DADCE0",
                                borderBottom: "1px solid #DADCE0",
                                py: 2,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "var(--text-medium)",
                                }}
                              >
                                Change Plane {arr[i + 1]?.departureCity},{" "}
                                {arr[i + 1]?.departureCountry} (
                                {arr[i]?.arrivalAirportCode}) Connecting time{" "}
                                {data?.transit}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        {/* change plane 1 time repet  */}
                      </Grid>
                    )
                  )}
                </Box>
                {/* Fare  details  */}
                <Box sx={{ pt: "30px" }}>
                  <Box
                    sx={{
                      px: "10px",
                      py: "5px",
                    }}
                  >
                    <Typography sx={{ fontSize: "22px" }}>
                      {" "}
                      Fare Details
                    </Typography>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        {/* table title row  */}
                        <TableRow>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Pax Type
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Pax Count
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Base Fare
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Tax
                          </TableCell>

                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Total Base Fare
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Total Tax
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Service Fee
                          </TableCell>
                          {/* <TableCell sx={{ fontWeight: "bold", fontSize: "13px" }}>
          Markup
        </TableCell> */}
                          <TableCell
                            sx={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            Sub Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* adult table row calcultaion  */}
                        {bookingQuedetails?.bookingInfo?.priceBreakDown?.map(
                          (data) => (
                            <TableRow>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                {data?.paxType}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                {data?.passengerCount}
                              </TableCell>

                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT {commaNumber(parseInt(data?.baseFare))}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT {commaNumber(parseInt(data?.tax))}
                              </TableCell>

                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT{" "}
                                {commaNumber(
                                  parseInt(data?.totalBaseFareAmount)
                                )}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT {commaNumber(parseInt(data?.totalTax))}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT{" "}
                                {commaNumber(
                                  parseInt(data?.serviceFee).toFixed(2)
                                )}
                              </TableCell>
                              {/* <TableCell sx={{ color: "#5F6368", fontSize: "12px" }}>
            BDT{" "}
            {commaNumber(parseInt(data?.OtherCharges).toFixed(2))}
          </TableCell> */}
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "12px" }}
                              >
                                BDT{" "}
                                {commaNumber(
                                  (parseInt(data?.baseFare) +
                                    parseInt(data?.tax) +
                                    parseInt(data?.serviceFee)) *
                                    parseInt(data?.passengerCount)
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                        <TableRow>
                          {/* total summary calculation  */}
                          <TableCell colSpan={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ color: "#5F6368", fontSize: "15px" }}
                              >
                                Fare Summary
                              </Typography>
                              <Box sx={{ width: "50%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Grand Total or Customer Total
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    BDT{" "}
                                    {
                                      bookingQuedetails?.bookingInfo
                                        ?.flightDetailsAndPrices
                                        ?.bookingTotalPrices?.clientPrice
                                    }
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "12px",
                                    pt: "7px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Discount{" "}
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    {" "}
                                    BDT{" "}
                                    {commaNumber(
                                      bookingQuedetails?.bookingInfo
                                        ?.flightDetailsAndPrices
                                        ?.bookingTotalPrices?.clientPrice -
                                        bookingQuedetails?.bookingInfo
                                          ?.flightDetailsAndPrices
                                          ?.bookingTotalPrices?.agentPrice
                                    )}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pt: "7px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    User Payable
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    BDT{" "}
                                    {
                                      bookingQuedetails?.bookingInfo
                                        ?.flightDetailsAndPrices
                                        ?.bookingTotalPrices?.agentPrice
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* passanger Details  */}
                <Box sx={{ py: "10px" }}>
                  <Box
                    sx={{
                      px: "10px",
                      py: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "500", fontSize: "22px" }}>
                      {" "}
                      Passenger Details
                    </Typography>
                  </Box>

                  <Box>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          {/* table title row  */}
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Passenger ID
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Title
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              First Name
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Last Name
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Nationality
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              DOB
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Gender
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              PAX
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Passport Number
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Expire Date
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Passport
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Visa
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "400", fontSize: "10px" }}
                            >
                              Ticked Number
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Passenger information */}
                          {bookingQuedetails?.passengers?.map((data) => (
                            <TableRow>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.paxId}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#5F6368",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.prefix}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#5F6368",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.firstName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#5F6368",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data?.lastName}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.passNation}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.dob}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.gender}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.type}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.passNo}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.passEx}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={data?.passportCopy}
                                />
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={data?.visaCopy}
                                />
                              </TableCell>
                              <TableCell
                                sx={{ color: "#5F6368", fontSize: "10px" }}
                              >
                                {data?.ticketNumber}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sx={{ bgcolor: "#FFFFFF", borderRadius: "5px" }}>
          <Grid container>
            <Grid item md={12}>
              <Box sx={{ mt: "50px", px: "10px" }}>
                <Box sx={{ my: "20px" }}>
                  <Typography
                    sx={{
                      py: "10px",
                      fontSize: "15px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    {" "}
                    Ticket Confirmation
                  </Typography>
                  {singleQuedetails?.status === "hold" && (
                    <Box>
                      <Button
                        sx={{
                          mt: "10px",
                          width: "100%",
                          background: "var(--primary-button-color)",
                          ":hover": {
                            background: "var(--primary-button-color)",
                          },
                          borderRadius: "5px",
                          color: "var(--thard-color)",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {singleQuedetails?.status === "Hold" && (
                    <Box>
                      <Button
                        sx={{
                          mt: "10px",
                          width: "100%",
                          background: "var(--primary-button-color)",
                          ":hover": {
                            background: "var(--primary-button-color)",
                          },
                          borderRadius: "5px",
                          color: "var(--thard-color)",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {singleQuedetails?.status === "Issue Request" && (
                    <Box>
                      <Box>
                        <Button
                          onClick={() =>
                            navigate(`/quickDashboard/quemakdetails`, {
                              state: { singleQuedetails, bookingQuedetails },
                            })
                          }
                          sx={{
                            width: "100%",
                            background: "var(--savStaff-color)",
                            ":hover": {
                              background: "var(--savStaff-color)",
                            },
                            borderRadius: "5px",
                            color: "var(--thard-color)",
                            fontSize: "15px",
                            textTransform: "capitalize",
                          }}
                        >
                          Make Ticketed
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          onClick={handleButtonClickIssueReject}
                          sx={{
                            mt: "10px",
                            width: "100%",
                            background: "var(--primary-button-color)",
                            ":hover": {
                              background: "var(--primary-button-color)",
                            },
                            borderRadius: "5px",
                            color: "var(--thard-color)",
                            fontSize: "15px",
                            textTransform: "capitalize",
                          }}
                        >
                          Cancel Issue Reject
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {singleQuedetails?.status === "Reissue Request" && (
                    <Box>
                      <Button
                        onClick={() =>
                          navigate(`/quickDashboard/quereissue`, {
                            state: { singleQuedetails, bookingQuedetails },
                          })
                        }
                        sx={{
                          width: "100%",
                          background: "var(--savStaff-color)",
                          ":hover": {
                            background: "var(--savStaff-color)",
                          },
                          borderRadius: "5px",
                          color: "var(--thard-color)",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        Make Reissued
                      </Button>
                      <Button
                        // onClick={() =>
                        //   navigate(`/quickDashboard/quemakdetails`, {
                        //     state: { singleQuedetails },
                        //   })
                        // }
                        sx={{
                          width: "100%",
                          mt: "10px",
                          background: "var(--primary-button-color)",
                          ":hover": {
                            background: "var(--primary-button-color)",
                          },
                          borderRadius: "5px",
                          color: "var(--thard-color)",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        Cancel Reissue
                      </Button>
                    </Box>
                  )}

                  {singleQuedetails?.status === "Void Request" && (
                    <Button
                      onClick={() => {
                        navigate("/quickDashboard/quevoided", {
                          state: { additionalId },
                        });
                      }}
                      sx={{
                        mt: "10px",
                        width: "100%",
                        background: "var(--primary-button-color)",
                        ":hover": {
                          background: "var(--primary-button-color)",
                        },
                        borderRadius: "5px",
                        color: "var(--thard-color)",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                    >
                      Make Voided
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Cancel Issue Reject modal start */}
      <Box>
        <Dialog
          open={isCancelReuestOpen}
          onClose={handleClickIssueRejectClose}
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="xl"
          sx={{
            height: { xs: "100vh", sm: "100vh", md: "100vh" },
          }}
        >
          <Box sx={{ pt: "10px" }}>
            <Typography sx={{ textAlign: "center" }}>Issue Reject</Typography>

            <Box
              sx={{
                background: "#ffff",
                width: "450px",
                px: "20px",
                borderRadius: "5px",
                py: "15px",
              }}
            >
              <form onSubmit={handleIsuueRejected}>
                <label style={{ color: "grey" }}>Remarks</label>
                <Box sx={{ pt: "5px" }}>
                  <textarea
                    onChange={(e) => setIssueRemarks(e.target.value)}
                    style={{
                      height: "110px",
                      width: "100%",
                      borderRadius: "5px",
                      resize: "none",
                      outline: "none",
                      border: "none",
                      border: "1px solid var( --trips-secondary-color)",
                    }}
                  />
                </Box>

                <DialogActions>
                  <Button
                    type="submit"
                    // onClick={handleDepositReq}
                    sx={{
                      borderRadius: "6px",
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                      width: "100%",
                      height: "45px",
                      textTransform: "capitalize",
                      fontSize: "15px",
                      border: "1px solid var(--trips-secondary-color)",
                      mt: "20px",
                      ":hover": {
                        backgroundColor: "var(--trips-secondary-color)",
                        color: "white",
                      },
                    }}
                  >
                    {/* {status} rejected */}
                    Issue Rejected
                  </Button>
                </DialogActions>
              </form>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default QuickQueDetails;
