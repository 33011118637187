import { Box, Button } from "@mui/material";
import React, { useState } from "react";

const GroupFareReqiredInfo = () => {
  const [requiredInfo, setRequiredInfo] = useState([{ requiredInfoList: "" }]);

  const handleAddAnother = () => {
    setRequiredInfo([...requiredInfo, { requiredInfoList: "" }]);
  };

  const handleRemoveAtIndex = (index) => {
    const updatedData = [...requiredInfo];
    updatedData.splice(index, 1);
    setRequiredInfo(updatedData);
  };

  return (
    <Box>
      {requiredInfo.map((required, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #DADCE0",
              pb: "15px",
              mt: "30px",
              width: "95%",
            }}
          >
            <Box sx={{ width: "700px" }}>
              <label style={{ color: "#5F6368", fontSize: "12px" }}>
                Required Info {index + 1}
              </label>
            </Box>
            <input
              placeholder={`Required Info ${index + 1}`}
              style={{
                border: "none",
                backgroundColor: "white",
                outline: "none",
              }}
              name={`requiredInfo${index}`}
            />
          </Box>

          <Box sx={{ gap: "5px", display: "flex", mt: "25px" }}>
            <Button
              onClick={handleAddAnother}
              sx={{
                fontSize: "12px",
                textTransform: "capitalize",
                bgcolor: "#DADCE0",
                color: "var(--meta-black)",
                ":hover": {
                  bgcolor: "#DADCE0",
                  color: "var(--meta-black)",
                },
                height: "35px",
                whiteSpace: "nowrap",
              }}
            >
              Add Anather
            </Button>

            {index !== 0 && (
              <Button
                onClick={() => handleRemoveAtIndex(index)}
                sx={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                  bgcolor: "#DADCE0",
                  color: "var(--meta-black)",
                  ":hover": {
                    bgcolor: "#DADCE0",
                    color: "var(--meta-black)",
                  },
                  height: "35px",
                }}
              >
                Remove
              </Button>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default GroupFareReqiredInfo;
