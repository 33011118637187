import React, { useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableBody,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import SearchableDropDown from "./SearchableDropDown";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import SearchableDropDown from "../SearchableDropDown/SearchableDropDown";

const QuickMakeTicketed = () => {
  const tabcellText = {
    whiteSpace: "nowrap",
    fontSize: "11px",
    color: "#000",
  };
  const tabcellTexts = {
    whiteSpace: "nowrap",
    fontSize: "8px",
    color: "#000",
  };

  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");

  const token = secureLocalStorage.getItem("token");
  const { singleQuedetails, bookingQuedetails } = location?.state;

  const [allVendor, setAllVendor] = useState([]);
  const actionBy = singleQuedetails?.agentName;
  const [allPassengerInfo, setAllPassengerInfo] = useState([
    ...bookingQuedetails?.passengers,
  ]);
  const [formData, setFormData] = useState({
    airlinesPNR: singleQuedetails?.airlinesPNR || "",
    systemPNR: singleQuedetails?.pnr || "",
    system: singleQuedetails?.gds || "sabre",
    gdsSegment: "",
    ticketingClass: bookingQuedetails?.bookingInfo?.bookingClass || "",
    baseFare:
      bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.basePrice || "",
    ait: "",
    tax:
      bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.totalTax || "",
    currency: "BDT",
    clientInvoice:
      bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.bookingTotalPrices
        ?.clientPrice || "",
    vendorInvioce: "",
    lossProfit: "",
    explanation: "",
    ticketCoupon: "",
    remarks: "",
  });

  const handleInputChange = (e, id) => {
    const passengerIndex = allPassengerInfo.findIndex((pass) => pass.id === id);

    const updatedPassenger = { ...allPassengerInfo[passengerIndex] };

    updatedPassenger.ticketNumber = e.target.value;
    const updatedPassengerInfo = [...allPassengerInfo];
    updatedPassengerInfo[passengerIndex] = updatedPassenger;
    setAllPassengerInfo(updatedPassengerInfo);
  };
  console.log("bookingdatails", bookingQuedetails);
  // get vendor details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = secureLocalStorage.getItem("token");

        const response = await fetch(
          "https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/related_data/vendor",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setAllVendor(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const options = allVendor?.map((vendor) => {
    return {
      value: vendor,
      label: `${vendor?.name} | IATA:${vendor?.iataNumber} | PCC:${vendor?.pccNumber}`,
    };
  });

  // todo: handle AutoFill
  const handleAutoFill = (vendor) => {
    setFormData({
      ...formData,
      vendorName: vendor.name,
      itanumber: vendor.iataNumber,
      pccnumber: vendor.pccNumber,
    });
  };

  const handleQuickeMakeTickted = async (e) => {
    e.preventDefault();
    const passengersData = allPassengerInfo.map((passenger) => ({
      id: passenger.id,
      ticketNumber: passenger.ticketNumber || "",
    }));
    const body = {
      gds: formData.gdsSegment,
      airlinesPnr: formData.airlinesPNR,
      gdsPnr: formData.systemPNR,
      ticketClass: formData.ticketingClass,
      baseFare: formData.baseFare,
      tax: formData.tax,
      vendorName: formData.vendorName,
      iataNumber: formData.itanumber,
      pccNumber: formData.pccnumber,
      currency: formData.currency,
      clientInvoiceAmount: formData.clientInvoice,
      vendorInvoiceAmount: formData.vendorInvioce,
      lossProfitAmount: formData.clientInvoice - formData.vendorInvioce,
      clientInvoiceCommission: 0,
      vendorInvoiceCommission: 0,
      lossProfitCommission: 0,
      lossProfitExplanation: formData.explanation,
      remarks: formData.remarks,
      ticketCoupon: formData.ticketCoupon,
      passengers: passengersData,
    };
    console.log(body);
    try {
      const response = await fetch(
        `https://quickticketsb2b-nodejs.de.r.appspot.com/api/v1/super_admin/approve_booking/${bookingQuedetails?.bookingInfo?.id}`,

        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      console.log(response);
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(`Error: ${response.status} - ${errorResponse.message}`);
      }

      const successResponse = await response.json();

      toast.success(
        successResponse.message || "ticketed successful." || "Success",
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        }
      );

      setFormData({
        gdsSegment: "",
        airlinesPNR: "",
        systemPNR: "",
        ticketingClass: "",
        baseFare: "",
        tax: "",
        vendorName: "",
        itanumber: "",
        pccnumber: "",
        currency: "",
        clientInvoice: "",
        vendorInvioce: "",
        lossProfit: "",
        explanation: "",
        remarks: "",
        ticketCoupon: "",
      });
    } catch (error) {
      // Display error message using SweetAlert2 with dynamic content
      toast.error(error.message || "An error occurred", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      console.error("API Error:", error);
    }
  };

  // textCapitalize
  const capitalize = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s|-)\S/g, (match) => match.toUpperCase());
  };
  return (
    <Box sx={{ width: "100%", my: "20px" }}>
      <form onSubmit={handleQuickeMakeTickted}>
        <Grid container>
          <Grid
            item
            container
            md={10}
            sx={{
              borderRight: "10px solid #EFF2F5",
              bgcolor: "#FFFFFF",
              borderRadius: "5px",
              width: "100%",
              height: "100%",
              pr: "20px",
            }}
          >
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                    color: "var(--secondary-color)",
                  }}
                >
                  {singleQuedetails?.bookingId} | {capitalize(singleQuedetails?.tripType)} |{" "}
                  {`${singleQuedetails?.deptFrom}-${singleQuedetails?.arriveTo}`}{" "}
                  |
                  {bookingQuedetails?.bookingInfo?.flightDetailsAndPrices?.flightDetailsByType?.map(
                    (data) => (
                      <>
                        {" "}
                        {data?.marketingAirlineName}
                        {"  "} |{" "}
                        {moment(data?.departureDateTime).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </>
                    )
                  )}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Booking Details
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText} component="th" scope="row">
                        Booking Id
                      </TableCell>
                      <TableCell style={tabcellText}>Status</TableCell>
                      <TableCell style={tabcellText}>Route</TableCell>
                      <TableCell style={tabcellText}>System</TableCell>
                      <TableCell style={tabcellText}>Segment</TableCell>
                      <TableCell style={tabcellText}>Airlines PNR</TableCell>
                      <TableCell style={tabcellText}>GDS PNR</TableCell>
                      <TableCell style={tabcellText}>
                        Expire Timleimit
                      </TableCell>
                      <TableCell style={tabcellText}>Ticket Type</TableCell>
                      <TableCell style={tabcellText}>Booking Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    <TableRow
                      sx={{
                        height: "20px",
                        ":hover": {
                          backgroundColor: "#DDDDDD",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell style={tabcellText} component="th" scope="row">
                        <Button
                          sx={{
                            bgcolor: "var(--primary-color)",
                            color: "var(--primary-text)",
                            fontSize: "11px",
                            textTransform: "capitalize",
                            ":hover": {
                              backgroundColor: "var(--primary-text)",
                            },
                          }}
                        >
                          {singleQuedetails?.bookingId}
                        </Button>
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.status}
                      </TableCell>
                      <TableCell
                        style={tabcellText}
                      >{`${singleQuedetails?.deptFrom}-${singleQuedetails?.arriveTo}`}</TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.gds}
                      </TableCell>
                      <TableCell style={tabcellText}>Comming</TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.airlinesPNR}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.pnr}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {" "}
                        {singleQuedetails?.timeLimit}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {singleQuedetails?.journeyType}
                      </TableCell>
                      <TableCell style={tabcellText}>
                        {moment(singleQuedetails?.assignTime).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Ticket Details
              </Typography>

              <Grid container spacing={5}>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",
                        mt: "20px",
                        width: "100%",
                        color: "#A3A4A9",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ fontSize: "12px" }}>Airlines PNR</label>
                      </Box>

                      <input
                        name="airlinesPNR"
                        value={formData.airlinesPNR}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Enter The Airlines Pnr"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",
                        mt: "20px",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          System pnr
                        </label>
                      </Box>

                      <input
                        name="systemPNR"
                        value={formData.systemPNR}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Enter The System Pnr"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Selected system
                        </label>
                      </Box>

                      <select
                        name="system"
                        value={formData.system}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        style={{
                          width: "45%",
                          outline: "none",
                          border: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          color: "#A3A4A9",
                        }}
                      >
                        {/* Add your options here */}
                        <option value="sabre">System Sabre</option>
                        <option value="gallleo">System Gallleo</option>

                        {/* Add more options as needed */}
                      </select>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          GDS Segment
                        </label>
                      </Box>

                      <input
                        name="gdsSegment"
                        value={formData.gdsSegment}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Enter The Gds Segment"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Ticketing Class
                        </label>
                      </Box>

                      <input
                        name="ticketingClass"
                        value={formData.ticketingClass}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder=" Enter The Ticketing Class"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Vendor Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Box sx={{ mt: "10px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                        Selected Vendor
                      </label>

                      <Box sx={{ mt: "10px" }}>
                        <SearchableDropDown
                          handler={handleAutoFill}
                          options={options}
                          placeholder="Select Vendor..."
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",
                        width: "100%",
                        color: "#A3A4A9",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ fontSize: "12px" }}>Base Fare</label>
                      </Box>

                      <input
                        name="baseFare"
                        value={formData.baseFare}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          if (typeof value === "number" || !isNaN(value)) {
                            setFormData({
                              ...formData,
                              [name]: value,
                            });
                          }
                        }}
                        placeholder="Enter the Base Fare"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Tax
                        </label>
                      </Box>

                      <input
                        name="tax"
                        value={formData.tax}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          if (typeof value === "number" || !isNaN(value)) {
                            setFormData({
                              ...formData,
                              [name]: value,
                            });
                          }
                        }}
                        placeholder="Enter The Tax"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          AIT
                        </label>
                      </Box>

                      <input
                        name="ait"
                        value={formData.ait}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Enter The AIT"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Vendor Name
                        </label>
                      </Box>

                      <input
                        name="vendorName"
                        value={formData.vendorName}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder=" Enter The Vendor Name"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          IATA Number
                        </label>
                      </Box>

                      <input
                        name="itanumber"
                        value={formData.itanumber}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Enter The IATA Number"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          PCC Number
                        </label>
                      </Box>

                      <input
                        name="pccnumber"
                        value={formData.pccnumber}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Enter The PCC Number"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Currency
                        </label>
                      </Box>

                      <input
                        name="currency"
                        value={formData.currency}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="BDT"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Invoice Details
              </Typography>

              <Grid container spacing={5} sx={{ mt: "5px" }}>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                        color: "#A3A4A9",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ fontSize: "12px" }}>
                          Client Invoice Amount
                        </label>
                      </Box>

                      <input
                        name="clientInvoice"
                        value={formData.clientInvoice}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          if (typeof value === "number" || !isNaN(value)) {
                            setFormData({
                              ...formData,
                              [name]: value,
                            });
                          }
                        }}
                        placeholder="Client Invoice Amount"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Vendor Invioce Amount
                        </label>
                      </Box>

                      <input
                        name="vendorInvioce"
                        value={formData.vendorInvioce}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          if (typeof value === "number" || !isNaN(value)) {
                            setFormData({
                              ...formData,
                              [name]: value,
                            });
                          }
                        }}
                        placeholder="Vendor Invioce Amount"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={6}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "300px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Loss/Profit Amount
                        </label>
                      </Box>

                      <input
                        name="lossProfit"
                        value={formData.clientInvoice - formData.vendorInvioce}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          if (typeof value === "number" || !isNaN(value)) {
                            setFormData({
                              ...formData,
                              [name]: value,
                            });
                          }
                        }}
                        placeholder="Loss/Profit Amount"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Passenger Details
              </Typography>
              <TableContainer sx={{ mt: "10px" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  style={{ width: "100%" }}
                >
                  <TableHead
                    sx={{ borderTop: "1px solid #DDDDDD" }}
                    style={{ width: "100%" }}
                  >
                    <TableRow>
                      <TableCell style={tabcellText}>Title</TableCell>
                      <TableCell style={tabcellText}>First Name</TableCell>
                      <TableCell style={tabcellText}>Last Name</TableCell>
                      <TableCell style={tabcellText}>Nationality</TableCell>
                      <TableCell style={tabcellText}>DOB</TableCell>
                      <TableCell style={tabcellText}>Gender</TableCell>
                      <TableCell style={tabcellText}>Pax</TableCell>
                      <TableCell style={tabcellText}>Passport Number</TableCell>
                      <TableCell style={tabcellText}>Expiry Date</TableCell>
                      <TableCell style={tabcellText}>Ticket Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ "& td, & th": {} }}>
                    {/* Passenger information */}
                    {allPassengerInfo?.map((data) => (
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.prefix}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.firstName}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.lastName}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passNation}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.dob}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.gender}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.type}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passNo}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          {data?.passEx}
                        </TableCell>
                        <TableCell sx={{ color: "#5F6368", fontSize: "10px" }}>
                          <input
                            name="ticketNumber"
                            value={data.ticketNumber || ""}
                            onChange={(e) => handleInputChange(e, data.id)}
                            style={{
                              outline: "none",
                              border: "1px solid grey",
                              borderRadius: "4px",
                              width: "90px",
                              height: "25px",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "5px",
                mt: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  mt: "10px",
                  color: "var(--secondary-color)",
                }}
              >
                Operation Details Written By {actionBy}
              </Typography>
              <Grid container sx={{ my: "20px" }} spacing={5}>
                <Grid item md={12}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "700px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Loss Profit Explanation
                        </label>
                      </Box>

                      <textarea
                        name="explanation"
                        value={formData.explanation}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Paste your ticket Coupon..."
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                          resize: "none",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #A3A4A9",
                        pb: "20px",

                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "700px" }}>
                        <label style={{ color: "#A3A4A9", fontSize: "12px" }}>
                          Remarks...
                        </label>
                      </Box>

                      <textarea
                        name="remarks"
                        value={formData.remarks}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData({
                            ...formData,
                            [name]: value,
                          });
                        }}
                        placeholder="Remarks..."
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          outline: "none",
                          resize: "none",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "#A3A4A9",
                      }}
                      type="checkbox"
                      checked={formData.agree}
                      onChange={(e) => {
                        const { name, type, checked, value } = e.target;
                        setFormData({
                          ...formData,
                          [name]: type === "checkbox" ? checked : value,
                        });
                      }}
                    />{" "}
                    <Typography
                      sx={{ color: "green", fontWeight: "500", pl: "5px" }}
                    >
                      {formData?.clientInvoice - formData?.vendorInvioce <= 0
                        ? `I, ${actionBy}, have accepted the loss amount of BDT
                  ${
                    Math?.abs(
                      formData?.clientInvoice - formData?.vendorInvioce
                    ) || 0
                  } and I am going to deliver
                  the ticket.`
                        : `I, ${actionBy}, have accepted the profit amount of BDT
                  ${
                    Math?.abs(
                      formData?.clientInvoice - formData?.vendorInvioce
                    ) || 0
                  } and I am going to deliver
                  the ticket.`}
                    </Typography>
                  </Box>
                  <Box sx={{ py: "10px" }}>
                    <Button
                      // disabled={!formData?.agree}
                      type="submit"
                      sx={{
                        textTransform: "capitalize",
                        bgcolor: "green",
                        color: "var(--thard-color)",
                        width: "100px",
                        ":hover": {
                          bgcolor: "green",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={2}
            sx={{ bgcolor: "#FFFFFF", borderRadius: "5px", px: "20px" }}
          >
            <Box sx={{ height: "96.5%" }}>
              <label> Ticket Coupon</label>
              <textarea
                name="ticketCoupon"
                value={formData.ticketCoupon}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setFormData({
                    ...formData,
                    [name]: value,
                  });
                }}
                placeholder="Paste Your ticket Coupon..."
                style={{
                  width: "100%",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  height: "500px",
                  resize: "none",
                  outline: "none",
                  marginTop: "3px",
                  height: "100%",
                  borderRadius: "5px",
                  border: "none",
                  border: "1px solid  #A3A4A9",
                }}
              ></textarea>
            </Box>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default QuickMakeTicketed;
